import React, { useState, useEffect } from "react";

import styled from "styled-components";

function Input(props) {
  return (
    <>
      <InputWrapper
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
        onKeyDown={props.onKeyDown}
        maxLength={props.maxLength}
        disabled={props.disabled}
        onClick={props.onClick}
      />
    </>
  );
}

export default Input;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  box-sizing: border-box;

  height: 44px;
  padding: 16px 12px;

  background: #fafafa;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  color: #191919;
  border: 0px;
  border-bottom: 1px solid #adb4ba;

  &::placeholder {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    color: #adb4ba;
  }

  &:focus {
    background: #fff;
    outline: 1px solid #00aeef;
  }

  &:disabled {
    outline: none;
    background: #adb4ba;
    color: #778088;
  }
`;
