import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { Radio } from "../../atoms/radio/radio";
import PretendardText from "../../atoms/text/pretendardText";

function RightLabelRadio({
  text,
  size,
  checked,
  inputStyle,
  onClick,
  textStyle,
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          ...inputStyle,
        }}
      >
        <Radio checked={checked} onChange={onClick} size={size} />

        <StyledP
          onClick={onClick}
          style={{
            ...textStyle,
          }}
        >
          {text}
        </StyledP>
      </div>
    </>
  );
}

export default RightLabelRadio;

const StyledP = styled(PretendardText)`
  color: #474747;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  margin-left: 5px;
  flex: 1;
`;
