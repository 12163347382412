import styled, { css, keyframes } from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import { rnd } from "../../../../lang/renewal/performanceLang";
import { useEffect, useRef, useState } from "react";
import { root } from "../../../../styles/GlobalStyle";
import DetailPages from "./nationalRnD/DetailPages";
import { useBodyScrollLock } from "../../../../hooks/useBodyScrollLock";

const ListItem = ({
  isDesktop,
  isMobile,
  onClick,
  bgColor,
  logo,
  title,
  desc,
  img = "",
}) => {
  const imgRef = useRef(null);

  return (
    <Container className="custom-cursor">
      {!isDesktop ? (
        <LinkItemMobile onClick={onClick} $bgColor={bgColor}>
          <img src={logo} alt="" />
          <LabelMobile $isMobile={isMobile}>{title}</LabelMobile>
          <LabelMobile $isMobile={isMobile}>{desc}</LabelMobile>
          {/* <Arrow $bgColor={bgColor}>&#8594;</Arrow> */}
          <p></p>
        </LinkItemMobile>
      ) : (
        <>
          <LinkItem onClick={onClick} $bgColor={bgColor}>
            <Label style={{ width: "260px" }}>
              <img
                style={{ height: "100%", width: "auto" }}
                src={logo}
                alt=""
              />
            </Label>
            <Label style={{ width: "280px" }}>{title}</Label>
            <Label style={{ width: "510px" }}>{desc}</Label>
            {/* <Arrow $bgColor={bgColor}>&#8594;</Arrow> */}
            {img && <ImgIcon src="/assets/icons/img.png" alt="" />}
          </LinkItem>
          <Image src={img} alt="" ref={imgRef} />
        </>
      )}
    </Container>
  );
};

const RnD = ({ isMobile, isTablet, isDesktop, langValue, onClick }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false); // Offcanvas 상태
  const [selectedMenu, setSelectedMenu] = useState(""); // 선택된 메뉴
  const [isBgVisible, setIsBgVisible] = useState(false); // 배경 레이어 상태
  const detailPagesRef = useRef(null); // DetailPages 컴포넌트 ref

  const { lockScroll, openScroll } = useBodyScrollLock(); // 스크롤 잠금 훅

  const [data, setData] = useState(rnd(isMobile)[langValue]);

  //widow 브라우저 뒤로가기 클릭 시 offcanvas 닫기 이벤트
  useEffect(() => {
    const handlePopState = () => {
      if (isOffcanvasOpen) {
        setIsOffcanvasOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isOffcanvasOpen]);

  useEffect(() => {
    setData(rnd(isMobile)[langValue]);
  }, [langValue, isMobile]);

  const handleOpenOffcanvas = (desc) => {
    setIsOffcanvasOpen(true);
    window.history.pushState({ offcanvas: "open" }, "");
    setSelectedMenu(desc);
    setIsBgVisible(true);
    lockScroll();
  };

  const handleCloseOffcanvas = () => {
    setIsOffcanvasOpen(false);
    window.history.back();
    setIsBgVisible(false);
    openScroll();
    if (detailPagesRef.current) {
      const contentsWrapper = detailPagesRef.current.querySelector(
        '[class^="Layout__ContentsWrapper"]'
      );
      if (contentsWrapper) {
        contentsWrapper.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <PageWrapper>
        <SectionWrapper $isMobile={isMobile}>
          <MainTitle $isDesktop={isDesktop}>{data.title}</MainTitle>
          <Description>{data.desc}</Description>
        </SectionWrapper>
        <SectionWrapper
          $isMobile={isMobile}
          style={{ flexDirection: "column" }}
        >
          <List>
            {data.contents.map((item, idx) => (
              <ListItem
                key={idx}
                isDesktop={isDesktop}
                isMobile={isMobile}
                onClick={() => handleOpenOffcanvas(item.desc)}
                bgColor={item.bgColor}
                {...item}
              />
            ))}
          </List>
        </SectionWrapper>
      </PageWrapper>
      {/* <OffcanvasWrapper $isOpen={isOffcanvasOpen} $isDesktop={isDesktop}>
        {isDesktop && (
          <OffcanvasBg
            $isVisible={isBgVisible}
            $isOpen={isOffcanvasOpen}
            $bgColor={root.navy}
            onClick={handleCloseOffcanvas}
          />
        )}
        <DetailPages
          ref={detailPagesRef}
          selectedMenu={selectedMenu}
          isMobile={isMobile}
        />
        <CloseButton onClick={handleCloseOffcanvas}>{`←`}</CloseButton>
      </OffcanvasWrapper> */}
    </>
  );
};

export default RnD;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
  gap: 4.7rem;
`;

const MainTitle = styled(PretendardText)`
  font-size: 4.8rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Description = styled.p`
  font-size: 2rem;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
`;

// ListItem
const Container = styled.div`
  position: relative;
`;

const List = styled.div`
  border-top: 1px solid #000;
`;

const LinkItemMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  border-bottom: 1px solid #000;
  transition: 0.4s;
  color: ${({ $bgColor }) => $bgColor};
  gap: 1rem;
`;

const LinkItem = styled(LinkItemMobile)`
  transition: color 0s, box-shadow 0.4s ease;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5rem;

  ${Container}:hover & {
    background: transparent;
    box-shadow: ${({ $bgColor }) => `inset 0 125px 0 0 ${$bgColor}`};
  }
`;

const LabelMobile = styled.span`
  font-size: ${({ $isMobile }) => ($isMobile ? "2rem" : "2.2rem")};
  font-weight: 400;
  padding-left: 10px;
  transition: 0.5s;
`;

const Label = styled(LabelMobile)`
  ${Container}:hover & {
    transform: translateX(20px);
  }
`;

const ImgIcon = styled.img`
  position: absolute;
  right: 7rem;

  transition: 0.5s;

  ${Container}:hover & {
    transform: translateX(20px);
  }
`;

const Arrow = styled.span`
  font-size: 7rem;
  font-weight: 400;
  transform: rotate(-45deg);

  transition: 0.5s;

  ${Container}:hover & {
    transform: translateX(-20px);
  }
`;

// Offcanvas
const OffcanvasBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  opacity: ${(props) => (props.$isOpen ? "1" : "0")};
  transition: opacity 1s ease;
  visibility: ${(props) => (props.$isVisible ? "show" : "hidden")};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const OffcanvasWrapper = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.$isOpen ? "0" : "-100%")};
  width: ${({ $isDesktop }) => ($isDesktop ? "88%" : "100%")};
  height: 100%;
  transition: right 1.2s;
  z-index: 1000;
  /* overflow: ${({ $isDesktop }) => ($isDesktop ? "" : "scroll")}; */
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${fadeIn} 1s ease
        `
      : css`
          ${fadeOut} 1s ease
        `};
`;

const CloseButton = styled.button`
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  background: none;
  border: none;
  font-size: 24px;
`;

const Image = styled.img`
  max-height: 200px;
  max-width: 300px;
  min-height: 300px;
  min-width: auto;
  width: auto; /* 가로 길이 자동 조정 */
  height: auto; /* 세로 길이 자동 조정 */
  // 이미지 비율 유지
  object-fit: contain;

  position: absolute;
  top: -100px;
  right: 0rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;

  ${Container}:hover & {
    opacity: 1;
  }
`;
