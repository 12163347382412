import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { forwardRef, useRef } from "react";
import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";
import { useNavigate } from "react-router-dom";
import {
  device,
  deviceExplanation,
  DOCKDO_BG,
} from "../../../../lang/renewal/homeLang";

const itemGap = 10;

const ScrollAnimationComponent = forwardRef(({ langValue }, ref) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const navigation = useNavigate();

  const leftContentRef = useRef(null);
  const rightContentRef = useRef(null);
  const contentContainerRef = useRef(null);
  const contentMarkerRefs = useRef([]);
  const bgImageRef = useRef(null);
  const textContainerRef = useRef(null);
  const textRefs = useRef([]);

  gsap.registerPlugin(ScrollTrigger);

  useGSAP(() => {
    gsap.defaults({ overwrite: "auto" });

    if (leftContentRef.current) {
      gsap.set(leftContentRef.current.children, {
        xPercent: -50,
        yPercent: -50,
      });
    }

    const contentMarkers = [
      ...gsap.utils.toArray(contentMarkerRefs.current),
      textContainerRef.current,
    ];

    // Content animations
    contentMarkers.forEach((marker, index) => {
      const markerContent = marker.dataset.markerContent;

      marker.content = markerContent;

      // device content focus scroll event
      ScrollTrigger.create({
        trigger: marker,
        start: "top center",
        end: `bottom center`,
        onEnter: () => {
          if (Number(marker.content) === device[langValue].length) {
            gsap.to(marker, { autoAlpha: 1, duration: 0.3 });
            contentMarkers.forEach((m, i) => {
              if (i !== index) {
                gsap.to(m, { autoAlpha: 0, duration: 0.3 });
              }
            });

            gsap.to(textContainerRef.current, { autoAlpha: 1, duration: 0.3 });

            // 글자 나타나기 효과
            gsap.from(textRefs.current, {
              y: 50,
              opacity: 0,
              stagger: 0.2,
              duration: 1.5,
              ease: "power2.out",
            });
          } else {
            gsap.to(marker, { autoAlpha: 1, duration: 0.3 });
            gsap.to(bgImageRef.current, {
              backgroundImage: `url(${device[langValue][marker.content].bg})`,
              duration: 0.5,
            });
          }
        },
        onLeave: () => {
          if (Number(marker.content) === device[langValue].length) {
            gsap.to(marker, { autoAlpha: 1, duration: 0.3 });
          } else {
            gsap.to(marker, { autoAlpha: 0.5, duration: 0.3 });
          }
        },
        onEnterBack: () => {
          if (Number(marker.content) === device[langValue].length) {
            gsap.to(marker, { autoAlpha: 1, duration: 0.3 });
          } else {
            gsap.to(marker, { autoAlpha: 1, duration: 0.3 });
            gsap.to(bgImageRef.current, {
              backgroundImage: `url(${device[langValue][marker.content].bg})`,
              duration: 0.5,
            });
          }
        },
        onLeaveBack: () => {
          if (Number(marker.content) === device[langValue].length) {
            gsap.to(marker, { autoAlpha: 0, duration: 0.3 });
            contentMarkers.forEach((m, i) => {
              if (i !== index) {
                gsap.to(m, { autoAlpha: 0.5, duration: 0.3 });
              }
            });
          } else {
            gsap.to(marker, { autoAlpha: 0.5, duration: 0.3 });
          }
        },
      });
    });
  }, []);

  const goDeviceDetail = (link) => {
    navigation(link);
  };

  return (
    <ContentContainer
      ref={(el) => {
        contentContainerRef.current = el;
        ref(el);
      }}
    >
      <BgImage ref={bgImageRef} />
      <RightContent ref={rightContentRef} $gap={itemGap} $isDesktop={isDesktop}>
        <Wrapper $gap={itemGap} $isDesktop={isDesktop}>
          <Center $gap={itemGap} $isDesktop={isDesktop}>
            {device[langValue].map((item, index) => (
              <Box
                key={index}
                className="contentMarker custom-cursor"
                data-marker-content={index}
                ref={(el) => (contentMarkerRefs.current[index] = el)}
                $isDesktop={isDesktop}
                $isTablet={isTablet}
                onClick={() => goDeviceDetail(item.link)}
              >
                <ImageContainer $isMobile={isMobile}>
                  <Image src={item.image} alt={item.title} loading="lazy" />
                </ImageContainer>
                <Title
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  $isMobile={isMobile}
                />
                <Arrow>&#8594;</Arrow>
              </Box>
            ))}
          </Center>
          <FloatSection>
            <TextContainer
              $isDesktop={isDesktop}
              key={device[langValue].length}
              ref={textContainerRef}
              data-marker-content={device[langValue].length}
              onClick={() => navigation(`/products#${device.link}`)}
              className="custom-cursor"
            >
              <H2 ref={(el) => (textRefs.current[0] = el)}>
                {deviceExplanation[langValue].title}
              </H2>
              <H3 ref={(el) => (textRefs.current[1] = el)}>
                {deviceExplanation[langValue].subTitle}
              </H3>

              {isMobile || isTablet ? (
                <div ref={(el) => (textRefs.current[2] = el)}>
                  <P>{deviceExplanation[langValue].description[0]}</P>
                  <P>{deviceExplanation[langValue].description[1]}</P>
                </div>
              ) : (
                <P ref={(el) => (textRefs.current[2] = el)}>
                  {deviceExplanation[langValue].description}
                </P>
              )}
            </TextContainer>
          </FloatSection>
        </Wrapper>
      </RightContent>
    </ContentContainer>
  );
});

export default ScrollAnimationComponent;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;

  & p {
    font-size: 2rem;
  }

  & .header-section {
    text-align: center;
    padding: 1rem 6rem;
  }
`;

const BgImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  transition: background-image 0.5s ease-in-out;
  background-image: url(${DOCKDO_BG});
  background-color: black;
`;

const RightContent = styled.div`
  width: 100%;
  position: relative;
  padding: ${(props) => (!props.$isDesktop ? "45vh 0" : "25% 0 ")};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isDesktop }) => ($isDesktop ? "flex-end" : "center")};
  gap: ${(props) => `${props.$gap}rem`};
`;

const Center = styled.div`
  width: ${({ $isDesktop }) => ($isDesktop ? "100%" : "fit-content")};
  display: flex;
  flex-direction: column;
  align-items: ${({ $isDesktop }) => ($isDesktop ? "flex-end" : "center")};
  gap: ${(props) => `${props.$gap}rem`};
`;

const Box = styled.div`
  display: flex;
  justify-content: "flex-end";
  align-items: center;
  gap: 1.5rem;
  opacity: 0.5;
  width: ${(props) => (props.$isDesktop ? "50%" : "100%")};
  padding: 2rem;
`;

const ImageContainer = styled.div`
  max-width: ${({ $isMobile }) => ($isMobile ? "14rem" : "18rem")};
  min-width: ${({ $isMobile }) => ($isMobile ? "14rem" : "18rem")};
  height: ${({ $isMobile }) => ($isMobile ? "14rem" : "18rem")};
  border-radius: 50%;
  background-color: #ffffffa1;
  img {
    width: 100%;
    height: 100%;
  }
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Image = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

const Title = styled.h4`
  font-size: ${({ $isMobile }) => ($isMobile ? "2.2em" : "2.5em")};
  text-align: left;
`;

const FloatSection = styled.div`
  height: 180vh;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
`;
const TextContainer = styled.div`
  position: sticky;
  top: 35vh;
  max-width: 1400px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (!props.$isDesktop ? "center" : "flex-end")};
  gap: 4.5rem;
  opacity: 0; // 초기에는 보이지 않게 설정
  padding-right: ${(props) => (!props.$isDesktop ? "0" : "0")};
`;

const H2 = styled.h2`
  font-size: 5.1em;
  text-align: center;
`;

const H3 = styled.h3`
  font-size: 2.5em;
  font-weight: 400;
  text-align: center;
`;

const P = styled.p`
  font-size: 2em;
  text-align: center;
`;

const Arrow = styled.span`
  font-size: 2.5em;
  font-weight: 700;
  color: white;
  transition: 0.5s;
  line-height: 1;

  ${Box}:hover & {
    transform: translateX(20px);
  }
`;
