import api from "./api";

export default class InquiryApi {
  static async GetKR() {
    return api.get(`inquiry-form/kr`);
  }

  static async GetEN() {
    return api.get(`inquiry-form/en`);
  }

  static async Submit(data) {
    return api.post(`inquiry`, data);
  }
}
