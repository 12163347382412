import ProductApi from "../api/product";
import { menus } from "../constant/menu";

export const getProduct = async (id) => {
  try {
    const response = (await ProductApi.GetById(id)).data.data;

    return response;
  } catch (error) {
    console.log(error);
  }
};

// export const getProducts = async (langValue) => {
//   const hideId = [];
//   let menu2 = { ...menus[1] };

//   try {
//     const response = (
//       await ProductApi.Get({ pageSize: 15, language: langValue })
//     ).data.data.items;

//     response.map((item) => {
//       if (!item.exposure) {
//         hideId.push(item.id);
//       }
//     });
//   } catch (error) {
//     console.log(error);
//   }

//   function removeById(menu, hideId) {
//     if (Array.isArray(menu)) {
//       return menu
//         .filter((item) => !item.id?.some((id) => hideId.includes(id)))
//         .map((item) => ({
//           ...item,
//           subMenus3: removeById(item.subMenus3, hideId),
//           subMenus4: removeById(item.subMenus4, hideId),
//         }));
//     }
//     return menu;
//   }

//   hideId.map(() => {
//     menu2.subMenus2 = removeById(menu2.subMenus2, hideId);
//   });

//   const changedMenus = [...menus];
//   changedMenus[1].subMenus2 = menu2.subMenus2;

//   return changedMenus;
// };

// export const getProducts2 = async (langValue) => {
//   // const hideId = [];
//   // let menu2 = { ...menus[1] };

//   // try {
//   //   const response = (
//   //     await ProductApi.Get({ pageSize: 15, language: langValue })
//   //   ).data.data.items;

//   //   response.forEach((item) => {
//   //     if (!item.exposure) {
//   //       hideId.push(item.id);
//   //     }
//   //   });
//   // } catch (error) {
//   //   console.log(error);
//   // }

//   // function updateExposureById(menu, hideId) {
//   //   if (Array.isArray(menu)) {
//   //     return menu.map((item) => {
//   //       if (item.id?.some((id) => hideId.includes(id))) {
//   //         return {
//   //           ...item,
//   //           exposure: false,
//   //           subMenus3: updateExposureById(item.subMenus3, hideId),
//   //           subMenus4: updateExposureById(item.subMenus4, hideId),
//   //         };
//   //       }
//   //       return {
//   //         ...item,
//   //         subMenus3: updateExposureById(item.subMenus3, hideId),
//   //         subMenus4: updateExposureById(item.subMenus4, hideId),
//   //       };
//   //     });
//   //   }
//   //   return menu;
//   // }

//   // menu2.subMenus2 = updateExposureById(menu2.subMenus2, hideId);

//   const changedMenus = [...menus];
//   // changedMenus[1].subMenus2 = menu2.subMenus2;

//   return changedMenus;
// };

//프로덕트 실험용
// export const getProducts = async (langValue) => {
//   let productArr = [];
//   let menu2 = { ...menus[1] };
//   let subMenu2 = [...menus[1].subMenus2];

//   try {
//     const response = (
//       await ProductApi.Get({ pageSize: 15, language: langValue })
//     ).data.data.items;

//     productArr = [...response];
//   } catch (error) {
//     console.log(error);
//   }

//   productArr.map((productItem) => {
//     //-----------서브메뉴2
//     subMenu2?.map((item2, index2) => {
//       if (item2.hasOwnProperty("id") && item2.id.includes(productItem.id)) {
//         const copyObj = {
//           ...item2,
//           title: productItem.pageName,
//           exposure: productItem.exposure,
//         };

//         subMenu2.splice(index2, 1, copyObj);
//       }

//       //-----------서브메뉴3
//       item2.subMenus3?.map((item3, index3) => {
//         if (item3.hasOwnProperty("id") && item3.id.includes(productItem.id)) {
//           const copyObj = {
//             ...item3,
//             title: productItem.pageName,
//             exposure: productItem.exposure,
//           };

//           subMenu2[index2].subMenus3.splice(index3, 1, copyObj);
//         }

//         //-----------서브메뉴4
//         item3.subMenus4?.map((item4, index4) => {
//           if (item4.hasOwnProperty("id") && item4.id.includes(productItem.id)) {
//             const copyObj = {
//               ...item4,
//               title: productItem.pageName,
//               exposure: productItem.exposure,
//             };

//             subMenu2[index2].subMenus3[index3].subMenus4.splice(
//               index4,
//               1,
//               copyObj
//             );
//           }
//         });
//       });
//     });
//   });

//   subMenu2.map((item2, index2) => {
//     item2.subMenus3?.map((item3, index3) => {
//       if (
//         item3.subMenus4.length > 0 &&
//         item3.subMenus4.every((subItem) => subItem.exposure === false)
//       ) {
//         const copyObj = {
//           ...item3,
//           exposure: false,
//         };

//         subMenu2[index2].subMenus3.splice(index3, 1, copyObj);
//       }
//     });
//   });

//   subMenu2.map((item2, index2) => {
//     if (item2.subMenus3.length > 0) {
//       const trueIndex = item2.subMenus3.findIndex(
//         (subItem) => subItem.exposure === true
//       );

//       const copyObj = {
//         ...item2,
//         link: item2.subMenus3[trueIndex]?.link,
//       };

//       subMenu2.splice(index2, 1, copyObj);

//       item2.subMenus3?.map((item3, index3) => {
//         if (item3.subMenus4.length > 0) {
//           const trueIndex = item3.subMenus4.findIndex(
//             (subItem) => subItem.exposure === true
//           );

//           const copyObj = {
//             ...item3,
//             link: item3.subMenus4[trueIndex]?.link,
//           };

//           subMenu2[index2].subMenus3.splice(index3, 1, copyObj);
//         }
//       });
//     }
//   });

//   const changedMenus = [...menus];
//   changedMenus[1].subMenus2 = subMenu2;

//   return changedMenus;
// };

export const getProducts = async (langValue) => {
  let productArr = [];
  let menu2 = { ...menus[1] };
  let subMenu2 = [...menus[1].subMenus2];

  try {
    // TODO: 오류 메시지로 임시로 주석처리함. 추후 수정 필요
    // const response = (
    //   await ProductApi.Get({ pageSize: 15, language: langValue })
    // ).data.data.items;
    // productArr = [...response];
  } catch (error) {
    console.log(error);
  }

  productArr.forEach((productItem) => {
    subMenu2 = subMenu2.map((item2) => {
      if (item2.hasOwnProperty("id") && item2.id.includes(productItem.id)) {
        return {
          ...item2,
          title: productItem.pageName,
          exposure: productItem.exposure,
        };
      }

      const updatedSubMenus3 =
        item2.subMenus3?.map((item3) => {
          if (item3.hasOwnProperty("id") && item3.id.includes(productItem.id)) {
            return {
              ...item3,
              title: productItem.pageName,
              exposure: productItem.exposure,
            };
          }

          const updatedSubMenus4 =
            item3.subMenus4?.map((item4) => {
              if (
                item4.hasOwnProperty("id") &&
                item4.id.includes(productItem.id)
              ) {
                return {
                  ...item4,
                  title: productItem.pageName,
                  exposure: productItem.exposure,
                };
              }
              return item4;
            }) || [];

          return {
            ...item3,
            subMenus4: updatedSubMenus4,
          };
        }) || [];

      return {
        ...item2,
        subMenus3: updatedSubMenus3,
      };
    });
  });

  subMenu2 = subMenu2.map((item2) => {
    const updatedSubMenus3 =
      item2.subMenus3?.map((item3) => {
        if (
          item3.subMenus4.length > 0 &&
          item3.subMenus4.every((subItem) => subItem.exposure === false)
        ) {
          return {
            ...item3,
            exposure: false,
          };
        }
        return item3;
      }) || [];
    return {
      ...item2,
      subMenus3: updatedSubMenus3,
    };
  });

  //submenu에서 exposure false일 때 link 바꾸기
  subMenu2 = subMenu2.map((item2) => {
    if (item2.subMenus3.length > 0) {
      const trueIndex = item2.subMenus3.findIndex(
        (subItem) => subItem.exposure === true
      );
      const updatedLink = item2.subMenus3[trueIndex]?.link;

      const updatedSubMenus3 = item2.subMenus3.map((item3) => {
        if (item3.subMenus4.length > 0) {
          const trueIndex = item3.subMenus4.findIndex(
            (subItem) => subItem.exposure === true
          );
          return {
            ...item3,
            link: item3.subMenus4[trueIndex]?.link,
          };
        }
        return item3;
      });

      return {
        ...item2,
        link: updatedLink,
        subMenus3: updatedSubMenus3,
      };
    }
    return item2;
  });

  subMenu2 = subMenu2.map((item2) => {
    if (item2.subMenus3.length > 0) {
      const trueIndex = item2.subMenus3.findIndex(
        (subItem) => subItem.exposure === true
      );
      const updatedLink = item2.subMenus3[trueIndex]?.link;

      const updatedSubMenus3 = item2.subMenus3.map((item3) => {
        if (item3.subMenus4.length > 0) {
          const trueIndex = item3.subMenus4.findIndex(
            (subItem) => subItem.exposure === true
          );
          return {
            ...item3,
            link: item3.subMenus4[trueIndex]?.link,
          };
        }
        return item3;
      });

      return {
        ...item2,
        link: updatedLink,
        subMenus3: updatedSubMenus3,
      };
    }
    return item2;
  });

  const changedMenus = [...menus];
  changedMenus[1] = {
    ...changedMenus[1],
    subMenus2: subMenu2,
  };

  return changedMenus;
};
