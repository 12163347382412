import React from "react";
import styled from "styled-components";
import ContentBox from "../../../components/templates/contentBox";
import PretendardText from "../../atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";

const TypeA = ({ title, content }) => {
  const { isMobile } = useDisplay();

  return (
    <ContentBox
      title={title}
      content={
        <ContentText $isMobile={isMobile}>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </ContentText>
      }
      style={{ marginTop: "40px" }}
    />
  );
};

export default TypeA;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;
