import React, { useState, useEffect } from "react";

import styled from "styled-components";
import PretendardText from "../../atoms/text/pretendardText";
import Checkbox from "../../atoms/checkbox/checkbox";
import useDisplay from "../../../hooks/useDisplay";

function RightLabelCheckbox({
  text,
  size,
  checked,
  onChange,
  inputStyle,
  textStyle,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          ...inputStyle,
        }}
      >
        <Checkbox onChange={onChange} checked={checked} size={size} />

        <StyledP
          onClick={onChange}
          style={{
            ...textStyle,
          }}
          $isMobile={isMobile}
        >
          {text}
        </StyledP>
      </div>
    </>
  );
}

export default RightLabelCheckbox;

const StyledP = styled(PretendardText)`
  color: #474747;
  font-weight: 400;
  font-size: ${(props) => (props.$isMobile ? "13px" : "14px")};

  flex: 1;
  margin-left: ${(props) => (props.$isMobile ? "5px" : "10px")};
`;
