import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useRecoilValue } from "recoil";

import PretendardText from "../../components/atoms/text/pretendardText";
import { langState } from "../../store/langState";
import { financialInfoLang } from "../../lang/investment/financialInfoLang";

function PreparedModal({ visiable, setVisiable, onClick, title, isScroll }) {
  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  const langValue = useRecoilValue(langState);

  return (
    <PreparedModalWrapper>
      <PreparedModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "16px",
              fontWeight: 600,
              textAlign: "center",
              marginBottom: 25,
            }}
          >
            {title ? title : financialInfoLang.prepared[langValue]}
          </PretendardText>
          <CheckBtn onClick={onClick}>
            <PretendardText
              style={{
                color: "#FFFFFF",
                fontSize: "15px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {langValue === "KR" ? "확인" : "Check"}
            </PretendardText>
          </CheckBtn>
        </div>
      </PreparedModalContainer>
    </PreparedModalWrapper>
  );
}

export default PreparedModal;

const PreparedModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const PreparedModalContainer = styled.div`
  width: 340px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;

const CheckBtn = styled.button`
  min-width: 122px;
  height: 44px;
  background: #00aeef;
  display: flex;
  justify-content: center;
  align-items: center;
`;
