import React, { useEffect, useState } from "react";

import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/text/pretendardText";

import FileForm from "../../atoms/fileForm/fileForm";
import CommonApi from "../../../api/commonApi";

const FileFormInput = ({ data, setList, list }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();

  let temp = list;

  const handleChange = async (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.fileName = file.name;

    fileReader.onload = async (data) => {
      setFile(file);
      setFileName(data.target.fileName);

      await fileUploadFunc(file, data.target.fileName);
    };

    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileName) => {
    try {
      const response = await CommonApi.FileUpload(file);

      let tempIndex = list.findIndex((item) => item.id === data.id);
      temp[tempIndex] = {
        ...data,
        value: { url: response.data.data, fileName: fileName },
      };

      setList(temp);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: 25,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              color: "#2F3941",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
            }}
          >
            {data.title}
          </PretendardText>
          {data.isRequired ? (
            <PretendardText
              style={{
                color: "#00AEEF",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "23.2px",
              }}
            >
              *
            </PretendardText>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FileForm handleChange={handleChange} />
          {fileName ? (
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  color: "#2F3941",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                {fileName}
              </PretendardText>
              <img
                src="/assets/icons/popup_close.png"
                alt="popup_close"
                style={{
                  cursor: "pointer",
                  width: 14,
                  height: 14,
                  marginLeft: 8,
                  marginTop: 2,
                }}
                onClick={() => {
                  setFile();
                  setFileName("");

                  let tempIndex = list.findIndex((item) => item.id === data.id);
                  temp[tempIndex] = {
                    ...data,
                    value: "",
                  };

                  setList(temp);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FileFormInput;
