import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useRecoilValue } from "recoil";

import PretendardText from "../../../components/atoms/text/pretendardText";
import BorderBottomInput from "../../../components/atoms/input/borderBottomInput";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import Pagination from "../../../components/organisms/pagination";
import NoticeCard from "../../../components/organisms/investment/noticeCard";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "./../../../components/templates/middleNavBar";

import useDisplay from "../../../hooks/useDisplay";
import NoticeCardM from "../../../components/organisms/investment/noticeCardM";
import NoticeApi from "../../../api/noticeApi";
import { route } from "../../../router/route";

import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { noticeLang } from "../../../lang/investment/noticeLang";

const Notice = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();
  const langValue = useRecoilValue(langState);

  const [keyword, setKeyword] = useState("");
  const [totalCtn, setTotalCtn] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 3, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getNoticeAPI();
  }, [location, langValue]);

  const getNoticeAPI = async () => {
    try {
      const data = {
        pageNo: page,
        pageSize: pagination.size,
        keyword: searchParams.get("keyword"),
        language: langValue,
      };

      const response = (await NoticeApi.GetList(data)).data.data;

      setTotalCtn(response.totalCount);
      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: response.totalPage,
        first: response.isFirst,
        last: response.isLast,
      });
      setNoticeList(response.items);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_notice.png"
            : "/assets/imgs/main-section/notice.png"
        }
        title={mainBannerLang.notice.title[langValue]}
        content={mainBannerLang.notice.content[langValue]}
        blackText={true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        {isMobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 48,
                position: "relative",
              }}
            >
              <BorderBottomInput
                type="text"
                placeholder="Search"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.notice}/1?keyword=${keyword}`);
                  }
                }}
              />
              <img
                onClick={() => {
                  navigate(`${route.notice}/1?keyword=${keyword}`);
                }}
                style={{
                  position: "absolute",
                  right: -6,
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                  height: 24,
                  width: 24,
                }}
                src="/assets/icons/search_02.svg"
              />
            </div>

            <PretendardText
              style={{
                color: "#778088",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18.9px",
                marginTop: 40,
              }}
            >
              {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
              {langValue === "KR" ? "건" : ""}
            </PretendardText>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "23.2px",
              }}
            >
              {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
              {langValue === "KR" ? "건" : ""}
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginLeft: 40,
                  width: 440,
                  height: 48,
                  position: "relative",
                }}
              >
                <BorderBottomInput
                  type="text"
                  placeholder="Search"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.notice}/1?keyword=${keyword}`);
                    }
                  }}
                />
                <img
                  onClick={() => {
                    navigate(`${route.notice}/1?keyword=${keyword}`);
                  }}
                  style={{
                    position: "absolute",
                    right: -6,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    height: 24,
                    width: 24,
                  }}
                  src="/assets/icons/search_02.svg"
                />
              </div>
            </div>
          </div>
        )}

        {noticeList.length > 0 ? (
          <>
            <div
              style={{
                borderTop: "2px solid #778088",
                borderBottom: "2px solid #778088",
                width: "100%",
                marginTop: isMobile ? 10 : 20,
              }}
            >
              {noticeList.map((item, index) => {
                return isSmall ? (
                  <NoticeCardM
                    key={index}
                    id={item.id}
                    order={index + 1 + (page - 1) * 10}
                    style={{
                      borderBottom:
                        noticeList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    title={item.title}
                    date={moment.utc(item.createdAt).format("YYYY.MM.DD")}
                  />
                ) : (
                  <NoticeCard
                    key={index}
                    id={item.id}
                    order={index + 1 + (page - 1) * 10}
                    style={{
                      borderBottom:
                        noticeList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    title={item.title}
                    date={moment.utc(item.createdAt).format("YYYY.MM.DD")}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: isSmall ? 40 : 60,
              }}
            >
              <Pagination
                route={route.notice}
                queryString={`keyword=${keyword}`}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </>
        ) : (
          <PretendardText
            style={{
              color: "#ADB4BA",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "21px",
              marginTop: 120,
              marginBottom: 60,
              textAlign: "center",
            }}
          >
            {searchParams.get("keyword") === "" ||
            searchParams.get("keyword") == null
              ? noticeLang.empty[langValue]
              : noticeLang.searchEmpty[langValue]}
          </PretendardText>
        )}
      </BodyConatin>
    </>
  );
};

export default Notice;
