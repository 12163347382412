export const xBandData = {
  id: 7,
  createdAt: "2024-04-19T14:17:20.352Z",
  updatedAt: "2024-05-24T14:59:01.240Z",
  pageName: "X-밴드 하향링크모듈",
  productName: "X-밴드 하향링크모듈",
  subtitle:
    "XDM-EGSE : X-Band Downlink Module Electrical Ground Support Equipment",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/8192dd00-5099-4de6-868c-6be24257910a.png",
  serverFileName: "8192dd00-5099-4de6-868c-6be24257910a.png",
  originFileName: "Group 1410116991.png",
  exposure: true,
  __productContents__: [
    {
      id: 88,
      createdAt: "2024-04-19T14:17:20.352Z",
      updatedAt: "2024-05-24T18:19:40.103Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>X-밴드 하향링크모듈(XDM: X-band Downlink Module) 및 XDM 구성 장치의 테스트를 수행하고,</p><p>통합 테스트에서 XDM 출력 RF 수신 및 해석을 수행하는 지상지원장비입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 89,
      createdAt: "2024-04-19T14:17:20.352Z",
      updatedAt: "2024-05-24T18:19:40.149Z",
      title: "img1",
      orderNum: 2,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 8,
          createdAt: "2024-05-24T18:19:40.223Z",
          updatedAt: "2024-05-24T18:19:40.223Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/cd07ec25-3b55-4645-80b3-51218229fdb2.png",
          serverFileName: "cd07ec25-3b55-4645-80b3-51218229fdb2.png",
          originFileName: "image 522.png",
        },
      ],
    },
    {
      id: 90,
      createdAt: "2024-04-19T14:17:20.352Z",
      updatedAt: "2024-05-24T18:19:40.260Z",
      title: "제원",
      orderNum: 3,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>ㆍ크기: 19 inch, 27 U, 38 U</p><p>ㆍ무게: &lt; 975 kg</p><p>ㆍ용량: 1.5 TB</p><p>ㆍ소모전력: &lt; 6100 W</p>",
      __productContentImages__: [],
    },
    {
      id: 91,
      createdAt: "2024-04-19T14:17:20.352Z",
      updatedAt: "2024-05-24T18:19:40.288Z",
      title: "장비의 구성",
      orderNum: 4,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>위성 총조립/시험 및 발사장 작업 단계에서 탑재체 자료전송 부체계(PDTS, Payload Data Transmission Subsystem)를</p><p>구성하는 XDM(XTX, MAS, XAA)의 신속한 검증에 사용할 수 있도록 하드웨어 및 소프트웨어를 조합해서 구성하고 있습니다.</p><p><br></p><p>연결도구는 열진공, 전자파 등 환경시험에 사용되는 것으로 XDM에 연결되는 유(또는 무)선 RF신호 수집 장치 및</p><p>케이블이 포함되어 있습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 143,
      createdAt: "2024-05-24T18:19:40.317Z",
      updatedAt: "2024-05-24T18:19:40.317Z",
      title: "장비의 기능",
      orderNum: 5,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>신호 모사 및 주파수, 스펙트럼, 변복조 등 XDM 주요 사양의 실시간 중앙 모니터링 및 처리(시각화, 캡처, 보관 등) 기능이</p><p>구비되어 있으며, XDM 단위 검증을 위한 XDM 입력(IDHU 출력)의 모사가 가능합니다.</p><p><br></p><p>XDM 출력의 실시간 수신 및 임무(20개 이상) 시험 영상 자료(Raw Data, Processed Data)저장이 가능하며,</p><p>저장 용량을 초과하는 경우 이전 시험 자료의 백업 수단이 구비되어 있습니다.</p><p>XTX, MAS, XAA의 구동(작동), 제어 및 상태 감시가 가능하며 패킷, 압축, 암호화, 부호화 등</p><p>주요 영상 규격 및 성능의 적절성 비교와 고속(위성 총 조립/시험 일정 영향을 최소화하도록 성능 설정)분석이 가능합니다.</p><p><br></p><p>XDM EGSE는 X-밴드 안테나 장치 XAA 구동에 필요한 TPF(Tracking Parameter Files)를 안테나 구동제어기 APD에 전달하고, TPF 지시에 따른 안테나 지향 장치 APM의 적절한 구동 여부를 감시할 수 있습니다.</p><p>자료전송 부체계 설계에 따른 중요 영상규격의(압축, 암호, 변조, 부호화 등)하드웨어 및 소프트웨어 모사 (복원 포함)기능을</p><p>포함하고 있습니다.</p>",
      __productContentImages__: [],
    },
  ],
};
