import React from "react";
import useDisplay from "../../../hooks/useDisplay";

const BodyConatin = ({ style, children, innerStyle }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <div
      style={{
        padding: isMobile
          ? "80px 20px"
          : isTablet
          ? "100px 70px"
          : "60px 0px 80px 0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          maxWidth: 1400,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          ...innerStyle,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default BodyConatin;
