export const languageType = ["KR", "EN"];

export const meetingLang = {
  empty: {
    KR: "등록된 주주총회가 없습니다.",
    EN: "There are no registered General Shareholders' Meeting.",
  },
  searchEmpty: {
    KR: "검색결과가 없습니다.",
    EN: "No search results found.",
  },
  list: {
    KR: "목록으로",
    EN: "List",
  },
};
