import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";
import { langState } from "../../store/langState";
import { faqFooterLang } from "../../lang/recruit/faqFooterLang";

const FaqFooter = ({ title, content, blackText }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const langValue = useRecoilValue(langState);

  return (
    <FaqFooterContainer $isMobile={isMobile} $isTablet={isTablet}>
      <FaqFooterWrapper $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PretendardText
            style={{
              color: "#00AEEF",
              fontSize: isSmall ? "14px" : "16px",
              fontWeight: 600,
              lineHeight: isSmall ? "18.9px" : "23.2px",
              marginBottom: 10,
            }}
          >
            FAQ
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontSize: isSmall ? "16px" : "20px",
              fontWeight: 600,
              lineHeight: isSmall ? "23.2px" : "29px",
              whiteSpace: "pre-wrap",
              marginBottom: isSmall ? 20 : 0,
            }}
          >
            {isMobile
              ? faqFooterLang.intro.mo[langValue]
              : faqFooterLang.intro.pc[langValue]}
          </PretendardText>
        </div>

        <InqueryBtn $isMobile={isMobile} href="mailto:recruit@lumir.space">
          <PretendardText
            style={{
              color: "#FFFFFF",
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 600,
              lineHeight: isMobile ? "23.2px" : "26.1px",
            }}
          >
            {faqFooterLang.inquiry[langValue]}
          </PretendardText>
        </InqueryBtn>
      </FaqFooterWrapper>
    </FaqFooterContainer>
  );
};

export default FaqFooter;

const FaqFooterContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: ${(props) => (props.$isMobile ? "265px" : "220px")};
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${(props) =>
    props.$isMobile ? "40px 20px" : props.$isTablet ? "40px 80px" : "0px"};
  background-image: ${(props) =>
    props.$isMobile
      ? `url("/assets/imgs/talent-type/faq_m.png")`
      : `url("/assets/imgs/talent-type/faq.png")`};
`;

const FaqFooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  align-items: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  justify-content: ${(props) =>
    props.$isMobile ? "flex-start" : "space-between"};
`;

const InqueryBtn = styled.a`
  min-width: ${(props) => (props.$isMobile ? "122px" : "143px")};
  height: ${(props) => (props.$isMobile ? "44px" : "54px")};
  border: 1px solid #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
`;
