import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import useDisplay from "../../../hooks/useDisplay";
import { mainBannerLang } from "../../../lang/lang";
import { langState } from "../../../store/langState";
import Heritage from "./components/Heritage";
import RnD from "./components/RnD";
import RewardRecognition from "./components/RewardRecognition";
import Ipr from "./components/Ipr";

const MajorPerformanceRenewal = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_heritage.png"
            : "/assets/imgs/main-section/heritage.png"
        }
        blackText={isMobile ? false : true}
        title={mainBannerLang.heritage.title[langValue]}
        content={mainBannerLang.heritage.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <Heritage {...{ isMobile, isTablet, isDesktop, langValue }} />
      <RnD {...{ isMobile, isTablet, isDesktop, langValue }} />
      <RewardRecognition {...{ isMobile, isTablet, isDesktop, langValue }} />
      <Ipr {...{ isMobile, isTablet, isDesktop, langValue }} />
    </>
  );
};

export default MajorPerformanceRenewal;
