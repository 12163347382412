import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";
import styled from "styled-components";
import BigImage from "../../../../business/renewalDetail/components/BigImage";
import { root } from "../../../../../styles/GlobalStyle";

const BigImgContents = ({ img, title, subTitle, desc, children }) => {
  const imgRef = useRef(null);

  useGSAP(() => {
    if (!imgRef.current) return;

    gsap.set(imgRef.current, {
      transform: `scale(1) perspective(600px) rotateX(0deg) rotateY(0deg)`,
    });

    const handleMouseMove = (e) => {
      // 마우스의 위치값을 가져온다.
      const { clientX, clientY } = e;

      // 이미지의 너비와 높이, 그리고 이미지의 위치값을 가져온다.
      const { offsetWidth, offsetHeight } = imgRef.current;

      /**
       * getBoundingClientRect() 메서드는 다음과 같은 속성을 포함하는 객체를 반환합니다:
        top: 요소의 상단 경계와 뷰포트의 상단 경계 사이의 거리.
        right: 요소의 오른쪽 경계와 뷰포트의 왼쪽 경계 사이의 거리.
        bottom: 요소의 하단 경계와 뷰포트의 상단 경계 사이의 거리.
        left: 요소의 왼쪽 경계와 뷰포트의 왼쪽 경계 사이의 거리.
        width: 요소의 너비.
        height: 요소의 높이.
        x: 요소의 왼쪽 경계와 뷰포트의 왼쪽 경계 사이의 거리 (left와 동일).
        y: 요소의 상단 경계와 뷰포트의 상단 경계 사이의 거리 (top과 동일).
      */
      const rect = imgRef.current.getBoundingClientRect();

      //화면상의 이미지의 중앙 위치
      const centerImgX = offsetWidth / 2 + rect.x;
      const centerImgY = offsetHeight / 2 + rect.y;

      // 이미지의 중심점에서 마우스의 위치
      const distanceX = clientX - centerImgX;
      const distanceY = clientY - centerImgY;

      const offsetX = distanceX / 100;
      const offsetY = distanceY / 100;

      gsap.to(imgRef.current, {
        transform: `scale(1.05) perspective(600px) rotateX(${offsetY}deg) rotateY(${-offsetX}deg)`,
        duration: 1,
        ease: "power4.out",
      });
    };

    const handleMouseEnter = () => {
      window.addEventListener("mousemove", handleMouseMove);
    };

    const handleMouseLeave = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      gsap.to(imgRef.current, {
        transform: `scale(1) perspective(600px) rotateX(0deg) rotateY(0deg)`,
        duration: 1,
        ease: "power4.out",
      });
    };

    imgRef.current.addEventListener("mouseenter", handleMouseEnter);
    imgRef.current.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      imgRef.current.removeEventListener("mouseenter", handleMouseEnter);
      imgRef.current.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  });

  return (
    <Wrapper>
      <ImgContainer ref={imgRef}>
        {children ? children : <BigImage img={img} />}
      </ImgContainer>
      <TextContainer>
        <TextItem>
          <Title>{subTitle}</Title>
          {desc?.map((item, idx) => (
            <Content key={idx} $Color={root.fontGray}>
              {item}
            </Content>
          ))}
        </TextItem>
      </TextContainer>
    </Wrapper>
  );
};

export default BigImgContents;

const Wrapper = styled.div``;

const ImgContainer = styled.div`
  width: 100%;
  margin-bottom: 6.2rem;
  /* transition: transform 0.3s ease; */
  &:hover {
    transform: scale(1.5);
  }
`;

const TextContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
`;

const TextItem = styled.div``;

const Title = styled.h6`
  font-size: 2.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Content = styled.p`
  color: ${(props) => props.$Color};
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem;
`;
