import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../../components/atoms/text/pretendardText";

import BRCard from "../../../components/organisms/investment/brCard";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";

import useDisplay from "../../../hooks/useDisplay";
import PaginationTemp from "../../../components/organisms/paginationTemp";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { financialInfoLang } from "../../../lang/investment/financialInfoLang";

const BusinessReport = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [dataList, setDataList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_management_info.png"
            : "/assets/imgs/main-section/management_info.png"
        }
        title={mainBannerLang.businessReport.title[langValue]}
        content={mainBannerLang.businessReport.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {financialInfoLang.bnReportTitle[langValue]}
        </PretendardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            marginTop: isMobile ? 40 : 30,
            marginBottom: isMobile ? 50 : 120,
          }}
        >
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {financialInfoLang.bnReport1Quarter[langValue]}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {financialInfoLang.bnReport2Quarter[langValue]}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {financialInfoLang.bnReport3Quarter[langValue]}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {financialInfoLang.bnReport4Quarter[langValue]}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
        </div>

        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {financialInfoLang.bnReportPre[langValue]}
        </PretendardText>

        <div
          style={{
            borderTop: "2px solid #778088",
            borderBottom: "2px solid #778088",
            width: "100%",
            marginTop: isMobile ? 40 : 30,
          }}
        >
          {dataList.length > 0
            ? dataList.map((item, index) => {
                return (
                  <BRCard
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    key={index}
                    title={2022 - index}
                    number={index + 1}
                  />
                );
              })
            : null}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: isMobile || isTablet ? 40 : 60,
          }}
        >
          <PaginationTemp />
        </div>
      </BodyConatin>
    </>
  );
};

export default BusinessReport;

const QuarterWrapper = styled.div`
  width: ${(props) => (props.$isMobile || props.$isTablet ? "100%" : "320px")};
  height: ${(props) => (props.$isMobile ? "74px" : "85px")};
  border: 1px solid rgb(173, 180, 186);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.$isTablet ? "0px 16px" : props.$isMobile ? "0px 30px" : "0px 31px"};
  margin-bottom: ${(props) => (props.$isMobile ? "30px" : "0px")};
`;

const QuarterText = styled(PretendardText)`
  color: rgb(38, 44, 49);
  font-size: ${(props) => (props.$isSmall ? "16px" : "20px")};
  font-weight: ${(props) => (props.$isSmall ? "600" : "500")};
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "29px")};
  margin-right: 10px;
`;
