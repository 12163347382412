import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import PretendardText from "../../../components/atoms/text/pretendardText";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import Pagination from "../../../components/organisms/pagination";
import IRCard from "../../../components/organisms/investment/irCard";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";

import useDisplay from "../../../hooks/useDisplay";
import PaginationTemp from "../../../components/organisms/paginationTemp";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { performanceLang } from "../../../lang/investment/performanceLang";

const monthEn = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const IREvent = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { pathname } = useLocation();
  const [date, setDate] = useState(new Date());
  const [days, setDays] = useState(0);
  const langValue = useRecoilValue(langState);

  const [dataList, setDataList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  useEffect(() => {
    const daysTemp = moment(date).daysInMonth();

    setDays(daysTemp);
  }, [date]);

  const daysUIFunc = () => {
    let temp = [];
    let day = Number(moment(date).format("DD"));

    for (let i = 1; i <= days; i++) {
      temp.push(
        <div
          onClick={() => {
            setDate(moment(date).format(`YYYY MM ${i}`));
          }}
          style={{
            cursor: "pointer",
            border: day === i ? "1px solid #00AEEF" : "none",
            borderRadius: "50%",
            width: 26,
            height: 26,
          }}
        >
          <PretendardText
            style={{
              color: day === i ? "#00AEEF" : "#262C31",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "center",
            }}
          >
            {i}
          </PretendardText>
        </div>
      );
    }

    return temp;
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_ir_event.png"
            : "/assets/imgs/main-section/ir_event.png"
        }
        title={mainBannerLang.IREvent.title[langValue]}
        content={mainBannerLang.IREvent.content[langValue]}
        blackText={true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
            marginBottom: isMobile ? 40 : 30,
          }}
        >
          {performanceLang.irTitle[langValue]}
        </PretendardText>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #ADB4BA",
            padding: "30px 29px 30px 27px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => {
                setDate(moment(date).subtract(1, "M"));
              }}
              src="/assets/icons/left.svg"
              alt="left_arrow"
              style={{
                width: 24,
                height: 24,
                cursor: "pointer",
              }}
            />
            <PretendardText
              style={{
                color: "#262C31",
                fontSize: isSmall ? "15px" : "20px",
                fontWeight: isSmall ? 600 : 500,
                lineHeight: isSmall ? "21px" : "29px",
                flex: 1,
                marginLeft: 5,
              }}
            >
              {langValue === "KR"
                ? `${moment(date).format("YYYY년")} ${String(
                    moment(date).format("MM월")
                  )} `
                : `${String(
                    monthEn[Number(moment(date).format("MM")) - 1]
                  )} ${moment(date).format("YYYY")}`}
            </PretendardText>
            <img
              onClick={() => {
                setDate(moment(date).add(1, "M"));
              }}
              src="/assets/icons/right.svg"
              alt="right_arrow"
              style={{
                width: 24,
                height: 24,
                cursor: "pointer",
              }}
            />
          </div>

          <div
            style={{
              borderBottom: "1px solid #9C9C9C",
              width: "100%",
              marginTop: isSmall ? 20 : 23,
              marginBottom: isSmall ? 15 : 22,
            }}
          />

          <OverflowXDiv $isSmall={isSmall}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: isSmall ? "1200px" : "100%",
              }}
            >
              {days > 0 ? daysUIFunc() : null}
            </div>
          </OverflowXDiv>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              background: "#F9F9F9",
              padding: "30px 31px",
              marginTop: 20,
            }}
          >
            <QuarterWrapper
              style={{
                marginBottom: 0,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <QuarterText
                style={{
                  marginBottom: isMobile ? "5px" : 0,
                }}
                $isSmall={isSmall}
              >
                {langValue === "KR"
                  ? "일정 제목이 이곳에 들어갑니다."
                  : "Title"}
              </QuarterText>
              <QuarterDateText $isSmall={isSmall}>
                {langValue === "KR" ? "2023년 1월 2일" : "January 2, 2023"}
              </QuarterDateText>
            </QuarterWrapper>
          </div>
        </div>

        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
            marginTop: isSmall ? 80 : 120,
          }}
        >
          {performanceLang.preTitle[langValue]}
        </PretendardText>

        <div
          style={{
            borderTop: "2px solid #778088",
            borderBottom: "2px solid #778088",
            width: "100%",
            marginTop: isMobile ? 40 : 30,
          }}
        >
          {dataList.length > 0
            ? dataList.map((item, index) => {
                return (
                  <IRCard
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    key={index}
                    title={2022 - index}
                    number={index + 1}
                  />
                );
              })
            : null}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: isSmall ? 40 : 60,
          }}
        >
          <PaginationTemp />
        </div>
      </BodyConatin>
    </>
  );
};

export default IREvent;

const QuarterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: "center";
  margin-bottom: 24px;
  box-sizing: content-box;
`;

const QuarterText = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) => (props.$isSmall ? "15px" : "20px")};
  font-weight: ${(props) => (props.$isSmall ? "600" : "500")};
  margin-right: ${(props) => (props.$isSmall ? "10px" : "15px")};
`;

const QuarterDateText = styled(PretendardText)`
  color: ${(props) => (props.$isSmall ? "#5A636A" : "#778088")};
  font-size: ${(props) => (props.$isSmall ? "15px" : "18px")};
  font-weight: 400;
  line-height: ${(props) => (props.$isSmall ? "21px" : "26.1px")};
`;

const OverflowXDiv = styled.div`
  width: 100%;
  overflow-x: ${(props) => (props.$isSmall ? "scroll" : "none")};
  padding-bottom: ${(props) => (props.$isSmall ? "4px" : "0px")};

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: #d7dce2;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: #adb4ba;
    border-radius: 10px;
  }
`;
