import styled from "styled-components";
import { root } from "../../../../styles/GlobalStyle";

const Client = ({ clientList, langValue }) => {
  return (
    <div>
      <Header>{langValue === "KR" ? "고객사" : "Client"}</Header>
      <ContentsContainer>
        {clientList?.map((item, index) => (
          <FeatureItem key={index}>
            <Divider />
            <Title>{item.title}</Title>
            {item?.content && (
              <Content $Color={root.fontGray}>{item.content}</Content>
            )}
            <Image src={item.image} alt={item.title} />
          </FeatureItem>
        ))}
      </ContentsContainer>
    </div>
  );
};

export default Client;

const Header = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4.6rem;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a8a8a8;
  margin-bottom: 1.8rem;
`;

const Title = styled.h6`
  font-size: 1.9rem;
  font-weight: 700;
`;

const Content = styled.p`
  color: ${(props) => props.$Color};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
`;

const Image = styled.img`
  width: 100%;
`;
