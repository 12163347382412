import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import Certification from "../components/Certification";
import Client from "../components/Client";
import Layout from "../components/Layout";
import { gavs } from "../../../../lang/renewal/productsLang";

const Gavs = ({ langValue }) => {
  const { isMobile } = useDisplay();

  const data = gavs[langValue];

  return (
    <Layout
      bgColor={data.bgColor}
      title={"GAVS"}
      subTitle={data.header}
      pdf={data.pdf}
    >
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>

        <Certification
          certificationList={data.certification}
          langValue={langValue}
          isMobile={isMobile}
        />

        <FramerSlideIn>
          <Client clientList={data.client} langValue={langValue} />
        </FramerSlideIn>
        <Wrapper $isMobile={isMobile}>
          <Header>
            {langValue === "KR" ? "제품소개" : "Product Introduction"}
          </Header>
          {data.deviceList.map((item, index) => (
            <FramerSlideIn key={index}>
              <ContentsContainer $isMobile={isMobile}>
                <Device>
                  <DeviceImg src={item.deviceImg} />
                </Device>
                <Info>
                  <InfoImg src={item.itemImg} />
                </Info>
              </ContentsContainer>
            </FramerSlideIn>
          ))}
        </Wrapper>
      </ContentsWrapper>
    </Layout>
  );
};

export default Gavs;

const ContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;

const Header = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ $isMobile }) => ($isMobile ? "3rem" : "5rem")};
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 6fr;
  gap: ${({ $isMobile }) => ($isMobile ? "1rem" : "4.6rem")};
`;

const Device = styled.div`
  width: 100%;
  height: 100%;
  background-color: #5bd2ff1a;
  padding: 14px;
`;

const Info = styled.div`
  width: 100%;
  height: 100%;
`;

const DeviceImg = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoImg = styled.img`
  width: 100%;
  height: 100%;
`;
