import React from "react";
import styled from "styled-components";

const SangsangshinText = (props) => {
  return <SangsangshinTextWrapper {...props} />;
};

export default SangsangshinText;

const SangsangshinTextWrapper = styled.div`
  @font-face {
    font-family: "SangSangShin";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_three@1.0/SangSangShin.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }

  font-family: "SangSangShin";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: block;
`;
