import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { rnd } from "../../../../../lang/renewal/performanceLang";
import { langState } from "../../../../../store/langState";
import FramerSlideIn from "../../../../../styles/framerSlideIn";
import BigImgContents from "./BigImgContents";
import Layout from "./Layout";
import { root } from "../../../../../styles/GlobalStyle";

const Section1 = ({ data, isMobile }) => {
  if (isMobile) {
    return (
      <div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div>
            <TextItem>
              <Title2>{data.section1.text1[0]}</Title2>
              <Content>{data.section1.text1[1]}</Content>
              <Content>{data.section1.text1[2]}</Content>
            </TextItem>
            <FramerSlideIn>
              <img src={data.section1.img1} alt="" width={"100%"} />
            </FramerSlideIn>
          </div>
          <div>
            <TextItem>
              <Title2>{data.section1.text2[0]}</Title2>
              <Content>{data.section1.text2[1]}</Content>
            </TextItem>
            <FramerSlideIn>
              <img src={data.section1.img2} alt="" width={"100%"} />
            </FramerSlideIn>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Section1Border>
      <Section1Wrapper>
        <Section1Box>
          <TextItem>
            <Title2>{data.section1.text1[0]}</Title2>
            <Content>{data.section1.text1[1]}</Content>
            <Content>{data.section1.text1[2]}</Content>
          </TextItem>
          <FramerSlideIn>
            <img src={data.section1.img1} alt="" width={"100%"} />
          </FramerSlideIn>
        </Section1Box>
        <Section1Box>
          <TextItem>
            <Title2>{data.section1.text2[0]}</Title2>
            <Content>{data.section1.text2[1]}</Content>
          </TextItem>
          <FramerSlideIn>
            <img src={data.section1.img2} alt="" width={"100%"} />
          </FramerSlideIn>
        </Section1Box>
      </Section1Wrapper>
    </Section1Border>
  );
};

const Third = ({ isMobile }) => {
  const langValue = useRecoilValue(langState);
  const [data, setData] = useState(rnd(isMobile)[langValue].contents[2].info);

  useEffect(() => {
    setData(rnd(isMobile)[langValue].contents[2].info);
  }, [isMobile, langValue]);

  return (
    <Layout title={data.title}>
      <FramerSlideIn>
        <BigImgContents {...data} />
      </FramerSlideIn>
      <ImageWrapper $isMobile={isMobile}>
        <FramerSlideIn style={{ width: isMobile ? "100%" : "33%" }}>
          <img
            src={data.imgs[0]}
            alt=""
            width={"100%"}
            style={{ marginBottom: "2rem" }}
          />
        </FramerSlideIn>
        <FramerSlideIn style={{ width: isMobile ? "100%" : "33%" }}>
          <img src={data.imgs[1]} alt="" width={"100%"} />
        </FramerSlideIn>
        <FramerSlideIn style={{ width: isMobile ? "100%" : "33%" }}>
          <img src={data.imgs[2]} alt="" width={"100%"} />
        </FramerSlideIn>
      </ImageWrapper>
      <TextContainer>
        <TextItem>
          <Title>{data.section1.title}</Title>
        </TextItem>
      </TextContainer>
      <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
        <Section1 data={data} isMobile={isMobile} />
        <Section2>
          <TextItem>
            <Title>{data.section2.title}</Title>
          </TextItem>
          <FramerSlideIn>
            <img src={data.section2.img} alt="" width={"100%"} />
          </FramerSlideIn>
          <div
            style={{
              width: "100%",
              backgroundColor: "lightgray",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "2rem" }}>{data.section2.text}</p>
          </div>
        </Section2>
      </div>
    </Layout>
  );
};

export default Third;

const ImageWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
`;

const TextContainer = styled.div`
  width: 100%;
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
`;

const TextItem = styled.div`
  height: 20%;
`;

const Title = styled.h6`
  font-size: 2.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Title2 = styled.h6`
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const Content = styled.p`
  color: ${root.fontGray};
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
`;

const Section1Border = styled.div`
  border: 1px solid gray;
  padding: 4rem;
`;

const Section1Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
`;

const Section1Box = styled.div`
  display: grid;
  width: 50%;
  grid-template-rows: 1fr 3.5fr;
`;

const Section2 = styled.div``;
