export const data = {
  message: "조회되었습니다.",
  data: {
    pageSize: "6",
    totalCount: 54,
    totalPage: 9,
    isLast: false,
    isFirst: true,
    items: [
      {
        id: 67,
        createdAt: "2024-04-23T09:56:29.719Z",
        updatedAt: "2024-04-23T09:56:29.719Z",
        title: '남명용 대표 “루미르, 국가 공헌하는 우주기업 될것"',
        mediaCompany: "더벨",
        link: "https://www.thebell.co.kr/free/content/ArticleView.asp?key=202404121439172560109620",
        exposure: true,
        serverFileName: "80b152bd-014c-4241-9d09-63ac9f5273b7.jpg",
        originFileName: "20240419.jpg",
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/80b152bd-014c-4241-9d09-63ac9f5273b7.jpg",
        language: "KR",
      },
      {
        id: 66,
        createdAt: "2024-04-23T09:55:14.353Z",
        updatedAt: "2024-04-23T09:55:14.353Z",
        title:
          "LumirX 항공시험 성능 검증 마쳐…기존 대비 2배 큰 실험실 갖춘 신사옥 건설",
        mediaCompany: "더벨",
        link: "https://www.thebell.co.kr/free/content/ArticleView.asp?key=202404111542425560104615",
        exposure: true,
        serverFileName: "321c4a5a-6ab9-440b-955d-f259c2ddd81e.jpg",
        originFileName: "20240418.jpg",
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/321c4a5a-6ab9-440b-955d-f259c2ddd81e.jpg",
        language: "KR",
      },
      {
        id: 58,
        createdAt: "2024-04-23T09:53:43.160Z",
        updatedAt: "2024-04-23T09:53:43.160Z",
        title: "'밸류체인 자부심' 루미르, 연내 IPO 드라이브",
        mediaCompany: "더벨",
        link: "https://thebell.co.kr/free/content/ArticleView.asp?key=202404091002404600104061&lcode=00&page=1&svccode=00",
        exposure: true,
        serverFileName: "78151166-7d35-4f2d-98fe-f9fd03a645b2.jpg",
        originFileName: "20240415.jpg",
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/78151166-7d35-4f2d-98fe-f9fd03a645b2.jpg",
        language: "KR",
      },
      {
        id: 57,
        createdAt: "2024-04-23T09:52:45.821Z",
        updatedAt: "2024-04-23T09:52:45.821Z",
        title: "'K-글로벌 프로젝트' 성적표 보니…일자리·매출·투자 두자릿수↑",
        mediaCompany: "머니투데이",
        link: "https://news.mt.co.kr/mtview.php?no=2024040409285315825",
        exposure: true,
        serverFileName: "e7b66f95-9530-4386-b63d-d8da84b366ff.jpg",
        originFileName: "20240404.jpg",
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e7b66f95-9530-4386-b63d-d8da84b366ff.jpg",
        language: "KR",
      },
      {
        id: 56,
        createdAt: "2024-04-23T09:51:40.468Z",
        updatedAt: "2024-04-23T09:51:40.468Z",
        title: "초소형 인공위성 개발 기업 ‘루미르’ 코스닥 상장 도전",
        mediaCompany: "매일경제",
        link: "https://www.mk.co.kr/news/stock/10971753",
        exposure: true,
        serverFileName: "2b465d87-d829-415f-86cb-aff27999bb9c.jpg",
        originFileName: "20240322.jpg",
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2b465d87-d829-415f-86cb-aff27999bb9c.jpg",
        language: "KR",
      },
      {
        id: 55,
        createdAt: "2024-04-23T09:50:35.867Z",
        updatedAt: "2024-04-23T09:50:35.867Z",
        title: "‘우주시대 열린다’···IPO 단골손님 예고한 우주항공 스타트업",
        mediaCompany: "시사저널",
        link: "https://www.sisajournal-e.com/news/articleView.html?idxno=401433",
        exposure: true,
        serverFileName: "6d06b596-11d9-4dfd-a58d-c9aef6144644.jpg",
        originFileName: "20240321.jpg",
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6d06b596-11d9-4dfd-a58d-c9aef6144644.jpg",
        language: "KR",
      },
    ],
  },
};
