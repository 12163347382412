import React from "react";
import { styled } from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

const DepartmentContact = ({ title, tel, email }) => {
  const { isMobile } = useDisplay();
  return (
    <DepartmentContactWrapper>
      <Title $isMobile={isMobile}>{title}</Title>
      <Content style={{ marginBottom: "3px" }}>{tel}</Content>
      <Content>{email}</Content>
      <img
        src="/assets/icons/department-contact.png"
        alt=""
        style={{ position: "absolute", top: "30px", right: "30px" }}
      />
    </DepartmentContactWrapper>
  );
};

export default DepartmentContact;

const DepartmentContactWrapper = styled.div`
  background: #fff;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 320px;
  height: 180px;
`;

const Title = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) => (props.$isMobile ? "16px" : "18px")};
  font-weight: 600;
  line-height: 145%;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
  margin-bottom: 10px;
`;

const Content = styled(PretendardText)`
  color: #5a636a;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
`;
