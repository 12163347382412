import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";
import { route } from "../../../router/route";

function PlayDLCard({ style, content }) {
  const navigation = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 15,
          ...style,
        }}
      >
        <img
          style={{
            cursor: "pointer",
            width: 24,
            height: 24,
            marginRight: 5,
          }}
          src="/assets/icons/play02.svg"
        />
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "23.2px",
          }}
        >
          {content}
        </PretendardText>
      </div>
    </>
  );
}

export default PlayDLCard;
