import { apiSDKRepository } from "./api.repository";

class ApiSDKService {
  apiSDKRepository;

  constructor(apiSDKRepository) {
    this.apiSDKRepository = apiSDKRepository;
  }

  async getNews(page, limit, language) {
    const news = await this.apiSDKRepository.getNews(page, limit);

    const newsList = await Promise.all(
      news.items.map(async (newsItem) => {
        const fileName = newsItem.thumbnails[0].fileName;
        const filePath = newsItem.thumbnails[0].filePath;
        try {
          const response = await this.apiSDKRepository.getFile(filePath);
          // Blob 처리
          const blob = await response.blob();
          const fileUrl = URL.createObjectURL(blob);

          return {
            id: newsItem._id,
            title: newsItem.title,
            date: newsItem.date,
            createdAt: newsItem.createdAt,
            updatedAt: newsItem.updatedAt,
            link: newsItem.link,
            exposure: newsItem.isPublic,
            serverFileName: `${filePath}`,
            originFileName: `${fileName}`,
            fileUrl: fileUrl,
            category: newsItem.category.name,
            language: language,
            order: newsItem.order,
          };
        } catch (e) {
          console.log("error", e);
          return {
            id: newsItem._id,
            title: newsItem.title,
            date: newsItem.date,
            createdAt: newsItem.createdAt,
            updatedAt: newsItem.updatedAt,
            link: newsItem.externalLink,
            exposure: newsItem.isPublic,
            serverFileName: `${filePath}`,
            originFileName: `${fileName}`,
            fileUrl: "",
            category: newsItem.category.name,
            language: "KR",
            order: newsItem.order,
          };
        }
      })
    );

    return {
      pageSize: news.limit,
      totalCount: news.total,
      totalPage: Math.ceil(news.total / news.limit),
      pageNo: news.page,
      isLast: !news.hasMore,
      isFirst: news.page === 1,
      items: newsList,
      language,
    };
  }

  async handleNewsItem(newsItem) {
    if (!newsItem) {
      return null;
    }

    const fileName = newsItem.thumbnails[0].fileName;
    const filePath = newsItem.thumbnails[0].filePath;
    const contentFilePath = newsItem.files[0].filePath;

    try {
      const file = await this.apiSDKRepository.getFile(filePath);
      const blob = await file.blob();
      const fileUrl = URL.createObjectURL(blob);

      const content = await this.apiSDKRepository.getFile(contentFilePath);

      const blobContent = await content.blob();
      const contentFileUrl = URL.createObjectURL(blobContent);

      return {
        id: newsItem._id,
        createdAt: newsItem.createdAt,
        updatedAt: newsItem.updatedAt,
        serverFileName: `${filePath}`,
        originFileName: `${fileName}`,
        fileUrl: fileUrl,
        title: newsItem.title,
        category: newsItem.category.name,
        content: contentFileUrl,
        exposure: newsItem.isPublic,
        date: newsItem.date,
        link: newsItem.externalLink,
        language: "KR",
        order: newsItem.order,
      };
    } catch (e) {
      return {
        id: newsItem._id,
        createdAt: newsItem.createdAt,
        updatedAt: newsItem.updatedAt,
        serverFileName: `${filePath}`,
        originFileName: `${fileName}`,
        fileUrl: "",
        title: newsItem.title,
        category: newsItem.category.name,
        content: newsItem.content,
        exposure: newsItem.isPublic,
        date: newsItem.date,
        link: newsItem.externalLink,
        language: "KR",
        order: newsItem.order,
      };
    }
  }

  async getNewsById(newsId) {
    try {
      const nowNews = await this.apiSDKRepository.getNewsById(newsId);

      // Assuming you need to get prev/next news items too
      const [prevNews, currentNews, nextNews] = await Promise.all([
        this.handleNewsItem(nowNews.prevNews), // If these exist
        this.handleNewsItem(nowNews.currentNews),
        this.handleNewsItem(nowNews.nextNews), // If these exist
      ]);

      return {
        prev: prevNews || {},
        now: currentNews,
        next: nextNews || {},
      };
    } catch (error) {
      console.error("Error fetching news:", error);
      throw error;
    }
  }

  async getPressReleases(page, limit, pageNo, totalPages, language) {
    const news = await this.apiSDKRepository.getPressReleases(page, limit);

    const newsList = await Promise.all(
      news.items.map(async (newsItem) => {
        const fileName = newsItem.thumbnails[0].fileName;
        const filePath = newsItem.thumbnails[0].filePath;
        try {
          const response = await this.apiSDKRepository.getFile(filePath);
          // Blob 처리
          const blob = await response.blob();
          const fileUrl = URL.createObjectURL(blob);

          return {
            id: newsItem._id,
            title: newsItem.title,
            date: newsItem.date,
            createdAt: newsItem.createdAt,
            updatedAt: newsItem.updatedAt,
            mediaCompany: newsItem.newsCompany,
            link: newsItem.externalLink,
            exposure: newsItem.isPublic,
            serverFileName: `${filePath}`,
            originFileName: `${fileName}`,
            fileUrl: fileUrl,
            language: "KR",
            order: newsItem.order,
          };
        } catch (e) {
          console.log("error", e);
          return {
            id: newsItem._id,
            title: newsItem.title,
            date: newsItem.date,
            createdAt: newsItem.createdAt,
            updatedAt: newsItem.updatedAt,
            mediaCompany: newsItem.newsCompany,
            link: newsItem.externalLink,
            exposure: newsItem.isPublic,
            serverFileName: `${filePath}`,
            originFileName: `${fileName}`,
            fileUrl: "",
            language: "KR",
            order: newsItem.order,
          };
        }
      })
    );

    return {
      pageSize: news.limit,
      totalCount: news.total,
      totalPage: Math.ceil(news.total / news.limit),
      pageNo: news.page,
      isLast: !news.hasMore,
      isFirst: news.page === 1,
      items: newsList,
      language,
    };
  }

  async getPressReleaseById(pressReleaseId) {
    return this.apiSDKRepository.getPressReleaseById(pressReleaseId);
  }

  async getFile(fileKey) {
    return this.apiSDKRepository.getFile(fileKey);
  }

  async createPageView(params) {
    return await this.apiSDKRepository.createPageView(params);
  }

  async updatePageView(params) {
    return await this.apiSDKRepository.updatePageView(params);
  }

  async getNotices(page, limit) {
    const notices = await this.apiSDKRepository.getNotices(page, limit);

    console.log("notices", notices);
    return await Promise.all(
      notices.items.map(async (notice) => {
        const mobileS3 = await this.apiSDKRepository.getFile(
          notice.mobileFiles[0].filePath
        );
        const pcS3 = await this.apiSDKRepository.getFile(
          notice.pcFiles[0].filePath
        );

        const mobileBlob = await mobileS3.blob();
        const pcBlob = await pcS3.blob();

        const mobileImg = URL.createObjectURL(mobileBlob);
        const pcImg = URL.createObjectURL(pcBlob);

        try {
          return {
            alt: notice.title,
            image: {
              mobile: mobileImg,
              pc: pcImg,
            },
          };
        } catch (e) {
          console.log("error", e);
          return {
            alt: notice.title,
            image: {
              mobile: "",
              pc: "",
            },
          };
        }
      })
    );
  }

  async getNoticeById(noticeId) {
    return this.apiSDKRepository.getNoticeById(noticeId);
  }
}

const apiSDKService = new ApiSDKService(apiSDKRepository);

export { apiSDKService };
