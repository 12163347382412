import useDisplay from "../../../../hooks/useDisplay";
import { Container, LeftContents, RightContents } from "./WeDo.styles";
import HighResolutionSection from "./HighResolutionSection";
import ObservationModesSection from "./ObservationModesSection";
import LightweightSection from "./LightweightSection";
import { weDo } from "../../../../lang/renewal/homeLang";

const WeDo = ({ langValue }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <Container $isDesktop={isDesktop} $isMobile={isMobile}>
      <LeftContents $isMobile={isMobile} $isTablet={isTablet}>
        {weDo.title}
      </LeftContents>
      <RightContents $isMobile={isMobile}>
        <HighResolutionSection
          isMobile={isMobile}
          isDesktop={isDesktop}
          data={weDo.highResolution[langValue]}
        />
        <ObservationModesSection
          isMobile={isMobile}
          isDesktop={isDesktop}
          data={weDo.observationMode[langValue]}
        />
        <LightweightSection
          isMobile={isMobile}
          isDesktop={isDesktop}
          data={weDo.lightWeight[langValue]}
        />
      </RightContents>
    </Container>
  );
};

export default WeDo;
