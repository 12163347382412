import React, { useState, useCallback } from "react";
import styled from "styled-components";
import VideoHoverPlay from "./VideoHoverPlay";
import VideosHoverPlayWide from "./VideosHoverPlayWide";
import { Content, Description, Title } from "./WeDo.styles";

const ObservationModesSection = ({ isDesktop, data }) => {
  const [hiddenIcons, setHiddenIcons] = useState(new Set());

  const handleClick = useCallback((index) => {
    setHiddenIcons((prev) => {
      const newSet = new Set(prev);
      newSet.add(index);
      return newSet;
    });
  }, []);

  return (
    <Content>
      <Title>{data.title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: data.description }} />
      {isDesktop ? (
        <VideosHoverPlayWide
          images={data.images}
          videos={data.videos.desktop}
        />
      ) : (
        data.images.map((img, index) => (
          <Wrapper key={index} onClick={() => handleClick(index)}>
            <VideoHoverPlay
              imageUrl={img}
              videoUrl={data.videos.mobile[index]}
              $imgIndex={index}
              hiddenIcons={hiddenIcons}
              index={index}
            />
          </Wrapper>
        ))
      )}
    </Content>
  );
};

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export default ObservationModesSection;
