import { useState } from "react";
import styled from "styled-components";

const Image = ({ img }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = (e) => {
    if (!hasError) {
      setHasError(true);
      e.target.src = "/assets/imgs/business/business_placeholder_img.png"; // 대체 이미지 경로
      e.target.alt = "lumir"; // 대체 이미지 설명
      console.log("error");
    }
  };
  return <Img src={img} alt={img} onError={handleError} />;
};

export default Image;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
