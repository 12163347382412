import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import FramerWipe from "../../../../styles/framerWipe";
import useDisplay from "../../../../hooks/useDisplay";

const VideosHoverPlayWide = ({ images, videos }) => {
  const { isTablet, isDesktop } = useDisplay();

  const [hoveringStates, setHoveringStates] = useState(images.map(() => false));
  const [animationStates, setAnimationStates] = useState(images.map(() => 0)); // 0: 초기상태, 1: 이미지 확대 중, 2: 비디오 재생 중
  const [videoErrors, setVideoErrors] = useState(images.map(() => false));
  const videoRefs = useRef(images.map(() => React.createRef()));

  useEffect(() => {
    videoRefs.current.forEach((ref, index) => {
      const video = ref.current;
      if (video) {
        video.addEventListener("error", () => handleVideoError(index));
        return () =>
          video.removeEventListener("error", () => handleVideoError(index));
      }
    });
  }, []);

  const handleVideoError = (index) => {
    console.error(`Video ${index} failed to load`);
    setVideoErrors((prev) => {
      const newErrors = [...prev];
      newErrors[index] = true;
      return newErrors;
    });
  };

  const handleMouseEnter = (index) => {
    if (hoveringStates.includes(true)) return;
    setHoveringStates((prev) => {
      const newStates = [...prev];
      newStates[index] = true;
      return newStates;
    });
    setAnimationStates((prev) => {
      const newStates = [...prev];
      newStates[index] = 1; // 이미지 확대 시작
      return newStates;
    });

    // 이미지 확대 애니메이션 후 비디오 재생
    setTimeout(() => {
      setAnimationStates((prev) => {
        const newStates = [...prev];
        newStates[index] = 2; // 비디오 재생 시작
        return newStates;
      });
      if (videoRefs.current[index].current && !videoErrors[index]) {
        videoRefs.current[index].current?.play().catch((error) => {
          console.error(`Error attempting to play video ${index}:`, error);
        });
      }
    }, 500); // 이미지 확대 애니메이션 시간과 일치시킴
  };

  const handleMouseLeave = (index) => {
    setAnimationStates((prev) => {
      const newStates = [...prev];
      newStates[index] = 0; // 초기 상태로 복귀
      return newStates;
    });
    setHoveringStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
    if (videoRefs.current[index].current && !videoErrors[index]) {
      videoRefs.current[index].current?.pause();
      videoRefs.current[index].current.currentTime = 0;
    }
  };
  return (
    <Images $isDesktop={isDesktop}>
      {images.map((image, index) => (
        <React.Fragment key={index}>
          <ImageContainer $imgIndex={index}>
            <ImageWrap>
              <FramerSlideIn>
                <FramerWipe>
                  {/* <Container> */}
                  <Container onMouseEnter={() => handleMouseEnter(index)}>
                    <Image
                      $isHovering={hoveringStates[index]}
                      $animationState={animationStates[index]}
                      $index={index}
                      src={image}
                      alt="Hover to play video"
                      loading="lazy"
                    />
                  </Container>
                </FramerWipe>
              </FramerSlideIn>
            </ImageWrap>
          </ImageContainer>
          <Video
            ref={videoRefs.current[index]}
            loop
            muted
            playsInline
            $isHovering={hoveringStates[index]}
            $animationState={animationStates[index]}
            $index={index}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <source src={videos[index]} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </React.Fragment>
      ))}
    </Images>
  );
};

export default VideosHoverPlayWide;

const Images = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: content-box;

  ${({ $isDesktop }) => {
    if (!$isDesktop) {
      return css`
        display: flex;
        flex-direction: column;
        gap: 1em;
      `;
    } else {
      return css`
        display: grid;
        gap: 1em;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 4fr);
      `;
    }
  }}
`;

const ImageContainer = styled.div`
  transform: translate3d(0px, 0px, 0px);
  overflow: visible;
  width: 100%;
  position: relative;

  ${({ $imgIndex }) => {
    if ($imgIndex === 0)
      return css`
        grid-area: 1/1/2/6;
      `;
    if ($imgIndex === 1)
      return css`
        grid-area: 1/6/2/11;
      `;
    if ($imgIndex === 2)
      return css`
        grid-area: 2/3/3/8;
      `;
    if ($imgIndex === 3)
      return css`
        grid-area: 2/8/3/13;
      `;
  }}
`;

const ImageWrap = styled.div`
  width: 100%;
  height: auto;
  overflow: visible;
  transition: all 0.3s ease-in-out;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 33vh;
  /* overflow: hidden; */
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;

  ${({ $index, $animationState }) => {
    const transformOrigin =
      $index === 0
        ? "top left"
        : $index === 1
        ? "top right"
        : $index === 2
        ? "bottom left"
        : "bottom right";

    const transform = $animationState >= 1 ? "scale(2)" : "scale(1)";

    return css`
      transform-origin: ${transformOrigin};
      transform: ${transform};
    `;
  }}
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  opacity: ${(props) => (props.$animationState >= 1 ? 1 : 0)};
  z-index: ${(props) => (props.$animationState >= 1 ? 2 : -1)};
  background-color: ${(props) =>
    props.$animationState === 2 ? "white" : "transparent"};

  ${({ $index, $isHovering }) => {
    const transformOrigin =
      $index === 0
        ? "top left"
        : $index === 1
        ? "top right"
        : $index === 2
        ? "bottom left"
        : "bottom right";

    const transform = $isHovering ? "scale(1)" : "scale(0.5)";

    return css`
      transform-origin: ${transformOrigin};
      transform: ${transform};
    `;
  }}

  ${({ $index }) => {
    switch ($index) {
      case 0:
        return css`
          top: 0;
          left: 0;
        `;
      case 1:
        return css`
          top: 0;
          right: 0;
        `;
      case 2:
        return css`
          bottom: 0;
          left: 0;
        `;
      case 3:
        return css`
          bottom: 0;
          right: 0;
        `;
      default:
        return "";
    }
  }}
`;
