import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "./../../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import AwardCard from "../../../components/templates/awardCard";
import {
  certification,
  intellectualStatus,
} from "../../../constant/certification";
import PretendardText from "../../../components/atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import { useRecoilValue } from "recoil";
import { mainBannerLang } from "../../../lang/lang";
import { langState } from "../../../store/langState";
import { certificationLang } from "../../../lang/CompanyIntroduction/certification";

const Certification = () => {
  const {
    isMobile,
    isTablet,
    isDesktop,
    certification1,
    certification2,
    certification3,
  } = useDisplay();
  const { pathname } = useLocation();

  const [tab, setTab] = useState([
    "Certification and Award",
    "Intellectual Property Rights Status",
  ]);

  const [activeTab, setActiveTab] = useState(0);

  const langValue = useRecoilValue(langState);

  useEffect(() => {
    const tabValue = [
      certificationLang.tab?.certification[langValue],
      certificationLang.tab?.property[langValue],
    ];
    setTab(tabValue);
  }, [langValue]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_certification.png"
            : "/assets/imgs/main-section/certification.png"
        }
        title={mainBannerLang.certification?.title[langValue]}
        content={mainBannerLang.certification?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
        <SectionWrapper>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: isMobile ? "40px" : "62px",
            }}
          >
            {tab.map((item, index) => {
              return (
                <TabWrapper
                  $isMobile={isMobile}
                  $langValue={langValue}
                  key={index}
                  id={index}
                  $activeTab={activeTab}
                  onClick={() => setActiveTab(index)}
                >
                  <TabText
                    $isMobile={isMobile}
                    $isTablet={isTablet}
                    id={index}
                    $activeTab={activeTab}
                  >
                    {item}
                  </TabText>
                </TabWrapper>
              );
            })}
          </div>
        </SectionWrapper>
        <div
          style={{
            maxWidth: certification2
              ? "680px"
              : certification3
              ? "1040px"
              : "1400px",
            margin: "0 auto",
          }}
        >
          {activeTab === 0 ? (
            <div
              style={{
                display: "flex",
                gap: isMobile || isTablet ? "30px" : "40px",
                flexWrap: "wrap",
                flexDirection: certification1 ? "column" : "row",
                alignItems: certification1 ? "center" : "",
              }}
            >
              {certification?.map((item, index) => {
                return (
                  <AwardCard
                    key={index}
                    src={item.image}
                    cardTitle={item.cardTitle}
                    title={item.title[langValue]}
                    date={item.date}
                  />
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: isMobile || isTablet ? "30px" : "40px",
                flexWrap: "wrap",
                flexDirection: certification1 ? "column" : "row",
                alignItems: certification1 ? "center" : "",
              }}
            >
              {intellectualStatus?.map((item, index) => {
                return (
                  <AwardCard
                    key={index}
                    src={`/assets/imgs/certification/right${index}.png`}
                    cardTitle={item.cardTitle}
                    title={item.title[langValue]}
                    date={item.date}
                  />
                );
              })}
            </div>
          )}
        </div>
      </PageWrapper>
    </>
  );
};

export default Certification;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabText = styled(PretendardText)`
  color: ${(props) => (props.$activeTab === props.id ? "#262c31" : "#adb4ba")};
  position: relative;
  bottom: ${(props) => (props.$activeTab === props.id ? "-1px" : "0")};
  font-size: ${(props) =>
    props.$isMobile || props.$isTablet ? "15px" : "20px"};
  font-weight: ${(props) => (props.$isMobile ? "600" : "500")};
  line-height: 140%;
  letter-spacing: ${(props) => (props.$isMobile ? 0 : "-0.2px")};
  text-align: center;
`;

const TabWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$langValue === "KR" ? "180px" : "375px"};
  height: ${(props) => (props.$isMobile ? "62px" : "59px")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$activeTab === props.id ? "3px solid #00AEEF" : "1px solid #ADB4BA"};
`;
