import React, { forwardRef } from "react";

import Gavs from "./renewalDetail/minsu/Gavs";
import LumirX from "./renewalDetail/satelliteSystem/LumirX";
import Cas5005 from "./renewalDetail/satelliteSystem/Cas5005";
import Catis from "./renewalDetail/mountedDevice/Catis";
import KaPA from "./renewalDetail/mountedDevice/KaPA";
import Dtnpl from "./renewalDetail/mountedDevice/Dtnpl";
import Idhu from "./renewalDetail/mountedDevice/Idhu";
import Obc from "./renewalDetail/mountedDevice/Obc";
import Cmgc from "./renewalDetail/mountedDevice/Cmgc";
import XdmEgse from "./renewalDetail/electric/XdmEgse";
import K7pEgse from "./renewalDetail/electric/K7pEgse";
import { useRecoilValue } from "recoil";
import { langState } from "../../store/langState";

const DetailPages = forwardRef(({ selectedMenu }, ref) => {
  const langValue = useRecoilValue(langState);

  const renderComponent = () => {
    switch (selectedMenu) {
      case "LumirX":
        return <LumirX langValue={langValue} />;
      case "CAS500-5":
        return <Cas5005 langValue={langValue} />;
      case "CATIS™":
      case "CATIS%E2%84%A2":
        return <Catis langValue={langValue} />;
      case "KAPA":
        return <KaPA langValue={langValue} />;
      case "DTNPL":
        return <Dtnpl langValue={langValue} />;
      case "IDHU":
        return <Idhu langValue={langValue} />;
      case "OBC":
        return <Obc langValue={langValue} />;
      case "CMGC":
        return <Cmgc langValue={langValue} />;
      case "XDM-EGSE":
        return <XdmEgse langValue={langValue} />;
      case "K7P-EGSE":
        return <K7pEgse langValue={langValue} />;
      case "GAVS":
        return <Gavs langValue={langValue} />;
      default:
        return null;
    }
  };

  return <div ref={ref}>{renderComponent()}</div>;
});

export default DetailPages;
