import React from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import PretendardText from "../../../components/atoms/text/pretendardText";
import MainSection from "../../../components/templates/mainSection";
import useDisplay from "../../../hooks/useDisplay";
import { mainBannerLang } from "../../../lang/lang";
import { introduce } from "../../../lang/renewal/introduceLang";
import { langState } from "../../../store/langState";
import { root } from "../../../styles/GlobalStyle";
import MiddleNavBar from "./../../../components/templates/middleNavBar";
import { introduceLang } from "./../../../lang/CompanyIntroduction/introduction";

const IntroduceRenewal = () => {
  const { pathname } = useLocation();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const langValue = useRecoilValue(langState);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/renewal/introduce/overview/bg-header.png"
            : "/assets/imgs/renewal/introduce/overview/bg-header.png"
        }
        title={mainBannerLang.introduce.title[langValue]}
        content={mainBannerLang.introduce.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isSmall={isSmall} $isDesktop={isDesktop}>
        <SectionWrapper>
          <MainTitle $isDesktop={isDesktop}>
            {introduce.title[langValue]}
          </MainTitle>
        </SectionWrapper>
        <SectionWrapper>
          <div style={{ width: "100%", marginBottom: "1.9rem" }}>
            <TitleText
              $isDesktop={isDesktop}
              dangerouslySetInnerHTML={{
                __html: introduce.info.title[langValue],
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isDesktop ? "row" : "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <ContentText
              style={{ width: isMobile ? "100%" : "", marginBottom: "2rem" }}
            >
              {introduce.info.content[langValue]}
            </ContentText>
            <Image
              src="/assets/imgs/renewal/introduce/overview/LumirX_Sola4.png"
              alt="entrance"
            />
          </div>
          <InfoSection $isDesktop={isDesktop}>
            {introduce.info[langValue].map((item, index) => (
              <InfoItem key={index} $isDesktop={isDesktop}>
                <Divider />
                <InfoTitle>{item.title}</InfoTitle>
                {item?.content && (
                  <Content
                    $Color={root.fontGray}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                )}
              </InfoItem>
            ))}
          </InfoSection>
        </SectionWrapper>
        <SectionWrapper>
          <TitleText
            dangerouslySetInnerHTML={{
              __html: introduce.philosophy.title[langValue],
            }}
          />
          <PhilosophyContents $isDesktop={isDesktop}>
            {introduce.philosophy[langValue].map((item, index) => (
              <PhilosophyCard key={index} $isDesktop={isDesktop}>
                <PhilosophyNumber $isDesktop={isDesktop}>
                  {index + 1}
                </PhilosophyNumber>
                <PhilosophyTitle $isMobile={isMobile}>
                  {item.title}
                </PhilosophyTitle>
                <PhilosophyContent
                  $isDesktop={isDesktop}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </PhilosophyCard>
            ))}
          </PhilosophyContents>
        </SectionWrapper>
        <SectionWrapper>
          <TitleText
            $isDesktop={isDesktop}
            dangerouslySetInnerHTML={{ __html: introduce.ceo.title[langValue] }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "flex-start",
              padding: isTablet ? "0 70px" : "0",
            }}
          >
            <div>
              <img
                src="/assets/imgs/introduce/ceo.png"
                alt=""
                style={{
                  objectFit: "contain",
                  width: isMobile || isTablet ? "100%" : "",
                  height: "100%",
                }}
              />
            </div>
            <CeoGreeting
              $isDesktop={isDesktop}
              $isTablet={isTablet}
              style={{ width: isTablet ? "100%" : "" }}
            >
              <TitleText
                $isDesktop={isDesktop}
                $isMobile={isMobile}
                style={{
                  marginBottom: isMobile ? "15px" : "20px",
                  marginTop: 0,
                }}
              >
                {introduceLang.section2?.ceoTitle[langValue]}
              </TitleText>
              <CaptionText
                style={{
                  fontWeight: 400,
                  color: "#5a636a",
                  marginBottom: "10px",
                }}
              >
                {introduceLang.section2?.ceoContent01[langValue]}
              </CaptionText>
              <CaptionText
                style={{
                  fontWeight: 400,
                  color: "#5a636a",
                  marginBottom: isMobile ? "10px" : "20px",
                }}
              >
                {introduceLang.section2?.ceoContent02[langValue]}
              </CaptionText>
              <CaptionText
                style={{
                  fontWeight: 400,
                  color: "#5a636a",
                  marginBottom: "10px",
                }}
              >
                {introduceLang.section2?.ceoContent03[langValue]}
              </CaptionText>
              <CaptionText
                style={{
                  fontWeight: 400,
                  color: "#5a636a",
                  marginBottom: "10px",
                }}
              >
                {introduceLang.section2?.ceoContent04[langValue]}
              </CaptionText>
              <CaptionText
                style={{
                  fontWeight: 400,
                  color: "#5a636a",
                  marginBottom: "20px",
                }}
              >
                {introduceLang.section2?.ceoContent05[langValue]}
              </CaptionText>
              <CaptionText style={{ color: "#5a636a" }}>
                {introduceLang.section2?.ceoContent06[langValue]}
              </CaptionText>
            </CeoGreeting>
          </div>
        </SectionWrapper>
        {/* 회사조직 */}
        <SectionWrapper>
          <TitleText
            $isDesktop={isDesktop}
            dangerouslySetInnerHTML={{
              __html: introduce.organization.title[langValue],
            }}
          />
          <div style={{ textAlign: "center" }}>
            <Image
              src="/assets/imgs/renewal/introduce/overview/조직도.png"
              alt="조직도"
            />
          </div>
        </SectionWrapper>
        {/* 심벌마크 */}
        <SectionWrapper
          style={{
            margin: "0 0 100px 0",
            flexDirection: "column",
            alignItems: "center",
          }}
          $isTablet={isTablet}
        >
          <TitleText
            $isDesktop={isDesktop}
            dangerouslySetInnerHTML={{ __html: introduce.ci.title[langValue] }}
            style={{ marginBottom: "6.3rem" }}
          />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "7.2rem" }}
          >
            <Ci $isDesktop={isDesktop}>
              <CiTitle>{introduce.ci.contents[langValue].symbol.title}</CiTitle>
              <CiContents $isDesktop={isDesktop}>
                <div>
                  <img
                    src={introduce.ci.contents[langValue].symbol.img}
                    alt="symbol"
                  />
                </div>
                <div>
                  <CiSubtitle
                    dangerouslySetInnerHTML={{
                      __html: introduce.ci.contents[langValue].symbol.subTitle,
                    }}
                  />
                  {introduce.ci.contents[langValue].symbol.desc.map(
                    (desc, index) => (
                      <CiDesc key={index}>{desc}</CiDesc>
                    )
                  )}
                </div>
              </CiContents>
            </Ci>
            <Ci $isDesktop={isDesktop}>
              <CiTitle>
                {introduce.ci.contents[langValue].colorSystem.title}
              </CiTitle>
              <CiContents $isDesktop={isDesktop}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                  }}
                >
                  {introduce.ci.contents[langValue].colorSystem.colors.map(
                    (color, index) => (
                      <div key={index} style={{ textAlign: "center" }}>
                        <img
                          src={color.img}
                          alt="color"
                          style={{
                            width: isMobile ? "100px" : "130px",
                          }}
                        />
                        <ColorInfo>
                          <p>
                            CMYK: <span>{color.cmyk}</span>
                          </p>
                          <p>
                            RGB: <span>{color.rgb}</span>
                          </p>
                        </ColorInfo>
                      </div>
                    )
                  )}
                </div>
                <div>
                  <CiSubtitle
                    dangerouslySetInnerHTML={{
                      __html:
                        introduce.ci.contents[langValue].colorSystem.subTitle,
                    }}
                  />
                  {introduce.ci.contents[langValue].colorSystem.desc.map(
                    (desc, index) => (
                      <CiDesc key={index}>{desc}</CiDesc>
                    )
                  )}
                </div>
              </CiContents>
            </Ci>
          </div>
        </SectionWrapper>
      </PageWrapper>
    </>
  );
};

export default IntroduceRenewal;

const Image = styled.img`
  max-width: 100%;
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.$isSmall ? "30px" : "120px")};
  padding: ${(props) => (props.$isDesktop ? "0" : "0 2rem")};
`;

const MainTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isDesktop ? "12rem" : "6rem")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
`;

const InfoSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.$isDesktop ? "repeat(5, 1fr)" : "repeat(1, 1fr)"};
  flex-direction: column;
  gap: 3rem;
  padding-top: ${(props) => (props.$isDesktop ? "10rem" : "5rem")};
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${(props) => (props.$isDesktop ? "20rem" : "auto")};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a8a8a8;
  margin-bottom: 1.8rem;
`;

const InfoTitle = styled.h6`
  font-size: 1.9rem;
  font-weight: 700;
`;

const Content = styled.p`
  color: ${(props) => props.$Color};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
`;

const PhilosophyContents = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$isDesktop ? "repeat(2, 1fr)" : "repeat(1, 1fr)"};
  background-image: url("/assets/imgs/renewal/introduce/overview/bg_check.png");
  background-size: cover;
  width: 100%;
  height: 50%;
  border: 1px dashed #b8b8b8;
`;

const PhilosophyNumber = styled.div`
  position: ${(props) => (props.$isDesktop ? "absolute" : "relative")};
  font-size: 2.4rem;
  color: #262c31;
  background-color: #ffffff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid #262c31;
  text-align: center;
  line-height: 4rem;
  margin: ${(props) => (props.$isDesktop ? "2rem" : "0 0 2rem 0")};
`;

const PhilosophyCard = styled.div`
  position: relative;
  height: 29rem;
  padding: ${(props) => (props.$isDesktop ? "0 10rem" : "0 2rem")};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.$isDesktop
      ? `
    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px dashed #b8b8b8;
    }
    
    &:nth-child(1),
    &:nth-child(3) {
      border-right: 1px dashed #b8b8b8;
      ${PhilosophyNumber} {
        top: 0;
        left: 0;
      }
    }
    
    &:nth-child(2),
    &:nth-child(4) {
      ${PhilosophyNumber} {
        top: 0;
        right: 0;
      }
    }
    
  `
      : ` &:nth-child(1),  &:nth-child(2),  &:nth-child(3) {
      border-bottom: 1px dashed #b8b8b8;
      }`}
`;

const PhilosophyTitle = styled.h6`
  font-size: 2.4rem;
  font-weight: 600;
  color: #262c31;
  margin-bottom: 2rem;
`;

const PhilosophyContent = styled.p`
  font-size: ${(props) => (props.$isDesktop ? "2.4rem" : "2.2rem")};
  font-weight: 400;
  color: #262c31;
  & > span {
    color: rgba(0, 174, 239, 1);
  }
  & > b {
    color: rgba(0, 174, 239, 1);
  }
  & > p {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

const TitleText = styled(PretendardText)`
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
  white-space: pre-wrap;
  & > span {
    font-size: 2.3rem;
  }
  margin-top: ${(props) => (props.$isDesktop ? "16rem" : "5rem")};
  margin-bottom: 2.5rem;
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #5a636a;
  white-space: pre-wrap;
`;

const CaptionText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: pre-wrap;
`;

const CeoGreeting = styled.div`
  margin-top: ${(props) => (props.$isDesktop ? "88px" : "40px")};
  margin-left: ${(props) =>
    props.$isDesktop || props.$isTablet ? "-90px" : "0"};
  padding: ${(props) =>
    props.$isDesktop
      ? "40px 10px 40px 40px"
      : props.$isTablet
      ? "20px"
      : "0 20px"};
  background-color: #fff;
`;

const Ci = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  justify-content: space-between;
  gap: 2rem;
`;

const CiTitle = styled.h6`
  font-size: 2.5rem;
  font-weight: 600;
  color: #262c31;
  margin-bottom: 2rem;
`;

const CiContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  width: ${(props) => (props.$isDesktop ? "100rem" : "100%")};
  margin-right: 20rem;
`;

const CiSubtitle = styled.h6`
  font-size: 2.4rem;
  font-weight: 600;
  color: #262c31;
  margin-bottom: 2rem;

  & > span {
    color: #00aeef;
  }
`;

const CiDesc = styled.p`
  font-size: 2.2rem;
  font-weight: 400;
  color: #8a8a8a;
  margin-bottom: 1rem;
`;

const ColorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 3.2rem;

  & > p > span {
    color: #778088;
  }
`;
