import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import PretendardText from "../../../components/atoms/text/pretendardText";
import MenuItem from "../../../components/templates/business/MenuItem";
import { useBodyScrollLock } from "../../../hooks/useBodyScrollLock";
import DetailPages from "../DetailPages";

const MenuSection = ({
  title = "",
  description = "",
  menuList = [],
  bgColor = "black",
  hash = "",
  isActive,
  activeDevice,
  isMobile,
  isDesktop,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false); // Offcanvas 상태
  const [selectedMenu, setSelectedMenu] = useState(""); // 선택된 메뉴
  const [isBgVisible, setIsBgVisible] = useState(false); // 배경 레이어 상태
  const detailPagesRef = useRef(null); // DetailPages 컴포넌트 ref

  const { lockScroll, openScroll } = useBodyScrollLock(); // 스크롤 잠금 훅

  useEffect(() => {
    if (isActive && activeDevice && !isOffcanvasOpen) {
      handleOpenOffcanvas(activeDevice);
    }
  }, [isActive, activeDevice]);

  useEffect(() => {
    if (isOffcanvasOpen) {
      setIsBgVisible(true);
      lockScroll();
    } else {
      const timer = setTimeout(() => setIsBgVisible(false), 1000);
      openScroll();
      return () => clearTimeout(timer);
    }
  }, [isOffcanvasOpen, lockScroll, openScroll]);

  useEffect(() => {
    const handlePopState = () => {
      if (isOffcanvasOpen) {
        handleCloseOffcanvas();
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      openScroll();
    };
  }, [isOffcanvasOpen, openScroll]);

  const handleOpenOffcanvas = (title) => {
    setIsOffcanvasOpen(true);
    setSelectedMenu(title);
    navigate(`${location.pathname}#${hash}?device=${title}`);
  };

  const handleCloseOffcanvas = () => {
    setIsOffcanvasOpen(false);
    setIsBgVisible(false);
    if (detailPagesRef.current) {
      const contentsWrapper = detailPagesRef.current.querySelector(
        '[class^="Layout__ContentsWrapper"]'
      );
      if (contentsWrapper) {
        contentsWrapper.scrollTo(0, 0);
      }
    }
    navigate(`${location.pathname}#${hash}`, { replace: true });
  };

  return (
    <>
      <SectionWrapper>
        <Title $isMobile={isMobile} style={{ marginBottom: "16px" }}>
          {title}
        </Title>
        <ContentText
          $isMobile={isMobile}
          style={{
            color: "#5A636A",
            marginBottom: isMobile ? "40px" : "30px",
          }}
          dangerouslySetInnerHTML={{
            __html: isDesktop
              ? description
              : description.split("<br/>").join(""),
          }}
        />
        <LinkList>
          {menuList.map((menu) => (
            <MenuItem
              onClick={() => handleOpenOffcanvas(menu.title)}
              key={menu.title}
              label={menu.title}
              link={menu.link}
              img={menu.img}
              bgColor={bgColor}
            />
          ))}
        </LinkList>
      </SectionWrapper>
      {isDesktop && (
        <OffcanvasBg
          $isVisible={isBgVisible}
          $isOpen={isOffcanvasOpen}
          $bgColor={bgColor}
          onClick={handleCloseOffcanvas}
        />
      )}
      <OffcanvasWrapper $isOpen={isOffcanvasOpen} $isDesktop={isDesktop}>
        <DetailPages ref={detailPagesRef} selectedMenu={selectedMenu} />
        <CloseButton onClick={handleCloseOffcanvas}>{`←`}</CloseButton>
      </OffcanvasWrapper>
    </>
  );
};

export default MenuSection;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "6rem" : "9rem")};
  font-weight: 600;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.18px" : "-0.24px")};
  word-break: keep-all;
  overflow-wrap: break-word;
  line-height: 1.2;
  max-width: 100%;
`;

const ContentText = styled(PretendardText)`
  margin-top: ${(props) => (props.$isMobile ? "2rem" : "5rem")};
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #000;
`;

const OffcanvasBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  opacity: ${(props) => (props.$isOpen ? "1" : "0")};
  transition: opacity 1s ease;
  z-index: ${(props) => (props.$isVisible ? "999" : "-1")};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const OffcanvasWrapper = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.$isOpen ? "0" : "-100%")};
  width: ${({ $isDesktop }) => ($isDesktop ? "88%" : "100%")};
  height: 100%;
  transition: right 1.2s;
  z-index: 1000;
  /* overflow: ${({ $isDesktop }) => ($isDesktop ? "" : "scroll")}; */
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${fadeIn} 1s ease
        `
      : css`
          ${fadeOut} 1s ease
        `};
`;

const CloseButton = styled.button`
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  background: none;
  border: none;
  font-size: 24px;
`;
