import styled, { keyframes } from "styled-components";

const KickinText = ({
  children,
  direction = "bottomToTop",
  duration = "1s",
  delay = "0s",
  timingFunction = "ease-out",
  fontSize,
  fontWeight,
  color,
  ...props
}) => {
  return (
    <AnimatedTextContainer
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      {...props}
    >
      <AnimatedTextWrapper
        direction={direction}
        duration={duration}
        delay={delay}
        timingFunction={timingFunction}
      >
        {children}
      </AnimatedTextWrapper>
    </AnimatedTextContainer>
  );
};

export default KickinText;

// 다양한 방향의 애니메이션 정의
const revealAnimations = {
  bottomToTop: keyframes`
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  `,
  topToBottom: keyframes`
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  `,
  leftToRight: keyframes`
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  `,
  rightToLeft: keyframes`
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  `,
};

const AnimatedTextContainer = styled.div`
  overflow: hidden;
  font-size: ${(props) => props.fontSize || "inherit"};
  font-weight: ${(props) => props.fontWeight || "inherit"};
  color: ${(props) => props.color || "inherit"};
`;

const AnimatedTextWrapper = styled.div`
  transform: translateX(100%);
  animation: ${(props) => revealAnimations[props.direction]}
    ${(props) => props.duration} ${(props) => props.timingFunction} forwards;
  animation-delay: ${(props) => props.delay};
`;
