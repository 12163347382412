import { useState, useEffect } from "react";
import {
  Content,
  Title,
  Description,
  ImagesWrapper,
  Images,
  ImageContainer,
  ImageWrap,
} from "./WeDo.styles";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import FramerWipe from "../../../../styles/framerWipe";
import styled from "styled-components";

const LoadingPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 1.2em;
`;

const LightweightSection = ({ isMobile, data }) => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    data.images.forEach((img, index) => {
      const image = new Image();
      image.src = img;
      image.onload = () => {
        setLoadedImages((prev) => [...prev, index]);
      };
    });
  }, [data.images]);

  return (
    <Content>
      <Title>{data.title}</Title>
      <Description>{data.description}</Description>
      <ImagesWrapper $isMobile={isMobile} $isLightweightSection>
        <Images $isLightweightSection>
          {data.images.map((img, index) => (
            <ImageContainer
              key={index}
              $isMobile={isMobile}
              $index={index}
              $isLightweightSection
            >
              <ImageWrap $index={index} $isLightweightSection>
                <FramerSlideIn>
                  <FramerWipe>
                    {loadedImages.includes(index) ? (
                      <img
                        src={img}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={`Lightweight ${index + 1}`}
                      />
                    ) : (
                      <LoadingPlaceholder>Loading...</LoadingPlaceholder>
                    )}
                  </FramerWipe>
                </FramerSlideIn>
              </ImageWrap>
            </ImageContainer>
          ))}
        </Images>
      </ImagesWrapper>
    </Content>
  );
};

export default LightweightSection;
