import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import PretendardText from "../../../components/atoms/text/pretendardText";
import Pagination from "../../../components/organisms/pagination";
import EMCard from "../../../components/organisms/investment/emCard";

import useDisplay from "../../../hooks/useDisplay";
import EMCardM from "../../../components/organisms/investment/emCardM";
import PaginationTemp from "../../../components/organisms/paginationTemp";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { performanceLang } from "../../../lang/investment/performanceLang";

const EventMaterials = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [dataList, setDataList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_ir_event.png"
            : "/assets/imgs/main-section/ir_event.png"
        }
        title={mainBannerLang.eventMaterials.title[langValue]}
        content={mainBannerLang.eventMaterials.content[langValue]}
        blackText={true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {performanceLang.biTitle[langValue]}
        </PretendardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: isMobile ? 40 : 30,
            marginBottom: isMobile ? 50 : 120,
            gap: isTablet ? "30px" : 0,
          }}
        >
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {langValue === "KR" ? "사업명" : "Title"}
            </QuarterText>
            <Division $isSmall={isSmall} />
            <QuarterImg $isSmall={isSmall}></QuarterImg>
            <DownLoadBtn $isSmall={isSmall}>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "15px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "21px" : "26.1px",
                }}
              >
                {performanceLang.download[langValue]}
              </PretendardText>
            </DownLoadBtn>
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {langValue === "KR" ? "사업명" : "Title"}
            </QuarterText>
            <Division $isSmall={isSmall} />
            <QuarterImg $isSmall={isSmall}></QuarterImg>
            <DownLoadBtn $isSmall={isSmall}>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "15px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "21px" : "26.1px",
                }}
              >
                {performanceLang.download[langValue]}
              </PretendardText>
            </DownLoadBtn>
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {langValue === "KR" ? "사업명" : "Title"}
            </QuarterText>
            <Division $isSmall={isSmall} />
            <QuarterImg $isSmall={isSmall}></QuarterImg>
            <DownLoadBtn $isSmall={isSmall}>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "15px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "21px" : "26.1px",
                }}
              >
                {performanceLang.download[langValue]}
              </PretendardText>
            </DownLoadBtn>
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              {langValue === "KR" ? "사업명" : "Title"}
            </QuarterText>
            <Division $isSmall={isSmall} />
            <QuarterImg $isSmall={isSmall}></QuarterImg>
            <DownLoadBtn $isSmall={isSmall}>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "15px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "21px" : "26.1px",
                }}
              >
                {performanceLang.download[langValue]}
              </PretendardText>
            </DownLoadBtn>
          </QuarterWrapper>
        </div>

        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
            marginBottom: isSmall ? 40 : 51,
          }}
        >
          {performanceLang.eventMaterials[langValue]}
        </PretendardText>

        <div
          style={{
            borderTop: "2px solid #778088",
            borderBottom: "2px solid #778088",
            width: "100%",
          }}
        >
          {dataList.length > 0
            ? dataList.map((item, index) => {
                return isMobile ? (
                  <EMCardM
                    key={index}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    title={
                      langValue === "KR"
                        ? "행사 제목이 이곳에 들어갑니다."
                        : "Title"
                    }
                    date={
                      langValue === "KR" ? "2024년 1월 2일" : "January 2, 2024"
                    }
                    place={langValue === "KR" ? "서울" : "Seoul"}
                    number={index + 1}
                  />
                ) : (
                  <EMCard
                    key={index}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    title={
                      langValue === "KR"
                        ? "행사 제목이 이곳에 들어갑니다."
                        : "Title"
                    }
                    date={
                      langValue === "KR" ? "2024년 1월 2일" : "January 2, 2024"
                    }
                    place={langValue === "KR" ? "서울" : "Seoul"}
                    number={index + 1}
                  />
                );
              })
            : null}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: isSmall ? 40 : 60,
          }}
        >
          <PaginationTemp />
        </div>
      </BodyConatin>
    </>
  );
};

export default EventMaterials;

const QuarterWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$isTablet ? "47.5%" : "320px"};

  border: 1px solid rgb(173, 180, 186);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-bottom: ${(props) => (props.$isMobile ? "30px" : "0px")};
`;

const QuarterText = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) => (props.$isSmall ? "16px" : "20px")};
  font-weight: ${(props) => (props.$isSmall ? "600" : "500")};
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "29px")};
`;

const QuarterImg = styled.img`
  width: 152px;
  height: 190px;
  margin-bottom: ${(props) => (props.$isSmall ? "15px" : "10px")};
`;

const Division = styled.div`
  width: 100%;
  margin: ${(props) => (props.$isSmall ? "15px 0px" : "20px 0px")};
  border-top: 1px solid rgb(173, 180, 186);
`;

const DownLoadBtn = styled.button`
  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
