import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";
import FramerSlideIn from "../../../styles/framerSlideIn";
import FramerWipe from "../../../styles/framerWipe";
import VideoHandler from "./common/VideoHandler";
import { SERVICE_BG, weService } from "../../../lang/renewal/homeLang";

const WeService = ({ langValue }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <Container $isMobile={isMobile} $isDesktop={isDesktop}>
      <LeftContents $isMobile={isMobile} $isTablet={isTablet}>
        We service
      </LeftContents>
      <RightContents $isMobile={isMobile}>
        <Content>
          <MainTitle>{weService[langValue].title}</MainTitle>
          <Description
            $isMobile={isMobile}
            dangerouslySetInnerHTML={{
              __html: weService[langValue].description,
            }}
          />
        </Content>
        <AIContent $isMobile={isMobile}>
          <div>
            <Title
              dangerouslySetInnerHTML={{
                __html: weService.services[0].title[langValue],
              }}
            />
            <ImageContainer>
              <ImageWrap>
                <FramerSlideIn>
                  <FramerWipe>
                    <VideoHandler
                      imageUrl={weService.services[0].image}
                      mediaUrl={
                        isDesktop
                          ? weService.services[0].video.desktop
                          : weService.services[0].video.mobile
                      }
                    />
                  </FramerWipe>
                </FramerSlideIn>
              </ImageWrap>
            </ImageContainer>
          </div>
        </AIContent>
        <InSarContent $isMobile={isMobile}>
          <div>
            <Title
              dangerouslySetInnerHTML={{
                __html: weService.services[1].title[langValue],
              }}
            />
            <ImageContainer>
              <ImageWrap>
                <FramerSlideIn>
                  <FramerWipe>
                    <VideoHandler
                      imageUrl={weService.services[1].image}
                      mediaUrl={
                        isDesktop
                          ? weService.services[1].video.desktop
                          : weService.services[1].video.mobile
                      }
                    />
                  </FramerWipe>
                </FramerSlideIn>
              </ImageWrap>
            </ImageContainer>
          </div>
        </InSarContent>
      </RightContents>
    </Container>
  );
};

export default WeService;

const Container = styled.div`
  min-height: 100vh;
  background-image: url(${SERVICE_BG});
  background-size: cover;
  background-position: bottom;
  background-color: white;
  padding: ${(props) =>
    !props.$isDesktop ? "9em 2em 9em 3em" : "9em 0 9em 13.5em"};
  display: ${({ $isMobile }) => ($isMobile ? "" : "flex")};
  justify-content: space-between;
  gap: 3em;
`;

const LeftContents = styled.div`
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  top: 4em;
  height: 100%;
  padding-bottom: ${({ $isMobile, $isTablet }) =>
    $isMobile ? "1em" : $isTablet ? "25vh" : "70vh"};
  font-size: 2.5em;
  font-weight: 600;
  z-index: 2;
`;

const RightContents = styled.div`
  width: ${({ $isMobile }) => ($isMobile ? "" : "80em")};
  display: flex;
  flex-direction: column;
  gap: 7em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
`;

const AIContent = styled(Content)`
  position: relative;
  display: flex;
  justify-content: flex-end; // 내용을 오른쪽으로 정렬합니다.
  width: 100%; // 전체 너비를 사용합니다.

  & > div {
    width: ${(props) => (props.$isMobile ? "100%" : "80%")};
    margin-left: auto;
  }
`;

const InSarContent = styled(Content)`
  & > div {
    width: ${(props) => (props.$isMobile ? "100%" : "80%")};
  }
`;

const MainTitle = styled.h2`
  font-size: 4.2em;
  text-align: left;
`;

const Description = styled.p`
  font-size: 1.3em;
  text-align: left;
  line-height: 1.5em;
  padding-right: ${(props) => (props.$isMobile ? "0" : "10em")};
`;

const Title = styled.h4`
  font-size: 1.7em;
  text-align: left;
  & > span {
    font-size: 4vw;
  }
`;

const ImageContainer = styled.div`
  transform: translate3d(0px, 0px, 0px);
  overflow: visible;
  width: 100%;
  position: relative;
`;

const ImageWrap = styled.div`
  width: 100%;
  height: auto;
  overflow: visible;
  transition: all 0.3s ease-in-out;
`;
