import styled from "styled-components";
import { root } from "../../../../styles/GlobalStyle";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import useDisplay from "../../../../hooks/useDisplay";

const DescriptDevice = ({ deviceList }) => {
  const { isMobile } = useDisplay();

  return (
    <Section>
      {deviceList?.map((device, index) => (
        <ContentsContainer key={index}>
          <FramerSlideIn>
            <TextItem>
              <TextTitle>{device.title}</TextTitle>
              <Content
                dangerouslySetInnerHTML={{ __html: device.content }}
                $Color={root.fontGray}
              />
            </TextItem>
          </FramerSlideIn>
          {device?.image && (
            <GridContainer $isMobile={isMobile}>
              <FramerSlideIn>
                <ImageContainer $isMobile={isMobile}>
                  <Image src={device.image} />
                </ImageContainer>
              </FramerSlideIn>
              <FeatureContainer $isMobile={isMobile}>
                {device.descriptions?.map((description, index) => (
                  <FramerSlideIn key={index}>
                    <FeatureItem key={index}>
                      <Divider />
                      <Title>{description}</Title>
                    </FeatureItem>
                  </FramerSlideIn>
                ))}
              </FeatureContainer>
            </GridContainer>
          )}
        </ContentsContainer>
      ))}
    </Section>
  );
};

export default DescriptDevice;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 7rem;
`;

const ContentsContainer = styled.div``;

const TextItem = styled.div`
  margin-bottom: 2.9rem;
`;

const TextTitle = styled.h6`
  font-size: 2.3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const Content = styled.p`
  color: ${(props) => props.$Color};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? "1fr" : "4fr 8fr")};
  gap: ${({ $isMobile }) => ($isMobile ? "3rem" : "4.6rem")};
  align-items: end;
  justify-items: center;
`;

const ImageContainer = styled.div`
  background: #316aba1a;
  width: 100%;
  aspect-ratio: ${({ $isMobile }) => ($isMobile ? "16 / 9" : "1 / 1")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ $isMobile }) => ($isMobile ? "3rem" : "4.6rem")};
  width: 100%;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a8a8a8;
  margin-bottom: 1.8rem;
`;

const Title = styled.h6`
  font-size: 1.9rem;
  font-weight: 700;
`;
