import React, { useEffect, useRef, useState } from "react";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "./../../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import PretendardText from "../../../components/atoms/text/pretendardText";
import RnDCard from "./../../../components/templates/rndCard";
import RnDModalContainer from "./rndModal.jsx/rndModalContainer";
import RnDMenuBar from "../../../components/templates/rndMenuBar";
import { useInView } from "react-intersection-observer";
import FramerFadeIn from "../../../styles/framerFadeIn";
import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { useRecoilValue } from "recoil";
import { mainBannerLang } from "../../../lang/lang";
import { nationalRnDLang } from "../../../lang/CompanyIntroduction/nationalRnD";

const NationalRnD = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [view, setView] = useState(0);

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();

  const [inviewRef1, inView1] = useInView({
    threshold: 0,
    onChange: (inview) => {
      if (inview) setView(1);
    },
  });
  const [inviewRef2, inView2] = useInView({
    threshold: 0.7,
    onChange: (inview) => {
      if (inview) setView(2);
    },
  });
  const [inviewRef3, inView3] = useInView({
    threshold: 0.7,
    onChange: (inview) => {
      if (inview) setView(3);
    },
  });
  const [inviewRef4, inView4] = useInView({
    threshold: 0.7,
    onChange: (inview) => {
      if (inview) setView(4);
    },
  });

  const moalFunc = (id, title) => {
    setId(id);
    setTitle(title);
    setIsModalOpen(true);
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_nationalRnD.png"
            : "/assets/imgs/main-section/nationalRnD.png"
        }
        title={mainBannerLang.rnd.title[langValue]}
        content={mainBannerLang.rnd.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isMobile={isMobile} $isTablet={isTablet}>
        <FirstSection $isMobile={isMobile} $isTablet={isTablet}>
          <SubTitle $isMobile={isMobile} style={{ marginBottom: "15px", textAlign: "center" }}>
            {nationalRnDLang.section1?.title[langValue]}
          </SubTitle>
          <ContentText
            $isMobile={isMobile}
            style={{ marginBottom: isMobile ? "30px" : "50px", textAlign: "center" }}
          >
            {nationalRnDLang.section1?.subTitle[langValue]}
          </ContentText>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: langValue === "KR" ? 0 : isMobile ? "30px" : "40px",
            }}
          >
            <FramerFadeIn
              delay={0.3}
              style={{
                border: langValue === "EN" ? "" : "1px solid #00AEEF",
                borderRadius: langValue === "EN" ? "" : "50%",
                backgroundColor: langValue === "EN" ? "" : "#fff",
                width: langValue === "EN" ? "" : "250px",
                height: langValue === "EN" ? "" : "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RnDItemWrapper $langValue={langValue}>
                <img src="/assets/icons/satellite.png" />
                <ContentText $isMobile={isMobile} style={{ fontWeight: 600, textAlign: "center" }}>
                  {nationalRnDLang.section1?.content1[langValue]}
                </ContentText>
              </RnDItemWrapper>
            </FramerFadeIn>
            {langValue === "KR" ? (
              <KrLine $isMobile={isMobile} />
            ) : isMobile ? (
              <HorizontalDashedLine />
            ) : (
              <VerticalLine />
            )}
            <FramerFadeIn
              delay={0.5}
              style={{
                border: langValue === "EN" ? "" : "1px solid #00AEEF",
                borderRadius: langValue === "EN" ? "" : "50%",
                backgroundColor: langValue === "EN" ? "" : "#fff",
                width: langValue === "EN" ? "" : "250px",
                height: langValue === "EN" ? "" : "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RnDItemWrapper $langValue={langValue}>
                <img src="/assets/icons/tech.png" />
                <ContentText $isMobile={isMobile} style={{ fontWeight: 600, textAlign: "center" }}>
                  {nationalRnDLang.section1?.content2[langValue]}
                </ContentText>
              </RnDItemWrapper>
            </FramerFadeIn>
            {langValue === "KR" ? (
              <KrLine $isMobile={isMobile} />
            ) : isMobile ? (
              <HorizontalDashedLine />
            ) : (
              <VerticalLine />
            )}
            <FramerFadeIn
              delay={0.7}
              style={{
                border: langValue === "EN" ? "" : "1px solid #00AEEF",
                borderRadius: langValue === "EN" ? "" : "50%",
                backgroundColor: langValue === "EN" ? "" : "#fff",
                width: langValue === "EN" ? "" : "250px",
                height: langValue === "EN" ? "" : "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RnDItemWrapper $langValue={langValue}>
                <img src="/assets/icons/development.png" />
                <ContentText $isMobile={isMobile} style={{ fontWeight: 600, textAlign: "center" }}>
                  {nationalRnDLang.section1?.content3[langValue]}
                </ContentText>
              </RnDItemWrapper>
            </FramerFadeIn>
          </div>
        </FirstSection>
        <SectionWrapper
          $isMobile={isMobile}
          style={{ paddingTop: isMobile ? "80px" : "120px", justifyContent: "space-between" }}
        >
          {isDesktop ? (
            <RnDMenuBar ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} view={view} />
          ) : null}

          <div style={{ width: isDesktop ? "920px" : "100%", padding: isTablet ? "0 70px" : "" }}>
            <div ref={inviewRef1}>
              <div ref={ref1} />
              <Title $isMobile={isMobile}>{nationalRnDLang.scienceAndICT?.title[langValue]}</Title>
              <HorizontalLine $isMobile={isMobile} />
              <SubTitle style={{ marginBottom: "10px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.scienceAndICT?.category1?.title[langValue]}
              </SubTitle>
              <ContentText style={{ marginBottom: "30px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.scienceAndICT?.category1?.content[langValue]}
              </ContentText>
              <RnDCard
                id={1}
                src={
                  isMobile
                    ? "/assets/imgs/introduce/rnd/m_rnd1.png"
                    : "/assets/imgs/introduce/rnd/rnd1.png"
                }
                title={nationalRnDLang.scienceAndICT?.category1?.product1[langValue]}
                style={{ marginBottom: isMobile ? "20px" : "30px" }}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={() =>
                  moalFunc(1, nationalRnDLang.scienceAndICT?.category1?.product1[langValue])
                }
              />
              <RnDCard
                id={2}
                src={
                  isMobile
                    ? "/assets/imgs/introduce/rnd/m_rnd2.png"
                    : "/assets/imgs/introduce/rnd/rnd2.png"
                }
                title={nationalRnDLang.scienceAndICT?.category1?.product2[langValue]}
                style={{ marginBottom: "20px" }}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={() =>
                  moalFunc(2, nationalRnDLang.scienceAndICT?.category1?.product2[langValue])
                }
              />
              <HorizontalLine />
              <SubTitle style={{ marginBottom: "10px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.scienceAndICT?.category2?.title[langValue]}
              </SubTitle>
              <ContentText style={{ marginBottom: "30px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.scienceAndICT?.category2?.content[langValue]}
              </ContentText>
              <RnDCard
                id={3}
                src={
                  isMobile
                    ? "/assets/imgs/introduce/rnd/m_rnd3.png"
                    : "/assets/imgs/introduce/rnd/rnd3.png"
                }
                title={nationalRnDLang.scienceAndICT?.category2?.product1[langValue]}
                style={{ marginBottom: isMobile ? "60px" : "120px" }}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={() =>
                  moalFunc(3, nationalRnDLang.scienceAndICT?.category2?.product1[langValue])
                }
              />
            </div>
            <div ref={inviewRef2}>
              <div ref={ref2} />
              <Title $isMobile={isMobile}>{nationalRnDLang.defenseScience?.title[langValue]}</Title>
              <HorizontalLine $isMobile={isMobile} />
              <SubTitle style={{ marginBottom: "10px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.defenseScience?.category1?.title[langValue]}
              </SubTitle>
              <ContentText style={{ marginBottom: "30px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.defenseScience?.category1?.content[langValue]}
              </ContentText>
              <RnDCard
                id={4}
                src={"/assets/imgs/introduce/rnd/rnd4.png"}
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "flex-start" : "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={
                        langValue === "EN"
                          ? "/assets/icons/rnd_mark.png"
                          : "/assets/icons/rnd_mark_kr.png"
                      }
                      alt=""
                      style={{ width: isMobile ? "80px" : "" }}
                    />
                    <PretendardText
                      style={{
                        fontWeight: 600,
                        fontSize: isMobile ? "16px" : "",
                        lineHeight: "145%",
                      }}
                    >
                      {nationalRnDLang.defenseScience?.category1?.product1[langValue]}
                    </PretendardText>
                  </div>
                }
                style={{ marginBottom: isMobile ? "60px" : "120px" }}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={() =>
                  moalFunc(4, nationalRnDLang.defenseScience?.category1?.product1[langValue])
                }
              />
            </div>
            <div ref={inviewRef3}>
              <div ref={ref3} />
              <Title $isMobile={isMobile}>{nationalRnDLang.SME?.title[langValue]}</Title>
              <HorizontalLine $isMobile={isMobile} />
              <SubTitle
                $isMobile={isMobile}
                style={{ marginBottom: "10px", fontSize: isMobile ? "16px" : "" }}
              >
                {nationalRnDLang.SME?.category1?.title[langValue]}
              </SubTitle>
              <ContentText style={{ marginBottom: "30px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.SME?.category1?.content[langValue]}
              </ContentText>
              <RnDCard
                id={5}
                src={
                  isMobile
                    ? "/assets/imgs/introduce/rnd/m_rnd5.png"
                    : "/assets/imgs/introduce/rnd/rnd5.png"
                }
                title={nationalRnDLang.SME?.category1?.product1[langValue]}
                style={{ marginBottom: isMobile ? "60px" : "120px" }}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={() => moalFunc(5, nationalRnDLang.SME?.category1?.product1[langValue])}
              />
            </div>
            <div ref={inviewRef4}>
              <div ref={ref4} />
              <Title $isMobile={isMobile}>
                {nationalRnDLang.promotionAssociation?.title[langValue]}
              </Title>
              <HorizontalLine $isMobile={isMobile} />
              <SubTitle style={{ marginBottom: "10px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.promotionAssociation?.category1?.title[langValue]}
              </SubTitle>
              <ContentText style={{ marginBottom: "30px", fontSize: isMobile ? "16px" : "" }}>
                {nationalRnDLang.promotionAssociation?.category1?.content[langValue]}
              </ContentText>
              <RnDCard
                id={6}
                src={
                  isMobile
                    ? "/assets/imgs/introduce/rnd/m_rnd6.png"
                    : "/assets/imgs/introduce/rnd/rnd6.png"
                }
                title={nationalRnDLang.promotionAssociation?.category1?.product1[langValue]}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={() =>
                  moalFunc(6, nationalRnDLang.promotionAssociation?.category1?.product1[langValue])
                }
              />
            </div>
          </div>
        </SectionWrapper>
      </PageWrapper>
      {isModalOpen ? (
        <RnDModalContainer id={id} title={title} setIsModalOpen={setIsModalOpen} />
      ) : null}
    </>
  );
};

export default NationalRnD;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "80px 0" : "120px 0px")};
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  padding: ${(props) => (props.$isMobile ? "80px 20px 0 20px" : "120px 0 0 0")};
  justify-content: space-between;
`;

const FirstSection = styled.div`
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 174, 239, 0.05) 0%, rgba(0, 174, 239, 0) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.$isTablet ? "0 70px" : "")};
  padding-bottom: ${(props) => (props.$isMobile ? "80px" : "120px")};
`;

const RnDItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: ${(props) => (props.$langValue === "KR" ? "" : "24px")};
`;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "20px" : "34px")};
  font-weight: 500;
  line-height: 145%;
  color: #7f2ca7;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.2px" : "-0.68px;")};
`;

const SubTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "18px" : "24px")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.18px" : "-0.24px")};
  white-space: pre-wrap;
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.18px")};
  white-space: pre-wrap;
`;

const VerticalLine = styled.div`
  border-right: 1px dashed #00aeef;
  height: 215px;
  width: 1px;
`;

const HorizontalDashedLine = styled.div`
  border-top: 1px dashed #00aeef;
  height: 1px;
  width: 215px;
`;

const KrLine = styled.div`
  background-color: #00aeef;
  height: ${(props) => (props.$isMobile ? "30px" : "1px")};
  width: ${(props) => (props.$isMobile ? "1px" : "88px")};
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #d7dce2;
  margin: ${(props) => (props.$isMobile ? "30px 0" : "40px 0")};
`;
