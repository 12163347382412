export const explorationComputerData = {
  id: 4,
  createdAt: "2024-04-19T14:13:15.010Z",
  updatedAt: "2024-04-19T15:02:44.392Z",
  pageName: "탑재컴퓨터",
  productName: "탑재컴퓨터",
  subtitle: "OBC, On-board Computer",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/de0b98a3-f750-43b8-a4e1-a1914ebdfaed.png",
  serverFileName: "de0b98a3-f750-43b8-a4e1-a1914ebdfaed.png",
  originFileName: "초소형OBC_신제품 1.png",
  exposure: true,
  __productContents__: [
    {
      id: 74,
      createdAt: "2024-04-19T14:13:15.010Z",
      updatedAt: "2024-05-24T18:12:09.391Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>인공위성의 두뇌에 해당하는 탑재컴퓨터는 인공위성 내의 모든 전장품을 제어하는 핵심 장치이며, 지상국과의 원격통신을</p><p>수행하는 역할을 합니다. 루미르가 개발한 탑재컴퓨터의 경우 정부 주도의 우주개발 사업이 아닌 민간 주도 첫 인공위성 시스템</p><p>개발 사업의 신형 탑재컴퓨터로서의 의미가 큽니다. </p><p><br></p><p>루미르의 탑재컴퓨터는 차세대중형위성 3호(기술검증위성)에 탑재되어 국내 발사체인 누리호 4차로 발사될 예정이며,</p><p>앞으로 우리나라 인공위성 표준 탑재컴퓨터로써 국내 위성 시리즈에 공급될 것입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 75,
      createdAt: "2024-04-19T14:13:15.010Z",
      updatedAt: "2024-05-24T18:12:09.415Z",
      title: "기능 · 용도 · 특징",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>인공위성 시스템에서 위성본체의 탑재컴퓨터는 지상국의로부터의 명령을 수신하여 인공위성 내의 모든 전자장치 전체를 제어하며</p><p>인공위성의모든 상태 정보를 수집하여 지상국에 내려 보내는 인공위성의 핵심 장치입니다.</p><p><br></p><p>탑재컴퓨터는 내장된 Processor 기반으로 Flight Software가 구동되어 인공위성 내의 모든 장치의 제어를 수행하므로</p><p>Processor의 성능이 중요하며, 또한 실시간 동작 제어를 해야 하므로 실시간 운영체계(Real-Time Operating System, RTOS)가</p><p>주요한 성능 요소입니다</p>",
      __productContentImages__: [],
    },
    {
      id: 134,
      createdAt: "2024-05-24T18:12:09.448Z",
      updatedAt: "2024-05-24T18:12:09.448Z",
      title: "img1",
      orderNum: 3,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 3,
          createdAt: "2024-05-24T18:12:09.567Z",
          updatedAt: "2024-05-24T18:12:09.567Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a4c9e961-8782-4056-b126-ebbafbcf2a5c.png",
          serverFileName: "a4c9e961-8782-4056-b126-ebbafbcf2a5c.png",
          originFileName: "Group 1410116746.png",
        },
      ],
    },
    {
      id: 135,
      createdAt: "2024-05-24T18:12:09.579Z",
      updatedAt: "2024-05-24T18:12:09.579Z",
      title: "기능 · 용도 · 특징",
      orderNum: 4,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르가 개발한 탑재컴퓨터는 Processor 성능 면에서 종래의 국내 실용급 위성 탑재컴퓨터 대비 연산능력이 2배 우수하며,</p><p>내부 보드 간 통신을 위성용 차세대 통신 네트워크인 SpaceWire Network으로 구성하여 종래의 CAN 통신 대비 전력소모가 낮고</p><p>원활한 데이터 교환이 수행될 수 있도록 하였습니다.</p><p><br></p><p>탑재컴퓨터는 위성에서 두뇌와 같은 역할을 수행하므로 모든 기능이 이중화(Full Redundancy)로 구현되어 있으며</p><p>오류상황 발생시 즉각 다른 구성품으로 자동으로 동작이 천이되는(Re-configuration) 기능을 가집니다.</p><p>종래에는 해당 Re-configuration 기능을 S/W 방식으로 적용하였으나 S/W 동작의 낮은 신뢰성 및 낮은 응답속도가 문제되었으나,</p><p>루미르 탑재컴퓨터는 이를 H/W 방식(FPGA 로직방식)으로 구현하여 동작의 신뢰성을 획기적으로 높였습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 136,
      createdAt: "2024-05-24T18:12:09.619Z",
      updatedAt: "2024-05-24T18:12:09.619Z",
      title: "img2",
      orderNum: 5,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 4,
          createdAt: "2024-05-24T18:12:09.700Z",
          updatedAt: "2024-05-24T18:12:09.700Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/7fde497e-94fa-418c-b255-a757d215edd1.png",
          serverFileName: "7fde497e-94fa-418c-b255-a757d215edd1.png",
          originFileName: "Group 1410117162.png",
        },
      ],
    },
    {
      id: 137,
      createdAt: "2024-05-24T18:12:09.720Z",
      updatedAt: "2024-05-24T18:12:09.720Z",
      title: "기능 · 용도 · 특징",
      orderNum: 6,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>탑재컴퓨터(OBC, On-board Computer)는 위성 본체의 핵심 유닛이며 위성의 모든 전장품을 제어하는 기능을 수행합니다.</p><p>루미르는 이러한 탑재컴퓨터에 대해 정부주도가 아닌 민간주도의 첫 탑재컴퓨터를 개발하고 있습니다.</p><p>현재 발사모델(FM, Flight Model) 제작을 앞두고 있으며 이러한 탑재컴퓨터는 차세대중형위성 3호기에 탑재되어</p><p>누리호 3차 발사체에 의해 발사 예정입니다.</p><p><br></p><p>차세대중형위성 3호기의 경우 탑재컴퓨터 뿐만 아니라 다양한 국산화 제작품이 포함되어 있으며</p><p>발사 후 우주공간에서 다양한 기술검증 임무를 수행하게 됩니다.</p>",
      __productContentImages__: [],
    },
  ],
};
