import React, { useEffect, useState } from "react";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "./../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import HistoryList from "../../components/templates/historyList";
import useDisplay from "../../hooks/useDisplay";
import HistoryApi from "../../api/history";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";
import { mainBannerLang } from "../../lang/lang";
import { data } from "../../lang/renewal/historyLang";
import PretendardText from "../../components/atoms/text/pretendardText";

const History = () => {
  const { pathname } = useLocation();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const langValue = useRecoilValue(langState);

  const [history2020, setHistory2020] = useState([]);
  const [history2010, setHistory2010] = useState([]);
  const [history2000, setHistory2000] = useState([]);

  const getHistoryList = async () => {
    try {
      const obj = {
        language: langValue,
      };

      // const response = (await HistoryApi.Get(obj)).data.data;
      const response = data[langValue];

      setHistory2020(response.Y2020);
      setHistory2010(response.Y2010);
      setHistory2000(response.Y2000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHistoryList();
  }, [langValue]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/renewal/introduce/history/bg-history.png"
            : "/assets/imgs/renewal/introduce/history/bg-history.png"
        }
        title={mainBannerLang.history.title[langValue]}
        content={mainBannerLang.history.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isSmall={isSmall} $isMobile={isMobile} $isTablet={isTablet}>
        <SectionWrapper>
          <MainTitle $isDesktop={isDesktop}>
            {langValue === "KR" ? "연혁" : "History"}
          </MainTitle>
        </SectionWrapper>
        <SectionWrapper $isMobile={isMobile} $isTablet={isTablet}>
          <HistoryList list={history2020} year={2020} />
        </SectionWrapper>
        <SectionWrapper $isMobile={isMobile} $isTablet={isTablet}>
          <HistoryList list={history2010} year={2010} />
        </SectionWrapper>
        <SectionWrapper $isMobile={isMobile} $isTablet={isTablet}>
          <HistoryList list={history2000} year={2000} />
        </SectionWrapper>
      </PageWrapper>
    </>
  );
};

export default History;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isMobile ? "0 20px" : props.$isTablet ? "0 70px" : 0};
  padding-bottom: ${(props) => (props.$isSmall ? "80px" : "112px")};
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: ${(props) =>
    props.$isMobile || props.$isTablet ? "column" : "row"};
  padding-top: ${(props) =>
    props.$isMobile || props.$isTablet ? "00px" : "120px"};
  gap: ${(props) =>
    props.$isMobile ? "30px" : props.$isTablet ? "60px" : "40px"};
`;

const MainTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isDesktop ? "12rem" : "6rem")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
`;
