import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "../../components/templates/middleNavBar";
import useDisplay from "../../hooks/useDisplay";
import { sardip } from "../../lang/renewal/sardipLang";
import { langState } from "../../store/langState";
import Intro from "./Intro";
import ContentsLayout from "./components/ContentsLayout";

const ServicePage = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile } = useDisplay();
  const { pathname, hash } = useLocation();

  const data = sardip[langValue];

  useEffect(() => {
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [hash]);

  return (
    <Page>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/renewal/data/header.png"
            : "/assets/imgs/renewal/data/header.png"
        }
        title="SAR Imagery & Information"
        content={data.header}
        blackText
      />
      <MiddleNavBar pathname={pathname} />
      <Intro data={data.intro} />
      <Container>
        {data.data.map((item, index) => (
          <ContentsLayout key={index} data={item} />
        ))}
      </Container>
    </Page>
  );
};

export default ServicePage;

const Page = styled.div`
  padding-bottom: 12.1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15.5rem;
`;
