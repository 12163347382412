export const languageType = ["KR", "EN"];

export const performanceLang = {
  quarter1: {
    KR: "1분기",
    EN: "First Quarter",
  },
  quarter2: {
    KR: "2분기",
    EN: "Second Quarter",
  },
  quarter3: {
    KR: "3분기",
    EN: "Third Quarter",
  },
  quarter4: {
    KR: "4분기",
    EN: "Fourth Quarter",
  },
  paTitle: {
    KR: "2023년도 자료",
    EN: "Data for 2023",
  },
  preTitle: {
    KR: "이전 자료",
    EN: "Previous Report",
  },
  paData01: {
    KR: "실시간 방송 듣기 [EN]",
    EN: "Listen to the Broadcast [EN]",
  },
  paData02: {
    KR: "실시간 방송 듣기 [KR]",
    EN: "Listen to the Broadcast [KR]",
  },
  paData03: {
    KR: "Download PDF",
    EN: "Download PDF",
  },
  irTitle: {
    KR: "최근 일정",
    EN: "Recent Schedule",
  },
  biTitle: {
    KR: "사업소개",
    EN: "Business Introduction",
  },
  download: {
    KR: "다운로드",
    EN: "Download",
  },
  eventMaterials: {
    KR: "행사자료",
    EN: "Event materials",
  },
  date: {
    KR: "일자",
    EN: "Date",
  },
  place: {
    KR: "장소",
    EN: "Place",
  },
};
