import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import "../../../styles/swiper.css";

import PretendardText from "../../../components/atoms/text/pretendardText";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "./../../../components/templates/middleNavBar";

import RecruitApi from "../../../api/recruitApi";
import FaqFooterTalent from "../../../components/templates/faqFooterTalent";
import useDisplay from "../../../hooks/useDisplay";
import { mainBannerLang } from "../../../lang/lang";
import { talentTypeLang } from "../../../lang/recruit/talentTypeLang";
import { langState } from "../../../store/langState";

const data = {
  title: {
    KR: ["우리는", "잘 만든 위성으로", "더 나은 대한민국을", "꿈꿉니다."],
    EN: ["We dream,", "of a better Korea", "with well-made satellites."],
  },
  benefit: {
    KR: [
      {
        img: "/assets/imgs/talent-type/1_휴가.gif",
        title: "자유로운 휴가 사용",
        content: "근로자 휴가비 지원사업 지원",
      },
      {
        img: "/assets/imgs/talent-type/2_육아휴직.gif",
        title: "고용안정 휴가",
        content: "육아휴직, 남성출산 휴가",
      },
      {
        img: "/assets/imgs/talent-type/3_식대및다과.gif",
        title: "식대 및 다과 지원",
        content: "다양한 제휴식당,\n중식·간식·석식 제공",
      },
      {
        img: "/assets/imgs/talent-type/4_경조사지원.gif",
        title: "경조사 지원",
        content: "생일 및 명절 선물,\n경조사 지원",
      },
      {
        img: "/assets/imgs/talent-type/5_스톡옵션.gif",
        title: "스톡옵션 부여",
        content: "스톡옵션 제도",
      },
      {
        img: "/assets/imgs/talent-type/6_인센티브.gif",
        title: "인센티브 제도",
        content: "성과급 및\n인센티브 지급",
      },
      {
        img: "/assets/imgs/talent-type/7_의료.gif",
        title: "건강한 생활",
        content: "건강검진, 가족·의료비 지원",
      },
      {
        img: "/assets/imgs/talent-type/8_포상.gif",
        title: "우수직원 포상",
        content: "포상금 및 상패, 채용장려금",
      },
      {
        img: "/assets/imgs/talent-type/9_조식이벤트.gif",
        title: "조식 이벤트",
        content: "조식 및 복날\n서프라이즈 이벤트",
      },
      {
        img: "/assets/imgs/talent-type/10_교육.gif",
        title: "직무발명 보상",
        content: "특허 발명에 따른 보상\n등록비 지원",
      },
      {
        img: "/assets/imgs/talent-type/11_특허.gif",
        title: "초청 세미나 개최",
        content: "우주산업 전문가\n초빙 세미나 개최",
      },
      {
        img: "/assets/imgs/talent-type/12_통신비.gif",
        title: "통신비 지원",
        content: "통신비\n일부 지원",
      },
    ],
    EN: [
      {
        img: "/assets/imgs/talent-type/1_휴가.gif",
        title: "Flexible Vacation Use",
        content: "Support for employee vacation expenses",
      },
      {
        img: "/assets/imgs/talent-type/2_육아휴직.gif",
        title: "Employment Stability Leave",
        content: "Parental leave, paternity leave",
      },
      {
        img: "/assets/imgs/talent-type/3_식대및다과.gif",
        title: "Meal and Snack Support",
        content:
          "Various affiliated restaurants,\nprovision of lunch, snacks, and dinner",
      },
      {
        img: "/assets/imgs/talent-type/4_경조사지원.gif",
        title: "Special Occasion Support",
        content: "Birthday and holiday gifts,\nsupport for family events",
      },
      {
        img: "/assets/imgs/talent-type/5_스톡옵션.gif",
        title: "Stock Option Grant",
        content: "Stock option program",
      },
      {
        img: "/assets/imgs/talent-type/6_인센티브.gif",
        title: "Incentive System",
        content: "Performance bonuses and\nincentive payments",
      },
      {
        img: "/assets/imgs/talent-type/7_의료.gif",
        title: "Healthy Living",
        content: "Health check-ups, family and medical expense support",
      },
      {
        img: "/assets/imgs/talent-type/8_포상.gif",
        title: "Outstanding Employee Awards",
        content: "Reward money and plaques, recruitment incentives",
      },
      {
        img: "/assets/imgs/talent-type/9_조식이벤트.gif",
        title: "Breakfast Events",
        content: "Breakfast and surprise events\non dog days of summer",
      },
      {
        img: "/assets/imgs/talent-type/10_교육.gif",
        title: "Employee Invention Compensation",
        content:
          "Compensation for patent inventions,\nsupport for registration fees",
      },
      {
        img: "/assets/imgs/talent-type/11_특허.gif",
        title: "Invitation Seminars",
        content: "Seminars featuring\nspace industry experts",
      },
      {
        img: "/assets/imgs/talent-type/12_통신비.gif",
        title: "Communication Expense Support",
        content: "Partial support for\ncommunication expenses",
      },
    ],
  },
};

const Talent = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '">' + "</div>";
    },
  };

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const { pathname } = useLocation();
  const navigation = useNavigate();

  const [recruitList, setRecruitList] = useState([]);

  const langValue = useRecoilValue(langState);

  useEffect(() => {
    getRecruitAPI();
  }, [langValue]);

  const getRecruitAPI = async () => {
    try {
      // const data = {
      //   language: langValue,
      // };

      // const response = (await RecruitApi.GetRecent(data)).data.data;
      const response = await RecruitApi.GetRecent();
      setRecruitList(response);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/talent_type.webp"
            : "/assets/imgs/main-section/talent_type.webp"
        }
        title={mainBannerLang.typeOfTalent.title[langValue]}
        content={mainBannerLang.typeOfTalent.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />

      <div
        style={{
          padding: isMobile
            ? "80px 20px"
            : isTablet
            ? "100px 70px"
            : "120px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#FFFFFF",
        }}
      >
        <Title $isDesktop={isDesktop}>
          {data.title[langValue].map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </Title>
        {/* <div style={{ width: "100px", textAlign: "left" }}> */}
        <CirclesWrapper>
          <Circle color="#00AEEF" left="0" />
          <Circle color="#257CC7" left="4.1rem" />
          <Circle color="#52008C" left="9.2rem" />
        </CirclesWrapper>
        {/* </div> */}
        <img
          src={isMobile ? talentTypeLang.image.mo : talentTypeLang.image.pc}
          alt="harmonia"
          style={{ width: "100%", maxWidth: "1400px" }}
        />
        <>
          <Wrapper $isMobile={isMobile} style={{ marginBottom: "17rem" }}>
            <Left>Benefit</Left>
            <Right $isMobile={isMobile}>
              <Grid $isMobile={isMobile}>
                {data.benefit[langValue].map((item, i) => {
                  return (
                    <div key={i}>
                      <ImgSize $isTablet={isTablet}>
                        <img
                          src={item.img}
                          alt="benefit"
                          style={{ width: "100%" }}
                        />
                      </ImgSize>
                      <CTitle>{item.title}</CTitle>
                      <CContent>{item.content}</CContent>
                    </div>
                  );
                })}
              </Grid>
            </Right>
          </Wrapper>
          <Wrapper $isMobile={isMobile}>
            <Left>Process</Left>
            <Right $isMobile={isMobile}>
              <ProcessImg
                src={
                  langValue === "KR"
                    ? "/assets/imgs/talent-type/process_kr.png"
                    : "/assets/imgs/talent-type/process.png"
                }
              />
            </Right>
          </Wrapper>
        </>
      </div>

      {/* {isSmall ? <SeekMUI /> : <SeekPCUI />} */}

      <FaqFooterTalent />
    </>
  );
};

export default Talent;

const HarmoniaBox = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$isSmall ? "100%" : "560px")};

  border: 1px solid rgb(173, 180, 186);
  margin-right: 40px;
`;

const ProcessText = styled(PretendardText)`
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translate(-50%, -50%);

  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

const MoreBtn = styled.button`
  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MDash = styled.div`
  width: 30px;
  border-bottom: 1px dashed rgb(173, 180, 186);
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
  margin: 15px 0px 17px;
`;

const Title = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
  font-size: ${(props) => (props.$isDesktop ? "9.5rem" : "6rem")};
  font-weight: 700;
  line-height: ${(props) => (props.$isDesktop ? "12.445rem" : "9rem")};
`;

const CirclesWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1400px;
  height: 23rem;
`;

const Circle = styled.div`
  position: absolute;
  width: 9.2rem;
  height: 9.2rem;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  left: ${(props) => props.left};
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  margin-top: 10rem;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  gap: 2rem;
`;

const Left = styled.div`
  width: 35%;
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 6.7rem;
  letter-spacing: -0.01em;
  text-align: left;
`;

const Right = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "65%")};
`;

const ProcessImg = styled.img`
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$isMobile ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};
  gap: 2rem;
  margin-top: 2rem;
`;

const ItemGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgSize = styled.div`
  width: 100%;
  height: ${(props) => (props.$isTablet ? "20rem" : "auto")};
  align-content: center;
`;

const CTitle = styled(PretendardText)`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 37.5px;
  text-align: center;
`;

const CContent = styled(PretendardText)`
  font-size: 2rem;
  font-weight: 400;
  line-height: 25.6px;
  text-align: center;
`;
