import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../../hooks/useDisplay";
import ContentBox2 from "../../../../../components/templates/contentBox2";
import { langState } from "../../../../../store/langState";
import { useRecoilValue } from "recoil";
import { titleLang } from "../../../../../lang/Business/title";
import { xbandLang } from "../../../../../lang/Business/xband";

const FunctionOfEquipment = () => {
  const { isMobile } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <ContentBox2
      style={{ marginTop: "40px" }}
      title={titleLang.xband?.functionOf[langValue]}
      content={
        <div style={{ display: "flex", flexDirection: "column", gap: isMobile ? "10px" : "30px" }}>
          <ContentText $isMobile={isMobile}>{xbandLang.function1[langValue]}</ContentText>
          <ContentText $isMobile={isMobile}>{xbandLang.function2[langValue]}</ContentText>
          <ContentText $isMobile={isMobile}>{xbandLang.function3[langValue]}</ContentText>
        </div>
      }
    />
  );
};

export default FunctionOfEquipment;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;
