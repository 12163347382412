import React, { useRef } from "react";
import { useRecoilValue } from "recoil";

import PretendardText from "../text/pretendardText";
import { langState } from "../../../store/langState";
import { inquiryLang } from "../../../lang/inquiryLang";

function FileForm(props) {
  // useRef를 이용해 input태그에 접근한다.
  const imageInput = useRef();

  // 버튼클릭시 input태그에 클릭이벤트를 걸어준다.
  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  const langValue = useRecoilValue(langState);

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .pdf, .hwp, .docx"
        ref={imageInput}
        onChange={props.handleChange}
      />
      <div
        onClick={onCickImageUpload}
        style={{
          cursor: "pointer",
          height: 48,
          width: 139,
          background: "#00AEEF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PretendardText
          style={{
            fontSize: 14,
            lineHeight: "18.9px",
            color: "#fff",
            fontWeight: 600,
            marginRight: 5,
          }}
        >
          {inquiryLang.file[langValue]}
        </PretendardText>
        <img
          src="/assets/icons/file.svg"
          style={{
            height: 18,
            width: 18,
            marginBottom: 2,
          }}
        />
      </div>
    </>
  );
}

export default FileForm;
