import React, { useState } from "react";
import styled from "styled-components";
import HistoryInfo from "./historyInfo";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

const HistoryList = ({ list, year }) => {
  const [src, setSrc] = useState("");
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <StickyWrapper $isMobile={isMobile} $isTablet={isTablet} $isSmall={isSmall}>
        <YearText $isMobile={isMobile} $isTablet={isTablet}>{`${year}'s`}</YearText>
        {src ? (
          <img
            src={src}
            alt=""
            style={{
              width: isMobile ? "100%" : "440px",
              height: isMobile ? "100%" : "310px",
              objectFit: "contain",
            }}
          />
        ) : null}
      </StickyWrapper>
      <HistoryWrapper $isSmall={isSmall}>
        {list?.map((item, index) => {
          return (
            <HistoryInfo
              key={index}
              date={item.date}
              content={item.content}
              imgSrc={item.fileUrl}
              src={src}
              setSrc={setSrc}
            />
          );
        })}
      </HistoryWrapper>
    </>
  );
};

export default HistoryList;

const StickyWrapper = styled.div`
  min-width: ${(props) => (props.$isSmall ? "100%" : "440px")};
  position: sticky;
  top: ${(props) => (props.$isSmall ? "0px" : "120px")};
  padding-top: ${(props) => (props.$isMobile ? "60px" : props.$isMobile ? "80px" : "0px")};
  align-self: flex-start;
  background-color: #fff;
  z-index: 5;
`;

const HistoryWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #d7dce2;
  margin-top: ${(props) => (props.$isSmall ? "0px" : "128px")};
`;

const YearText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "34px" : props.$isTablet ? "85px" : "85px")};
  font-weight: 500;
  line-height: 150%;
  color: #00aeff;
`;
