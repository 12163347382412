import FramerSlideIn from "../../../../styles/framerSlideIn";
import FramerWipe from "../../../../styles/framerWipe";
import {
  Content,
  Description,
  ImageContainer,
  Images,
  ImagesWrapper,
  ImageWrap,
  Title,
} from "./WeDo.styles";
import HoverZoom from "./HoverZoom";
import { useRef, useState } from "react";
import styled from "styled-components";

const HighResolutionSection = ({ isMobile, isDesktop, data }) => {
  const [showHoverImage, setShowHoverImage] = useState(true);
  const [initialZoomPosition, setInitialZoomPosition] = useState(null);
  const containerRefs = useRef([]);

  const handleHoverImageClick = (index, event) => {
    const container = containerRefs.current[index];
    if (container) {
      const rect = container.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setInitialZoomPosition({ x, y });
      setShowHoverImage(false);
    }
  };

  return (
    <Content>
      <Title>{data.title}</Title>
      <Description>{data.description}</Description>
      <ImagesWrapper $isMobile={isMobile}>
        <Images>
          {data.images.map((img, index) => (
            <ImageContainer
              key={index}
              $isMobile={isMobile}
              ref={(el) => (containerRefs.current[index] = el)}
            >
              <ImageWrap $isHighResolutionSection>
                <FramerSlideIn>
                  <FramerWipe>
                    <HoverZoom
                      imageUrl={img.src}
                      zoom={3}
                      initialPosition={
                        !showHoverImage ? initialZoomPosition : null
                      }
                    />
                    {!isDesktop && showHoverImage && (
                      <HoverImageContainer
                        onClick={(e) => handleHoverImageClick(index, e)}
                      >
                        <Icon>
                          <img src="/assets/icons/pointer.png" alt="Hover" />
                          <p>Click to zoom</p>
                        </Icon>
                      </HoverImageContainer>
                    )}
                  </FramerWipe>
                </FramerSlideIn>
              </ImageWrap>
            </ImageContainer>
          ))}
        </Images>
      </ImagesWrapper>
    </Content>
  );
};

export default HighResolutionSection;

export const HoverImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  img {
    max-width: 50px; // 이미지 크기를 조절하세요
    height: auto;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  p {
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
`;
