import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";

import PretendardText from "../../atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import moment from "moment";

function VideoGalleryCardM({ data }) {
  const { isMobile, isTablet } = useDisplay();

  const [isPlay, setIsPlay] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: isTablet ? 440 : 182,
            position: "relative",
            background: "rgba(0, 0, 0, 0.35)",
          }}
        >
          {isPlay ? (
            <YouTube
              videoId={data.link} //동영상 주소
              opts={{
                width: "100%",
                height: isTablet ? 400 : 182,
                playerVars: {
                  autoplay: 1, //자동 재생 여부
                  modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
                  loop: 1, //반복 재생
                  playlist: data.link, //반복 재생으로 재생할 플레이 리스트
                },
              }}
              onReady={(e) => {
                // e.target.mute(); //소리 끔
              }}
            />
          ) : (
            <img
              src={data.imageUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}

          {isPlay ? null : (
            <div
              style={{
                background: "rgba(0,0,0,0.35)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
              }}
            />
          )}

          {isPlay ? null : (
            <img
              onClick={() => {
                setIsPlay(true);
              }}
              src="/assets/icons/play.svg"
              style={{
                position: "absolute",
                zIndex: 11,
                top: "50%",
                left: "50%",
                transform: " translate(-50%, -50%)",
                width: isTablet ? 64 : 40,
                height: isTablet ? 64 : 40,
                cursor: "pointer",
              }}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <PretendardText
            style={{
              color: "#00AEEF",
              fontSize: "13px",
              fontWeight: 600,
              lineHeight: "17.55px",
              marginBottom: 10,
            }}
          >
            {data.videoCategory}
          </PretendardText>
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: isTablet ? "18px" : "16px",
              fontWeight: 500,
              lineHeight: "23.2px",
              marginBottom: 10,
            }}
          >
            {data.title}
          </PretendardText>
          <PretendardText
            style={{
              color: "#ADB4BA",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "21px",
            }}
          >
            {moment.utc(data.createdAt).format("YYYY.MM.DD")}
          </PretendardText>
        </div>
      </div>
    </>
  );
}

export default VideoGalleryCardM;
