import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";

const WideImg = ({ title = "", img = "", alt = "" }) => {
  const { isMobile } = useDisplay();

  return (
    <Container>
      <Title $isMobile={isMobile}>{title}</Title>
      <ImageContainer>
        <Image src={img} alt={alt} />
      </ImageContainer>
    </Container>
  );
};

export default WideImg;

const Container = styled.section`
  position: relative;
  width: 100%;
  height: auto;
`;

const Title = styled.h6`
  position: absolute;
  top: ${({ $isMobile }) => ($isMobile ? "10px" : "28px")};
  left: ${({ $isMobile }) => ($isMobile ? "15px" : "41px")};
  font-size: ${({ $isMobile }) => ($isMobile ? "1.8rem" : "2.3rem")};
  font-weight: 700;
  color: white;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;
