import React, { useEffect, useState } from "react";
import PretendardText from "../../../components/atoms/text/pretendardText";
import ArticleCard from "../../../components/templates/articleCard";
import useDisplay from "../../../hooks/useDisplay";

import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { langState } from "./../../../store/langState";
import { useRecoilValue } from "recoil";
import NewsApi from "../../../api/newsApi";
import ArticleApi from "../../../api/articleApi";
import moment from "moment/moment";
import { data } from "../datas/news";

const News = () => {
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;
  const [news, setNews] = useState(data.data.items);

  const langValue = useRecoilValue(langState);

  const getNews = async () => {
    try {
      const data = {
        pageNo: 1,
        pageSize: 6,
        language: langValue,
      };

      const response = (await ArticleApi.GetList(data)).data.data;
      setNews(response.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNews();
  }, [langValue]);

  return (
    <>
      {news.length > 0 ? (
        <div style={{ width: "100%", position: "relative" }}>
          <Swiper
            spaceBetween={40}
            slidesPerView={isSmall ? 1 : 2}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className={`home-news ${
              isMobile ? "mobile-home-news" : "custom-cursor"
            }`}
          >
            {news.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <ArticleCard
                    style={{ width: isMobile ? "335px" : "560px" }}
                    src={item.fileUrl}
                    author={item.category}
                    date={moment.utc(item.createdAt).format("YYYY/MM/DD")}
                    title={item.title}
                    link={item.link}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <PretendardText
          style={{
            fontSize: "15px",
            fontWeight: 400,
            lineheight: "140%",
            color: "#adb4ba",
            marginTop: isMobile ? "80px" : "72px",
          }}
        >
          There are no registered posts.
        </PretendardText>
      )}
    </>
  );
};

export default News;
