import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";
import ReportDLCard from "../../molecules/investment/reportDLCard";
import PlayDLCard from "../../molecules/investment/playDLCard";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { performanceLang } from "../../../lang/investment/performanceLang";

function EMCardM({ style, title, date, place, number }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          width: "100%",
          padding: "20px 8px",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#778088",
              width: 20,
            }}
          >
            {number}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#262C31",
            }}
          >
            {title}
          </PretendardText>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "18.9px",
              color: "#778088",
              marginRight: 15,
            }}
          >
            {performanceLang.date[langValue]}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.9px",
              color: "#778088",
            }}
          >
            {date}
          </PretendardText>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <PretendardText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "18.9px",
              color: "#778088",
              marginRight: 15,
            }}
          >
            {performanceLang.place[langValue]}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.9px",
              color: "#778088",
            }}
          >
            {place}
          </PretendardText>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: "1px solid #BFBFBF",
            paddingTop: "20px",
          }}
        >
          <PlayDLCard content={performanceLang.paData01[langValue]} />
          <PlayDLCard content={performanceLang.paData02[langValue]} />
          <ReportDLCard
            content={performanceLang.paData03[langValue]}
            style={{
              marginBottom: 0,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default EMCardM;
