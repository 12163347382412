import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import { ipr } from "../../../../lang/renewal/performanceLang";
import { useEffect, useState } from "react";

const ListItem = ({
  isDesktop,
  isMobile,
  onClick,
  bgColor,
  logo,
  title,
  desc,
}) => {
  return (
    <Container>
      {!isDesktop ? (
        <LinkItemMobile onClick={onClick} $bgColor={bgColor}>
          <img src={logo} alt="" />
          <LabelMobile $isMobile={isMobile}>{title}</LabelMobile>
          <LabelMobile $isMobile={isMobile}>{desc}</LabelMobile>
        </LinkItemMobile>
      ) : (
        <>
          <LinkItem onClick={onClick} $bgColor={bgColor}>
            <Label style={{ width: "250px" }}>
              <img src={logo} alt="" />
            </Label>
            <Label style={{ width: "300px" }}>{title}</Label>
            <Label style={{ width: "550px" }}>{desc}</Label>
          </LinkItem>
        </>
      )}
    </Container>
  );
};

const Ipr = ({ isMobile, isTablet, isDesktop, langValue }) => {
  const [data, setData] = useState(ipr[langValue]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setData(ipr[langValue]);
  }, [langValue]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <PageWrapper>
      <SectionWrapper $isMobile={isMobile}>
        <MainTitle $isDesktop={isDesktop}>{data.title}</MainTitle>
        <Description>{data.desc}</Description>
      </SectionWrapper>
      <SectionWrapper $isMobile={isMobile} style={{ flexDirection: "column" }}>
        <AnimatedList $showAll={showAll} $isDesktop={isDesktop}>
          {data.contents.map((item, idx) => (
            <ListItem
              key={idx}
              isDesktop={isDesktop}
              isMobile={isMobile}
              bgColor={item.bgColor}
              {...item}
            />
          ))}
        </AnimatedList>
        {data.contents.length > 5 && (
          <div style={{ textAlign: "center" }}>
            <ToggleButton onClick={toggleShowAll} className="custom-cursor">
              {showAll
                ? langValue === "KR"
                  ? "숨기기"
                  : "Hide"
                : langValue === "KR"
                ? "더보기"
                : "Show More"}
            </ToggleButton>
          </div>
        )}
      </SectionWrapper>
    </PageWrapper>
  );
};

export default Ipr;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
  gap: 4.7rem;
`;

const MainTitle = styled(PretendardText)`
  font-size: 4.8rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Description = styled.p`
  font-size: 2rem;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
`;

const ToggleButton = styled.button`
  padding: 1.1rem 4rem;
  font-size: 1.5rem;
  color: #00aeef;
  border: 1px solid #00aeef;
  transition: background-color 0.3s;
  margin-top: 4.6rem;

  &:hover {
    color: white;
    background-color: #00aeef;
  }
`;

// ListItem
const Container = styled.div`
  position: relative;
`;

const AnimatedList = styled.div`
  border-top: 1px solid #000;
  max-height: ${(props) =>
    props.$showAll
      ? "2000px"
      : props.$isDesktop
      ? "64.2rem"
      : "125.1rem"}; // 5개 아이템의 대략적인 높이
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

const LinkItemMobile = styled.div`
  height: 25rem;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  border-bottom: 1px solid #000;
  transition: 0.4s;
  color: ${({ $bgColor }) => $bgColor};
  gap: 1rem;
`;

const LinkItem = styled(LinkItemMobile)`
  height: 12.8rem;
  transition: color 0s, box-shadow 0.4s ease;
  flex-direction: row;
  ${Container}:hover & {
    background: transparent;
    box-shadow: ${({ $bgColor }) => `inset 0 125px 0 0 ${$bgColor}`};
  }
`;

const LabelMobile = styled.span`
  font-size: ${({ $isMobile }) => ($isMobile ? "2rem" : "2.2rem")};
  font-weight: 400;
  transition: 0.5s;
`;

const Label = styled(LabelMobile)`
  ${Container}:hover & {
    transform: translateX(20px);
  }
`;
