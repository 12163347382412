import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";
import { langState } from "../../store/langState";
import { faqFooterLangTalent } from "../../lang/recruit/faqFooterLang";
import { useNavigate } from "react-router-dom";

const FaqFooterTalent = ({ title, content, blackText }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  return (
    <FaqFooterContainer $isMobile={isMobile} $isTablet={isTablet}>
      <FaqFooterWrapper $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PretendardText
            style={{
              color: "#fff",
              fontSize: isSmall ? "14px" : "9.5rem",
              fontWeight: 900,
              lineHeight: isSmall ? "18.9px" : "12.4rem",
              marginBottom: 10,
            }}
          >
            Be Braver!
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontSize: isSmall ? "16px" : "20px",
              fontWeight: 600,
              lineHeight: isSmall ? "23.2px" : "29px",
              whiteSpace: "pre-wrap",
              marginBottom: isSmall ? 20 : 0,
            }}
          >
            {isMobile
              ? faqFooterLangTalent.intro.mo[langValue]
              : faqFooterLangTalent.intro.pc[langValue]}
          </PretendardText>
        </div>

        <InqueryBtn
          $isMobile={isMobile}
          $isDesktop={isDesktop}
          onClick={() => navigation("/recruit/job-announcement/1")}
        >
          <PretendardText
            style={{
              color: "#FFFFFF",
              fontWeight: 600,
              lineHeight: isMobile ? "23.2px" : "26.1px",
            }}
          >
            {faqFooterLangTalent.inquiry[langValue]}
          </PretendardText>
        </InqueryBtn>
      </FaqFooterWrapper>
    </FaqFooterContainer>
  );
};

export default FaqFooterTalent;

const FaqFooterContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: ${(props) => (props.$isMobile ? "265px" : "74rem")};
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${(props) =>
    props.$isMobile ? "40px 20px" : props.$isTablet ? "40px 80px" : "0px"};
  background-image: ${(props) =>
    props.$isMobile
      ? `url("/assets/imgs/talent-type/faq_talent.png")`
      : `url("/assets/imgs/talent-type/faq_talent.png")`};
`;

const FaqFooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  align-items: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  justify-content: ${(props) =>
    props.$isMobile ? "flex-start" : "space-between"};
`;

const InqueryBtn = styled.a`
  min-width: ${(props) => (props.$isMobile ? "122px" : "143px")};
  /* height: ${(props) => (props.$isMobile ? "44px" : "54px")}; */
  border: 1px solid #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00aeef; /* 밝은 파란색 배경 */
  color: white; /* 흰색 텍스트 */
  border: none; /* 테두리 없음 */
  padding: ${(props) =>
    props.$isDesktop
      ? "4.3rem 8rem"
      : "2rem 4rem"}; /* 상하 15px, 좌우 30px 패딩 */
  font-size: ${(props) =>
    props.$isDesktop ? "3.5rem" : "2.5rem"}; /* 글자 크기 */
  font-weight: bold; /* 굵은 글씨 */
  border-radius: 50px; /* 둥근 모서리 */
  cursor: pointer; /* 마우스 오버시 포인터 커서 */
  text-align: center; /* 텍스트 중앙 정렬 */
  transition: background-color 0.3s ease; /* 배경색 변경시 부드러운 전환 효과 */

  &:hover {
    background-color: #0099cc; /* 마우스 오버시 약간 어두운 파란색으로 변경 */
  }
`;
