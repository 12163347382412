import React, { useEffect, useState } from "react";
import MainSection from "../../../components/templates/mainSection";
import useDisplay from "../../../hooks/useDisplay";
import { mainBannerLang } from "../../../lang/lang";
import { useRecoilValue } from "recoil";
import { langState } from "../../../store/langState";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../../components/atoms/text/pretendardText";
import { workspace } from "../../../lang/renewal/workspaceLang";

const BlockItem = ({
  children,
  isMobile,
  isTablet,
  isDesktop,
  category,
  title,
  desc,
}) => {
  return (
    <SectionWrapper $isMobile={isMobile} $isTablet={isTablet}>
      <Left $isMobile={isMobile}>
        <CategoryText
          $isMobile={isMobile}
          dangerouslySetInnerHTML={{ __html: category }}
        />
      </Left>
      <Right $isMobile={isMobile}>
        <Gap>
          <TitleText $isMobile={isMobile} $isDesktop={isDesktop}>
            {title}
          </TitleText>
          <Description>{desc}</Description>
          {children}
        </Gap>
      </Right>
    </SectionWrapper>
  );
};

const HoverImageName = ({ data: { src, name, desc }, isDesktop, fontSize }) => {
  return (
    <div style={{ position: "relative" }}>
      <Image src={src} alt={name} />
      <HoverImage>
        <ImageContents>
          <ImageName $isDesktop={isDesktop}>{name}</ImageName>
          {desc && (
            <ImageDesc $isDesktop={isDesktop} $fontSize={fontSize}>
              {desc}
            </ImageDesc>
          )}
        </ImageContents>
      </HoverImage>
    </div>
  );
};

const Workspace = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [data, setData] = useState(workspace[langValue]);

  useEffect(() => {
    setData(workspace[langValue]);
  }, [langValue]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_workplace.png"
            : "/assets/imgs/main-section/workplace.png"
        }
        title={mainBannerLang.workplace?.title[langValue]}
        content={mainBannerLang.workplace?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper>
        <SectionWrapper>
          <MainTitle $isDesktop={isDesktop}>
            {workspace.title[langValue]}
          </MainTitle>
        </SectionWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16.4rem",
          }}
        >
          <BlockItem
            {...{ isDesktop, isTablet, isMobile, langValue }}
            category={data.new.category}
            title={data.new.title}
            desc={data.new.desc}
          >
            <Image src={data.new.imgs[0]} alt="lumir building" />
          </BlockItem>
          <BlockItem
            {...{ isDesktop, isTablet, isMobile, langValue }}
            category={data.facility.category}
            title={data.facility.title}
            desc={data.facility.desc}
          >
            <ImageContainer>
              {/* <HoverImageName {...{ isDesktop }} data={data.facility.imgs[0]} /> */}
              <Image
                src={data.facility.imgs[0].src}
                alt={data.facility.imgs[0].name}
              />
              <div style={{ display: "flex", gap: "1.8rem" }}>
                <ImageFlex>
                  {/* <HoverImageName
                    {...{ isDesktop }}
                    data={data.facility.imgs[1]}
                  /> */}
                  <Image
                    src={data.facility.imgs[1].src}
                    alt={data.facility.imgs[1].name}
                  />

                  {/* <HoverImageName
                    {...{ isDesktop }}
                    data={data.facility.imgs[3]}
                  /> */}
                  <Image
                    src={data.facility.imgs[3].src}
                    alt={data.facility.imgs[3].name}
                  />
                </ImageFlex>
                <ImageFlex style={{ marginTop: "16.2rem" }}>
                  {/* <HoverImageName
                    {...{ isDesktop }}
                    data={data.facility.imgs[2]}
                  />
                  <HoverImageName
                    {...{ isDesktop }}
                    data={data.facility.imgs[4]}
                  /> */}
                  <Image
                    src={data.facility.imgs[2].src}
                    alt={data.facility.imgs[2].name}
                  />
                  <Image
                    src={data.facility.imgs[4].src}
                    alt={data.facility.imgs[4].name}
                  />
                </ImageFlex>
              </div>
            </ImageContainer>
          </BlockItem>
          {/* <BlockItem
            {...{ isDesktop, isMobile, langValue }}
            category={data.facility2.category}
            title={data.facility2.title}
            desc={data.facility2.desc}
          >
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
            >
              {data.facility2.imgs.map((img, idx) => (
                <HoverImageName {...{ isDesktop }} data={img} key={idx} />
              ))}
            </div>
          </BlockItem> */}
          <BlockItem
            {...{ isDesktop, isTablet, isMobile, langValue }}
            category={data.facility2.category}
            title={data.facility2.title}
            desc={data.facility2.desc}
          >
            {data.facility2.imgs.map((img, i) => (
              <HoverImageName
                {...{ isDesktop }}
                data={img}
                key={i}
                fontSize={"same"}
              />
              // <Image key={i} src={img.src} alt={img.name} />
            ))}
          </BlockItem>
          <BlockItem
            {...{ isDesktop, isTablet, isMobile, langValue }}
            category={data.facility3.category}
            title={data.facility3.title}
            desc={data.facility3.desc}
          >
            {data.facility3.imgs.map((img, i) => (
              <HoverImageName
                {...{ isDesktop }}
                data={img}
                key={i}
                fontSize={"same"}
              />
              // <Image key={i} src={img.src} alt={img.name} />
            ))}
          </BlockItem>
        </div>
      </PageWrapper>
    </>
  );
};

export default Workspace;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const MainTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isDesktop ? "12rem" : "6rem")};
  font-weight: 600;
  line-height: 145%;
  margin-bottom: 6.8rem;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  position: relative;
  ${(props) => props.$isDesktop && `min-width: 1400px`};
  ${(props) => props.$isTablet && `gap: 5rem;`};
`;

const Left = styled.div`
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  width: ${(props) => (props.$isMobile ? "100%" : "35%")};
`;

const Right = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "65%")};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const CategoryText = styled(PretendardText)`
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  font-size: 4.8rem;
  font-weight: 600;
  top: 120px;
  height: auto;
`;

const TitleText = styled(PretendardText)`
  font-size: ${({ $isDesktop }) => ($isDesktop ? "8rem" : "6rem")};
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 2rem;
  color: #5a636a;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

const ImageFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 50%;
  & > img {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const HoverImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);

  &:hover {
    opacity: 1;
  }
`;

const ImageContents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: white;
`;

const ImageName = styled(PretendardText)`
  font-size: ${({ $isDesktop }) => ($isDesktop ? "4rem" : "2.2rem")};
  font-weight: 700;
  text-align: center;
`;

const ImageDesc = styled(PretendardText)`
  font-size: ${({ $isDesktop, $fontSize }) =>
    $fontSize === "same"
      ? $isDesktop
        ? "4rem"
        : "2.2rem"
      : $isDesktop
      ? "2rem"
      : "1.5rem"};
  font-weight: ${({ $fontSize }) => ($fontSize === "same" ? "700" : "500")};
  text-align: center;
`;
