import React, { useEffect, useState } from "react";

import TextForm from "../organisms/inputForm/textForm";
import TextNumberForm from "../organisms/inputForm/textNumberForm";
import useDisplay from "../../hooks/useDisplay";
import EmailForm from "../organisms/inputForm/emailForm";
import PhoneForm from "../organisms/inputForm/phoneForm";
import TextareaForm from "../organisms/inputForm/textareaForm";
import TermsForm from "../organisms/inputForm/termsForm";
import FileFormInput from "../organisms/inputForm/fileFormInput";
import RadioForm from "../organisms/inputForm/radioForm";
import CheckboxForm from "../organisms/inputForm/checkboxForm";

const FormUI = ({ data, setList, list }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  let indexTemp;
  const formBasicUI = (data) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        if (index === indexTemp) {
          return;
        }

        switch (item.inquiryFieldType) {
          case "TEXT":
            return (
              <TextForm key={index} data={item} setList={setList} list={list} />
            );

          case "NUMBER":
            return (
              <TextNumberForm
                key={index}
                data={item}
                setList={setList}
                list={list}
              />
            );

          case "EMAIL":
            if (
              data[index + 1] &&
              data[index + 1].inquiryFieldType === "PHONE"
            ) {
              indexTemp = index + 1;

              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    marginBottom: 25,
                  }}
                >
                  <EmailForm
                    data={item}
                    setList={setList}
                    list={list}
                    style={{
                      marginRight: isMobile ? 0 : 48,
                      marginBottom: isMobile ? 25 : 0,
                    }}
                  />

                  <PhoneForm
                    data={data[index + 1]}
                    setList={setList}
                    list={list}
                    style={{
                      marginBottom: 0,
                    }}
                  />
                </div>
              );
            } else {
              return (
                <EmailForm
                  key={index}
                  data={item}
                  setList={setList}
                  list={list}
                />
              );
            }

          case "PHONE":
            if (
              data[index + 1] &&
              data[index + 1].inquiryFieldType === "EMAIL"
            ) {
              indexTemp = index + 1;

              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    marginBottom: 25,
                  }}
                >
                  <PhoneForm
                    data={item}
                    setList={setList}
                    list={list}
                    style={{
                      marginRight: isMobile ? 0 : 48,
                      marginBottom: isMobile ? 25 : 0,
                    }}
                  />

                  <EmailForm
                    data={data[index + 1]}
                    setList={setList}
                    list={list}
                    style={{
                      marginBottom: 0,
                    }}
                  />
                </div>
              );
            } else {
              return (
                <PhoneForm
                  key={index}
                  data={item}
                  setList={setList}
                  list={list}
                />
              );
            }

          case "TEXT_AREA":
            return (
              <TextareaForm
                key={index}
                data={item}
                setList={setList}
                list={list}
              />
            );

          case "TERMS":
            return (
              <TermsForm
                key={index}
                data={item}
                setList={setList}
                list={list}
              />
            );

          case "FILE":
            return (
              <FileFormInput
                key={index}
                data={item}
                setList={setList}
                list={list}
              />
            );

          case "RADIO":
            return (
              <RadioForm
                key={index}
                data={item}
                setList={setList}
                list={list}
              />
            );

          case "CHECK_BOX":
            return (
              <CheckboxForm
                key={index}
                data={item}
                setList={setList}
                list={list}
              />
            );
        }
      });
    }
  };

  return <>{formBasicUI(data)}</>;
};

export default FormUI;
