import React from "react";
import useDisplay from "../../../hooks/useDisplay";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

const TypeC = ({ imageSet }) => {
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <div
      style={{
        marginTop: "40px",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
        width: isSmall ? "100%" : "1400px",
      }}
    >
      {imageSet.length < 3 ? (
        <>
          {imageSet?.map((item, index) => {
            return (
              <img
                key={index}
                src={item.fileUrl}
                alt=""
                style={{
                  width: isSmall
                    ? "100%"
                    : imageSet.length === 2
                    ? "calc(50% - 15px)"
                    : "",
                  maxWidth: "100%",
                }}
              />
            );
          })}
        </>
      ) : (
        <div style={{ width: "100%", position: "relative" }}>
          <Swiper
            spaceBetween={40}
            slidesPerView={isSmall ? 1 : 2}
            loop={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className={`product-swiper ${
              isMobile ? "mobile-product-swiper" : ""
            }`}
          >
            {imageSet.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    key={index}
                    src={item.fileUrl}
                    alt=""
                    style={{ width: isSmall ? "100%" : "", maxWidth: "100%" }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default TypeC;
