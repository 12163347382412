import React from "react";
import { styled } from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

const HistoryInfo = ({ date, content, imgSrc, src, setSrc }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <HistoryInfoWrapper
      $isMobile={isMobile}
      $isImg={imgSrc?.length > 0}
      onMouseOver={() => {
        if (isSmall) return;
        setSrc(imgSrc);
      }}
      onMouseLeave={() => {
        if (isSmall) return;
        setSrc("");
      }}
      onClick={() => {
        if (isDesktop) return;
        if (src) {
          setSrc("");
          return;
        }
        setSrc(imgSrc);
      }}
    >
      {isMobile && imgSrc ? (
        <img src="/assets/icons/img.png" alt="" style={{}} />
      ) : null}
      <DateText $isMobile={isMobile} $isTablet={isTablet}>
        {date}
      </DateText>
      <div style={{ position: "relative", width: "100%" }}>
        {imgSrc && !isMobile ? (
          <img
            src="/assets/icons/img.png"
            alt=""
            style={{
              position: "absolute",
              left: "-29px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        ) : null}
        <Content $isMobile={isMobile} $isTablet={isTablet}>
          {content}
        </Content>
      </div>
    </HistoryInfoWrapper>
  );
};

export default HistoryInfo;

const HistoryInfoWrapper = styled.div`
  width: 100%;
  padding: ${(props) => (props.$isMobile ? "15px 10px" : "20px 0px 20px 30px")};
  border-bottom: 1px solid #d7dce2;
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  align-items: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  gap: ${(props) => (props.$isMobile ? "5px" : "0")};
  /* cursor: ${(props) => (props.$isImg ? "pointer" : "default")}; */

  &:hover {
    background-color: ${(props) => (props.$isImg ? "#f9f9f9" : "unset")};
  }
`;

const DateText = styled(PretendardText)`
  width: 210px;
  font-size: ${(props) => (props.$isMobile ? "15px" : "24px")};
  font-weight: 600;
  line-height: 145%;
  color: ${(props) => (props.$isMobile ? "#5a636a" : "#778088")};
`;

const Content = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "20px")};
  font-weight: ${(props) => (props.$isMobile ? 400 : 500)};
  line-height: 145%;
  letter-spacing: -0.2px;
  color: #262c31;
`;
