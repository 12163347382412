import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import InputForm from "../../molecules/inputForm";

import { langState } from "../../../store/langState";
import { inquiryLang } from "../../../lang/inquiryLang";

const PhoneForm = ({ data, setList, list, style }) => {
  let temp = list;

  const langValue = useRecoilValue(langState);

  return (
    <>
      <InputForm
        title={data ? data.title : "Phone Number"}
        essential={data ? data.isRequired : false}
        type="text"
        placeholder={inquiryLang.placeholderPhone[langValue]}
        onChange={(e) => {
          let tempIndex = list.findIndex((item) => item.id === data.id);
          temp[tempIndex] = {
            ...data,
            value: e.target.value,
          };

          setList(temp);
        }}
        style={{
          marginBottom: 25,
          ...style,
        }}
      />
    </>
  );
};

export default PhoneForm;
