import styled from "styled-components";
import { idhu } from "../../../../lang/renewal/productsLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import MainFeature from "../components/MainFeature";
import MiddleImg from "../components/MiddleImg";

const Idhu = ({ langValue }) => {
  const data = idhu[langValue];
  return (
    <Layout bgColor={data.bgColor} title={"IDHU"} subTitle={data.header}>
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <MainFeature featureList={data.feature} />
        <FramerSlideIn>
          <MiddleImg middleImg={data.middleImg} />
        </FramerSlideIn>
        <FramerSlideIn>
          <DescriptImg imageList={data.desctiptImg} />
        </FramerSlideIn>
      </ContentsWrapper>
    </Layout>
  );
};

export default Idhu;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
