export const directionLang = {
  direction: {
    title: {
      KR: "오시는 길",
      EN: "Directions",
    },
    address: {
      KR: "경기도 용인시 수지구 신수로 767, A-1103\n(동천동, 분당수지 U-TOWER)",
      EN: "767, Sinsu-ro, Suji-gu, Yongin-si,\nGyeonggi-do, A-1103\n(Dongcheon-dong, Bundang Suji U-TOWER)",
    },
  },
  // tel: {
  //   title: {
  //     KR: "사업 분류별 연락처",
  //     EN: "Contact by business category",
  //   },
  //   management: {
  //     KR: "경영지원팀",
  //     EN: "Management Support",
  //   },
  //   ir: {
  //     KR: "IR 공시 담당",
  //     EN: "In charge of IR Disclosure",
  //   },
  //   personnel: {
  //     KR: "인사 담당",
  //     EN: "Personnel in Charge",
  //   },
  //   overseas: {
  //     KR: "해외 사업 담당",
  //     EN: "Overseas Business",
  //   },
  //   technology: {
  //     KR: "기술 사업 담당",
  //     EN: "Technology Business",
  //   },
  // },
  // tel: {
  //   title: {
  //     KR: "사업 분류별 연락처",
  //     EN: "Contact by business category",
  //   },
  //   management: {
  //     KR: "기술/사업 담당",
  //     EN: "Technology/Business Management",
  //   },
  //   ir: {
  //     KR: "IR 공시 담당",
  //     EN: "In charge of IR Disclosure",
  //   },
  //   personnel: {
  //     KR: "인사 담당",
  //     EN: "Personnel in Charge",
  //   },
  //   overseas: {
  //     KR: "구매 담당",
  //     EN: "Procurement Department",
  //   },
  //   technology: {
  //     KR: "홍보 담당",
  //     EN: "Public Relations Department",
  //   },
  // },
  tel: {
    title: {
      KR: "사업 분류별 연락처",
      EN: "Contact by business category",
    },
    management: {
      KR: "공시/IR 담당",
      EN: "In charge of IR Disclosure",
    },
    ir: {
      KR: "홍보 담당",
      EN: "Public Relations Department",
    },
    personnel: {
      KR: "기술/사업 담당",
      EN: "Technology/Business Management",
    },
    overseas: {
      KR: "구매 담당",
      EN: "Procurement Department",
    },
    technology: {
      KR: "인사 담당",
      EN: "Personnel in Charge",
    },
  },
};
