const PDFViewer = ({ url }) => {
  return (
    <div style={{ width: "100%", height: "600px" }}>
      <object
        data={`${process.env.PUBLIC_URL}${url}`}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          이 브라우저는 PDF를 직접 표시하지 못합니다.
          <a href={url}>PDF 다운로드</a>
        </p>
      </object>
    </div>
  );
};

export default PDFViewer;
