import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";

import PretendardText from "../../atoms/text/pretendardText";
import moment from "moment";

function VideoGalleryCard({ data }) {
  const [isPlay, setIsPlay] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PretendardText
            style={{
              color: "#00AEEF",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "26.1px",
              marginBottom: 10,
            }}
          >
            {data.videoCategory}
          </PretendardText>
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "28px",
              fontWeight: 500,
              lineHeight: "40.6px",
              marginBottom: 20,
              width: 402,
            }}
          >
            {data.title}
          </PretendardText>
          <PretendardText
            style={{
              color: "#ADB4BA",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "21px",
            }}
          >
            {moment.utc(data.createdAt).format("YYYY.MM.DD")}
          </PretendardText>
        </div>

        <div
          style={{
            width: 920,
            height: 500,
            position: "relative",
            background: "rgba(0, 0, 0, 0.35)",
          }}
        >
          {isPlay ? (
            <YouTube
              videoId={data.link} //동영상 주소
              opts={{
                width: 920,
                height: 500,
                playerVars: {
                  autoplay: 1, //자동 재생 여부
                  modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
                  loop: 1, //반복 재생
                  playlist: data.link, //반복 재생으로 재생할 플레이 리스트
                },
              }}
              onReady={(e) => {
                // e.target.mute(); //소리 끔
              }}
            />
          ) : (
            <img
              src={data.imageUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}

          {isPlay ? null : (
            <div
              style={{
                background: "rgba(0,0,0,0.35)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
              }}
            />
          )}

          {isPlay ? null : (
            <img
              onClick={() => {
                setIsPlay(true);
              }}
              src="/assets/icons/play.svg"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: " translate(-50%, -50%)",
                width: 75,
                height: 75,
                cursor: "pointer",
                zIndex: 11,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default VideoGalleryCard;
