import api from "./api";

export default class MMInfoApi {
  static async GetList(data) {
    return api.get(`managementInfo/list`, data);
  }

  static async GetDetail(id) {
    return api.get(`managementInfo/${id}`);
  }

  static async GetRuleKR() {
    return api.get(`management-rule/kr`);
  }

  static async GetRuleEN() {
    return api.get(`management-rule/en`);
  }
}
