import { motion } from "framer-motion";

const FramerWipe = ({
  style,
  children,
  duration = 1,
  delay = null,
  direction = "up",
}) => {
  const getInitialClipPath = () => {
    switch (direction) {
      case "up":
        return "inset(100% 0 0 0)";
      case "down":
        return "inset(0 0 100% 0)";
      case "left":
        return "inset(0 100% 0 0)";
      case "right":
        return "inset(0 0 0 100%)";
      default:
        return "inset(100% 0 0 0)";
    }
  };

  return (
    <motion.div
      initial={{ clipPath: getInitialClipPath() }}
      whileInView={{
        clipPath: "inset(0 0 0 0)",
        transition: {
          type: "tween",
          ease: "easeInOut",
          duration: duration,
          delay: delay ?? 0,
        },
      }}
      viewport={{ once: true }}
      {...{ style }}
    >
      {children}
    </motion.div>
  );
};

export default FramerWipe;
