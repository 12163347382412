import React from "react";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  max-width: 440px;
  min-width: 320px;
  border-radius: 8px;
  overflow: hidden;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const ImageSkeleton = styled.div`
  width: 100%;
  height: 248px;
  background-color: #e5e7eb; // gray-200 equivalent
`;

const ContentWrapper = styled.div`
  padding: 24px 0;
`;

const MetaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const AuthorSkeleton = styled.div`
  height: 16px;
  width: 80px;
  background-color: #e5e7eb;
  border-radius: 4px;
`;

const DateSkeleton = styled.div`
  height: 16px;
  width: 96px;
  background-color: #e5e7eb;
  border-radius: 4px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleLineFullSkeleton = styled.div`
  height: 20px;
  width: 100%;
  background-color: #e5e7eb;
  border-radius: 4px;
`;

const TitleLinePartialSkeleton = styled.div`
  height: 20px;
  width: 75%;
  background-color: #e5e7eb;
  border-radius: 4px;
`;

const ArticleCardSkeleton = () => {
  return (
    <CardWrapper>
      <ImageSkeleton />
      <ContentWrapper>
        <MetaWrapper>
          <AuthorSkeleton />
          <DateSkeleton />
        </MetaWrapper>
        <TitleWrapper>
          <TitleLineFullSkeleton />
          <TitleLinePartialSkeleton />
        </TitleWrapper>
      </ContentWrapper>
    </CardWrapper>
  );
};

export default ArticleCardSkeleton;
