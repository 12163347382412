import React, { useEffect } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";

const RnDCard = ({ style, id, title, src, isModalOpen, setIsModalOpen, onClick }) => {
  const { isMobile } = useDisplay();

  const langValue = useRecoilValue(langState);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  return (
    <>
      <RnDWrapper $isMobile={isMobile} src={src} style={{ ...style }}>
        <Title $isMobile={isMobile}>{title}</Title>
        <MoreBtn onClick={onClick}>{langValue === "KR" ? "더보기" : "More"}</MoreBtn>
      </RnDWrapper>
    </>
  );
};

export default RnDCard;

const RnDWrapper = styled.div`
  width: 100%;
  height: 335px;
  padding: ${(props) => (props.$isMobile ? "20px 0 20px 20px" : "32px 30px")};
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: ${(props) => (props.$isMobile ? "center right" : "right")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "16px" : "20px")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.2px")};
  white-space: pre-wrap;

  & div {
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    color: #262c31;
    letter-spacing: -0.2px;
  }
`;

const MoreBtn = styled.button`
  width: 122px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #adb4ba;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  color: #262c31;
  background-color: #ffffff;
`;
