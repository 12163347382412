import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";
import { langState } from "../../../../store/langState";
import { useRecoilValue } from "recoil";
import { nationalRnDLang } from "../../../../lang/CompanyIntroduction/nationalRnD";

const RnDModal4 = () => {
  const { isMobile } = useDisplay();

  const langValue = useRecoilValue(langState);

  return (
    <div>
      <div style={{ display: isMobile ? "flex" : "" }}>
        {isMobile ? (
          <div style={{ width: "3px", height: "16px", backgroundColor: "#00AEEF" }} />
        ) : null}
        <Title $isMobile={isMobile}>{nationalRnDLang.rndModal4?.title[langValue]}</Title>
      </div>
      <ContentText $isMobile={isMobile}>
        {nationalRnDLang.rndModal4?.content1[langValue]}
      </ContentText>
    </div>
  );
};

export default RnDModal4;
const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 26.1px;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.18px")};
  border-left: ${(props) => (props.$isMobile ? "none" : "3px solid #00aeff")};
  padding-left: 10px;
  margin-bottom: ${(props) => (props.$isMobile ? "15px" : "20px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "15px")};
  font-weight: 400;
  line-height: 135%;
  color: #5a636a;
`;
