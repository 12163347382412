import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";

import RnDModal1 from "./rndModal1";
import RnDModal2 from "./rndModal2";
import RnDModal3 from "./rndModal3";
import RnDModal4 from "./rndModal4";
import RnDModal5 from "./rndModal5";
import RnDModal6 from "./rndModal6";

const RnDModalContainer = ({ title, id, setIsModalOpen }) => {
  const { isMobile } = useDisplay();

  return (
    <ModalContainer $isMobile={isMobile}>
      <ModalWrapper $isMobile={isMobile}>
        <ImgWrapper
          $isMobile={isMobile}
          src={
            isMobile
              ? `/assets/imgs/introduce/rnd/m_rnd_modal${id}.png`
              : `/assets/imgs/introduce/rnd/rnd_modal${id}.png`
          }
          id={id}
        >
          <Title $isMobile={isMobile}>{title}</Title>
          <img
            src="/assets/icons/modal_x.png"
            alt=""
            style={{
              position: "absolute",
              top: isMobile ? "20px" : "28px",
              right: isMobile ? "20px" : "28px",
              width: isMobile ? "20px" : "",
              cursor: "pointer",
            }}
            onClick={() => setIsModalOpen(false)}
          />
        </ImgWrapper>
        <div style={{ padding: isMobile ? "20px" : "40px" }}>
          {id === 1 ? (
            <RnDModal1 title={title} />
          ) : id === 2 ? (
            <RnDModal2 title={title} />
          ) : id === 3 ? (
            <RnDModal3 title={title} />
          ) : id === 4 ? (
            <RnDModal4 title={title} />
          ) : id === 5 ? (
            <RnDModal5 title={title} />
          ) : id === 6 ? (
            <RnDModal6 title={title} />
          ) : null}
        </div>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default RnDModalContainer;

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${(props) => (props.$isMobile ? "30px 20px" : "70px 0")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 99;
`;

const ModalWrapper = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "1000px")};
  position: relative;
  background-color: #fff;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.$isMobile ? "250px" : "450px")};
  padding: ${(props) => (props.$isMobile ? "20px 0 20px 20px" : "42px 0 42px 40px")};
  padding-right: ${(props) => (props.id === 5 ? "80px" : 0)};
  position: relative;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  display: flex;
  align-items: flex-end;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "16px" : "24px")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.24px")};
`;
