import styled from "styled-components";
import { root } from "../../../../styles/GlobalStyle";
import useDisplay from "../../../../hooks/useDisplay";
import FramerSlideIn from "../../../../styles/framerSlideIn";

const MainFeature = ({ featureList, langValue }) => {
  const { isDesktop } = useDisplay();
  return (
    <div>
      <Header>{langValue === "KR" ? "주요특징" : "Main Features"}</Header>
      <ContentsContainer $isDesktop={isDesktop}>
        {featureList?.map((feature, index) => (
          <FramerSlideIn key={index}>
            <FeatureItem key={index}>
              <Divider />
              <Title>{feature.title}</Title>
              {feature?.content && (
                <Content $Color={root.fontGray}>{feature.content}</Content>
              )}
            </FeatureItem>
          </FramerSlideIn>
        ))}
      </ContentsContainer>
    </div>
  );
};

export default MainFeature;

const Header = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ $isDesktop }) =>
    $isDesktop ? "repeat(3, 1fr)" : "repeat(1, 1fr)"};
  gap: 4.6rem;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a8a8a8;
  margin-bottom: 1.8rem;
`;

const Title = styled.h6`
  font-size: 1.9rem;
  font-weight: 700;
`;

const Content = styled.p`
  color: ${(props) => props.$Color};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
`;
