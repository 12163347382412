export const certificationLang = {
  tab: {
    certification: {
      KR: "인증 및 수상",
      EN: "Certification and Award",
    },
    property: {
      KR: "지적재산권현황",
      EN: "Intellectual Property Rights Status",
    },
  },
};
