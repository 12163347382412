import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { root } from "./../styles/GlobalStyle";
import useDisplay from "../hooks/useDisplay";

const NotFoundPage = () => {
  const { isDesktop } = useDisplay();
  const navigate = useNavigate();

  return (
    <NotFoundContainer $isDesktop={isDesktop}>
      <NotFoundContent>
        <NotFoundTitle>404</NotFoundTitle>
        <NotFoundText>Oops! Page not found</NotFoundText>
        <NotFoundDescription>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </NotFoundDescription>
        <ButtonGroup>
          <HomeButton onClick={() => navigate("/")}>Go to Homepage</HomeButton>
          <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
        </ButtonGroup>
      </NotFoundContent>
    </NotFoundContainer>
  );
};

export default NotFoundPage;

// Styled components
const NotFoundContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  top: ${(props) => (props.$isDesktop ? "-80px" : "-65px")};
`;

const NotFoundContent = styled.div`
  text-align: center;
  padding: 20px;
`;

const NotFoundTitle = styled.h1`
  font-size: 120px;
  color: #343a40;
  margin-bottom: 0;
`;

const NotFoundText = styled.h2`
  font-size: 32px;
  color: #495057;
`;

const NotFoundDescription = styled.p`
  font-size: 18px;
  color: #6c757d;
  max-width: 500px;
  margin: 20px auto;
`;

const ButtonGroup = styled.div`
  margin-top: 30px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: none;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  transition: background-color 0.3s;
`;

const HomeButton = styled(Button)`
  background-color: ${root.purple};
  color: white;

  &:hover {
    background-color: ${root.businessNavy};
  }
`;

const BackButton = styled(Button)`
  background-color: #6c757d;
  color: white;

  &:hover {
    background-color: #545b62;
  }
`;
