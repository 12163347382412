import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { apiSDKService } from "../api/renewal/api.service";
import { PAGE_NAMES, PAGE_NAMES_MAIN } from "../constant/page-view";

const getOrCreateSessionId = () => {
  // sessionStorage에서 기존 세션 ID 확인
  let sessionId = sessionStorage.getItem("session_id");

  // 없으면 새로 생성하고 저장
  if (!sessionId) {
    sessionId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    sessionStorage.setItem("session_id", sessionId);
  }

  return sessionId;
};

export const getPageNameFromUrl = (pathname) => {
  // 각 페이지 정의를 순회하면서 매칭되는 경로 찾기

  if (
    String(pathname) === PAGE_NAMES_MAIN.paths[0] ||
    String(pathname) === PAGE_NAMES_MAIN.paths[1]
  ) {
    return PAGE_NAMES_MAIN.name;
  }

  const matchedPage = Object.entries(PAGE_NAMES).find(([_, pageInfo]) => {
    return pageInfo.paths.some((path) => pathname.startsWith(path));
  });

  // 매칭되는 페이지가 있으면 해당 페이지명 반환, 없으면 메인 페이지명 반환
  return matchedPage ? matchedPage[1].name : PAGE_NAMES.MAIN.name;
};

export const usePageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    let sessionId;
    let enterTime;

    const trackPageView = async () => {
      // 페이지 진입 시점 기록
      sessionId = getOrCreateSessionId();
      enterTime = new Date();

      try {
        await apiSDKService.createPageView({
          sessionId,
          pageName: getPageNameFromUrl(location.pathname),
          enterTime,
          url: window.location.href,
          title: document.title,
        });
      } catch (error) {
        console.error("페이지 뷰 생성 중 오류 발생:", error);
      }
    };

    const updatePageView = async () => {
      if (!sessionId || !enterTime) return;

      const exitTime = new Date();
      const stayDuration = exitTime.getTime() - enterTime.getTime();

      try {
        await apiSDKService.updatePageView({
          sessionId,
          pageName: getPageNameFromUrl(location.pathname),
          exitTime,
          stayDuration,
          url: window.location.href,
          title: document.title,
        });
      } catch (error) {
        console.error("페이지 뷰 업데이트 중 오류 발생:", error);
      }
    };

    // 페이지 진입 시 추적 시작
    trackPageView();

    // 컴포넌트 언마운트 또는 페이지 변경 시 추적 종료
    return () => {
      updatePageView();
    };
  }, [location.pathname]);
};
