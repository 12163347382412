import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import InputForm from "../../molecules/inputForm";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { inquiryLang } from "../../../lang/inquiryLang";

const TextareaForm = ({ data, setList, list }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  let temp = list;

  const langValue = useRecoilValue(langState);

  return (
    <>
      <InputForm
        isTextArea={true}
        title={data ? data.title : ""}
        essential={data ? data.isRequired : false}
        type="text"
        placeholder={inquiryLang.placeholderTextarea[langValue]}
        onChange={(e) => {
          let tempIndex = list.findIndex((item) => item.id === data.id);
          temp[tempIndex] = {
            ...data,
            value: e.target.value,
          };

          setList(temp);
        }}
        style={{
          marginBottom: 25,
        }}
        inputStyle={{
          verticalAlign: "top",
          height: isMobile ? 250 : 130,
          resize: "none",
        }}
      />
    </>
  );
};

export default TextareaForm;
