import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import { performance } from "../../../../lang/renewal/performanceLang";
import VideoHandler from "../../../home/renewal/common/VideoHandler";

const VideoContent = ({ title, tags, desc, img, video }) => {
  return (
    <Gap>
      <TitleText>{title}</TitleText>
      <TagContainer>
        {tags.map((tag, idx) => (
          <Tag key={idx}>{tag}</Tag>
        ))}
      </TagContainer>
      <Description>{desc}</Description>
      <VideoHandler mediaUrl={video} imageUrl={img} isVideoUse={true} />
    </Gap>
  );
};

const Heritage = ({ langValue, isMobile, isTablet, isDesktop }) => {
  return (
    <PageWrapper>
      <SectionWrapper>
        <MainTitle $isDesktop={isDesktop}>
          {performance.title[langValue]}
        </MainTitle>
      </SectionWrapper>
      <SectionWrapper $isMobile={isMobile}>
        <Left $isMobile={isMobile}>
          <CategoryText $isMobile={isMobile}>
            {performance[langValue].heritage.category}
          </CategoryText>
        </Left>
        <Right $isMobile={isMobile}>
          <Gap>
            <TitleText $isMobile={isMobile}>
              {performance[langValue].heritage.title}
            </TitleText>
            <TagContainer>
              {performance[langValue].heritage.tag.map((tag, idx) => (
                <Tag key={idx}>{tag}</Tag>
              ))}
            </TagContainer>
            <Description>{performance[langValue].heritage.desc}</Description>
            <ImageContainer $isDesktop={isDesktop}>
              {performance[langValue].heritage.imgs.map((img, idx) => (
                <ImageWrapper key={idx} $isDesktop={isDesktop}>
                  <Image
                    src={img.src}
                    alt="heritage"
                    key={idx}
                    $isDesktop={isDesktop}
                  />
                  <HoverImage>
                    <ImageContents>
                      <ImageName $isDesktop={isDesktop}>{img.name}</ImageName>
                      {img?.desc && (
                        <ImageDesc $isDesktop={isDesktop}>{img.desc}</ImageDesc>
                      )}
                    </ImageContents>
                  </HoverImage>
                </ImageWrapper>
              ))}
            </ImageContainer>
          </Gap>
          {performance[langValue].heritage.contents.map((content, idx) => (
            <VideoContent key={idx} {...content} />
          ))}
        </Right>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default Heritage;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const MainTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isDesktop ? "12rem" : "6rem")};
  font-weight: 600;
  line-height: 145%;
  margin-bottom: 6.8rem;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  position: relative;
`;

const Left = styled.div`
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  width: ${(props) => (props.$isMobile ? "100%" : "35%")};
`;

const Right = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "65%")};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const CategoryText = styled(PretendardText)`
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  font-size: 4.8rem;
  font-weight: 600;
  top: 120px;
  height: auto;
`;

const TitleText = styled(PretendardText)`
  font-size: 8rem;
  font-weight: 600;
`;

const TagContainer = styled.div`
  display: flex;
  column-gap: 5.2rem;
  row-gap: 1rem;
  flex-wrap: wrap;
`;

const Tag = styled.p`
  font-size: 2rem;
  color: #00aeef;
`;

const Description = styled.p`
  font-size: 2rem;
  color: #5a636a;
`;

const ImageContainer = styled.div`
  position: relative;
  height: ${(props) => (props.$isDesktop ? "100vh" : "fit-content")};
  display: ${(props) => (props.$isDesktop ? "block" : "flex")};
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  gap: ${(props) => (props.$isDesktop ? "0" : "20px")};
`;

const ImageWrapper = styled.div`
  width: ${(props) => (props.$isDesktop ? "42%" : "100%")};
  position: ${(props) => (props.$isDesktop ? "absolute" : "relative")};
  overflow: hidden;
  ${(props) =>
    props.$isDesktop &&
    `&:first-child {
    top: 5%;
    left: 0;
  }
  &:nth-child(2) {
    top: 20%;
    right: 0;
  }
  &:last-child {
    top: 55%;
    left: 6.5%;
  }`}
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;

const HoverImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s ease;

  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

const ImageContents = styled.div`
  position: absolute;
  width: 100%;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageName = styled(PretendardText)`
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
`;

const ImageDesc = styled(PretendardText)`
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
`;
