import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";
import { route } from "../../../router/route";

function NoticeCard({ style, title, date, order, id }) {
  const navigation = useNavigate();

  return (
    <>
      <div
        onClick={() => {
          navigation(`${route.noticeDetail}/${id}`);
        }}
        style={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "row",
          alignItems: "center",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          height: 66,
          width: "100%",
          padding: "0px 30px",
          ...style,
        }}
      >
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "26.1px",
            color: "#778088",
            width: 72,
          }}
        >
          {order}
        </PretendardText>

        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "26.1px",
            color: "#262C31",
            flex: 1,
          }}
        >
          {title}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "26.1px",
            color: "#ADB4BA",
          }}
        >
          {date}
        </PretendardText>
      </div>
    </>
  );
}

export default NoticeCard;
