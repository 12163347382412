import React, { useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { menuLang } from "../../lang/lang";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { route } from "../../router/route";
import PreparedModal from "../../pages/modal/preparedModal";

const HeaderSubMenu = ({ list }) => {
  const language = useRecoilValue(langState);
  const navigation = useNavigate();

  const [mouseOver, setMouseOver] = useState("");
  const [mouseOver2, setMouseOver2] = useState("");
  const [isPreparedModal, setIsPreparedModal] = useState(false);

  return (
    <SubMenuWrapper>
      {list?.map((item, index) => {
        if (item.originLink && item.subMenus3.length <= 0) {
          return;
        }
        if (item.exposure == false) {
          return;
        }

        return (
          <SubMenuTextWrapper
            key={index}
            onMouseOver={() => setMouseOver(item.title)}
            onMouseLeave={() => setMouseOver("")}
          >
            <SubMenuText
              key={index}
              onClick={() => {
                if (item.link === route.faq) {
                  return;
                }

                if (item.link === route.stockInfo) {
                  return setIsPreparedModal(true);
                } else {
                  navigation(item.link);
                }
              }}
            >
              {/* {menuLang[item.title][language]} */}
              {item.title.startsWith("MENU")
                ? menuLang[item.title][language]
                : item.title}
            </SubMenuText>
            <CrossLine />
            {item.subMenus3 && mouseOver === item.title ? (
              <SubMenuWrapper style={{ left: "210px", top: 0 }}>
                {item.subMenus3?.map((item2, index2) => {
                  if (item2.originLink && item2.subMenus4.length <= 0) {
                    return;
                  }
                  if (item2.exposure === false) {
                    return;
                  }

                  return (
                    <SubMenuTextWrapper
                      key={index2}
                      onMouseOver={() => setMouseOver2(item2.title)}
                      onMouseLeave={() => setMouseOver2("")}
                    >
                      <SubMenuText
                        key={index2}
                        onClick={() => {
                          // if (item2.link === route.electronicAnnounce) {
                          //   return setIsPreparedModal(true);
                          // } else {
                          navigation(item2.link);
                          // }
                        }}
                      >
                        {/* {menuLang[item2.title][language]} */}
                        {item2.title.startsWith("MENU")
                          ? menuLang[item2.title][language]
                          : item2.title}
                      </SubMenuText>
                      <CrossLine />
                      {item2.subMenus4 && mouseOver2 === item2.title ? (
                        <SubMenuWrapper style={{ left: "210px", top: 0 }}>
                          {item2.subMenus4?.map((item3, index3) => {
                            if (item3.exposure === false) {
                              return;
                            }

                            return (
                              <div
                                key={index3}
                                onMouseOver={() => setMouseOver(item3.title)}
                                onMouseLeave={() => setMouseOver("")}
                              >
                                <SubMenuText
                                  key={index3}
                                  onClick={() => navigation(item3.link)}
                                >
                                  {/* {menuLang[item3.title][language]} */}
                                  {item3.title.startsWith("MENU")
                                    ? menuLang[item3.title][language]
                                    : item3.title}
                                </SubMenuText>
                                <CrossLine />
                              </div>
                            );
                          })}
                        </SubMenuWrapper>
                      ) : null}
                    </SubMenuTextWrapper>
                  );
                })}
              </SubMenuWrapper>
            ) : null}
          </SubMenuTextWrapper>
        );
      })}

      {isPreparedModal ? (
        <PreparedModal
          onClick={() => {
            setIsPreparedModal(false);
          }}
        />
      ) : null}
    </SubMenuWrapper>
  );
};

export default HeaderSubMenu;

const SubMenuWrapper = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 80px;
  left: -20px;

  z-index: 999;
`;

const SubMenuTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubMenuText = styled(PretendardText)`
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #5a636a;
  /* cursor: pointer; */
  padding: 15px 15px;

  &:hover {
    color: #00aeef;
  }
`;

const CrossLine = styled.div`
  border-bottom: 1px solid #f1f1f1;
  width: 180px;
`;
