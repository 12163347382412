import styled from "styled-components";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import BigImgContents from "../components/BigImgContents";
import MainFeature from "../components/MainFeature";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import { root } from "../../../../styles/GlobalStyle";
import WideImg from "../components/WideImg";
import useDisplay from "../../../../hooks/useDisplay";
import { dtnpl } from "../../../../lang/renewal/productsLang";

const Dtnpl = ({ langValue }) => {
  const { isDesktop } = useDisplay();
  const data = dtnpl[langValue];
  return (
    <Layout bgColor={data.bgColor} title={"DTNPL"} subTitle={data.header}>
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <MainFeature featureList={data.feature} />
        <FullWidthSection $isDesktop={isDesktop}>
          <FramerSlideIn>
            <WideImg title={data.wideImg.title} img={data.wideImg.src} />
          </FramerSlideIn>
        </FullWidthSection>
        <DescriptImg imageList={data.desctiptImg} />
      </ContentsWrapper>
    </Layout>
  );
};

export default Dtnpl;

const ContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;

const FullWidthSection = styled.div`
  position: relative;
  width: ${(props) =>
    props.$isDesktop ? "calc(100% + 20rem)" : "calc(100% + 10rem)"};
  left: ${(props) => (props.$isDesktop ? "-10rem" : "-5rem")};
`;
