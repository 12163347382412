import styled from "styled-components";
import { catis } from "../../../../lang/renewal/productsLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import DescriptDevice from "../components/DescriptDevice";
import Layout from "../components/Layout";

const Catis = ({ langValue }) => {
  const data = catis[langValue];
  return (
    <Layout bgColor={data.bgColor} title={"CATIS™"} subTitle={data.header}>
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <DescriptDevice deviceList={data.devices} />
      </ContentsWrapper>
    </Layout>
  );
};

export default Catis;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
