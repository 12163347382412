import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import Input from "../atoms/input/input";
import Textarea from "../atoms/textarea/textarea";
import useDisplay from "../../hooks/useDisplay";

const InputForm = ({
  title,
  essential,
  type,
  placeholder,
  value,
  onChange,
  inputStyle,
  style,
  isTextArea,
}) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <InputFormWrapper style={style}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <PretendardText
          style={{
            color: "#2F3941",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "23.2px",
            display: "contents",
          }}
        >
          {title}
        </PretendardText>
        {essential ? (
          <PretendardText
            style={{
              color: "#00AEEF",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              display: "contents",
            }}
          >
            *
          </PretendardText>
        ) : null}
      </div>

      {isTextArea ? (
        <Textarea
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{
            ...inputStyle,
          }}
        />
      ) : (
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{
            ...inputStyle,
          }}
        />
      )}
    </InputFormWrapper>
  );
};

export default InputForm;

const InputFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
