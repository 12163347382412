// src/styles/GlobalStyle.js
import { createGlobalStyle } from "styled-components";

export const root = {
  blue: "#00AEEF",
  navy: "#316ABA",
  purple: "#632585",
  fontGray: "#8A8A8A",
  businessSky: "#00AEEF",
  businessBlue: "#3874CF",
  businessNavy: "#5B50BB",
  businessPurple: "#7F2CA7",
};

const GlobalStyle = createGlobalStyle`
/* 웹킷 브라우저 (Chrome, Safari 등) */
::-webkit-scrollbar {
  display: none;
}

.outlined-text {
  color: white; /* 글자 색상 (투명하게 보이도록 흰색으로 설정) */
  -webkit-text-stroke: 2px black; /* 테두리 두께 및 색상 설정 */
  text-align: center; /* 가운데 정렬 */
}
`;

export default GlobalStyle;
