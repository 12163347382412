import { Mobile } from "../../../hooks/mobile";

const MobileBr = () => {
  return (
    <Mobile>
      <br />
    </Mobile>
  );
};

export default MobileBr;
