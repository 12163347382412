import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../../hooks/useDisplay";
import ContentBox2 from "../../../../../components/templates/contentBox2";
import DeskTopBr from "../../../../../components/molecules/br/deskTopBr";
import TabletBr from "../../../../../components/molecules/br/tabletBr";
import { langState } from "../../../../../store/langState";
import { useRecoilValue } from "recoil";
import { titleLang } from "./../../../../../lang/Business/title";
import { xbandLang } from "../../../../../lang/Business/xband";

const Configuration = () => {
  const { isMobile } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <ContentBox2
      style={{ marginTop: isMobile ? "80px" : "120px" }}
      title={titleLang.xband?.configurationOf[langValue]}
      content={
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <ContentText $isMobile={isMobile}>{xbandLang.configuration1[langValue]}</ContentText>
          <ContentText $isMobile={isMobile}>{xbandLang.configuration2[langValue]}</ContentText>
        </div>
      }
    />
  );
};

export default Configuration;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;
