import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../components/atoms/text/pretendardText";
import Pagination from "../../components/organisms/pagination";
import ArticleCard from "../../components/templates/articleCard";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "./../../components/templates/middleNavBar";

import useDisplay from "../../hooks/useDisplay";

import { apiSDKService } from "../../api/renewal/api.service";
import { mainBannerLang } from "../../lang/lang";
import { lumirNewsLang } from "../../lang/relation/lumirNewsLang";
import { route } from "../../router/route";
import { langState } from "../../store/langState";
import ArticleCardSkeleton from "./ArticleCardSkeleton";

const NewArticle = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const langValue = useRecoilValue(langState);

  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [keyword, setKeyword] = useState("");
  const [totalCtn, setTotalCtn] = useState(0);
  const [articleList, setArticleList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 9,
    rangeSize: 1,
    totalCount: 0,
    totalPages: 0,
    first: true,
    last: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getArticleAPI();
  }, [location, langValue]);

  const getArticleAPI = async () => {
    try {
      setIsLoading(true);
      const news = await apiSDKService.getPressReleases(
        page,
        pagination.size,
        pagination.pageNo,
        pagination.totalPages,
        langValue
      );

      setTotalCtn(news.totalCount);
      setPagination({
        ...pagination,
        totalCount: news.totalCount,
        totalPages: news.totalPage,
        first: news.isFirst,
        last: news.isLast,
      });
      setArticleList(news.items);
      setIsServerError(false);
    } catch (e) {
      console.error(e);
      console.log("Error occurred while fetching news articles");
      setIsServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isServerError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
            gap: "20px",
          }}
        >
          <PretendardText
            style={{
              color: "#ADB4BA",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "21px",
            }}
          >
            뉴스 정보를 불러오는 중 오류가 발생했습니다.
          </PretendardText>
          <button
            onClick={() => window.location.reload()}
            style={{
              padding: "12px 24px",
              backgroundColor: "gray",
              color: "white",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 500,
              transition: "background-color 0.2s",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "gray")
            }
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "gray")}
          >
            페이지 새로고침
          </button>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: isTablet ? "space-between" : "flex-start",
            flexWrap: "wrap",
            gap: isMobile || isTablet ? "30px 0px" : "50px 40px",
          }}
        >
          {Array(9)
            .fill(null)
            .map((_, index) => (
              <ArticleCardSkeleton key={index} />
            ))}
        </div>
      );
    }

    return (
      <>
        {isMobile ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18.9px",
                marginTop: 40,
              }}
            >
              {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
              {langValue === "KR" ? "건" : ""}
            </PretendardText>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "23.2px",
              }}
            >
              {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
              {langValue === "KR" ? "건" : ""}
            </PretendardText>
          </div>
        )}

        {articleList?.length > 0 ? (
          <>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: isTablet ? "space-between" : "flex-start",
                flexWrap: "wrap",
                gap: isMobile || isTablet ? "30px 0px" : "50px 40px",
              }}
            >
              {articleList.map((item, index) => (
                <ArticleCard
                  key={index}
                  src={item.fileUrl}
                  author={item.mediaCompany}
                  date={moment.utc(item.date).format("YYYY.MM.DD")}
                  title={item.title}
                  link={item.link}
                />
              ))}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: isMobile || isTablet ? 40 : 60,
              }}
            >
              <Pagination
                route={route.newArticle}
                queryString={`keyword=${keyword}`}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </>
        ) : (
          <PretendardText
            style={{
              color: "#ADB4BA",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "21px",
              marginTop: isMobile ? 60 : isTablet ? 80 : 120,
              textAlign: "center",
            }}
          >
            {searchParams.get("keyword") === "" ||
            searchParams.get("keyword") == null
              ? lumirNewsLang.empty[langValue]
              : lumirNewsLang.searchEmpty[langValue]}
          </PretendardText>
        )}
      </>
    );
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_new_article.png"
            : "/assets/imgs/main-section/new_article.png"
        }
        title={mainBannerLang.newsArticle.title[langValue]}
        content={mainBannerLang.newsArticle.content[langValue]}
        blackText={!isMobile}
      />
      <MiddleNavBar pathname={pathname} />
      <div
        style={{
          padding: isMobile ? "0px 20px" : isTablet ? "50px 70px" : "60px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default NewArticle;
