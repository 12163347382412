import api, { _axios } from "./api";

export default class CommonApi {
  static async Download(requestDto) {
    return await _axios.post(`download`, requestDto, {
      responseType: "blob",
    });
  }

  static async FileUpload(data) {
    return await api.fileForm(`upload`, data, "post");
  }
}
