import React from "react";
import { motion } from "framer-motion";

const FramerSlideIn = ({
  style,
  children,
  duration = null,
  delay = null,
  amount = 0.5,
}) => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      viewport={{ once: true, amount: amount }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: { duration: duration ?? 0.7, delay: delay ?? 0 },
      }}
      style={{ ...style }}
    >
      {children}
    </motion.div>
  );
};

export default FramerSlideIn;
