import { motion } from "framer-motion";
import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import FloatingImage from "../../../../components/atoms/image/floatingImage";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";
import {
  CATIS,
  CATIS_TEXT,
  intro,
  INTRO_BG,
  LumirX,
} from "../../../../lang/renewal/homeLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";

const ImageSlider = ({ images, currentIndex }) => {
  const { isTablet, isDesktop, isIPadLandscape } = useDisplay();
  const isWide = isDesktop || isIPadLandscape;

  return (
    <FloatingImage width={isTablet ? 50 : 40}>
      <SliderContainer $isWide={isWide}>
        {images.map((image, index) => (
          <Slide
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentIndex ? 1 : 0 }}
            transition={{ duration: 1 }}
            $isWide={isWide}
            $index={index}
          >
            <SlideImage
              src={image}
              alt={`Slide ${index + 1}`}
              $isWide={isWide}
              $isIPadLandscape={isIPadLandscape}
              className="custom-cursor"
            />
            {index === 0 && <PinLogo src={CATIS_TEXT} $isWide={isWide} />}
          </Slide>
        ))}
      </SliderContainer>
    </FloatingImage>
  );
};

const Intro = forwardRef(
  ({ langValue, isDesktop, isMobile, isIPadLandscape }, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigation = useNavigate();
    const isWide = isDesktop || isIPadLandscape;

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % intro.subTitle[langValue].length
        );
      }, 5000);

      return () => clearInterval(interval);
    }, [langValue]);

    return (
      <Container
        ref={ref}
        $isMobile={isMobile}
        $isWide={isWide}
        $isIPadLandscape={isIPadLandscape}
        $introBg={INTRO_BG}
      >
        <TextWrapper $isWide={isWide} $isIPadLandscape={isIPadLandscape}>
          <CircleContainer $isWide={isWide}>
            <Circle
              $isCurrent={currentIndex === 0}
              onClick={() => setCurrentIndex(0)}
              className="custom-cursor"
            />
            <Circle
              $isCurrent={currentIndex === 1}
              onClick={() => setCurrentIndex(1)}
              className="custom-cursor"
            />
          </CircleContainer>
          <TextContainer
            $isWide={isWide}
            className="custom-cursor"
            onClick={() => navigation(intro.link[currentIndex])}
          >
            <FramerSlideIn delay={0}>
              <H2>{intro.title[langValue]}</H2>
            </FramerSlideIn>
            <FramerSlideIn delay={0.3}>
              <TextSlideContainer>
                {intro.subTitle[langValue].map((subTitle, index) => (
                  <TextSlide
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: index === currentIndex ? 1 : 0 }}
                    transition={{ duration: 1 }}
                    $isWide={isWide}
                  >
                    <H3
                      key={index}
                      dangerouslySetInnerHTML={{ __html: subTitle }}
                    />
                  </TextSlide>
                ))}
              </TextSlideContainer>
            </FramerSlideIn>
            <FramerSlideIn delay={0.6}>
              <P>{intro.description[langValue]}</P>
            </FramerSlideIn>
          </TextContainer>
          <ImageSlider
            images={[CATIS, LumirX]}
            onClick={() => navigation(intro.link[currentIndex])}
            currentIndex={currentIndex}
          />
        </TextWrapper>
        <ScrollDown>
          <div id="scroll-down" style={{ position: "relative" }}>
            <img
              src="/assets/icons/home_arrow.png"
              style={{ position: "relative", right: "-9px" }}
              alt="scroll-down"
            />
          </div>
          <PretendardText
            style={{
              fontSize: isMobile ? "15px" : "16px",
              fontWeight: 600,
              lineHeight: isMobile ? "21px" : "23.2px",
              color: "#fff",
              marginTop: "22px",
            }}
          >
            SCROLL DOWN
          </PretendardText>
        </ScrollDown>
      </Container>
    );
  }
);

Intro.displayName = "Intro";

export default Intro;

const Container = styled.div`
  position: relative;
  top: ${(props) => (props.$isWide ? "-80px" : "-67px")};
  width: 100%;
  height: ${(props) =>
    props.$isMobile ? "calc(100vh + 280px)" : "calc(100vh + 80px)"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: ${({ $introBg }) => `url(${$introBg})`};
  padding: 110px 0 0 0;
`;

const TextContainer = styled.div`
  max-width: 1400px;
  width: ${(props) => (props.$isWide ? "50%" : "100%")};
  text-align: ${(props) => (!props.$isWide ? "center" : "left")};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4.5rem;
  color: white;
`;

const H2 = styled.h2`
  font-size: 8em;
`;

const TextSlideContainer = styled.div`
  position: relative;
  height: 5em;
  width: 100%;
`;

const TextWrapper = styled.div`
  position: relative;
  width: ${(props) =>
    !props.$isWide ? "90%" : props.$isIPadLandscape ? "80%" : "70%"};
  display: flex;
  flex-direction: ${(props) => (!props.$isWide ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
  gap: 4.5em;
`;

const TextSlide = styled(motion.div)`
  position: absolute;
  bottom: 0;
  ${({ $isWide }) =>
    !$isWide &&
    css`
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    `};
`;

const H3 = styled.h3`
  font-size: 2.5em;
  font-weight: 400;
  & > span {
    font-weight: 700;
  }
`;

const P = styled.p`
  font-size: 1.5em;
  font-weight: 700;
`;

const CircleContainer = styled.div`
  display: flex;
  gap: 1em;
  flex-direction: ${(props) => (props.$isWide ? "column" : "row")};
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  opacity: ${(props) => (props.$isCurrent ? 1 : 0.3)};
  bottom: -50px;
  right: -50px;
`;

const SliderContainer = styled.div`
  position: relative;
  width: ${({ $isWide }) => (!$isWide ? "100%" : "auto")};
  height: ${({ $isWide }) => (!$isWide ? "50vh" : "75vh")};
`;

const Slide = styled(motion.div)`
  position: absolute;
  top: ${({ $isWide }) => (!$isWide ? "0" : "50%")};
  left: ${({ $isWide }) => (!$isWide ? "0" : "50%")};
  transform: ${({ $isWide, $index }) =>
    !$isWide
      ? $index === 1
        ? "translate(0, 50%);"
        : ""
      : "translate(-50%, -50%);"};
  width: ${({ $isWide }) => (!$isWide ? "100%" : "80%")};
`;

const SlideImage = styled.img`
  ${({ $isWide }) =>
    !$isWide &&
    css`
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
    `};

  max-width: ${({ $isWide, $isIPadLandscape }) =>
    !$isWide ? "70vw" : $isIPadLandscape ? "50vh" : "75vh"};
  max-height: ${({ $isWide }) => (!$isWide ? "50vh" : "75vh")};
  height: auto;
  width: auto;
  object-fit: cover;
`;

const ScrollDown = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PinLogo = styled.img`
  position: absolute;
  height: auto;
  width: 15em;
  object-fit: cover;
  bottom: 6em;
  right: ${({ $isWide }) => ($isWide ? "-3em" : "0")};
`;
