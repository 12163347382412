import React, { useMemo } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useNavigate } from "react-router-dom";

function PaginationTemp({ style }) {
  const navigate = useNavigate();

  return (
    <>
      <PaginationWrapper style={style}>
        <ArrowContainer>
          <ArrowBox>
            <img
              src="/assets/icons/pagination/left_arrow.svg"
              alt="left_arrow"
              style={{
                width: 7,
                height: 13,
              }}
            />
          </ArrowBox>
        </ArrowContainer>
        <NumberWrapper>
          <PageText
            style={{
              color: "#000000",
            }}
          >
            1
          </PageText>
          <PageText>2</PageText>
          <PageText>3</PageText>
        </NumberWrapper>
        <ArrowContainer>
          <ArrowBox>
            <img
              src="/assets/icons/pagination/right_arrow.svg"
              alt="right_arrow"
              style={{
                width: 7,
                height: 13,
              }}
            />
          </ArrowBox>
        </ArrowContainer>
      </PaginationWrapper>
    </>
  );
}

export default PaginationTemp;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ArrowBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const PageText = styled(PretendardText)`
  color: #818181;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  cursor: pointer;
`;
