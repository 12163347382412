import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

const ContentBox = ({ title, content, style }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <ContentBoxWrapper style={{ ...style }} $isDesktop={isDesktop} $isMobile={isMobile}>
      <Title $isMobile={isMobile} $isTablet={isTablet}>
        {title}
      </Title>
      {isMobile && <VerticalLine />}
      <Content $isTablet={isTablet} $isMobile={isMobile}>
        {content}
      </Content>
    </ContentBoxWrapper>
  );
};

export default ContentBox;

const ContentBoxWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  background-color: #fbfbfb;
  border: 1px solid #d7dce2;
  padding: ${(props) => (props.$isDesktop ? "50px 60px 50px 60px" : "20px")};
  display: flex;
  align-items: flex-start;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
`;

const Title = styled(PretendardText)`
  min-width: 160px;
  width: ${(props) => (props.$isMobile ? "100%" : props.$isTablet ? "" : "326px")};
  font-weight: 600;
  font-size: ${(props) => (props.$isMobile ? "15px" : "24px")};
  line-height: 145%;
  color: #262c31;
`;

const Content = styled(PretendardText)`
  max-width: 931px;
  font-weight: 400;
  font-size: ${(props) => (props.$isMobile || props.$isTablet ? "15px" : "18px")};
  line-height: 140%;
  letter-spacing: ${(props) => (props.$isMobile || props.$isTablet ? "" : "-0.18px")};
  color: #262c31;
  border-left: ${(props) => (props.$isMobile ? "none" : "1px solid #f1f1f1")};
  padding-left: ${(props) => (props.$isMobile ? 0 : "23px")};
  margin-left: ${(props) => (props.$isMobile ? 0 : "23px")};
`;

const VerticalLine = styled.div`
  width: 100%;
  margin: 15px 0;
  border-top: 1px solid #f1f1f1;
`;
