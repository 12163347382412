import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import { useRecoilValue } from "recoil";
import { langState } from "../../store/langState";
import { menuLang } from "../../lang/lang";
import MiddleNavBarMenu from "./middleNavBarMenu";
import useDisplay from "../../hooks/useDisplay";
import { menuState } from "../../store/menuState";

const MiddleNavBar = ({ pathname }) => {
  const { isMobile, isTablet, isMiddleNav } = useDisplay();
  const menuAtom = useRecoilValue(menuState);

  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  const [openNum, setOpenNum] = useState(0);

  const [depth1, setDepth1] = useState("");
  const [depthList1, setDepthList1] = useState([]);

  const [depth2, setDepth2] = useState("");
  const [depthList2, setDepthList2] = useState([]);

  const [depth3, setDepth3] = useState("");
  const [depthList3, setDepthList3] = useState([]);

  const [depth4, setDepth4] = useState("");
  const [depthList4, setDepthList4] = useState([]);

  useEffect(() => {
    getProductFunc();
  }, [langValue]);

  const getProductFunc = async () => {
    const depthList1 = [];
    const depthList2 = [];
    const depthList3 = [];
    const depthList4 = [];

    menuAtom?.map((depth1) => {
      //depth1 처리
      depthList1.push({
        title: depth1.title.startsWith("MENU")
          ? menuLang[depth1.title][langValue]
          : depth1.title,
        link: depth1.link,
        exposure: depth1.exposure,
      });
      if (pathname.includes(depth1.originLink)) {
        setDepth1(
          depth1.title.startsWith("MENU")
            ? menuLang[depth1.title][langValue]
            : depth1.title
        );

        //depth2 처리
        depth1.subMenus2?.map((depth2) => {
          depthList2.push({
            title: depth2.title.startsWith("MENU")
              ? menuLang[depth2.title][langValue]
              : depth2.title,
            link: depth2.link,
            exposure: depth2.exposure,
          });

          if (pathname.includes("products")) {
            langValue === "KR"
              ? setDepth2("위성시스템")
              : setDepth2("Satellite System");
          }

          if (pathname.includes("service")) {
            setDepth2("SARDIP™");
          }

          if (
            pathname.includes(depth2.link) ||
            pathname.includes(depth2.originLink)
          ) {
            setDepth2(
              depth2.title.startsWith("MENU")
                ? menuLang[depth2.title][langValue]
                : depth2.title
            );
          }

          //depth3 처리
          depth2.subMenus3?.map((depth3) => {
            if (pathname.includes(depth2.originLink)) {
              depthList3.push({
                title: depth3.title.startsWith("MENU")
                  ? menuLang[depth3.title][langValue]
                  : depth3.title,
                link: depth3.link,
                exposure: depth3.exposure,
              });
            }
            if (
              pathname.includes(depth3.link) ||
              pathname.includes(depth3.originLink)
            ) {
              setDepth3(
                depth3.title.startsWith("MENU")
                  ? menuLang[depth3.title][langValue]
                  : depth3.title
              );
            }

            //depth4 처리
            depth3.subMenus4?.map((depth4) => {
              if (pathname.includes(depth3.originLink)) {
                depthList4.push({
                  title: depth4.title.startsWith("MENU")
                    ? menuLang[depth4.title][langValue]
                    : depth4.title,
                  link: depth4.link,
                  exposure: depth4.exposure,
                });
              }
              if (pathname.includes(depth4.link)) {
                setDepth4(
                  depth4.title.startsWith("MENU")
                    ? menuLang[depth4.title][langValue]
                    : depth4.title
                );
              }
            });
          });
        });
      }
    });

    setDepthList1(depthList1);
    setDepthList2(depthList2);
    setDepthList3(depthList3);
    setDepthList4(depthList4);
  };

  return (
    <NavBarSection $isMobile={isMobile} $isTablet={isTablet}>
      <NavBarWrapper>
        {isMiddleNav ? null : (
          <img
            src="/assets/icons/go-home.png"
            alt=""
            style={{
              // cursor: "pointer",
              marginRight: "3px",
            }}
            onClick={() => navigation(route.default)}
            className="custom-cursor"
          />
        )}

        {/* ========== depth1 ========== */}
        {isMiddleNav ? null : (
          <>
            {!isMiddleNav && <VerticalLine />}
            <MiddleNavBarMenu
              index={1}
              depthTitle={depth1}
              depthList={depthList1}
              openNum={openNum}
              setOpenNum={setOpenNum}
            />
          </>
        )}

        {/* ========== depth2 ========== */}
        {depth3 && isMiddleNav ? null : (
          <>
            {!isMiddleNav && <VerticalLine />}
            <MiddleNavBarMenu
              index={2}
              depthTitle={depth2}
              depthList={depthList2}
              openNum={openNum}
              setOpenNum={setOpenNum}
            />
          </>
        )}

        {/* ========== depth3 ========== */}
        {depth4 && isMiddleNav ? null : (
          <>
            {depth3 ? (
              <>
                {!isMiddleNav && <VerticalLine />}
                <MiddleNavBarMenu
                  index={3}
                  depthTitle={depth3}
                  depthList={depthList3}
                  openNum={openNum}
                  setOpenNum={setOpenNum}
                />
              </>
            ) : null}
          </>
        )}

        {/* ========== depth4 ========== */}
        {depth4 ? (
          <>
            {!isMiddleNav && <VerticalLine />}
            <MiddleNavBarMenu
              index={4}
              depthTitle={depth4}
              depthList={depthList4}
              openNum={openNum}
              setOpenNum={setOpenNum}
            />
          </>
        ) : null}
      </NavBarWrapper>
    </NavBarSection>
  );
};

export default MiddleNavBar;

const NavBarSection = styled.div`
  width: 100%;
  height: ${(props) => (props.$isMobile ? "42px" : "60px")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.$isTablet ? "0 70px" : "0")};
`;

const NavBarWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 25px;
  background-color: #ccd1d7;
  margin: 0px 10px;
`;
