import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";
import { route } from "../../../router/route";

function NoticeCardM({ style, title, date, order, id }) {
  const navigation = useNavigate();

  return (
    <>
      <div
        onClick={() => {
          navigation(`${route.noticeDetail}/${id}`);
        }}
        style={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          width: "100%",
          padding: "20px 10px",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#778088",
              marginRight: 10,
            }}
          >
            {order}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#262C31",
            }}
          >
            {title}
          </PretendardText>
        </div>

        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "18.9px",
            color: "#ADB4BA",
            marginTop: 10,
          }}
        >
          {date}
        </PretendardText>
      </div>
    </>
  );
}

export default NoticeCardM;
