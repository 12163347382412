import styled, { css } from "styled-components";

// 공통 스타일
const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

// 컨테이너 스타일
export const Container = styled.div`
  position: relative;
  padding: ${({ $isDesktop }) =>
    $isDesktop ? "9em 0 9em 13.5em" : "9em 2em 9em 3em"};
  display: ${({ $isMobile }) => ($isMobile ? "block" : "flex")};
  justify-content: space-between;
  margin-top: 10%;
  background-color: white;
  gap: 3em;
`;

// 왼쪽 콘텐츠 스타일
export const LeftContents = styled.div`
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  top: 4em;
  height: 100%;
  padding-bottom: ${({ $isMobile, $isTablet }) =>
    $isMobile ? "1em" : $isTablet ? "30vh" : "70vh"};
  font-size: 2.5em;
  font-weight: 600;
`;

// 오른쪽 콘텐츠 스타일
export const RightContents = styled.div`
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "80em")};
  ${flexColumn}
  gap: 7em;
`;

// 콘텐츠 섹션 스타일
export const Content = styled.div`
  ${flexColumn}
  gap: 3em;
`;

export const Title = styled.h4`
  font-size: 4.2em;
  text-align: left;
`;

export const Description = styled.p`
  font-size: 1.3em;
  text-align: left;
  line-height: 1.5em;
`;

// 이미지 래퍼 스타일
export const ImagesWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  ${({ $isMobile, $isLightweightSection }) =>
    $isMobile &&
    $isLightweightSection &&
    css`
      width: calc(100% + 5em);
      position: relative;
      left: -3em;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
    `}
`;

// 이미지 컨테이너 스타일
export const Images = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: content-box;

  ${({ $isHighResolutionSection }) =>
    $isHighResolutionSection &&
    css`
      ${flexColumn}
      gap: 1em;
    `}

  ${({ $isObservationModesSection, $isDesktop }) =>
    $isObservationModesSection &&
    css`
      display: ${$isDesktop ? "grid" : "flex"};
      gap: 1em;
      ${$isDesktop &&
      css`
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 4fr);
      `}
      ${!$isDesktop &&
      css`
        flex-direction: column;
      `}
    `}

  ${({ $isLightweightSection, $isMobile }) =>
    $isLightweightSection &&
    css`
      display: ${$isMobile ? "inline-flex" : "flex"};
      ${$isMobile && "width: 150%"}
    `}
`;

// 개별 이미지 컨테이너 스타일
export const ImageContainer = styled.div`
  position: relative;
  transform: translate3d(0px, 0px, 0px);
  overflow: visible;
  width: 100%;
  transition: all 0.3s ease-in-out;

  ${({ $isObservationModesSection, $index, $isDesktop }) =>
    $isObservationModesSection &&
    $isDesktop &&
    css`
      grid-area: ${$index === 0
        ? "1/1/2/6"
        : $index === 1
        ? "1/6/2/11"
        : $index === 2
        ? "2/3/3/8"
        : $index === 3
        ? "2/8/3/13"
        : ""};
    `}

  ${({ $isLightweightSection, $index }) =>
    $isLightweightSection &&
    css`
      z-index: ${50 + $index};
      &:hover {
        z-index: 52;
        transform: scale(1.05);
      }
    `}
`;

// 이미지 래퍼 스타일
export const ImageWrap = styled.div`
  width: 100%;
  height: auto;
  overflow: visible;

  ${({ $isLightweightSection, $index }) =>
    $isLightweightSection &&
    css`
      ${$index === 1 &&
      css`
        position: absolute;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%);
        width: 40em;
      `}
      ${$index === 2 &&
      css`
        position: absolute;
        height: 100%;
        object-fit: cover;
      `}
    `}
`;

// 이미지 스타일
export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;

  ${ImageWrap}:hover & {
    transform: scale(1.05);
  }
`;
