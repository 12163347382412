import React, { useEffect, useState } from "react";
import useDisplay from "../../../hooks/useDisplay";
import TalentCard from "../../../components/templates/talentCard";
import RecruitApi from "../../../api/recruitApi";
import { route } from "../../../router/route";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../../styles/swiper.css";
import { Autoplay } from "swiper/modules";
import { Pagination } from "swiper/modules";
import PretendardText from "../../../components/atoms/text/pretendardText";
import { useRecoilValue } from "recoil";
import { langState } from "../../../store/langState";

const Recruit = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '">' + "</div>";
    },
  };

  const langValue = useRecoilValue(langState);
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [recruitList, setRecruitList] = useState([]);

  useEffect(() => {
    const getRecruitAPI = async () => {
      try {
        // const data = {
        //   language: langValue,
        // };

        // const response = (await RecruitApi.GetRecent(data)).data.data;
        const response = await RecruitApi.GetRecent();
        setRecruitList(response);
      } catch (e) {
        console.error("Failed to fetch recruit data:", e);
      }
    };

    getRecruitAPI();
  }, [langValue]);

  return (
    <>
      {recruitList.length > 0 ? (
        <>
          {isSmall ? (
            <Swiper
              style={{
                width: "100%",
                height: isTablet ? 270 : 240,
              }}
              spaceBetween={20}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={pagination}
              modules={[Autoplay, Pagination]}
            >
              {recruitList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <TalentCard
                      key={index}
                      order={index + 1}
                      status={item.recruitStatus}
                      department={item.department}
                      title={item.title}
                      link={`${route.jobAnnounceDetail}/${item.id}`}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div style={{ display: "flex", gap: "40px" }}>
              {recruitList.map((item, index) => {
                return (
                  <TalentCard
                    key={index}
                    order={index + 1}
                    status={item.recruitStatus}
                    department={item.department}
                    title={item.title}
                    link={`${route.jobAnnounceDetail}/${item.id}`}
                  />
                );
              })}
            </div>
          )}
        </>
      ) : (
        <PretendardText
          style={{
            width: "100%",
            fontSize: "15px",
            fontWeight: 400,
            lineheight: "140%",
            color: "#adb4ba",
            textAlign: "center",
            margin: isMobile ? "50px 0" : "90px 0",
          }}
        >
          There is no registered announcement.
        </PretendardText>
      )}
    </>
  );
};

export default Recruit;
