import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { nationalRnDLang } from "../../lang/CompanyIntroduction/nationalRnD";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";

const RnDMenuBar = ({ ref1, ref2, ref3, ref4, view }) => {
  const langValue = useRecoilValue(langState);

  const onMoveBox = (ref) => {
    if (ref)
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div style={{ position: "sticky", top: "120px", alignSelf: "flex-start" }}>
      <RnDMenuWrapper>
        <RnDMenu onClick={() => onMoveBox(ref1)} className="custom-cursor">
          <CircleContainer>
            {view === 1 ? <CheckedCircle /> : null}
            <UncheckedCircle view={view} id={1} />
          </CircleContainer>
          <RnDText view={view} id={1} className="custom-cursor">
            {nationalRnDLang.menuBar?.scienceAndICT[langValue]}
          </RnDText>
        </RnDMenu>
        <RnDMenu onClick={() => onMoveBox(ref2)} className="custom-cursor">
          <CircleContainer>
            {view === 2 ? <CheckedCircle /> : null}
            <UncheckedCircle view={view} id={2} />
          </CircleContainer>
          <RnDText view={view} id={2}>
            {nationalRnDLang.menuBar?.defenseScience[langValue]}
          </RnDText>
        </RnDMenu>
        <RnDMenu onClick={() => onMoveBox(ref3)} className="custom-cursor">
          <CircleContainer>
            {view === 3 ? <CheckedCircle /> : null}
            <UncheckedCircle view={view} id={3} />
          </CircleContainer>
          <RnDText view={view} id={3}>
            {nationalRnDLang.menuBar?.koreaTechnologyInformation[langValue]}
          </RnDText>
        </RnDMenu>
        <RnDMenu onClick={() => onMoveBox(ref4)} className="custom-cursor">
          <CircleContainer>
            {view === 4 ? <CheckedCircle /> : null}
            <UncheckedCircle view={view} id={4} />
          </CircleContainer>
          <RnDText view={view} id={4}>
            {nationalRnDLang.menuBar?.koreaIndustrialTechnology[langValue]}
          </RnDText>
        </RnDMenu>
        <div
          style={{
            position: "absolute",
            left: "21px",
            top: "50%",
            transform: "translateY(-50%)",
            width: "1px",
            height: "85%",
            backgroundColor: "#ADB4BA",
            zIndex: 1,
          }}
        />
      </RnDMenuWrapper>
    </div>
  );
};

export default RnDMenuBar;

const RnDMenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const RnDMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  z-index: 2;
`;

const RnDText = styled(PretendardText)`
  font-size: 18px;
  font-weight: ${(props) => (props.view === props.id ? 600 : 400)};
  line-height: 26.1px;
  letter-spacing: -0.18px;
  color: ${(props) => (props.view === props.id ? "#262c31" : "#778088")};
  white-space: pre-wrap;
`;

const CircleContainer = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
`;

const UncheckedCircle = styled.div`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: ${(props) =>
    props.view === props.id ? "#00aeef" : "#adb4ba"};
`;

const CheckedCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #00aeef;
  width: 100%;
  height: 100%;
  background-color: #00aeef10;
`;
