import styled from "styled-components";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import BigImgContents from "../components/BigImgContents";
import DescriptDevice from "../components/DescriptDevice";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import { root } from "../../../../styles/GlobalStyle";

const data = {
  bigImg: [
    {
      title: "위성 본체 전력계 전기지상지원장비",
      content:
        "위성 본체 전력계 전기지상지원장비는 위성 전력계의 지상시험 및 검증을 위하여, 위성의 종합 조립 및 성능 시험에 필요한 전력 공급과 위성에 비축된 전력을 소모하기 위한 부하 모사, 위성의 전원 계통 상태 모니터링, 신호 획득 및 모사, 태양전지판의 전력생성을 모사하는 장비입니다.",
      image: "/assets/imgs/renewal/business/XdmEgse/main.png",
    },
  ],
  deviceList: [
    {
      title: "장비의 특징",
      content:
        "당사는 다목적실용위성 7호 (KOMPSAT-7) 전력계 EGSE 사업에 참여하여 기존에 턴 키 형태로 전량 외주 제작되던 태양전지배열기 시뮬레이터(SAS: Solar Array Simulator) 국산화 개발을 달성하였습니다. 성공적인 과업 완수가 EO/IR 위성의 전력계 EGSE 사업 수주로 이어지는 등 해당 분야에서 타사 대비 월등한 기술 실력으로 우위를 점하고 있습니다.",
    },
  ],
  desctiptImg: [
    {
      title: "K7P-EGSE의 제원",
      src: "/assets/imgs/renewal/business/K7pEgse/item.png",
    },
  ],
};

const K7pEgse = () => {
  return (
    <Layout
      bgColor={data.bgColor}
      title={"K7P-EGSE"}
      subTitle={"위성 본체 전력계<br/>전기지상지원장비"}
    >
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <DescriptDevice deviceList={data.deviceList} />
        <DescriptImg imageList={data.desctiptImg} />
      </ContentsWrapper>
    </Layout>
  );
};

export default K7pEgse;

const ContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
