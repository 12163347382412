import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";

import PretendardText from "../../components/atoms/text/pretendardText";
import InputForm from "../../components/molecules/inputForm";
import FormUI from "../../components/templates/formUI";

import PreparedModal from "./preparedModal";
import ConfirmModal from "./confirmModal";

import useDisplay from "../../hooks/useDisplay";
import InquiryApi from "../../api/inquiryApi";
import { langState } from "../../store/langState";
import { inquiryLang } from "../../lang/inquiryLang";

function InqueryModal({ visiable, setVisiable }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [basicList, setBasicList] = useState();
  const [detailList, setDetailList] = useState();
  const [nameFiled, setNameFiled] = useState();
  const [emailFiled, setEmailFiled] = useState();
  const [phoneFiled, setPhoneFiled] = useState();
  const [isPreparedModal, setIsPreparedModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const langValue = useRecoilValue(langState);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  useEffect(() => {
    getInquiryAPI();
  }, []);

  const getInquiryAPI = async () => {
    try {
      let response;
      if (langValue === "KR") {
        response = (await InquiryApi.GetKR()).data.data;
      } else {
        response = (await InquiryApi.GetEN()).data.data;
      }

      setNameFiled(response.nameField);
      setEmailFiled(response.emailField);

      if (response.basicList.length > 0) {
        const phoneFiledTemp = response.basicList.filter(
          (item) => item.inquiryFieldType === "PHONE"
        );

        if (phoneFiledTemp && phoneFiledTemp.length > 0) {
          setPhoneFiled(phoneFiledTemp[0]);
        }

        const indexTemp = response.basicList.findIndex(
          (item) => item.inquiryFieldType === "PHONE"
        );

        if (indexTemp !== -1) {
          response.basicList.splice(indexTemp, 1);
        }

        if (response.basicList && response.basicList.length > 0) {
          setBasicList(response.basicList);
        }
      }

      if (response.detailList.length > 0) {
        if (response.detailList && response.detailList.length > 0) {
          setDetailList(response.detailList);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const inqueryCheckFunc = async () => {
    try {
      let valueCheck01 = false;
      let valueCheck02 = false;
      let valueCheck03 = false;

      if (email === "" || name === "") {
        return setIsPreparedModal(true);
      }

      if (phoneFiled) {
        if (phoneFiled.isRequired) {
          if (phone === "") {
            valueCheck01 = false;
            return setIsPreparedModal(true);
          } else {
            valueCheck01 = true;
          }
        } else {
          valueCheck01 = true;
        }
      } else {
        valueCheck01 = true;
      }

      if (basicList && basicList.length > 0) {
        await Promise.all(
          basicList.map(async (item) => {
            if (item.isRequired) {
              if (item.value) {
                if (item.value === "") {
                  return false;
                } else {
                }
              } else {
                return false;
              }
            }
          })
        ).then((res) => {
          if (res.some((item02) => item02 === false)) {
            valueCheck02 = false;
            return setIsPreparedModal(true);
          } else {
            valueCheck02 = true;
          }
        });
      } else {
        valueCheck02 = true;
      }

      if (detailList && detailList.length > 0) {
        await Promise.all(
          detailList.map(async (item) => {
            if (item.isRequired) {
              if (item.value) {
                if (item.value === "") {
                  return false;
                } else {
                }
              } else {
                return false;
              }
            }
          })
        ).then((res) => {
          if (res.some((item02) => item02 === false)) {
            valueCheck03 = false;
            return setIsPreparedModal(true);
          } else {
            valueCheck03 = true;
          }
        });
      } else {
        valueCheck03 = true;
      }

      if (valueCheck01 && valueCheck02 && valueCheck03) {
        setIsConfirmModal(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const inquerySubmit = async () => {
    try {
      let contentTemp = [];

      if (phoneFiled) {
        phoneFiled["value"] = phone;

        contentTemp = [...contentTemp, phoneFiled];
      }

      if (basicList && basicList.length > 0) {
        contentTemp = [...contentTemp, ...basicList];
      }

      if (detailList && detailList.length > 0) {
        contentTemp = [...contentTemp, ...detailList];
      }

      const rData = {
        language: langValue,
        email: email,
        name: name,
        content: JSON.stringify(contentTemp),
      };

      await InquiryApi.Submit(rData);

      toast(inquiryLang.completed[langValue]);

      setTimeout(() => {
        setIsConfirmModal(false);
        setVisiable(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <InqueryModalWrapper $isMobile={isMobile}>
        <InqueryModalContainer $isMobile={isMobile}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "24px",
              position: "relative",
              marginBottom: 15,
            }}
          >
            <PretendardText
              style={{
                color: "#262C31",
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: 600,
                marginTop: isMobile ? "32px" : "0px",
              }}
            >
              {inquiryLang.title[langValue]}
            </PretendardText>
            <img
              src="/assets/icons/popup_close.png"
              alt="popup_close"
              style={{
                cursor: "pointer",
                width: isMobile ? 24 : 32,
                height: isMobile ? 24 : 32,
                position: "absolute",
                bottom: isMobile ? 24 : 10,
                right: 0,
              }}
              onClick={() => setVisiable(false)}
            />
          </div>

          <PretendardText
            style={{
              color: "#5A636A",
              fontSize: isMobile ? "15px" : "18px",
              fontWeight: 400,
              lineHeight: isMobile ? "21px" : "26.1px",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {inquiryLang.content[langValue]}
          </PretendardText>

          <div
            style={{
              borderBottom: "1px solid #D7DCE2",
              margin: "20px 0px",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: isMobile ? 20 : 25,
            }}
          >
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: isMobile ? "18px" : "20px",
                fontWeight: 600,
                lineHeight: isMobile ? "26.1px" : "29px",
              }}
            >
              01&nbsp;
            </PretendardText>
            <PretendardText
              style={{
                color: "#2F3941",
                fontSize: isMobile ? "18px" : "20px",
                fontWeight: 500,
                lineHeight: isMobile ? "26.1px" : "29px",
              }}
            >
              {inquiryLang.basicInfo[langValue]}
            </PretendardText>
          </div>

          <InputForm
            title={nameFiled ? nameFiled.title : "Name"}
            essential={nameFiled ? nameFiled.isRequired : false}
            type="text"
            placeholder={inquiryLang.placeholderName[langValue]}
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              marginBottom: 25,
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              marginBottom: 25,
            }}
          >
            {phoneFiled ? (
              <InputForm
                title={phoneFiled.title}
                essential={phoneFiled.isRequired}
                type="text"
                placeholder={inquiryLang.placeholderPhone[langValue]}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{
                  marginRight: isMobile ? 0 : 48,
                  marginBottom: isMobile ? 25 : 0,
                }}
              />
            ) : null}

            <InputForm
              title={emailFiled ? emailFiled.title : "E-mail"}
              essential={emailFiled ? emailFiled.isRequired : false}
              type="text"
              placeholder={inquiryLang.placeholderEmail[langValue]}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{}}
            />
          </div>

          <FormUI data={basicList} setList={setBasicList} list={basicList} />

          {detailList && detailList.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 5,
                marginBottom: isMobile ? 20 : 25,
              }}
            >
              <PretendardText
                style={{
                  color: "#ADB4BA",
                  fontSize: isMobile ? "18px" : "20px",
                  fontWeight: 600,
                  lineHeight: isMobile ? "26.1px" : "29px",
                }}
              >
                02&nbsp;
              </PretendardText>
              <PretendardText
                style={{
                  color: "#2F3941",
                  fontSize: isMobile ? "18px" : "20px",
                  fontWeight: 500,
                  lineHeight: isMobile ? "26.1px" : "29px",
                }}
              >
                {inquiryLang.inquiryCotent[langValue]}
              </PretendardText>
            </div>
          ) : null}

          <FormUI data={detailList} setList={setDetailList} list={detailList} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 15,
              width: "100%",
            }}
          >
            <InqueryBtn
              onClick={() => {
                inqueryCheckFunc();
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "15px",
                  fontWeight: 600,
                  lineHeight: "21px",
                }}
              >
                {inquiryLang.send[langValue]}
              </PretendardText>
            </InqueryBtn>
          </div>
        </InqueryModalContainer>
      </InqueryModalWrapper>

      {isPreparedModal ? (
        <PreparedModal
          isScroll={true}
          title={inquiryLang.preparedModal[langValue]}
          onClick={() => {
            setIsPreparedModal(false);
          }}
        />
      ) : null}

      {isConfirmModal ? (
        <ConfirmModal
          isScroll={true}
          title={inquiryLang.confirmModal[langValue]}
          onClick={() => {
            setIsConfirmModal(false);
          }}
          onYClick={() => {
            inquerySubmit();
          }}
        />
      ) : null}
    </>
  );
}

export default InqueryModal;

const InqueryModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${(props) => (props.$isMobile ? "30px 20px" : "70px 0")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 999;
`;

const InqueryModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "720px")};
  padding: ${(props) => (props.$isMobile ? "20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;

const InqueryBtn = styled.button`
  min-width: 122px;
  height: 44px;
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
