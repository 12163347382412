import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ExternalBrowserAlert = () => {
  const [isInApp, setIsInApp] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || "";
    const isKakaoTalk = /KAKAOTALK/i.test(userAgent);
    const isInWebView = /wv/i.test(userAgent) || /NAVER/i.test(userAgent);
    setIsInApp(isKakaoTalk || isInWebView);
  }, []);

  const handleOpenExternal = () => {
    const currentUrl = window.location.href;
    let opened = false;

    if (/android/i.test(navigator.userAgent)) {
      // Android: Chrome으로 열기 시도
      window.location.href = `intent://${currentUrl.replace(
        /^https?:\/\//,
        ""
      )}#Intent;scheme=https;package=com.android.chrome;end`;
      // 타이머를 사용하여 Chrome 열기 실패 확인
      setTimeout(() => {
        if (!opened) {
          // 실패 시 기본 브라우저로 열기
          window.open(currentUrl, "_system");
        }
      }, 2000);
    } else if (/iphone|ipad|ipod/i.test(navigator.userAgent)) {
      // iOS: Chrome으로 열기 시도
      window.location.href = `googlechrome://${currentUrl.replace(
        /^https?:\/\//,
        ""
      )}`;
      // 타이머를 사용하여 Chrome 열기 실패 확인
      setTimeout(() => {
        if (!opened) {
          // 실패 시 Safari로 열기
          window.location.href = currentUrl;
        }
      }, 2000);
    } else {
      // 데스크톱 또는 기타 환경: 새 탭에서 열기
      window.open(currentUrl, "_blank");
    }

    // 페이지 이동 감지
    window.addEventListener("pagehide", () => {
      opened = true;
    });

    // 사용자에게 URL 복사 옵션 제공
    const urlTextArea = document.createElement("textarea");
    urlTextArea.value = currentUrl;
    document.body.appendChild(urlTextArea);
    urlTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(urlTextArea);
    alert(
      "URL이 클립보드에 복사되었습니다. 원하는 브라우저에 직접 붙여넣을 수 있습니다."
    );
  };

  if (!isInApp || !showAlert) return null;

  return (
    <>
      <AlertBg />
      <AlertContainer>
        <AlertTitle>
          <IconSpan>🔗</IconSpan>
          외부 브라우저로 열기
        </AlertTitle>
        <p>더 나은 경험을 위해 이 페이지를 외부 브라우저에서 열어보세요.</p>
        <ButtonContainer>
          <Button primary onClick={handleOpenExternal}>
            외부 브라우저로 열기
          </Button>
          <CloseButton onClick={() => setShowAlert(false)}>닫기</CloseButton>
        </ButtonContainer>
      </AlertContainer>
    </>
  );
};

export default ExternalBrowserAlert;

const AlertBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const AlertContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #f8f8f8;
  z-index: 1000;
`;

const AlertTitle = styled.h4`
  margin-top: 0;
  display: flex;
  align-items: center;
`;

const IconSpan = styled.span`
  margin-right: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin-right: 8px;
  background-color: ${(props) => (props.primary ? "#007bff" : "#fff")};
  color: ${(props) => (props.primary ? "#fff" : "#000")};
  border: 1px solid ${(props) => (props.primary ? "#007bff" : "#ccc")};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#e9ecef")};
  }
`;

const CloseButton = styled(Button)`
  background-color: transparent;
  border: 1px solid "#ccc";

  &:hover {
    background-color: #f1f3f5;
  }
`;
