import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import PretendardText from "../../components/atoms/text/pretendardText";
import MainSection from "../../components/templates/mainSection";

import { apiSDKService } from "../../api/renewal/api.service";
import useDisplay from "../../hooks/useDisplay";
import { lumirNewsLang } from "../../lang/relation/lumirNewsLang";
import { route } from "../../router/route";
import { langState } from "../../store/langState";
import LoadingOverlay from "./LoadingOverlay";

const LumirNewsDetail = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [dataInfo, setDataInfo] = useState();
  const [preDataInfo, setPreDataInfo] = useState();
  const [nextDataInfo, setNextDataInfo] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const langValue = useRecoilValue(langState);

  useEffect(() => {
    getNewsDetailAPI();
  }, [id]);

  const getNewsDetailAPI = async () => {
    try {
      setIsLoading(true);

      const response = await apiSDKService.getNewsById(id);

      setDataInfo(response.now);
      setPreDataInfo(response.prev);
      setNextDataInfo(response.next);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <MainSection
        bg={
          dataInfo
            ? dataInfo.fileUrl
            : "/assets/imgs/main-section/introduce.png"
        }
        style={{
          backgroundPosition: "center center",
        }}
      />
      <div
        style={{
          padding: isMobile
            ? "80px 20px"
            : isTablet
            ? "100px 70px"
            : "80px 0px 120px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TitleText
            style={{
              fontSize: isMobile ? "22px" : isTablet ? "25px" : "28px",
              lineHeight: isMobile ? "31.9px" : isTablet ? "35px" : "40.6px",
              marginBottom: isMobile ? 10 : isTablet ? 14 : 32,
            }}
          >
            {dataInfo ? dataInfo.title : ""}
          </TitleText>
          <SubTitleWrapper>
            <CategoryText
              style={{
                color: isMobile || isTablet ? "#ADB4BA" : "#778088",
                fontSize: isMobile ? "13px" : isTablet ? "15px" : "20px",
                lineHeight: isMobile ? "17.55px" : isTablet ? "20px" : "29px",
              }}
            >
              {dataInfo ? dataInfo.category : ""}
            </CategoryText>

            <DateText
              style={{
                fontSize: isMobile ? "15px" : isTablet ? "16px" : "20px",
                fontWeight: isMobile || isTablet ? 400 : 600,
                lineHeight: isMobile ? "21px" : isTablet ? "22px" : "29px",
              }}
            >
              {dataInfo
                ? moment.utc(dataInfo.createdAt).format("YYYY.MM.DD")
                : ""}
            </DateText>
          </SubTitleWrapper>

          <DivisionLine
            style={{
              marginTop: isMobile || isTablet ? 25 : 26,
            }}
          />

          <div
            style={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              height: "100%",
              marginBottom: 25,
            }}
          >
            {/* <EditorContainer
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: dataInfo ? dataInfo.content : "",
              }}
            /> */}
            <img
              style={{ width: "100%" }}
              src={dataInfo?.content || ""}
              alt={dataInfo?.title || ""}
            />
          </div>

          {preDataInfo ? (
            <div
              onClick={() => {
                navigate(`${route.lumirNewsDetail}/${preDataInfo.id}`);
              }}
              style={{
                height: isSmall ? 86 : 80,
                display: "flex",
                flexDirection: "row",
                alignItems: isSmall ? "flex-start" : "center",
                paddingTop: isSmall ? 20 : 0,
                width: "100%",
                borderTop: "1px solid #ADB4BA",
                borderBottom: "1px solid #ADB4BA",
                cursor: "pointer",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "16px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "23.2px" : "26.1px",
                  width: isSmall ? 50 : 95,
                  borderRight: "1px solid #ADB4BA",
                }}
              >
                Prev
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmall ? "column" : "row",
                  alignItems: isSmall ? "flex-start" : "center",
                  flex: 1,
                }}
              >
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: isSmall ? "16px" : "18px",
                    fontWeight: isSmall ? 400 : 600,
                    lineHeight: isSmall ? "23.2px" : "26.1px",
                    marginLeft: isSmall ? 15 : 20,
                    flex: 1,
                  }}
                >
                  {preDataInfo.title}
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#778088",
                    fontSize: isSmall ? "13px" : "18px",
                    fontWeight: 400,
                    lineHeight: isSmall ? "17.55px" : "26.1px",
                    marginRight: isSmall ? 0 : 30,
                    marginLeft: isSmall ? 22 : 0,
                    marginTop: isSmall ? 5 : 0,
                  }}
                >
                  {moment.utc(preDataInfo.createdAt).format("YYYY.MM.DD")}
                </PretendardText>
              </div>
            </div>
          ) : null}

          {nextDataInfo ? (
            <div
              onClick={() => {
                navigate(`${route.lumirNewsDetail}/${nextDataInfo.id}`);
              }}
              style={{
                height: isSmall ? 86 : 80,
                display: "flex",
                flexDirection: "row",
                alignItems: isSmall ? "flex-start" : "center",
                paddingTop: isSmall ? 20 : 0,
                width: "100%",
                borderBottom: "1px solid #ADB4BA",
                borderTop: preDataInfo ? "none" : "1px solid #ADB4BA",
                cursor: "pointer",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "16px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "23.2px" : "26.1px",
                  width: isSmall ? 50 : 95,
                  borderRight: "1px solid #ADB4BA",
                }}
              >
                Next
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmall ? "column" : "row",
                  alignItems: isSmall ? "flex-start" : "center",
                  flex: 1,
                }}
              >
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: isSmall ? "16px" : "18px",
                    fontWeight: isSmall ? 400 : 600,
                    lineHeight: isSmall ? "23.2px" : "26.1px",
                    marginLeft: isSmall ? 15 : 20,
                    flex: 1,
                  }}
                >
                  {nextDataInfo.title}
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#778088",
                    fontSize: isSmall ? "13px" : "18px",
                    fontWeight: 400,
                    lineHeight: isSmall ? "17.55px" : "26.1px",
                    marginRight: isSmall ? 0 : 30,
                    marginLeft: isSmall ? 22 : 0,
                    marginTop: isSmall ? 5 : 0,
                  }}
                >
                  {moment.utc(nextDataInfo.createdAt).format("YYYY.MM.DD")}
                </PretendardText>
              </div>
            </div>
          ) : null}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop: 40,
            }}
          >
            <ListBtn
              $isSmall={isSmall}
              onClick={() => {
                navigate(`${route.lumirNews}/1`);
              }}
            >
              <PretendardText
                style={{
                  color: "#2F3941",
                  fontSize: isSmall ? "15px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "21px" : "26.1px",
                }}
              >
                {lumirNewsLang.list[langValue]}
              </PretendardText>
            </ListBtn>
          </div>
        </div>
      </div>
    </>
  );
};

export default LumirNewsDetail;

const ListBtn = styled.button`
  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(PretendardText)`
  color: #262c31;
  font-weight: 500;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const CategoryText = styled(PretendardText)`
  font-weight: 600;
`;

const DateText = styled(PretendardText)`
  color: #adb4ba;
`;

const DivisionLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #adb4ba;
  margin-bottom: 25px;
`;

// Styled component for the editor container
const EditorContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  /* Style for images inside the editor */
  img {
    width: 100%;
    height: auto;
  }
`;
