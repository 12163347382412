import api from "./api";

export default class ShareholderApi {
  static async GetKR() {
    return api.get(`shareholder/kr`);
  }

  static async GetEN() {
    return api.get(`shareholder/en`);
  }

  static async GetList(data) {
    return api.get(`shareholderResult`, data);
  }
}
