import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";

import { route } from "../../../router/route";
import { jobAnnounceLang } from "../../../lang/recruit/jobAnnounceLang";
import { langState } from "../../../store/langState";
import styled from "styled-components";

const StatusCard = ({ isHiring, isFullTime, langValue }) => {
  if (isHiring) {
    return isFullTime ? (
      <div
        style={{
          width: 76,
          height: 33,
          borderRadius: 5,
          border: "1px solid #00AEEF",
          marginRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PretendardText
          style={{
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "21px",
            color: "#00AEEF",
          }}
        >
          {jobAnnounceLang.isFullTime[langValue]}
        </PretendardText>
      </div>
    ) : (
      <div
        style={{
          width: 76,
          height: 33,
          borderRadius: 5,
          border: "1px solid #00AEEF",
          marginRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PretendardText
          style={{
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "21px",
            color: "#00AEEF",
          }}
        >
          {jobAnnounceLang.hiring[langValue]}
        </PretendardText>
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: 76,
          height: 33,
          borderRadius: 5,
          border: "1px solid #ADB4BA",
          marginRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PretendardText
          style={{
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "21px",
            color: "#ADB4BA",
          }}
        >
          {jobAnnounceLang.end[langValue]}
        </PretendardText>
      </div>
    );
  }
};

function JACard({
  style,
  order,
  isHiring,
  title,
  content,
  isFullTime,
  id,
  end,
  link,
}) {
  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  const isEndShow = end && isHiring && !isFullTime ? true : false;

  return (
    <>
      <JACardWrapper
        onClick={() => {
          link
            ? navigation(`${route.jobAnnounceDetail}/${id}`)
            : window.open(route.jobAnnounceSaramin);
        }}
        style={{
          ...style,
        }}
        className="custom-cursor"
      >
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "26.1px",
            color: "#778088",
            width: 72,
          }}
        >
          {order}
        </PretendardText>
        <StatusCard
          isHiring={isHiring}
          isFullTime={isFullTime}
          langValue={langValue}
        />
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "26.1px",
            color: "#5A636A",
            marginRight: 10,
          }}
        >
          {title}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "26.1px",
            color: "#262C31",
            flex: 1,
          }}
        >
          {content}
        </PretendardText>
        <div>
          {isEndShow && (
            <PretendardText
              style={{
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "21px",
                color: "#262C31",
              }}
            >
              ~ {end}
            </PretendardText>
          )}
        </div>
      </JACardWrapper>
    </>
  );
}

export default JACard;

const JACardWrapper = styled.div`
  display: flex;
  // cursor: "pointer";
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #adb4ba;
  height: 73px;
  width: 100%;
  padding: 0px 30px;

  &:hover {
    background: #f9f9f9;
  }
`;
