export const data = [
  {
    image: {
      pc: "/assets/imgs/popup/PC_코스닥.jpg",
      mobile: "/assets/imgs/popup/MOBILE_코스닥.jpg",
    },
    alt: "루미르 코스닥 상장",
  },
  {
    image: {
      pc: "/assets/imgs/popup/700px_WEB_피싱.jpg",
      mobile: "/assets/imgs/popup/500px_MOBILE_피싱.jpg",
    },
    alt: "루미르 IPO 사칭 웹사이트 및 피싱 문자 주의 안내",
  },
];
