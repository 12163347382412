import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { rnd } from "../../../../../lang/renewal/performanceLang";
import { langState } from "../../../../../store/langState";
import FramerSlideIn from "../../../../../styles/framerSlideIn";
import BigImgContents from "./BigImgContents";
import Layout from "./Layout";

const First = ({ isMobile }) => {
  const langValue = useRecoilValue(langState);
  const [data, setData] = useState(rnd(isMobile)[langValue].contents[0].info);

  useEffect(() => {
    setData(rnd(isMobile)[langValue].contents[0].info);
  }, [isMobile, langValue]);

  return (
    <Layout title={data.title}>
      <FramerSlideIn>
        <BigImgContents {...data} />
      </FramerSlideIn>
      <ImageWrapper>
        {data.imgs.map((img, index) => (
          <FramerSlideIn>
            <img key={index} src={img} alt="" width={"100%"} />
          </FramerSlideIn>
        ))}
      </ImageWrapper>
    </Layout>
  );
};

export default First;

const ImageWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 5fr 4fr;
  justify-content: center;
  gap: 5rem;
  margin-top: 2rem;
`;
