export const performance = {
  title: {
    KR: "주요실적",
    EN: "Major Performance",
  },
  KR: {
    heritage: {
      category: "Heritage",
      title: "차세대중형위성",
      tag: [
        "1/2/4호 영상자료처리장치",
        "3호 탑재컴퓨터",
        "5호 탑재체 (레이다부체계 + 자료전송부체계)",
      ],
      desc: "루미르는 국가 위성 사업인 차세대중형위성 사업에서 1/2/4호 영상자료처리장치, 3호 탑재컴퓨터, 5호 탑재체 사업 전체를 수주함으로써 모든 시리즈의 사업에 참여하였습니다. ",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/major-performance/IDHU.png",
          name: "1호 · 2호 · 4호",
          desc: "영상자료처리장치",
        },
        {
          src: "/assets/imgs/renewal/introduce/major-performance/OBC.png",
          name: "3호",
          desc: "탑재컴퓨터",
        },
        {
          src: "/assets/imgs/renewal/introduce/major-performance/CAS500-5.png",
          name: "5호",
          desc: "탑재체 전체",
        },
      ],
      contents: [
        {
          title: "다누리호",
          tags: ["우주인터넷 통신장치"],
          desc: "루미르는 한국 최초의 한국형 달 탐사선인 ‘다누리호’의 우주인터넷 통신장치 탑재체 사업에 참여하였습니다.",
          img: "/assets/imgs/renewal/introduce/major-performance/다누리호.png",
          video:
            "/assets/imgs/renewal/introduce/major-performance/다누리호.mp4",
        },
        {
          title: "Lumir-T1",
          tags: ["누리호 3차 부탑재위성"],
          desc: "루미르는 누리호 3차 발사체 부탑재위성으로 선정되어, 민간기업 최초로 큐브위성 발사 성공과 비콘 신호 수신, 그리고 민수 부품의 우주활용 기술에 대한 우주실증 임무를 성공적으로 수행하였습니다.",
          img: "/assets/imgs/renewal/introduce/major-performance/T1.png",
          video: "/assets/imgs/renewal/introduce/major-performance/T1.mp4",
        },
        {
          title: "다목적실용위성",
          tags: ["6호 SAR 제어장치", "7호 지상지원장비"],
          desc: "루미르는 다목적실용위성 6호 사업에서 SAR 제어장치 탑재체 개발 실적과 7호 사업에서 감시정찰위성의 본체 전력계 지상지원장비 개발 실적을 보유하고 있습니다.",
          img: "/assets/imgs/renewal/introduce/major-performance/다목적실용위성.webp",
          // video:
          // "/assets/imgs/renewal/introduce/major-performance/다목적실용위성.mp4",
        },
      ],
    },
  },
  EN: {
    heritage: {
      category: "Heritage",
      title: "CAS500",
      tag: [
        "CAS500-1/2/4 IDHU",
        "CAS500-3 OBC",
        "CAS500-5 SAR Satellite System (C-SAR +  PDTS)",
      ],
      desc: "Lumir participated in the entire series of projects in the national satellite program, the CAS500 project, by securing contracts for the Image Handling Data Unit for CAS500-1/2/4, the On-board Computer for CAS500-3, and the SAR Satellite System project for CAS500-5.",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/major-performance/IDHU.png",
          name: "1 · 2 · 4",
          desc: "Image Data Processing Device",
        },
        {
          src: "/assets/imgs/renewal/introduce/major-performance/OBC.png",
          name: "3",
          desc: "Onboard Computer",
        },
        {
          src: "/assets/imgs/renewal/introduce/major-performance/CAS500-5.png",
          name: "5",
          desc: "Onboard Body",
        },
      ],
      contents: [
        {
          title: "DANURI (KPLO)",
          tags: ["Delay-Tolerant Network Payload"],
          desc: `Lumir participated in the Delay-Tolerant Network Payload project for "Danuri (KPLO, The Korea Pathfinder Lunar Orbiter)," South Korea's first indigenous lunar exploration spacecraft.`,
          img: "/assets/imgs/renewal/introduce/major-performance/다누리호.png",
          video:
            "/assets/imgs/renewal/introduce/major-performance/다누리호.mp4",
        },
        {
          title: "Lumir-T1",
          tags: ["NURIHO 3rd Sub-satellite"],
          desc: "Lumir was selected as the 3rd launch vehicle sub-satellite of NURIHO, successfully launching the first private company's cube satellite, receiving beacon signals, and successfully performing a space utilization mission for civilian components.",
          img: "/assets/imgs/renewal/introduce/major-performance/T1.png",
          video: "/assets/imgs/renewal/introduce/major-performance/T1.mp4",
        },
        {
          title: "KOMPSAT",
          tags: [
            "KOMPSAT-6 SAR Control System",
            "KOMPSAT-7 EGSE of Power Subsystem",
          ],
          desc: "Lumir has developed the SAR control system for KOMPSAT-6 and the Ground Support Equipment for the power subsystem of KOMPSAT-7.",
          img: "/assets/imgs/renewal/introduce/major-performance/다목적실용위성.webp",
          // video:
          //   "/assets/imgs/renewal/introduce/major-performance/다목적실용위성.mp4",
        },
      ],
    },
  },
};

// menuList
const IMG_LIST = {
  KaPA: "/assets/imgs/renewal/introduce/major-performance/KAPA.webp",
  SPCC: "/assets/imgs/renewal/introduce/major-performance/SPCC.png",
  MicroSatellite:
    "/assets/imgs/renewal/introduce/major-performance/Lumir-V.webp",
  Antenna: "/assets//imgs/renewal/introduce/major-performance/Antenna.png",
  HMVS: "/assets/imgs/renewal/introduce/major-performance/HMVS.png",
};

export const rnd = (isMobile) => {
  return {
    KR: {
      title: "국가 R&D",
      desc: "루미르는 과기부 주관 대표 3가지 우주개발 프로젝트는 모두 수주한 기업으로, 다양한 국가 R&D 사업에 참여하며 우주산업 분야의 선두로 끊임없이 발전하고 있습니다.",
      contents: [
        {
          id: 1,
          logo: "/assets/imgs/renewal/introduce/major-performance/과기부.png",
          title: "스페이스파이오니어",
          desc: "Ka-band 송신기 및 Ka-band 능동형 어레이 안테나",
          img: IMG_LIST.KaPA,
          info: {
            title: "Ka-Band 송신기 및 <br/>Ka Band 능동형 어레이 안테나",
            subTitle: "Ka-Band 송신기 및 Ka Band 능동형 어레이 안테나",
            img: isMobile
              ? "/assets/imgs/introduce/rnd/m_rnd_modal1.png"
              : "/assets/imgs/introduce/rnd/rnd_modal1.png",
            desc: [
              "ㆍ실용위성의 ka 밴드 다운링크 시스템의 구성품으로 전자 빔조향 안테나 기술 및 소프트웨어 정의 라디오 기술이 적용된 고기능/소형/경량의 Ka 밴드 안테나 및 Ka 밴드 데이터 전송 장치",
              "ㆍKa 밴드 송신기, Ka 밴드 능동형 어레이 안테나 단일 유닛 모듈화를 통한 시스템 최적화",
              "ㆍ4 Gbps 이상의 고속 데이터 전송이 필요한 위성 탑재체 PDTS(Payload Data Transmission Subsystem)의 핵심 구성품",
            ],
            imgs: [
              isMobile
                ? "/assets/imgs/introduce/rnd/m_rnd1_1_kr.png"
                : "/assets/imgs/introduce/rnd/rnd1_1.png",
              "/assets/imgs/introduce/rnd/rnd1_2.png",
              "/assets/imgs/introduce/rnd/rnd1_3.png",
              "/assets/imgs/introduce/rnd/rnd1_4.png",
            ],
          },
        },
        {
          id: 2,
          logo: "/assets/imgs/renewal/introduce/major-performance/과기부.png",
          title: "스페이스파이오니어",
          desc: "고속 고기동 위성의 제어모먼트 자이로 ",
          img: IMG_LIST.SPCC,
          info: {
            title: "고속 고기동 위성의 <br/>제어모멘트자이로",
            subTitle: "고속 고기동 위성의 제어모멘트자이로",
            img: isMobile
              ? "/assets/imgs/introduce/rnd/m_rnd_modal2.png"
              : "/assets/imgs/introduce/rnd/rnd_modal2.png",
            desc: [
              "ㆍ제어모멘트자이로(Control Moment Gyro, CMG)는 인공위성의 자세를 고속 고기동으로 변환할 수 있는 저궤도(LEO) 위성용 자세제어시스템의 핵심 구성품",
              "ㆍ반작용 휠(Reaction Wheel) 장착 방식보다 수십 배 큰 제어 토크 발생이 가능한 위성 고속 고기동 자세 변환 기술의 핵심 기술",
              "ㆍ중형급 실용위성에 탑재 가능한 위성 자세제어용 CGM 4기로 구성된 CMA(Control Moment Gyro Assembly) 개발을 진행 중",
            ],
            imgs: [
              isMobile
                ? "/assets/imgs/introduce/rnd/m_rnd2_1_kr.png"
                : "/assets/imgs/introduce/rnd/rnd2_1.png",
              "/assets/imgs/introduce/rnd/rnd2_2.png",
              "/assets/imgs/introduce/rnd/rnd2_3.png",
              "/assets/imgs/introduce/rnd/rnd2_4.png",
            ],
          },
        },
        {
          id: 3,
          logo: "/assets/imgs/renewal/introduce/major-performance/과기부.png",
          title: "스페이스이노베이션",
          desc: "초분광, 가시광 듀얼센서 카메라를 탑재한 초소형 위성",
          img: IMG_LIST.MicroSatellite,
          info: {
            title: "초분광, 가시광 듀얼센서 카메라를 <br/>탑재한 초소형 위성",
            subTitle:
              "스피이노베이션-초분광, 가시광 듀얼센서 카메라를 탑재한 초소형 위성",
            img: isMobile
              ? "/assets/imgs/introduce/rnd/m_rnd_modal3.png"
              : "/assets/imgs/introduce/rnd/rnd_modal3.png",
            desc: [
              "스페이스이노베이션 사업은 중소벤처기업이 우주시장에 진출할 수 있도록 초소형위성 기반의 비즈니스 모델 개발 및 우주검증 목표 사업으로, 루미르는 초분광·가시광 듀얼센서 카메라를 탑재한 초소형 인공위성 개발 및 관측 데이터를 통한 국내외 영상서비스를 제공하고자 합니다.",
            ],
            imgs: [
              "/assets/imgs/introduce/rnd/rnd3_1_kr.png",
              "/assets/imgs/introduce/rnd/rnd3_2_kr.png",
              "/assets/imgs/introduce/rnd/rnd3_3_kr.png",
            ],
            section1: {
              title:
                "초분광/가시광 카메라의 특징을 활용하여 대상표적 식별 뿐만 아니라 표적의 매질 정보 추출 확인",
              text1: [
                "대상 표적 구분 및 표적 매질 정보 파악",
                "ㆍ가시광카메라(EOC)의 높은 공간해상도를 RGB 영상획득",
                "ㆍ초분광카메라(HSC)의 높은 파장분해능을 이용한 암석과 식생 구분",
              ],
              img1: "/assets/imgs/introduce/rnd/rnd3_4.png",
              text2: [
                "식생파악 (NDVI index 활용)",
                "ㆍNDVI Index를 활용하여 수락산의 식생을 구분",
              ],
              img2: "/assets/imgs/introduce/rnd/rnd3_5.png",
            },
            section2: {
              title: "탑재체 획득 영상에 대한 이미지 분석 및 퓨전 수행",
              img: isMobile
                ? "/assets/imgs/introduce/rnd/m_rnd3_6_kr.png"
                : "/assets/imgs/introduce/rnd/rnd3_6_kr.png",
              text: "취득영상에 대한 Image Fusion 처리",
            },
            section3: {
              title: "적용 알고리즘",
              text: "Coupled non-negative matrix decomposition (CNMF)",
              img: isMobile
                ? "/assets/imgs/introduce/rnd/m_rnd3_7_kr.png"
                : "/assets/imgs/introduce/rnd/rnd3_7_kr.png",
            },
          },
        },
        {
          id: 4,
          logo: "/assets/imgs/renewal/introduce/major-performance/국방과학연구소.png",
          title: "민군겸용우주기술개발",
          desc: "우주용 고신뢰성 대용량 적층형 메모리 모듈 개발",
          // img: "/assets/imgs/introduce/rnd/rnd_modal4.png",
          info: {
            title: "우주용 고신뢰성 대용량 적층형 메모리 모듈 개발",
            subTitle: "우주용 고신뢰성 대용량 적층형 메모리 모듈 개발",
            desc: [
              "루미르는 국방과학연구소(ADD)가 주관하는 우주용 고신뢰성 대용량 적측형 메모리 모듈을 개발하고 있습니다. 루미르가 기확보하고 있는 차세대중형위성 등에 적용된 대용량 저장장치의 메모리 모듈과 호환 적용을 검토하고 있으며, 적층 기술의 우주용 RF 부품 응용 활용 및 한국형 우주용 부품 제조 공정의 표준화를 기대하고 있습니다.",
            ],
          },
        },
        {
          id: 5,
          logo: "/assets/imgs/renewal/introduce/major-performance/중소기업기술정보진흥원.png",
          title: "중소기업기술정보진흥원 투자형 R&D",
          desc: "초고해상도 초소형 SAR위성 탑재용 X-band 능동위상배열안테나 개발",
          img: IMG_LIST.Antenna,
          info: {
            title:
              "초고해상도 초소형 SAR위성 탑재용 X-Band 능동위상배열안테나 개발",
            subTitle:
              "초고해상도 초소형 SAR위성 탑재용 X-Band 능동위상배열안테나 개발",
            desc: [
              "루미르가 개발하고 있는 초고해상도 초소형 SAR위성 탑재용 X-Band 능동위상배열안테나는 SAM(SAR Antenna), TRM(Transmit/Receive Module), TCM(TRM Control Module), DCM(Dividing & Combing Module)으로 구성되어 있습니다.",
              "이 장치는 해외 수출제한품목 문제 해결로 인한 비용 절감은 물론 소형 경량화를 통해 수출 경쟁력을 확보함으로써  초소형 영상레이다(SAR) 위성 시장 진출을 기대하고 있습니다.",
            ],
            img: isMobile
              ? "/assets/imgs/introduce/rnd/m_rnd_modal5_kr.png"
              : "/assets/imgs/introduce/rnd/rnd_modal5_kr.png",
            imgs: isMobile
              ? [
                  "/assets/imgs/introduce/rnd/rnd5_1_kr.png",
                  "/assets/imgs/introduce/rnd/rnd5_2.png",
                  "/assets/imgs/introduce/rnd/m_rnd5_3_1.png",
                  "/assets/imgs/introduce/rnd/m_rnd5_3_2.png",
                  "/assets/imgs/introduce/rnd/m_rnd5_3_3.png",
                  "/assets/imgs/introduce/rnd/m_rnd5_3_4.png",
                ]
              : [
                  "/assets/imgs/introduce/rnd/rnd5_1_kr.png",
                  "/assets/imgs/introduce/rnd/rnd5_2.png",
                  "/assets/imgs/introduce/rnd/rnd5_3.png",
                  "/assets/imgs/introduce/rnd/rnd5_3_1.png",
                  "/assets/imgs/introduce/rnd/rnd5_3_2.png",
                ],
          },
        },
        {
          id: 6,
          logo: "/assets/imgs/renewal/introduce/major-performance/한국산업기술진흥협회.png",
          title: "R&D 역량강화 사업",
          desc: "초분광 센서를 이용한 머신비전 시스템 구출 개발",
          img: IMG_LIST.HMVS,
          info: {
            title: "초분광 센서를 이용한 머신비전 시스템 구축 개발",
            subTitle: "초분광 센서를 이용한 머신비전 시스템 구축 개발",
            desc: [
              "루미르는 해외기술에 전량 의존하고 있는 초분광 카메라의 핵심 기술을 기확보하여 세계적인 수준의 성능을 갖춘 머신비전 제품을 개발중입니다.",
              "이 기술은 국내 머신비전 시스템에서 주로 사용하는 가시광 비전 센서의 정보 제한성을 개선할 수 있으며, IoT 기술과 결합하여 F&B 분야에서 자동화된 검출 시스템을 구축하고 비용을 절감하는 효과를 가져옵니다.",
            ],
            imgs: [
              "/assets/imgs/introduce/rnd/rnd6_1.png",
              "/assets/imgs/introduce/rnd/rnd6_2.png",
              "/assets/imgs/introduce/rnd/rnd6_3.png",
            ],
            text: "HMVS",
          },
        },
      ],
    },
    EN: {
      title: "National R&D",
      desc: "Lumir is a company that has won all three representative space development projects hosted by the Ministry of Science and ICT, and is constantly developing as a leader in the space industry by participating in various national R&D projects.",
      contents: [
        {
          id: 1,
          logo: "/assets/imgs/renewal/introduce/major-performance/ministry-of-science.png",
          title: "Space Pioneer",
          desc: "Ka-band Transmitter and Ka-band Active Array Antenna",
          img: IMG_LIST.KaPA,
          info: {
            title: "Ka-Band Transmitter and Ka Band Active Array Antenna",
          },
        },
        {
          id: 2,
          logo: "/assets/imgs/renewal/introduce/major-performance/ministry-of-science.png",
          title: "Space Pioneer",
          desc: "High-speed High-mobility Satellite Control Moment Gyro",
          img: IMG_LIST.SPCC,
          info: {
            title: "High-speed High-mobility Satellite Control Moment Gyro",
          },
        },
        {
          id: 3,
          logo: "/assets/imgs/renewal/introduce/major-performance/ministry-of-science.png",
          title: "Space Innovation",
          desc: "Ultra-small satellite equipped with hyperspectral and visible light dual sensor cameras",
          img: IMG_LIST.MicroSatellite,
          info: {
            title:
              "Ultra-small satellite equipped with hyperspectral and visible light dual sensor cameras",
          },
        },
        {
          id: 4,
          logo: "/assets/imgs/renewal/introduce/major-performance/국방과학연구소.png",
          title: "Dual-use Space Technology Development",
          desc: "High-reliability, high-capacity stacked memory module for space",
          // img: IMG_LIST.Antenna,
          info: {
            title:
              "High-reliability, high-capacity stacked memory module for space",
          },
        },
        {
          id: 5,
          logo: "/assets/imgs/renewal/introduce/major-performance/중소기업기술정보진흥원.png",
          title:
            "Korea SME Technology Information Promotion Institute Investment Type R&D",
          desc: "X-band active phased array antenna for ultra-high-resolution ultra-small SAR satellite",
          img: IMG_LIST.Antenna,
          info: {
            title:
              "X-band active phased array antenna for ultra-high-resolution ultra-small SAR satellite",
          },
        },
        {
          id: 6,
          logo: "/assets/imgs/renewal/introduce/major-performance/한국산업기술진흥협회.png",
          title: "R&D Capability Enhancement Project",
          desc: "Development of machine vision system using hyperspectral sensor",
          img: IMG_LIST.HMVS,
          info: {
            title:
              "Development of machine vision system using hyperspectral sensor",
          },
        },
      ],
    },
  };
};

export const awardsCertifications = {
  KR: {
    title: "수상 및 인증",
    contents: [
      {
        id: 1,
        logo: "/assets/imgs/renewal/introduce/major-performance/과기부.png",
        year: "2023",
        type: "수상",
        title: "대한민국 ICT 대상 디지털 부문 (혁신기술) 선정 장관표창장",
      },
      {
        id: 2,
        logo: "/assets/imgs/renewal/introduce/major-performance/과기부.png",
        year: "2024",
        type: "인증",
        title: "우수 기업부설연구소 지정",
      },
      {
        id: 3,
        logo: "/assets/imgs/renewal/introduce/major-performance/방위사업청.png",
        year: "2023",
        type: "인증",
        title: "방산혁신기업 선정",
      },
      {
        id: 4,
        logo: "/assets/imgs/renewal/introduce/major-performance/KEIT.png",
        year: "2022",
        type: "인증",
        title: "소재·부품·장비 전문기업",
      },
      {
        id: 5,
        logo: "/assets/imgs/renewal/introduce/major-performance/중소벤처기업부.png",
        year: "2022",
        type: "인증",
        title: "기술혁신형 중소기업(Inno-Biz) 인증",
      },
      {
        id: 6,
        logo: "/assets/imgs/renewal/introduce/major-performance/벤처기업확인기관.png",
        year: "2022",
        type: "인증",
        title: "벤처기업 인증",
      },
      {
        id: 7,
        logo: "/assets/imgs/renewal/introduce/major-performance/KPIC.png",
        year: "2021",
        type: "인증",
        title: "뿌리기업 인증",
      },
      {
        id: 8,
        logo: "/assets/imgs/renewal/introduce/major-performance/Intertek.png",
        year: "2016",
        type: "인증",
        title: "AS9100:D and ISO9001:2015 인증",
      },
      {
        id: 9,
        logo: "/assets/imgs/renewal/introduce/major-performance/G-CERTS.png",
        year: "2012",
        type: "인증",
        title: "ISO 9001:2015 품질경영시스템 인증",
      },
      {
        id: 10,
        logo: "/assets/imgs/renewal/introduce/major-performance/G-CERTS.png",
        year: "2012",
        type: "인증",
        title: "ISO 14001:2015 환경경영시스템 인증",
      },
    ],
  },
  EN: {
    title: "Awards & Certifications",
    contents: [
      {
        id: 1,
        logo: "/assets/imgs/renewal/introduce/major-performance/ministry-of-science.png",
        year: "2023",
        type: "Award",
        title:
          "Minister's Commendation for the Republic of Korea ICT Award Digital Division (Innovative Technology)",
      },
      {
        id: 2,
        logo: "/assets/imgs/renewal/introduce/major-performance/ministry-of-science.png",
        year: "2024",
        type: "Certification",
        title: "Designation as an Excellent Corporate Research Institute",
      },
      {
        id: 3,
        logo: "/assets/imgs/renewal/introduce/major-performance/방위사업청_en.png",
        year: "2023",
        type: "Certification",
        title: "Selected as a Defense Innovation Company",
      },
      {
        id: 4,
        logo: "/assets/imgs/renewal/introduce/major-performance/KEIT.png",
        year: "2022",
        type: "Certification",
        title: "Specialized Company in Materials, Parts, and Equipment",
      },
      {
        id: 5,
        logo: "/assets/imgs/renewal/introduce/major-performance/중소벤처기업부_en.png",
        year: "2022",
        type: "Certification",
        title:
          "Certified as a Technology Innovation Small and Medium-sized Enterprise (Inno-Biz)",
      },
      {
        id: 6,
        logo: "/assets/imgs/renewal/introduce/major-performance/벤처기업확인기관.png",
        year: "2022",
        type: "Certification",
        title: "Venture Company Certification",
      },
      {
        id: 7,
        logo: "/assets/imgs/renewal/introduce/major-performance/KPIC.png",
        year: "2021",
        type: "Certification",
        title: "Root Company Certification",
      },
      {
        id: 8,
        logo: "/assets/imgs/renewal/introduce/major-performance/Intertek.png",
        year: "2016",
        type: "Certification",
        title: "AS9100:D and ISO9001:2015 Certification",
      },
      {
        id: 9,
        logo: "/assets/imgs/renewal/introduce/major-performance/G-CERTS.png",
        year: "2012",
        type: "Certification",
        title: "ISO 9001:2015 Quality Management System Certification",
      },
      {
        id: 10,
        logo: "/assets/imgs/renewal/introduce/major-performance/G-CERTS.png",
        year: "2012",
        type: "Certification",
        title: "ISO 14001:2015 Environmental Management System Certification",
      },
    ],
  },
};

export const ipr = {
  KR: {
    title: "지적재산권",
    contents: [
      {
        id: 1,
        logo: "/assets/imgs/renewal/introduce/major-performance/uspto.png",
        title: "미국 특허 US11767928",
        desc: "방폭 구조를 구비하는 솔레노이드 밸브, 연료 공급 시스템 및 그 제조방법",
      },
      {
        id: 2,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청.png",
        title: "특허 제 10-2363328 호",
        desc: "SAR 송신 장치",
      },
      {
        id: 3,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청.png",
        title: "특허 제 10-1376908 호",
        desc: "가시광 통신 신호에 기초한 영상 내 객체 검출",
      },
      {
        id: 4,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청.png",
        title: "특허 제 10-1427770 호",
        desc: "편대비행 UAV 센서 네트워크의 동적 트리 토폴로지 형성 방법",
      },
      {
        id: 5,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청.png",
        title: "특허 제 10-1667400 호",
        desc: "싱글 이벤트 업셋 발생 및 검출장치 및 방법",
      },
      {
        id: 6,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청.png",
        title: "특허 제 10-2392276 호",
        desc: "위상 오차의 보상이 가능한 DDS 첩 신호 발생 장치",
      },
      {
        id: 7,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청.png",
        title: "특허 제 10-2454706 호",
        desc: "SAR 시스템",
      },
    ],
  },
  EN: {
    title: "Intellectual Property Rights",
    contents: [
      {
        id: 1,
        logo: "/assets/imgs/renewal/introduce/major-performance/uspto.png",
        title: "US Patent US11767928",
        desc: "Solenoid valve having explosion-proof structure, fuel supply system, and method of manufacturing the same",
      },
      {
        id: 2,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청_en.png",
        title: "Patent No. 10-2363328",
        desc: "SAR transmission device",
      },
      {
        id: 3,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청_en.png",
        title: "Patent No. 10-1376908",
        desc: "Object detection in image based on visible light communication signal",
      },
      {
        id: 4,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청_en.png",
        title: "Patent No. 10-1427770",
        desc: "Method of forming dynamic tree topology of sensor network for swarm UAV",
      },
      {
        id: 5,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청_en.png",
        title: "Patent No. 10-1667400",
        desc: "Single event upset generation and detection device and method",
      },
      {
        id: 6,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청_en.png",
        title: "Patent No. 10-2392276",
        desc: "DDS baseband signal generation device capable of compensating phase error",
      },
      {
        id: 7,
        logo: "/assets/imgs/renewal/introduce/major-performance/특허청_en.png",
        title: "Patent No. 10-2454706",
        desc: "SAR system",
      },
    ],
  },
};
