import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import PretendardText from "../../../components/atoms/text/pretendardText";
import Pagination from "../../../components/organisms/pagination";
import PACard from "../../../components/organisms/investment/paCard";
import ReportDLCard from "../../../components/molecules/investment/reportDLCard";
import PlayDLCard from "../../../components/molecules/investment/playDLCard";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";

import useDisplay from "../../../hooks/useDisplay";
import PaginationTemp from "../../../components/organisms/paginationTemp";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { performanceLang } from "../../../lang/investment/performanceLang";

const PerformanceAnnounce = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [dataList, setDataList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_electronic_announce.png"
            : "/assets/imgs/main-section/electronic_announce.png"
        }
        title={mainBannerLang.performanceAnnouncement.title[langValue]}
        content={mainBannerLang.performanceAnnouncement.content[langValue]}
        blackText={true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {performanceLang.paTitle[langValue]}
        </PretendardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            justifyContent: "space-between",
            marginTop: isSmall ? 40 : 30,
            marginBottom: isSmall ? 50 : 120,
          }}
        >
          <QuarterWrapper $isSmall={isSmall}>
            <QuarterText $isSmall={isSmall}>
              {performanceLang.quarter1[langValue]}
            </QuarterText>

            <Division $isSmall={isSmall} />
            <PlayDLCard content={performanceLang.paData01[langValue]} />
            <PlayDLCard content={performanceLang.paData02[langValue]} />
            <ReportDLCard
              content={performanceLang.paData03[langValue]}
              style={{
                marginBottom: 0,
              }}
            />
          </QuarterWrapper>
          <QuarterWrapper $isSmall={isSmall}>
            <QuarterText $isSmall={isSmall}>
              {performanceLang.quarter2[langValue]}
            </QuarterText>

            <Division $isSmall={isSmall} />

            <PlayDLCard content={performanceLang.paData01[langValue]} />
            <PlayDLCard content={performanceLang.paData02[langValue]} />
            <ReportDLCard
              content={performanceLang.paData03[langValue]}
              style={{
                marginBottom: 0,
              }}
            />
          </QuarterWrapper>
          <QuarterWrapper $isSmall={isSmall}>
            <QuarterText $isSmall={isSmall}>
              {performanceLang.quarter3[langValue]}
            </QuarterText>

            <Division $isSmall={isSmall} />

            <PlayDLCard content={performanceLang.paData01[langValue]} />
            <PlayDLCard content={performanceLang.paData02[langValue]} />
            <ReportDLCard
              content={performanceLang.paData03[langValue]}
              style={{
                marginBottom: 0,
              }}
            />
          </QuarterWrapper>
          <QuarterWrapper $isSmall={isSmall}>
            <QuarterText $isSmall={isSmall}>
              {performanceLang.quarter4[langValue]}
            </QuarterText>

            <Division $isSmall={isSmall} />

            <PlayDLCard content={performanceLang.paData01[langValue]} />
            <PlayDLCard content={performanceLang.paData02[langValue]} />
            <ReportDLCard
              content={performanceLang.paData03[langValue]}
              style={{
                marginBottom: 0,
              }}
            />
          </QuarterWrapper>
        </div>

        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {performanceLang.preTitle[langValue]}
        </PretendardText>

        <div
          style={{
            borderTop: "2px solid #778088",
            borderBottom: "2px solid #778088",
            width: "100%",
            marginTop: isMobile ? 40 : 30,
          }}
        >
          {dataList.length > 0
            ? dataList.map((item, index) => {
                return (
                  <PACard
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    key={index}
                    title={2022 - index}
                    number={index + 1}
                  />
                );
              })
            : null}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: isSmall ? 40 : 60,
          }}
        >
          <PaginationTemp />
        </div>
      </BodyConatin>
    </>
  );
};

export default PerformanceAnnounce;

const QuarterWrapper = styled.div`
  width: ${(props) => (props.$isSmall ? "100%" : "320px")};

  border: 1px solid rgb(173, 180, 186);
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-bottom: ${(props) => (props.$isSmall ? "30px" : "0px")};
`;

const QuarterText = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) => (props.$isSmall ? "16px" : "20px")};
  font-weight: 600;
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "29px")};
`;

const Division = styled.div`
  width: 100%;
  margin: ${(props) => (props.$isSmall ? "15px 0px" : "20px 0px")};

  border-top: 1px solid rgb(173, 180, 186);
`;
