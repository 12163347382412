import React, { useEffect, useState } from "react";

import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/text/pretendardText";
import RightLabelRadio from "../../molecules/radio/rightLabelRadio";

const RadioForm = ({ data, setList, list }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [radioValue, setRadioValue] = useState("");
  const [radioList, setRadioList] = useState([]);

  let temp = list;

  useEffect(() => {
    let listTemp = data.item.split(",");
    setRadioList(listTemp);
  }, [data]);

  return (
    <>
      <div
        style={{
          marginBottom: 25,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: isMobile ? 10 : 11,
          }}
        >
          <PretendardText
            style={{
              color: "#2F3941",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
            }}
          >
            {data.title}
          </PretendardText>
          {data.isRequired ? (
            <PretendardText
              style={{
                color: "#00AEEF",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "23.2px",
              }}
            >
              *
            </PretendardText>
          ) : null}
        </div>

        {radioList.length > 0
          ? radioList.map((item, index) => {
              return (
                <RightLabelRadio
                  key={index}
                  inputStyle={{
                    marginBottom: radioList.length === index + 1 ? 0 : 12,
                  }}
                  text={item}
                  checked={radioValue === item}
                  onClick={() => {
                    setRadioValue(item);

                    let tempIndex = list.findIndex(
                      (item02) => item02.id === data.id
                    );
                    temp[tempIndex] = {
                      ...data,
                      value: item,
                    };

                    setList(temp);
                  }}
                />
              );
            })
          : null}
      </div>
    </>
  );
};

export default RadioForm;
