import { forwardRef, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import useDisplay from "../../../../../hooks/useDisplay";
import { langState } from "../../../../../store/langState";
import { useRecoilValue } from "recoil";
import { root } from "../../../../../styles/GlobalStyle";

const BASE_URL = process.env.PUBLIC_URL || "";

const Layout = forwardRef(
  ({ children, bgColor = root.businessNavy, title, subTitle, pdf }, ref) => {
    const langValue = useRecoilValue(langState);
    const { isDesktop } = useDisplay();
    const [isTop, setIsTop] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    const subTitleText = isDesktop
      ? subTitle
      : subTitle?.split("<br/>").join(" ") || subTitle;

    useEffect(() => {
      const handleScroll = () => {
        setIsTop(window.scrollY <= 100);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleDownload = useCallback(() => {
      if (!pdf || isDownloading) return;

      setIsDownloading(true);
      const fileUrl =
        process.env.NODE_ENV === "production"
          ? `${BASE_URL}${pdf.src}`
          : pdf.src;

      // 직접 다운로드 링크 사용
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", pdf.downloadFileName || "download.pdf");
      link.setAttribute("target", "_blank"); // 새 탭에서 열기
      link.rel = "noopener noreferrer"; // 보안 개선
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloading(false);
    }, [pdf, isDownloading]);

    return (
      <Container $bgColor={bgColor} $isDesktop={isDesktop}>
        <Sidebar $bgColor={bgColor} $isTop={isTop} $isDesktop={isDesktop}>
          <TextSection>
            <Subtitle dangerouslySetInnerHTML={{ __html: subTitleText }} />
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </TextSection>
          {pdf && (
            <DownloadButton
              $bgColor={bgColor}
              aria-label="Download PDF"
              onClick={handleDownload}
              disabled={isDownloading}
            >
              {/* <StyledDownloadPdfIcon $bgColor={bgColor} /> */}
              {langValue === "KR" ? (
                <p>{isDownloading ? "다운로드 중..." : "PDF 다운로드"}</p>
              ) : (
                <p>{isDownloading ? "Downloading..." : "Download PDF"}</p>
              )}
            </DownloadButton>
          )}
        </Sidebar>
        <ContentsWrapper $isDesktop={isDesktop} ref={ref}>
          <Contents $isDesktop={isDesktop}>
            {children}
            <Bottom />
          </Contents>
        </ContentsWrapper>
      </Container>
    );
  }
);

export default Layout;

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  height: 100vh;
  background-color: ${(props) => `${props.$bgColor}`};
`;

const Sidebar = styled.div`
  transition: 0.5s;
  /* height: ${(props) => (props.$isTop ? "var(--calc-vh)" : "100vh")}; */
  height: ${({ $isDesktop }) => ($isDesktop ? "100vh" : "auto")};
  width: ${({ $isDesktop }) => ($isDesktop ? "23vw" : "100vw")};
  background-color: ${(props) => `${props.$bgColor}`};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  padding: ${({ $isDesktop }) =>
    $isDesktop ? "100px 40px 80px 60px;" : "70px 0 50px 60px;"};
  position: ${({ $isDesktop }) => ($isDesktop ? "sticky" : "static")};
  top: 0;
`;

const ContentsWrapper = styled.div`
  position: relative;
  flex: 1;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: ${(props) => (props.$isDesktop ? "10rem" : "5rem")};
  overflow-y: scroll;
`;
const Contents = styled.div`
  min-height: 100%;

  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      * {
        /* font-size: 2.5rem; */
      }
    `}
`;

const TextSection = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
`;

const Subtitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.5;
`;

const DownloadButton = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  border: solid 1px white;
  color: white;
  border-radius: 6px;
  padding: 10px 30px;
  margin-top: 20px;
  transition: 0.3s;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "none")};

  &:hover:not(:disabled) {
    background-color: white;
    color: ${(props) => `${props.$bgColor}`};
  }
`;

const Bottom = styled.div`
  height: 10rem;
  width: 100%;
`;
