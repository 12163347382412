import React, { useState, useEffect } from "react";

function Checkbox({ size, checked, onChange }) {
  return (
    <>
      {checked ? (
        <img
          onClick={onChange}
          src="/assets/icons/check_a.svg"
          style={{
            height: size ? size : 15,
            width: size ? size : 15,
            marginTop: 1,
          }}
        />
      ) : (
        <img
          onClick={onChange}
          src="/assets/icons/check_d.png"
          style={{
            height: size ? size : 15,
            width: size ? size : 15,
            marginTop: 1,
          }}
        />
      )}
    </>
  );
}

export default Checkbox;
