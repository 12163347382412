import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { route } from "../../../router/route";

import useDisplay from "../../../hooks/useDisplay";

import PretendardText from "../../../components/atoms/text/pretendardText";
import BorderBottomInput from "../../../components/atoms/input/borderBottomInput";
import Pagination from "../../../components/organisms/pagination";
import BrochureCard from "../../../components/organisms/relationOffice/brochureCard";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import PromotionApi from "../../../api/promotionApi";

import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { brochureLang } from "../../../lang/relation/brochureLang";

const Brochure = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const langValue = useRecoilValue(langState);

  const [keyword, setKeyword] = useState("");
  const [totalCtn, setTotalCtn] = useState(0);
  const [brochureList, setBrochureList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 4, // 한 페이지에 표시될 게시글 수
    rangeSize: 3, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [tab, setTab] = useState([
    {
      title: brochureLang.video[langValue],
      link: route.videoGallery,
    },
    {
      title: brochureLang.brochure[langValue],
      link: route.brochure,
    },
  ]);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getBrochureAPI();
  }, [location, langValue]);

  useEffect(() => {
    setTab([
      {
        title: brochureLang.video[langValue],
        link: route.videoGallery,
      },
      {
        title: brochureLang.brochure[langValue],
        link: route.brochure,
      },
    ]);
  }, [langValue]);

  const getBrochureAPI = async () => {
    try {
      const data = {
        pageNo: page,
        pageSize: pagination.size,
        keyword: searchParams.get("keyword"),
        language: langValue,
        promotionType: "BROSURE",
      };

      const response = (await PromotionApi.GetList(data)).data.data;

      setTotalCtn(response.totalCount);
      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: response.totalPage,
        first: response.isFirst,
        last: response.isLast,
      });
      setBrochureList(response.items);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_public_relation.png"
            : "/assets/imgs/main-section/public_relation.png"
        }
        title={mainBannerLang.publicRelationsOffice.title[langValue]}
        content={mainBannerLang.publicRelationsOffice.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />

      <div
        style={{
          padding: isMobile
            ? "80px 20px"
            : isTablet
            ? "100px 70px"
            : "120px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {tab.map((item, index) => {
              return (
                <TabWrapper
                  $isMobile={isMobile}
                  $isTablet={isTablet}
                  key={index}
                  id={index}
                  $activeTab={activeTab}
                  onClick={() => {
                    if (item.title !== brochureLang.brochure[langValue]) {
                      navigate(item.link);
                    } else {
                      navigate(`${item.link}/1`);
                    }
                    setActiveTab(index);
                  }}
                >
                  <TabText
                    id={index}
                    $activeTab={activeTab}
                    $isMobile={isMobile}
                    $isTablet={isTablet}
                  >
                    {item.title}
                  </TabText>
                </TabWrapper>
              );
            })}
          </div>

          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 50,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 48,
                  position: "relative",
                }}
              >
                <BorderBottomInput
                  type="text"
                  placeholder="Search"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.brochure}/1?keyword=${keyword}`);
                    }
                  }}
                />
                <img
                  onClick={() => {
                    navigate(`${route.brochure}/1?keyword=${keyword}`);
                  }}
                  style={{
                    position: "absolute",
                    right: -6,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    height: 24,
                    width: 24,
                  }}
                  src="/assets/icons/search_02.svg"
                />
              </div>

              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  marginTop: 40,
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 60,
                width: "100%",
              }}
            >
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "23.2px",
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
              <div
                style={{
                  width: isTablet ? 340 : 440,
                  height: 48,
                  position: "relative",
                }}
              >
                <BorderBottomInput
                  type="text"
                  placeholder="Search"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.brochure}/1?keyword=${keyword}`);
                    }
                  }}
                />
                <img
                  onClick={() => {
                    navigate(`${route.brochure}/1?keyword=${keyword}`);
                  }}
                  style={{
                    position: "absolute",
                    right: -6,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    height: 24,
                    width: 24,
                  }}
                  src="/assets/icons/search_02.svg"
                />
              </div>
            </div>
          )}

          {brochureList.length > 0 ? (
            <>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: isTablet ? "space-between" : "flex-start",
                  flexWrap: "wrap",
                  gap: isMobile || isTablet ? "30px 0px" : "40px",
                }}
              >
                {brochureList.map((item, index) => {
                  return (
                    <BrochureCard
                      key={index}
                      imgSrc={item.imageUrl}
                      title={item.title}
                      serverFileName={item.serverFileName}
                      originFileName={item.originFileName}
                      date={moment.utc(item.createdAt).format("YYYY.MM.DD")}
                    />
                  );
                })}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: isMobile || isTablet ? 40 : 60,
                }}
              >
                <Pagination
                  route={route.brochure}
                  queryString={`keyword=${keyword}`}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            </>
          ) : (
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "21px",
                marginTop: isMobile ? 60 : isTablet ? 80 : 120,
                textAlign: "center",
              }}
            >
              {searchParams.get("keyword") === "" ||
              searchParams.get("keyword") == null
                ? brochureLang.empty[langValue]
                : brochureLang.searchEmpty[langValue]}
            </PretendardText>
          )}
        </div>
      </div>
    </>
  );
};

export default Brochure;

const TabText = styled(PretendardText)`
  color: ${(props) => (props.$activeTab === props.id ? "#262c31" : "#adb4ba")};
  position: relative;
  bottom: ${(props) => (props.$activeTab === props.id ? "-1px" : "0")};
  font-size: ${(props) =>
    props.$isMobile ? "15px" : props.$isTablet ? "18px" : "20px"};

  font-weight: 600;
  line-height: ${(props) =>
    props.$isMobile ? "21px" : props.$isTablet ? "26px" : "29px"};

  letter-spacing: -0.2px;
  text-align: center;
`;

const TabWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$isTablet ? "340px" : "375px"};
  height: ${(props) => (props.$isMobile ? "41px" : "59px")};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$activeTab === props.id ? "3px solid #00AEEF" : "1px solid #ADB4BA"};
`;
