import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";
import { langState } from "../../../../store/langState";
import { useRecoilValue } from "recoil";
import { nationalRnDLang } from "../../../../lang/CompanyIntroduction/nationalRnD";

const RnDModal3 = ({ title }) => {
  const { isMobile } = useDisplay();

  const langValue = useRecoilValue(langState);

  return (
    <div>
      <div style={{ display: isMobile ? "flex" : "" }}>
        {isMobile ? (
          <div style={{ width: "3px", height: "16px", backgroundColor: "#00AEEF" }} />
        ) : null}
        <Title $isMobile={isMobile}>{nationalRnDLang.rndModal3?.title[langValue]}</Title>
      </div>
      <ContentText $isMobile={isMobile} style={{ marginBottom: isMobile ? "20px" : "30px" }}>
        {nationalRnDLang.rndModal3?.content1[langValue]}
      </ContentText>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? "20px" : "",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <img
          src={`/assets/imgs/introduce/rnd/rnd3_1${langValue === "KR" ? "_kr" : ""}.png`}
          alt=""
        />
        <img
          src={`/assets/imgs/introduce/rnd/rnd3_2${langValue === "KR" ? "_kr" : ""}.png`}
          alt=""
        />
        <img
          src={`/assets/imgs/introduce/rnd/rnd3_3${langValue === "KR" ? "_kr" : ""}.png`}
          alt=""
        />
      </div>
      <ContentText
        $isMobile={isMobile}
        style={{ fontWeight: 600, color: "#262c31", marginBottom: isMobile ? "10px" : "20px" }}
      >
        {nationalRnDLang.rndModal3?.content2[langValue]}
      </ContentText>
      <div
        style={{
          border: isMobile ? "none" : "1px solid #D7DCE2",
          padding: isMobile ? 0 : "23px 27px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          marginBottom: isMobile ? "20px" : "30px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div>
            <SmallText style={{ fontWeight: 600, color: "#262c31", marginBottom: "10px" }}>
              {nationalRnDLang.rndModal3?.content3?.subTitle1[langValue]}
            </SmallText>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <SmallText>ㆍ</SmallText>
              <SmallText>{nationalRnDLang.rndModal3?.content3?.subContent1[langValue]}</SmallText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: isMobile ? "15px" : "20px",
              }}
            >
              <SmallText>ㆍ</SmallText>
              <SmallText>{nationalRnDLang.rndModal3?.content3?.subContent2[langValue]}</SmallText>
            </div>
          </div>
          <img src="/assets/imgs/introduce/rnd/rnd3_4.png" alt="" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: isMobile ? "20px" : "",
          }}
        >
          <div>
            <SmallText
              style={{
                fontWeight: 600,
                color: "#262c31",
                marginBottom: "10px",
              }}
            >
              {nationalRnDLang.rndModal3?.content3?.subTitle2[langValue]}
            </SmallText>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: isMobile ? "15px" : "0",
              }}
            >
              <SmallText>ㆍ</SmallText>
              <SmallText>{nationalRnDLang.rndModal3?.content3?.subContent3[langValue]}</SmallText>
            </div>
          </div>
          <img src="/assets/imgs/introduce/rnd/rnd3_5.png" alt="" />
        </div>
      </div>
      <ContentText
        $isMobile={isMobile}
        style={{ fontWeight: 600, color: "#262c31", marginBottom: "20px" }}
      >
        {nationalRnDLang.rndModal3?.content4[langValue]}
      </ContentText>
      <img
        src={
          isMobile
            ? `/assets/imgs/introduce/rnd/m_rnd3_6${langValue === "KR" ? "_kr" : ""}.png`
            : `/assets/imgs/introduce/rnd/rnd3_6${langValue === "KR" ? "_kr" : ""}.png`
        }
        alt=""
        style={{ marginBottom: "10px" }}
      />
      <GrayBox>
        <ContentText $isMobile={isMobile} style={{ textAlign: isMobile ? "center" : "" }}>
          {nationalRnDLang.rndModal3?.content4?.caption[langValue]}
        </ContentText>
      </GrayBox>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? "0" : "8px",
          marginBottom: isMobile ? "15px" : "20px",
        }}
      >
        <ContentText style={{ fontWeight: 600, color: "#262c31" }}>
          {langValue === "KR" ? "적용 알고리즘" : "Application Algorithm"}
        </ContentText>
        <ContentText>Coupled non-negative matrix decomposition (CNMF)</ContentText>
      </div>
      <img
        src="/assets/imgs/introduce/rnd/rnd3_7.png"
        alt=""
        style={{ width: isMobile ? "100%" : "" }}
      />
    </div>
  );
};

export default RnDModal3;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 26.1px;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.18px")};
  border-left: ${(props) => (props.$isMobile ? "none" : "3px solid #00aeff")};
  padding-left: 10px;
  margin-bottom: ${(props) => (props.$isMobile ? "15px" : "20px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "15px")};
  font-weight: 400;
  line-height: 140%;
  color: #5a636a;
`;

const SmallText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  color: #5a636a;
`;

const GrayBox = styled.div`
  width: 100%;
  height: 51px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
