export const gavsLang = {
  caption1: {
    KR: "루미르의 IECEx 인증 대용량 솔레노이드를 채용한 연료조절 밸브",
    EN: "Fuel control valve with a large capacity solenoid certified by the IECEx of Lumir",
  },
  overview1: {
    KR: "루미르는 Zero0, EPL Ga 등급의 IECEx 내압방폭 인증을 만족하는 대용량 솔레노이드를 개발하였습니다. 루미르가 개발한 솔레노이드는 대형 선박 엔진의 전자식 능동제어 밸브를 구성하는 핵빔 부품이며 능동 정밀 연료 제어로 인한 배출가스 감소 및 연료 효율을 높이는 중요한 기능을 구현합니다.",
    EN: "Lumir developed a high-capacity solenoid that satisfies the IECEx explosion proof certification of Zero0, EPL Ga grade. The solenoid developed by Lumir is a nuclear beam component that constitutes the electronic active control valve of a large ship engine and implements important functions to reduce emissions and increase fuel efficiency by active precision fuel control.",
  },
  overview2: {
    KR: "IMO(국제해사기구)의 Nox 규제구역이 확대되고 세계 선급 협회에서도 선박용 엔진 능동 연료제어 밸브에 대한 IECEx 인증이 의무화되면서, 친환경 선박 기자재의 수요가 증가함에 따라 루미르가 개발한 솔레노이드는의 수요는 더욱 많아질 것으로 예상됩니다.  루미르의 선박 엔진 연료제어 밸브용 솔레노이드는 세계 최초 IECEx 내압방폭 인증 제품이며 국내 특허와 미국 특허를 모두 확보하였습니다. ",
    EN: "With the expansion of the NOx regulatory zone of IMO (International Maritime Organization) and the mandatory IECEx certification of active fuel control valves for ship engines by the World Shipping Association, the demand for solenoids developed by Lumir is expected to increase. Lumir's solenoid for fuel control valves for ship engines is the world's first IECEx withstand voltage explosion certification product and has secured both domestic and U.S. patents.",
  },
  patent: {
    domestic: {
      KR: "국내 특허 번호",
      EN: "Domestic patent number",
    },
    us: {
      KR: "미국 특허 번호",
      EN: "US patent number",
    },
  },
  feature: {
    KR: "루미르의 IECEx 인증 대용량 솔레노이드 제품은 방폭 구조를 구비하는 솔레노이드 밸브, 연료 공급 시스템 및 그 제조방법에 대해 국내/외 특허를 보유하고 있는 제품으로 선박용 엔진의 연료(Gas)조절 밸브입니다.",
    EN: "Lumir's IECEx-certified high-capacity solenoid product is a fuel control valve of a ship's engine, which holds domestic/foreign patents for solenoid valves with explosion-proof structures, fuel supply systems, and manufacturing methods.",
  },
  featureCaption: {
    KR: "솔레노이드 방식 능동제어 밸브의 구조",
    EN: "Structure of Solenoid Active Control Valve",
  },
  qualityOfMaterial: {
    KR: "재질",
    EN: "Quality of The Material",
    caption1: {
      KR: "폭발압력에 견딜 수 있는 재질 적용",
      EN: "Application of materials that can withstand explosion pressure",
    },
  },
  gasConnectionSealed: {
    KR: "가스 접합면 밀폐",
    EN: "Gas Connection Sealed",
    caption1: {
      KR: "용접 구조 적용으로 가스 유입 원천 차단",
      EN: "Application of welding structure to block the source of gas inflow",
    },
  },
  mold: {
    caption1: {
      KR: "내부 공간 Epoxy Mold 처리",
      EN: "Process Epoxy Mold for internal space",
    },
    caption2: {
      KR: "내부 가스유입 가능성 최소화",
      EN: "Minimize the possibility of internal gas inflow",
    },
  },
  cableGland: {
    KR: "Ex d 등급의 Cable Gland 적용",
    EN: "Applying Cable Gland in Exd Grade",
  },
  hanwhaAerospace: {
    KR: "한화에어로스페이스",
    EN: "Hanwha Aerospace",
  },
  hyundai: {
    KR: "HD 현대중공업",
    EN: "HD Hyundai Heavy Industries",
  },
};
