export const languageType = ["KR", "EN"];

export const menuLang = {
  MENU_1: {
    KR: "회사소개", //회사소개
    EN: "ABOUT",
  },
  MENU_1_1: {
    KR: "회사소개",
    EN: "Company introduction",
  },
  MENU_1_2: {
    KR: "연혁",
    EN: "History",
  },
  MENU_1_3: {
    KR: "주요실적",
    EN: "Major performance",
  },
  MENU_1_3_1: {
    KR: "헤리티지",
    EN: "Heritage",
  },
  MENU_1_3_2: {
    KR: "국가 R&D",
    EN: "National R&D",
  },
  MENU_1_3_3: {
    KR: "인증 및 수상",
    EN: "Certification and award",
  },
  MENU_1_4: {
    KR: "사업장 소개",
    EN: "Introduction to the workplace",
  },
  MENU_1_5: {
    KR: "오시는길",
    EN: "Directions",
  },

  MENU_2: {
    KR: "제품", //사업소개
    EN: "PRODUCTS",
  },
  MENU_2_0: {
    KR: "위성시스템",
    EN: "Satellite",
  },
  MENU_2_1: {
    KR: "Constellation",
    EN: "Constellation",
  },
  MENU_2_2: {
    KR: "올드스페이스",
    EN: "Old space",
  },
  MENU_2_2_1: {
    KR: "위성 탑재 장치",
    EN: "Satellite-mounted device",
  },
  MENU_2_2_1_1: {
    KR: "차세대중형위성 5호",
    EN: "CAS500-5",
  },
  MENU_2_2_1_2: {
    KR: "달 탐사용 통신장치",
    EN: "Communication equipment for lunar exploration",
  },
  MENU_2_2_1_3: {
    KR: "영상자료처리장치",
    EN: "Image data processing apparatus",
  },
  MENU_2_2_1_4: {
    KR: "탑재컴퓨터",
    EN: "Exploration Computer",
  },
  MENU_2_2_1_5: {
    KR: "제어모멘텀자이로 제어장치",
    EN: "Control device for control momentimator",
  },
  MENU_2_2_2: {
    KR: "전기지상지원장비",
    EN: "Electric ground support equipment",
  },
  MENU_2_2_2_1: {
    KR: "X-밴드 하향링크 모듈",
    EN: "X-band downlink module",
  },
  MENU_2_2_2_2: {
    KR: "위성 본체 전력계",
    EN: "Satellite body power meter",
  },
  MENU_2_3: {
    KR: "뉴스페이스",
    EN: "New space",
  },
  MENU_2_3_1: {
    KR: "LUMIR-X",
    EN: "LUMIR-X",
  },
  MENU_2_3_1_1: {
    KR: "CATIS™",
    EN: "CATIS™",
  },
  MENU_2_3_1_2: {
    KR: "SARDIP™",
    EN: "SARDIP™",
  },
  MENU_2_3_2: {
    KR: "LUMIR-V",
    EN: "LUMIR-V",
  },
  MENU_2_3_3: {
    KR: "New Business",
    EN: "New Business",
  },
  MENU_2_3_3_1: {
    KR: "Data Fusion",
    EN: "Data Fusion",
  },
  MENU_2_4: {
    KR: "서비스 범위",
    EN: "Service scope",
  },
  MENU_2_5: {
    KR: "GAVS",
    EN: "GAVS",
  },
  MENU_2_6: {
    KR: "위성시스템",
    EN: "Satellite System",
  },
  MENU_2_7: {
    KR: "위성탑재장치",
    EN: "Satellite-mounted device",
  },
  MENU_2_8: {
    KR: "전기지상지원장비",
    EN: "Electric ground support equipment",
  },
  MENU_2_9: {
    KR: "민수장치",
    EN: "Civilian device",
  },

  MENU_3: {
    KR: "투자정보", //투자정보
    EN: "IR",
  },
  // MENU_3_1: {
  //   KR: "재무정보&공시",
  //   EN: "Financial information & disclosure",
  // },
  //QA 메뉴
  MENU_3_1: {
    KR: "전자공고", //경영정보공시
    EN: "Management information disclosure",
  },
  MENU_3_1_1: {
    KR: "전자공고", //경영정보공시
    EN: "Management information disclosure",
  },
  MENU_3_1_2: {
    KR: "사업보고서",
    EN: "Business report",
  },
  MENU_3_1_3: {
    KR: "감사/검토보고서",
    EN: "Audit/Review Report",
  },
  MENU_3_1_4: {
    KR: "기업지배구조보고서",
    EN: "Corporate Governance Report",
  },
  MENU_3_1_5: {
    KR: "공시자료", //전자공고
    EN: "Electronic announcement",
  },
  MENU_3_2: {
    KR: "실적발표&IR행사",
    EN: "Performance Table & IR Event",
  },
  MENU_3_2_1: {
    KR: "실적발표",
    EN: "Performance announcement",
  },
  MENU_3_2_2: {
    KR: "IR행사",
    EN: "IR event",
  },
  MENU_3_2_3: {
    KR: "행사자료",
    EN: "Event materials",
  },
  MENU_3_3: {
    KR: "주주총회",
    EN: "General shareholder’s meeting",
  },
  MENU_3_3_1: {
    KR: "주주통신문",
    EN: "Shareholder's communication",
  },
  MENU_3_3_2: {
    KR: "주주총회",
    EN: "General shareholder’s meeting",
  },
  MENU_3_4: {
    KR: "주식정보",
    EN: "Stock Information",
  },
  MENU_3_5: {
    KR: "공지사항",
    EN: "Notice",
  },

  MENU_4: {
    KR: "사이버홍보", //사이버홍보
    EN: "MEDIA",
  },
  MENU_4_1: {
    KR: "보도자료",
    EN: "News article",
  },
  MENU_4_2: {
    KR: "루미르 뉴스",
    EN: "Lumir News",
  },
  MENU_4_3: {
    KR: "홍보자료실",
    EN: "Public Relations Office",
  },
  MENU_4_3_1: {
    KR: "영상 갤러리",
    EN: "video archive",
  },
  MENU_4_3_2: {
    KR: "브로슈어",
    EN: "brochure",
  },

  MENU_5: {
    KR: "채용정보", //채용정보
    EN: "CAREER",
  },
  MENU_5_1: {
    KR: "인재상",
    EN: "Type of talent",
  },
  MENU_5_2: {
    KR: "채용공고",
    EN: "Job announcement",
  },
  MENU_5_3: {
    KR: "FAQ",
    EN: "FAQ",
  },

  CONTACT: {
    KR: "문의하기", //문의하기
    EN: "CONTACT",
  },

  MENU_6: {
    KR: "서비스", //SARDIP™
    EN: "SERVICE",
  },
  MENU_6_1: {
    KR: "SAR Level-1 영상 처리",
    EN: "SAR Level-1 Image Processing",
  },
  MENU_6_2: {
    KR: "AI기반 객체 탐지",
    EN: "AI-based object detection",
  },
  MENU_6_3: {
    KR: "재난재해 피해분석",
    EN: "Disaster Damage Analysis",
  },
  MENU_6_4: {
    KR: "도시 변화 모니터링",
    EN: "Monitoring urban change",
  },
  MENU_6_5: {
    KR: "수자원 시설 안전 관리",
    EN: "Manage water facility safety",
  },
};

export const footerLang = {
  COMPANY: {
    KR: "주식회사 루미르",
    EN: "Lumir Co., Ltd",
  },
  REPRESENTATIVE: {
    KR: "대표자",
    EN: "Representative",
  },
  REPRESENTATIVE_VALUE: {
    KR: "남명용",
    EN: "Nam Myung-yong",
  },
  ADDRESS: {
    KR: "주소",
    EN: "Address",
  },
  ADDRESS_VALUE: {
    KR: "(16827) 경기도 용인시 수지구 신수로 767, A-1103",
    EN: "(16827), 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, A-1103",
  },
  TEL: {
    KR: "전화",
    EN: "TEL",
  },
  ENGLISH_CONSULTATION: {
    KR: "영어상담",
    EN: "English Consultation",
  },
  FAX: {
    KR: "팩스",
    EN: "FAX",
  },
  CUSTOMER_INQUIRIES: {
    KR: "고객문의",
    EN: "Customer Inquiries",
  },
  RECRUITMENT_INQUIRY: {
    KR: "채용문의",
    EN: "Recruitment inquiry",
  },
};

export const mainBannerLang = {
  introduce: {
    title: {
      KR: "회사소개",
      EN: "Company introduction",
    },
    content: {
      KR: "루미르를 소개합니다.",
      EN: "Let me introduce you to Lumir.",
    },
  },
  history: {
    title: {
      KR: "연혁",
      EN: "History",
    },
    content: {
      KR: "루미르의 발자취를 확인하세요.",
      EN: "Check out Lumir's footsteps.",
    },
  },
  heritage: {
    title: {
      KR: "주요실적",
      EN: "Major Performance",
    },
    content: {
      KR: "국가 우주개발 사업에서 검증된 루미르의 우수한 제품입니다.",
      EN: "Lumir's excellent product proven in the national space development project.",
    },
  },
  rnd: {
    title: {
      KR: "국가 R&D",
      EN: "National R&D",
    },
    content: {
      KR: "차세대 우주기술의 선도주자 루미르입니다.",
      EN: "Lumir, a leader in the next generation of space technology.",
    },
  },
  certification: {
    title: {
      KR: "인증 및 수상",
      EN: "Certification and award",
    },
    content: {
      KR: "루미르의 빛나는 경험과 노력의 결과를 소개합니다.",
      EN: "Introducing the results of Lumir's brilliant experience and hard work.",
    },
  },
  workplace: {
    title: {
      KR: "사업장 소개",
      EN: "Introduction to the workplace",
    },
    content: {
      KR: "루미르의 사업장을 소개합니다.",
      EN: "Introducing Lumir's workplace.",
    },
  },
  directions: {
    title: {
      KR: "오시는 길",
      EN: "Directions",
    },
    content: {
      KR: "루미르로 오시는 길을 소개합니다.",
      EN: "Let me introduce you to the way to Lumir.",
    },
  },
  constellation: {
    title: {
      KR: "Constellation",
      EN: "Constellation",
    },
    content: {
      KR: "루미르의 비즈니스 여정을 소개합니다.",
      EN: "Let me introduce you to Lumir's business journey.",
    },
  },
  nextGenerationHeavySatellite5: {
    title: {
      KR: "차세대중형위성 5호",
      EN: "CAS500-5",
    },
    content: {
      KR: "위성 탑재 장치의 차세대중형위성 5호 입니다.",
      EN: "It is the CAS500-5 of satellite-mounted devices.",
    },
  },
  lunarExploration: {
    title: {
      KR: "달 탐사용 통신장치",
      EN: "Communication Equipment for Lunar Exploration",
    },
    content: {
      KR: "위성 탑재 장치의 달 탐사용 통신장치입니다.",
      EN: "It is a communication device for lunar exploration of satellite-mounted devices.",
    },
  },
  imageDataProcessing: {
    title: {
      KR: "영상자료처리장치",
      EN: "Image Data Processing Apparatus",
    },
    content: {
      KR: "위성 탑재 장치의 영상자료처리장치입니다.",
      EN: "It is an image data processing device of a satellite mounting device.",
    },
  },
  explorationComputer: {
    title: {
      KR: "탑재컴퓨터",
      EN: "Exploration Computer",
    },
    content: {
      KR: "위성 탑재 장치의 의 탑재컴퓨터 입니다.",
      EN: "It is a mounted computer of a satellite mounted device.",
    },
  },
  controlDevice: {
    title: {
      KR: "제어모멘텀자이로 제어장치",
      EN: "Control Device for Control Momentimator",
    },
    content: {
      KR: "위성 탑재 장치의 제어모멘텀자이로 제어장치입니다.",
      EN: "It is a control momentum controller of a satellite mounted device.",
    },
  },
  xBand: {
    title: {
      KR: "X-밴드 하향링크 모듈",
      EN: "X-Band Downlink Module",
    },
    content: {
      KR: "전기지상지원장비의 X-밴드 하향링크 모듈입니다.",
      EN: "This is an X-band downlink module of electrical ground support equipment.",
    },
  },
  bodyPowerMeter: {
    title: {
      KR: "위성 본체 전력계",
      EN: "Satellite Body Power Meter",
    },
    content: {
      KR: "전기지상지원장비의 위성 본체 전력계입니다.",
      EN: "It is a satellite body power meter with electric ground support equipment.",
    },
  },
  catis: {
    title: {
      KR: "CATIS™",
      EN: "CATIS™",
    },
    content: {
      KR: "LUMIR-X의 소형 SAR 탑재체입니다.",
      EN: "LUMIR-X's small SAR payload.",
    },
  },
  sardip: {
    title: {
      KR: "SARDIP™",
      EN: "SARDIP™",
    },
    content: {
      KR: "LUMIR-X의 SAR 영상처리시스템 입니다.",
      EN: "This is LUMIR-X's SAR image processing system.",
    },
  },
  lumirV: {
    title: {
      KR: "Lumir-V",
      EN: "Lumir-V",
    },
    content: {
      KR: "초분광 카메라를 탑재하는 초소형 위성 Lumir-V입니다.",
      EN: "It is a very small satellite Lumir-V equipped with a hyperspectral camera.",
    },
  },
  dataFusion: {
    title: {
      KR: "Data Fusion",
      EN: "Data Fusion",
    },
    content: {
      KR: "루미르의 새로운 비즈니스, Data Fusion입니다.",
      EN: "Lumir's new business, Data Fusion.",
    },
  },
  serviceScope: {
    title: {
      KR: "서비스 범위",
      EN: "Service Scope",
    },
    content: {
      KR: "루미르의 사업 가능 범위에 대해 소개합니다.",
      EN: "Introducing Lumir's business possibilities.",
    },
  },
  gavs: {
    title: {
      KR: "GAVS",
      EN: "GAVS",
    },
    content: {
      KR: "루미르의 선박용 엔진 연료조절 밸브입니다.",
      EN: "Lumir's engine fuel control valve for ships.",
    },
  },
  managementInformationDisclosure: {
    title: {
      KR: "전자공고", //경영정보공시
      EN: "Management Information Disclosure",
    },
    content: {
      KR: "루미르의 전자공고를 확인하세요.",
      EN: "Check the disclosure information.",
    },
  },
  businessReport: {
    title: {
      KR: "사업보고서",
      EN: "Business Report",
    },
    content: {
      KR: "루미르의 사업보고서를 확인하세요.",
      EN: "Check out Lumir's business report.",
    },
  },
  reviewReport: {
    title: {
      KR: "감사/검토보고서",
      EN: "Audit/Review Report",
    },
    content: {
      KR: "루미르의 연도별 감사/검토보고서를 확인하세요.",
      EN: "Check out Lumir's annual audit/review report.",
    },
  },
  governanceReport: {
    title: {
      KR: "기업지배구조 보고서",
      EN: "Corporate Governance Report",
    },
    content: {
      KR: "루미르의 기업지배구조 보고서를 확인하세요.",
      EN: "Check out Lumir's Corporate Governance Report.",
    },
  },
  electronicAnnouncement: {
    title: {
      KR: "공시자료", //전자공고
      EN: "Electronic Announcement",
    },
    content: {
      KR: "루미르의 공시자료를 확인하세요.",
      EN: "Check out Lumir's electronic announcement.",
    },
  },
  performanceAnnouncement: {
    title: {
      KR: "실적발표",
      EN: "Performance Announcement",
    },
    content: {
      KR: "루미르의 연도별 실적을 확인하세요.",
      EN: "Check Out Lumir's Annual Earnings.",
    },
  },
  IREvent: {
    title: {
      KR: "IR 행사",
      EN: "IR Event",
    },
    content: {
      KR: "루미르의 IR 행사를 확인하세요.",
      EN: "Check out Lumir's IR event.",
    },
  },
  eventMaterials: {
    title: {
      KR: "행사자료",
      EN: "Event Materials",
    },
    content: {
      KR: "루미르의 다양한 행사 자료를 확인하세요.",
      EN: "Check out Lumir's various event materials.",
    },
  },
  shareholderCommunication: {
    title: {
      KR: "주주통신문",
      EN: "Shareholder's Communication",
    },
    content: {
      KR: "주주 여러분께 소식을 전합니다.",
      EN: "Here's the news to our shareholders.",
    },
  },
  generalShareholderMeeting: {
    title: {
      KR: "주주총회",
      EN: "General Shareholders' Meeting",
    },
    content: {
      KR: "주주 여러분께 소식을 전합니다.",
      EN: "Here's the news to our shareholders.",
    },
  },
  stockInformation: {
    title: {
      KR: "주식정보",
      EN: "Stock Information",
    },
    content: {
      KR: "현재 주가 현황을 확인하세요.",
      EN: "Check the current stock price status.",
    },
  },
  notice: {
    title: {
      KR: "공지사항",
      EN: "Notice",
    },
    content: {
      KR: "루미르 공지사항을 확인하세요.",
      EN: "Check out the Lumir announcement.",
    },
  },
  newsArticle: {
    title: {
      KR: "보도자료",
      EN: "News Article",
    },
    content: {
      KR: "끊임없는 도전의 이야기를 세상에 전합니다.",
      EN: "It tells the world the story of constant challenges.",
    },
  },
  lumirNews: {
    title: {
      KR: "루미르 뉴스",
      EN: "Lumir News",
    },
    content: {
      KR: "루미르의 다양한 소식을 확인하세요.",
      EN: "Check out Lumir's various news.",
    },
  },
  publicRelationsOffice: {
    title: {
      KR: "홍보자료실",
      EN: "Public Relations Office",
    },
    content: {
      KR: "새로운 가치와 시장을 창출하는 루미르입니다.",
      EN: "It is a Lumir that creates new values and markets.",
    },
  },
  typeOfTalent: {
    title: {
      KR: "인재상",
      EN: "Type of Talent",
    },
    content: {
      KR: "가치를 공유하고 화합을 통해 발전하는 인재와 함께합니다.",
      EN: "Share values and be with talented people who develop through harmony.",
    },
  },
  jobAnnouncement: {
    title: {
      KR: "채용공고",
      EN: "Job Announcement",
    },
    content: {
      KR: "가치를 공유하고 화합을 통해 발전하는 인재와 함께합니다.",
      EN: "Share values and be with talented people who develop through harmony.",
    },
  },
};
