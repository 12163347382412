import React from "react";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import ReportDLCard from "../../molecules/investment/reportDLCard";
import { langState } from "../../../store/langState";
import { shareholderLang } from "../../../lang/investment/shareholderLang";
import { fileDownload } from "../../../util/file-management";

const Shareholders = ({ data }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <div
      style={{
        border: "1px solid #ADB4BA",
        padding: isTablet ? "30px 30px 30px 30px" : "21px 38px 30px 40px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isTablet ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isTablet ? "flex-start" : "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: isTablet ? 16 : 0,
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: isTablet ? "16px" : "20px",
              fontWeight: 600,
              lineHeight: isTablet ? "23.2px" : "29px",
              marginBottom: isTablet ? 20 : 32,
            }}
          >
            {shareholderLang.meetingSummary[langValue]}
          </PretendardText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <PretendardText
              style={{
                color: "#778088",
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 600,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                width: isTablet ? 60 : 75,
              }}
            >
              {shareholderLang.meetingPlace[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                color: "#778088",
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 400,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                whiteSpace: "pre-wrap",
              }}
            >
              {data ? data.place : ""}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 6,
            }}
          >
            <PretendardText
              style={{
                color: "#778088",
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 600,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                width: isTablet ? 60 : 75,
              }}
            >
              {shareholderLang.meetingDate[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                color: "#778088",
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 400,
                lineHeight: isTablet ? "18.9px" : "23.2px",
              }}
            >
              {data ? data.meetingDate : ""}
            </PretendardText>
          </div>
        </div>

        <div
          style={{
            borderTop: isTablet ? "1px solid #D7DCE2" : "none",
            borderLeft:
              isTablet ||
              (!data?.fileUrlCR &&
                !data?.fileUrlCA &&
                !data?.fileUrlRD &&
                !data?.fileUrlAR)
                ? "none"
                : "1px solid #ADB4BA",
            padding: isTablet ? "14px 0px 0px 0px" : "9px 20px 0px 20px",
            width: isTablet ? "100%" : 380,
          }}
        >
          {data && data?.fileUrlCR ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameCR, data.originFileNameCR);
              }}
              content={shareholderLang.meetingDLData01[langValue]}
            />
          ) : null}

          {data && data?.fileUrlCA ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameCA, data.originFileNameCA);
              }}
              content={shareholderLang.meetingDLData02[langValue]}
            />
          ) : null}

          {data && data?.fileUrlRD ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameRD, data.originFileNameRD);
              }}
              content={shareholderLang.meetingDLData03[langValue]}
            />
          ) : null}

          {data && data?.fileUrlAR ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameAR, data.originFileNameAR);
              }}
              content={shareholderLang.meetingDLData04[langValue]}
              style={{
                marginBottom: 0,
              }}
            />
          ) : null}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          borderTop: "1px solid #ADB4BA",
          margin: "20px 0px",
        }}
      />

      <PretendardText
        style={{
          color: "#262C31",
          fontSize: isTablet ? "16px" : "20px",
          fontWeight: 600,
          lineHeight: isTablet ? "23.2px" : "29px",
          marginBottom: 20,
        }}
      >
        {shareholderLang.meetingResult[langValue]}
      </PretendardText>
      <PretendardText
        style={{
          color: "#5A636A",
          fontSize: isTablet ? "14px" : "16px",
          fontWeight: 400,
          lineHeight: isTablet ? "18.9px" : "23.2px",
          marginBottom: isTablet ? 23 : 10,
        }}
      >
        {data ? data.result : ""}
      </PretendardText>
      <div
        style={{
          width: "100%",
          height: 55,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          background: "#F9F9F9",
          borderTop: "1px solid #ADB4BA",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <PretendardText
          style={{
            color: "#000000",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 600,

            flex: 1,
            textAlign: "center",
          }}
        >
          {shareholderLang.meetingAgenda[langValue]}
        </PretendardText>
        <PretendardText
          style={{
            color: "#000000",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 600,

            width: 200,
            textAlign: "center",
          }}
        >
          {shareholderLang.meetingApprovalRate[langValue]}
        </PretendardText>
      </div>
      <div
        style={{
          width: "100%",
          height: 67,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            marginLeft: 30,
            width: 140,
          }}
        >
          {shareholderLang.meeting1stAgenda[langValue]}
        </PretendardText>
        <PretendardText
          style={{
            color: "#5A636A",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            flex: 1,
          }}
        >
          {data ? data.firstAgenda : ""}
        </PretendardText>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            width: 200,
            textAlign: "center",
          }}
        >
          {data ? data.firstRate : ""}
        </PretendardText>
      </div>
      <div
        style={{
          width: "100%",
          height: 67,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            marginLeft: 30,
            width: 140,
          }}
        >
          {shareholderLang.meeting2stAgenda[langValue]}
        </PretendardText>
        <PretendardText
          style={{
            color: "#5A636A",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            flex: 1,
          }}
        >
          {data ? data.secondAgenda : ""}
        </PretendardText>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            width: 200,
            textAlign: "center",
          }}
        >
          {data ? data.secondRate : ""}
        </PretendardText>
      </div>
      <div
        style={{
          width: "100%",
          height: 67,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            marginLeft: 30,
            width: 140,
          }}
        >
          {shareholderLang.meeting3stAgenda[langValue]}
        </PretendardText>
        <PretendardText
          style={{
            color: "#5A636A",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            flex: 1,
          }}
        >
          {data ? data.thirdAgenda : ""}
        </PretendardText>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 400,
            width: 200,
            textAlign: "center",
          }}
        >
          {data ? data.thirdRate : ""}
        </PretendardText>
      </div>

      <PretendardText
        style={{
          color: "#778088",
          fontSize: isTablet ? "11px" : "13px",
          fontWeight: 400,
          lineHeight: isTablet ? "14.85px" : "17.55px",
          marginTop: 10,
          whiteSpace: "pre-wrap",
        }}
      >
        {data ? data.reference : ""}
      </PretendardText>
    </div>
  );
};

export default Shareholders;
