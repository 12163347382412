export const nextGenerationHeavySatelliteData = {
  id: 1,
  createdAt: "2024-04-19T14:02:08.637Z",
  updatedAt: "2024-05-24T17:58:36.442Z",
  pageName: "차세대중형위성 5호",
  productName: "차세대중형위성 5호",
  subtitle: "CAS500-5 Payload System",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2708f088-e25d-4c75-914b-0b671393b66f.png",
  serverFileName: "2708f088-e25d-4c75-914b-0b671393b66f.png",
  originFileName: "차세대중형위성5호 2.png",
  exposure: true,
  __productContents__: [
    {
      id: 60,
      createdAt: "2024-04-19T14:02:08.637Z",
      updatedAt: "2024-05-24T18:00:34.265Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>차세대중형위성 시리즈는 공공분야의 위성영상 수요에 효과적으로 대응하고, 국내 위성산업 저변 확대 및 산업체 육성, 위성의</p><p>해외 수출 촉진을 위한 정부 주도의 개발 사업입니다. 루미르는 기존 장치 개발 수준으로 차세대중형위성 사업에 참여해 왔으나,</p><p>차세대중형위성 5호 사업에서는 임무탑재체 전체에 해당되는 영상레이다 부체계 및 자료전송 부체계 두 과제를 모두 수주 받아</p><p>체계 종합 수준의 사업 범위를 맡게 되었습니다</p><p><br></p><p>환경부에서 주로 활용될 차세대중형위성 5호는 수자원 조사, 하천관리, 해양환경 감시, 재난 재해 대응용 위성으로</p><p>해상도 10 m급 관측폭 120km급의 C-Band 영상레이다가 탑재됩니다. 루미르는 정부 사용자가 요구하는 성능 사양을 모두</p><p>만족함은 물론이고 나아가 소형경량 모델 구현이 가능하고, 실질적으로 선행사업인 MCSAR 개발과 반사판 기반</p><p>C-Band 고속 빔 포밍 모듈 개발을 통해 소요기술을 100% 보유하고 있었기에 해당 사업의 수주에 유리한 위치에 있었습니다.</p><p><br></p><p>차세대중형위성 5호는 우리나라가 개발한 누리호 발사체를 이용하여 발사될 예정이기에,</p><p>우리나라가 100% 독자적인 기술로 개발한 위성을 우리나라 발사체로 발사한다는 점에서 더욱 중요한 의미를 갖고 있습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 61,
      createdAt: "2024-04-19T14:02:08.637Z",
      updatedAt: "2024-05-24T18:00:34.306Z",
      title: "기능 · 용도 · 특징",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>차세대중형위성 5호에 탑재되는 영상레이다는 능동센서이기 때문에 수동센서인 광학카메라와 달리 비, 구름, 연기, 안개 등 기상조건이나</p><p>주야간, 역광 등 일조량에 관계없이 원하는 시간대에 광범위한 지역에 대한 영상획득이 가능합니다.</p><p>차세대중형위성 5호의 임무탑재체는 국가 수자원 관리 및 재난 재해의 주야간 전천후 감시를 지원하기 위한</p><p>지구관측 데이터를 획득하는 것으로, 환경부, 행안부, 해수부, 농진청 등 다양한 정부 부처에서 활용될 것입니다.</p><p><br></p><p>특히 C-Band 영상레이다 신호는 우리나라처럼 초목의 잎이 크지 않은 환경에서 토양수분관측 및 홍수 감시 등 분야에서</p><p>유용하게 활용될 수 있으며, 우리나라의 물자원 효율적 관리, 토양 습도, 댐 수위, 하천 유량, 홍수, 가뭄, 재해재난 감시를 위한</p><p>산출물 획득에 효과적인 특징을 갖고 있습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 128,
      createdAt: "2024-05-24T18:00:34.350Z",
      updatedAt: "2024-05-24T18:00:34.350Z",
      title: "사진1",
      orderNum: 3,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 1,
          createdAt: "2024-05-24T18:00:34.540Z",
          updatedAt: "2024-05-24T18:00:34.540Z",
          fileUrl:
            "https://lumir-media.s3.amazonaws.com/board/c164689d-e0d9-4289-b6be-00b3bc9da3dd.png",
          serverFileName: "c164689d-e0d9-4289-b6be-00b3bc9da3dd.png",
          originFileName: "Group 1410117173.png",
        },
      ],
    },
    {
      id: 129,
      createdAt: "2024-05-24T18:00:34.556Z",
      updatedAt: "2024-05-24T18:00:34.556Z",
      title: "형상 및 기술적 특징",
      orderNum: 4,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>∙ C-Band(5.4 GHz) 메쉬(mesh) 타입 반사판 안테나 국내 최초 적용</p><p>∙ C-Band 주파수 직접 생성 및 획득하여 RF소자의 비선형 왜곡 제거</p><p>∙ 다채널/다중 주파수 동시 운용으로 관측 성능 확대</p><p>∙ 최신 우주기술을 적용한 초소형/초경량화로 임무탑재체 전체 목표 무게 150 kg 이하</p>",
      __productContentImages__: [],
    },
  ],
};
