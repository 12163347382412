import React, { useMemo } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useNavigate } from "react-router-dom";

function Pagination({
  route,
  queryString,
  totalPages,
  currentPage,
  rangeSize,
  first,
  last,
  style,
}) {
  const navigate = useNavigate();

  const pageList = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [totalPages]);

  const handlePageChange = (page) => {
    navigate(`${route}/${page}${queryString ? `?${queryString}` : ""}`);
  };

  const handlePrevPage = () => {
    if (!first) {
      handlePageChange(Number(currentPage) - 1);
    }
  };

  const handleNextPage = () => {
    if (!last) {
      handlePageChange(Number(currentPage) + 1);
    }
  };

  if (pageList.length === 0) return null;

  return (
    <PaginationWrapper style={style}>
      <ArrowContainer>
        <ArrowBox
          className="custom-cursor"
          onClick={handlePrevPage}
          disabled={first}
          aria-label="Previous page"
        >
          <img
            src="/assets/icons/pagination/left_arrow.svg"
            alt=""
            style={{ width: 7, height: 13 }}
          />
        </ArrowBox>
      </ArrowContainer>
      <NumberWrapper>
        {pageList.map((page) => (
          <PageText
            className="custom-cursor"
            key={page}
            $isActive={Number(currentPage) === page}
            onClick={() => handlePageChange(page)}
            aria-current={Number(currentPage) === page ? "page" : undefined}
          >
            {page}
          </PageText>
        ))}
      </NumberWrapper>
      <ArrowContainer>
        <ArrowBox
          className="custom-cursor"
          onClick={handleNextPage}
          disabled={last}
          aria-label="Next page"
        >
          <img
            src="/assets/icons/pagination/right_arrow.svg"
            alt=""
            style={{ width: 7, height: 13 }}
          />
        </ArrowBox>
      </ArrowContainer>
    </PaginationWrapper>
  );
}

export default Pagination;

const PaginationWrapper = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ArrowBox = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  //cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PageText = styled(PretendardText)`
  color: ${(props) => (props.$isActive ? "#000000" : "#818181")};
  font-size: 18px;
  font-weight: ${(props) => (props.$isActive ? 600 : 400)};
  line-height: 25.2px;
  padding: 0 10px;
  //cursor: pointer;
`;
