import React from "react";
import MainSection from "../../../../components/templates/mainSection";
import MiddleNavBar from "./../../../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";
import MobileBr from "../../../../components/molecules/br/mobileBr";
import DeskTopBr from "../../../../components/molecules/br/deskTopBr";
import TabletBr from "../../../../components/molecules/br/tabletBr";
import { langState } from "../../../../store/langState";
import { useRecoilValue } from "recoil";
import { mainBannerLang } from "../../../../lang/lang";
import { dataFusionLang } from "../../../../lang/Business/dataFusion";

const DataFusion = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_data_fusion.png"
            : "/assets/imgs/main-section/data_fusion.png"
        }
        title="Data Fusion"
        content={mainBannerLang.dataFusion?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
        <SectionWrapper>
          <Title $isMobile={isMobile} style={{ marginBottom: "16px" }}>
            {dataFusionLang.title[langValue]}
          </Title>
          <ContentText
            $isMobile={isMobile}
            style={{
              textAlign: "center",
              color: "#5A636A",
              marginBottom: isMobile ? "40px" : "30px",
            }}
          >
            {dataFusionLang.content1[langValue]}
          </ContentText>
          <img
            src={
              isMobile
                ? "/assets/imgs/business/m_data_fusion1.png"
                : "/assets/imgs/business/data_fusion1.png"
            }
            alt=""
            style={{ marginBottom: "44px", width: isTablet ? "100%" : "" }}
          />
          <img
            src={
              isMobile
                ? `/assets/imgs/business/m_data_fusion2${langValue === "KR" ? "_kr" : ""}.png`
                : `/assets/imgs/business/data_fusion2${langValue === "KR" ? "_kr" : ""}.png`
            }
            alt=""
            style={{ marginBottom: isMobile ? "0" : "88px", width: isTablet ? "100%" : "" }}
          />
        </SectionWrapper>
      </PageWrapper>
      <GrayBox $isMobile={isMobile}>
        <SectionWrapper
          style={{
            alignItems: isMobile ? "center" : "flex-start",
            padding: isTablet ? "0px 70px" : "",
          }}
        >
          <Title
            $isMobile={isMobile}
            style={{
              marginBottom: isMobile ? "15px" : "25px",
              textAlign: isMobile ? "center" : "",
            }}
          >
            {dataFusionLang.title2[langValue]}
          </Title>
          <ContentText
            $isMobile={isMobile}
            style={{
              marginBottom: "30px",
              textAlign: isMobile ? "center" : "",
              color: isMobile ? "#5A636A" : "",
            }}
          >
            {dataFusionLang.content2[langValue]}
          </ContentText>
          <div
            style={{
              width: isMobile ? "100%" : "",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "30px" : "40px",
            }}
          >
            <div style={{ width: isMobile || isTablet ? "100%" : "" }}>
              <SubTitle $isMobile={isMobile}>Infrastructure</SubTitle>
              <img
                src="/assets/imgs/business/data_fusion3.png"
                alt=""
                style={{
                  margin: isMobile ? "10px 0" : "6px 0 15px 0",
                  width: isMobile || isTablet ? "100%" : "",
                }}
              />
              <ContentText $isMobile={isMobile}>
                {dataFusionLang.infrastructure[langValue]}
              </ContentText>
            </div>
            <div style={{ width: isMobile || isTablet ? "100%" : "" }}>
              <SubTitle $isMobile={isMobile}>City</SubTitle>
              <img
                src="/assets/imgs/business/data_fusion4.png"
                alt=""
                style={{
                  margin: isMobile ? "10px 0" : "6px 0 15px 0",
                  width: isMobile || isTablet ? "100%" : "",
                }}
              />
              <ContentText $isMobile={isMobile}>{dataFusionLang.city[langValue]}</ContentText>
            </div>
            <div style={{ width: isMobile || isTablet ? "100%" : "" }}>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                <SubTitle $isMobile={isMobile}>Environment</SubTitle>
                <ContentText
                  $isMobile={isMobile}
                  style={{
                    color: "#8B8B8B",
                    fontSize: isMobile ? "15px" : "16px",
                    lineHeight: "140%",
                  }}
                >
                  Land, Water, Air
                </ContentText>
              </div>
              <img
                src="/assets/imgs/business/data_fusion5.png"
                alt=""
                style={{
                  margin: isMobile ? "10px 0" : "6px 0 15px 0",
                  width: isMobile || isTablet ? "100%" : "",
                }}
              />
              <ContentText $isMobile={isMobile}>
                {dataFusionLang.environment[langValue]}
              </ContentText>
            </div>
          </div>
        </SectionWrapper>
      </GrayBox>
    </>
  );
};

export default DataFusion;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "18px" : "24px")};
  font-weight: 600;
  line-height: 145%px;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.18px" : "-0.24px")};
`;

const SubTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "20px")};
  font-weight: 600;
  line-height: 140%;
  color: #7f2ca7;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.2px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;

const GrayBox = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "80px 20px" : " 110px 0 120px 0")};
`;
