import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import FramerSlideIn from "../../styles/framerSlideIn";
import useDisplay from "../../hooks/useDisplay";

const MainSection = ({ bg, title, content, blackText, style }) => {
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <MainSectionContainer
      $isMobile={isMobile}
      $isTablet={isTablet}
      style={{ backgroundImage: `url(${bg})`, ...style }}
    >
      <MainSectionWrapper>
        <FramerSlideIn>
          <Title $isMobile={isMobile} $isTablet={isTablet} $blackText={blackText}>
            {title}
          </Title>
          <Content $isMobile={isMobile} $isTablet={isTablet} $blackText={blackText}>
            {content}
          </Content>
        </FramerSlideIn>
      </MainSectionWrapper>
    </MainSectionContainer>
  );
};

export default MainSection;

const MainSectionContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${(props) => (props.$isMobile ? "20px" : props.$isTablet ? "20px 70px" : "0")};
  padding-bottom: ${(props) => (props.$isMobile ? "40px" : "50px")};
`;

const MainSectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
`;

const Title = styled(PretendardText)`
  font-weight: 600;
  font-size: ${(props) => (props.$isMobile ? "24px" : props.$isTablet ? "34px" : "42px")};
  line-height: 145%;
  color: ${(props) => (props.$blackText ? "#262c31" : "#fff")};
  letter-spacing: ${(props) => (props.$isMobile ? "-0.24px" : "-0.42px")};
  margin-bottom: 10px;
`;

const Content = styled(PretendardText)`
  font-weight: ${(props) => (props.$isMobile ? 400 : 600)};
  font-size: ${(props) => (props.$isMobile || props.$isTablet ? "18px" : "20px")};
  line-height: 145%;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.18px" : "-0.2px")};
  color: ${(props) => (props.$blackText ? "#262c31" : props.$isMobile ? "#fff" : "#d7dce2")};
`;
