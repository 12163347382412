import styled from "styled-components";
import { kapa } from "../../../../lang/renewal/productsLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import MainFeature from "../components/MainFeature";

const KaPA = ({ langValue }) => {
  const data = kapa[langValue];
  return (
    <Layout
      bgColor={data.bgColor}
      title={"KAPA"}
      subTitle={data.header}
      pdf={data.pdf}
    >
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <MainFeature featureList={data.feature} />
        <DescriptImg imageList={data.desctiptImg} />
      </ContentsWrapper>
    </Layout>
  );
};

export default KaPA;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
