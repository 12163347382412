import React from "react";
import styled from "styled-components";
import PretendardText from "../../../components/atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { useRecoilValue } from "recoil";
import { ServiceScopeLang } from "../../../lang/Business/serviceScope";

const Scope1 = () => {
  const { isMobile, isTablet } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <ScopeWrapper $isMobile={isMobile}>
      <ContentText $isMobile={isMobile}>{ServiceScopeLang.scope1[langValue]}</ContentText>
      <ImgWrapper $isMobile={isMobile} $isTablet={isTablet}>
        <div>
          <img
            src="/assets/imgs/business/scope1.png"
            alt=""
            style={{ width: isMobile || isTablet ? "100%" : "" }}
          />
        </div>
        <div>
          <img
            src="/assets/imgs/business/scope2.png"
            alt=""
            style={{ width: isMobile || isTablet ? "100%" : "" }}
          />
        </div>
      </ImgWrapper>
    </ScopeWrapper>
  );
};

export default Scope1;

const ScopeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => (props.$isMobile ? "40px" : "30px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
  text-align: center;
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  gap: 40px;
`;
