export const homeLang = {
  section1: {
    title: {
      KR: "루미르는 인공위성 탑재장치부터 인공위성 시스템, 위성기반 정보 서비스 사업 등 광범위한 우주개발 사업 역량을 보유하고 있습니다.",
      EN: "Lumir has a wide range of space development capabilities, from satellite payloads to satellite systems and satellite-based information service businesses.",
    },
    content: {
      KR: "Legacy Space와 New Space를 아우르는 비즈니스 모델로 우주산업을 선도합니다. 또한 국가 실용위성 사업 참여를 통해 검증된 다양한 인공위성 탑재체 및 본체 시스템 기술을 보유하고 있습니다",
      EN: "It leads the space industry with a business model that encompasses Legacy Space and New Space. It also has various satellite payload and body system technologies verified through participation in the national practical satellite project.",
    },
    img1: {
      title: {
        KR: "인공위성 시스템",
        EN: "Satellite System",
      },
      content: {
        KR: "전천후 지구관측이 가능한 소형 SAR위성 시스템",
        EN: "A small SAR satellite system capable of all-weather earth observation",
      },
    },
    img2: {
      title: {
        KR: "인공위성 탑재장치",
        EN: "Satellite-Mounted device",
      },
      content: {
        KR: "관측, 자료저장, 통신, 제어 등 위성의 임무에 따라 필요한 탑재 장치",
        EN: "Mounting devices required according to the mission of the satellite, such as observation, data storage, communication, control, etc",
      },
    },
    img3: {
      title: {
        KR: "위성기반 정보 서비스",
        EN: "Satellite-Based Information Service",
      },
      content: {
        KR: "위성 SAR 자료를 활용한 영상 정보 처리 및 분석 서비스",
        EN: "Image information processing and analysis service using satellite SAR data",
      },
    },
  },

  section2: {
    title: {
      KR: "초소형 SAR 위성\nLumir-X",
      EN: "Micro SAR Satellites\nLumir-X",
    },
    subtitle1: {
      KR: "주야간 및 전천후 지구 관측이 가능한\n소형 SAR 군집위성시스템",
      EN: "A small SAR cluster satellite system capable of\nday and night and all weather observations",
    },
    content1: {
      KR: "Lumir-X는 총 18기의 소형 SAR 위성으로 구성되었습니다. 1시간 마다 특정 관심 지역의 영상을 제공하며, 시스템 향상을 위해 지속적인 업데이트 내용이 적용되어 사용자는 SAR 영상 처리 및 분석 플랫폼에 직접 접속하여 관심 지역 영상을 빠르게 확인할 수 있습니다.",
      EN: "Lumir-X consists of a total of 18 small SAR satellites. It provides images of specific regions of interest every hour, and continuous updates are applied to improve the system, allowing users to access the SAR image processing and analysis platform directly to quickly check images of regions of interest.",
    },
    subtitle2: {
      KR: "지표 형상 및 미소 변형 탐지 가능",
      EN: "Can detect surface geometry and microdeformation",
    },
    content2_1: {
      KR: "Lumir-X 능동센서 관측 방식으로, 스스로 전차를 대상물에 발사하여 반사된 전파를 검출하여 영상을 생성합니다.",
      EN: "The Lumir-X active sensor observation method fires the chariot at the object on its own to detect reflected radio waves and produce images.",
    },
    content2_2: {
      KR: "야간 관측 기능과 구름, 비 투시 기능을 통해 지구 전체의 100% 취득이 가능합니다.",
      EN: "It is possible to acquire 100% of the entire planet through night observation, cloud, and non-visibility functions.",
    },
  },

  section3: {
    title: {
      KR: "초소형 초분광 위성\nLumir-V",
      EN: "Ultra-Small Hyperspectral\nSatellite Lumir-V",
    },
    subtitle1: {
      KR: "초분광 카메라를 탑재하는\n초소형 초분과 위성",
      EN: "Ultra-small Supernova satellite with\nhyperspectral cameras",
    },
    content1: {
      KR: "Lumir-V는 지질, 재질을 관측할 수 있는 위성으로 가시광 및 적외선 영역의 256 주파수 대역 측정을 통하여 대상물질 고유의 재질 특성을 식별할 수 있습니다.",
      EN: "Lumir-V is a satellite that can observe geology and material, and it can identify the unique material characteristics of the target material through measurement of 256 frequency bands in the visible and infrared regions.",
    },
    subtitle2: {
      KR: "초분광 영상의 미래성",
      EN: "Future of hyperspectral imaging",
    },
    content2_1: {
      KR: "초분광 영상을 통하여 환경 및 기후 분야, 도시 및 기반 시설의 안전 분야 등에 대해 빠르고 정확한 모니터링이 가능합니다.",
      EN: "Through hyperspectral imaging, it is possible to quickly and accurately monitor the environment, climate, and safety of cities and infrastructure.",
    },
    content2_2: {
      KR: "녹조 등 환경의 이싱징후 판별과 환경재해를 분석하고, 광물 탐사에 활용됩니다. 의료 분야에서도 의약품 성분 및 공정 품질 분석에 용이하는 등 다양한 방법으로 인류에 공헌할 수 있습니다.",
      EN: "It is used to determine the symptoms of environmental symptoms such as green algae, analyze environmental disasters, and explore minerals. Even in the medical field, it can contribute to humanity in a variety of ways, such as making it easier to analyze drug ingredients and process quality.",
    },
  },

  section4: {
    title: {
      KR: "영상레이다 장치\nCATIS™",
      EN: "Image Radar Device\nCATIS™",
    },
    subtitle1: {
      KR: "인공위성 영상레이다 장치 CATIS™",
      EN: "Satellite Imaging Radar Device CATIS™",
    },
    content1: {
      KR: "CATIS™는 초고해상도 X-Band 영상레이다 장치로, 0.3 m 초고해상도와 우수한 저장용량 및 지상전송속도를 제공합니다. 70 kg 급 초경량화로 구현하였으며, 레이다 신호 송수신 장치 SCEU, 능동 위상배열 안테나 SATA, 데이터 다운링크 안테나 KaPA의 3가지 구성품으로 이루어집니다.",
      EN: "CATIS™ is an ultra-high resolution X-Band image radar device that offers 0.3m ultra-high resolution, superior storage capacity, and ground transmission speed. It is implemented with a 70 kg ultra-lightweight and consists of three components: radar signal transceiving unit SCEU, active phase array antenna SATA, and data downlink antenna KaPA.",
    },
  },

  section5: {
    title: {
      KR: "SAR 신호 영상 처리 소프트웨어\nSARDIP™",
      EN: "SAR Signal Image\nProcessing Software\nSARDIP™",
    },
    subtitle1: {
      KR: "웹 기반 SAR 데이터 및 영상 처리\n서비스 플랫폼",
      EN: "Web-based SAR data and\nimage processing service platform",
    },
    content1_1: {
      KR: "SARDIP™은 신호 및 영상처리 소프트웨어로, 모든 위성의 데이터를 처리할 수 있는 기능을 가진 클라우드 기반 컴퓨팅 소프트웨어 및 웹서비스 플랫폼입니다.",
      EN: "SARDIP™ is signal and image processing software, a cloud-based computing software and web service platform with the ability to process data from all satellites.",
    },
    content1_2: {
      KR: "클라우드 컴퓨팅을 통해 빠른 시간 내 대용량의 데이터를 처리하고 영상을 생성하여 사용자에게 제공합니다.",
      EN: "Cloud computing processes large amounts of data in a short period of time, generates images, and provides them to users.",
    },
    subtitle2: {
      KR: "단계별 프로세서로\n더욱 정확하고 직관적인 정보 제공",
      EN: "Step-by-step processors provide more accurate\nand intuitive information",
    },
    content2_1: {
      KR: "Level 1 프로세서 : SAR 영상 생성",
      EN: "Level 1 Processor: Creating SAR Images",
    },
    content2_2: {
      KR: "Level 1 프로세서는 Level 0 데이터(Raw data)를 처리하여 영상을 생성하고, 보정을 거쳐 사용자가 사용할 수 있는 영상을 생성하는 기능을 수행합니다. Stripmap(ST), Wide Swath(WS), Spotlight(HR), Sliding-spotlight, High Resolution Wide Swath(HRWS) 등 다양한 운용모드에 맞춘 영상처리 알고리즘을 제공합니다.",
      EN: "The Level 1 processor performs the function of processing Level 0 data to generate an image, and to generate an image available to the user through calibration. It provides image processing algorithms tailored to various operating modes such as Stripmap (ST), Wide Swath (WS), Spotlight (HR), Sliding-spotlight, and High Resolution Wide Swath (HRWS).",
    },
    content3_1: {
      KR: "Level 2 프로세서 : 편파 및 위상 정보 활용 다양한 정보를 추출할 수 있는 영상 제공",
      EN: "Level 2 Processor: Provides an image that can extract various information using polarization and phase information",
    },
    content3_2: {
      KR: "Level 2 프로세서는 Level 1 프로세서에 생성된 영상에 포함된 진폭 및 위상 정보를 활용하여 다양한 정보를 추출할 수 있는 영상을 생성하는 프로세서입니다.",
      EN: "The Level 2 processor is a processor that generates an image that can extract various information by utilizing the amplitude and phase information contained in the image generated by the Level 1 processor.",
    },
    content3_3: {
      KR: "대표적으로 편파를 사용하는 PolSAR 계열과, 2개 이상 SAR 영상의 위상 차이를 사용하는 InSAR 계열이 있습니다. PolSAR 계열은 주로 지표면의 특징 추출에 사용되며, InSAR 계열은 고도 정보 관련 분야에 활용됩니다. ",
      EN: "Representatively, there are the PolSAR series that use polarization and the InSAR series that use phase differences of two or more SAR images. The PolSAR series is mainly used to extract features of the surface, and the InSAR series is used in fields related to altitude information.",
    },
  },

  section6: {
    title: {
      KR: "초소형 위성 개발 스타트업\n루미르의 새로운 소식을 확인하세요.",
      EN: "Check out the new news from ultra-small\nsatellite development startup Lumir.",
    },
  },

  section7: {
    title: {
      KR: "루미르의 새로운 인재를 기다리고 있습니다!",
      EN: "Waiting for Lumir's New Talent!",
    },
    title_desktop: {
      KR: "루미르의 새로운 인재를\n기다리고 있습니다!",
      EN: "Waiting for Lumir's\nNew Talent!",
    },
  },

  recruitbox: {
    title1: {
      KR: "루미르의 인재상이 궁금하신가요?",
      EN: "Are you curious about\nLumir's talent?",
    },
    title2: {
      KR: "채용 관련 문의사항이 있으신가요?",
      EN: "Do you have any questions\nabout hiring?",
    },
  },

  moreBtn: {
    KR: "더보기",
    EN: "More",
  },
};
