import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { route } from "../../router/route";
import { useRecoilValue } from "recoil";
import { langState } from "../../store/langState";
import useDisplay from "../../hooks/useDisplay";
import MobileBr from "../molecules/br/mobileBr";
import BaseInfoApi from "../../api/baseInfoApi";
import { menuLang, footerLang } from "../../lang/lang";
import InqueryModal from "../../pages/modal/inqueryModal";
import { useNavigate } from "react-router-dom";
import { menus } from "../../constant/menu";

const data = {
  KR: {
    companyName: "루미르 주식회사",
    representative: "남명용",
    address: "(16827) 경기도 용인시 수지구 신수로 767, A-1103",
    tel: "031-525-3800",
    englishConsultation: "031-525-3805",
    fax: "031-525-3801",
    language: "KR",
  },
  EN: {
    companyName: "Lumir Co., Ltd",
    representative: "Nam Myung-yong",
    address: "(16827), 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do, A-1103",
    tel: "031-525-3800",
    englishConsultation: "031-525-3805",
    fax: "031-525-3801",
    language: "EN",
  },
};

const Footer = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const navigation = useNavigate();

  const language = useRecoilValue(langState);

  const menuList = [
    { title: menuLang.MENU_1[language], src: route.introduce },
    { title: menuLang.MENU_2[language], src: route.business },
    { title: menuLang.MENU_3[language], src: route.investment },
    { title: menuLang.MENU_4[language], src: route.pr },
    { title: menuLang.MENU_5[language], src: route.recruit },
  ];
  const [isInqueryModal, setIsInqueryModal] = useState(false);

  const [baseInfo, setBaseInfo] = useState({});

  const getBaseInfo = async () => {
    try {
      // const response = (await BaseInfoApi.GetEn()).data.data;

      // setBaseInfo(response);
      setBaseInfo(data[langValue]);
    } catch (error) {}
  };

  useEffect(() => {
    getBaseInfo();
  }, [language]);

  return (
    <>
      <FooterSection $isDesktop={isDesktop} $isTablet={isTablet}>
        <FooterWrapper $isDesktop={isDesktop}>
          <FooterLeftSection $isDesktop={isDesktop}>
            <MenuWrapper>
              <img
                src="/assets/icons/logo_white.png"
                alt=""
                onClick={() => navigation(route.default)}
                // style={{ cursor: "pointer" }}
                className="custom-cursor"
              />
              {isDesktop ? (
                <div style={{ display: "flex", gap: "30px" }}>
                  {menus.map((item, index) => {
                    return (
                      <MenuText
                        key={index}
                        onClick={() => navigation(item.link)}
                        className="custom-cursor"
                      >
                        {menuLang[item.title][language]}
                      </MenuText>
                    );
                  })}
                </div>
              ) : null}
            </MenuWrapper>
            <InfoWrapper>
              <InfoText style={{ color: "#fff", fontWeight: 600 }}>
                {baseInfo.companyName}
              </InfoText>
              <div>
                {/* <InfoText style={{ fontWeight: 600 }}>
                  {footerLang.REPRESENTATIVE[language]}{" "}
                </InfoText>
                <InfoText style={{ marginRight: "15px" }}>
                  {baseInfo.representative}
                </InfoText> */}
                <MobileBr />
                <InfoText style={{ fontWeight: 600 }}>
                  {footerLang.ADDRESS[language]}{" "}
                </InfoText>
                <InfoText>{baseInfo.address}</InfoText>
                <br />
                <InfoText style={{ fontWeight: 600 }}>
                  {footerLang.TEL[language]}{" "}
                </InfoText>
                <InfoText style={{ marginRight: "15px" }}>
                  {baseInfo.tel}
                </InfoText>
                {/* <InfoText style={{ fontWeight: 600 }}>
                  {footerLang.ENGLISH_CONSULTATION[language]}{" "}
                </InfoText>
                <InfoText style={{ marginRight: "15px" }}>
                  {baseInfo.englishConsultation}
                </InfoText> */}
                <InfoText style={{ fontWeight: 600 }}>
                  {footerLang.FAX[language]}{" "}
                </InfoText>
                <InfoText>{baseInfo.fax}</InfoText>
              </div>
              <CopyrightText $isMobile={isMobile}>
                Copyright (C) 2024 LUMIR All Rights Reverved.
              </CopyrightText>
            </InfoWrapper>
          </FooterLeftSection>
          <FooterRightSection>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "13px" : "15px",
                // cursor: "pointer",
              }}
              className="custom-cursor"
              onClick={() => {
                navigation(route.tel);

                setTimeout(() => {
                  const element = document.getElementById("tel");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }, 100);
              }}
            >
              <RightText $isMobile={isMobile}>
                {footerLang.CUSTOMER_INQUIRIES[language]}
              </RightText>
              <img src="/assets/icons/go-page.png" alt="" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "13px" : "15px",
                // cursor: "pointer",
              }}
              className="custom-cursor"
              onClick={() => {
                navigation(route.tel);

                setTimeout(() => {
                  const element = document.getElementById("tel");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }, 100);
              }}
            >
              <RightText $isMobile={isMobile}>
                {footerLang.RECRUITMENT_INQUIRY[language]}
              </RightText>
              <img src="/assets/icons/go-page.png" alt="" />
            </div>
          </FooterRightSection>
        </FooterWrapper>
      </FooterSection>
      {isInqueryModal ? <InqueryModal setVisiable={setIsInqueryModal} /> : null}
    </>
  );
};

export default Footer;

const FooterSection = styled.div`
  width: 100%;
  min-height: 275px;
  background-color: #262c31;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.$isDesktop ? "0" : props.$isTablet ? "50px 70px" : "50px 20px"};
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  align-items: ${(props) => (props.$isDesktop ? "center" : "flex-start")};
  gap: ${(props) => (props.$isDesktop ? "0" : "30px")};
  justify-content: space-between;
`;

const FooterLeftSection = styled.div`
  background-color: #262c31;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${(props) => (props.$isDesktop ? "center" : "flex-start")};
`;

const FooterRightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 20px;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
`;

const MenuText = styled(PretendardText)`
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: #fff;
  /* cursor: pointer; */
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const InfoText = styled(PretendardText)`
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  color: #ccd1d7;
`;

const CopyrightText = styled(PretendardText)`
  font-weight: 600;
  font-size: ${(props) => (props.$isMobile ? "12px" : "13px")};
  line-height: 18.2px;
  color: #778088;
`;

const RightText = styled(PretendardText)`
  font-weight: 600;
  font-size: ${(props) => (props.$isMobile ? "16px" : "20px")};
  line-height: 29px;
  color: #fff;
`;
