export const languageType = ["KR", "EN"];

export const faqFooterLangTalent = {
  intro: {
    pc: {
      KR: "우리와 함께 세상에 없는 것을 만들기 위해\n용기있게 도전하고 싶으신가요?",
      EN: `Do you want to challenge bravely with us\nto create something that does not exist in the world?`,
    },
    mo: {
      KR: "우리와 함께 세상에 없는 것을 만들기 위해\n용기있게 도전하고 싶으신가요?",
      EN: `Do you want to challenge bravely with us\nto create something that does not exist in the world?`,
    },
  },
  inquiry: {
    KR: "채용 바로가기",
    EN: `Go to Recruitment`,
  },
};

export const faqFooterLang = {
  intro: {
    pc: {
      KR: "채용정보에 대하여 궁금한 사항이 있으신가요?\n문의사항을 작성해 주시면 빠르게 답변드리겠습니다.",
      EN: `Do you have any questions about the hiring information?\nIf you fill out your inquiry, I'll get back to you quickly.`,
    },
    mo: {
      KR: "채용정보에 대하여 궁금한 사항이 있으신가요?\n문의사항을 작성해 주시면 빠르게 답변드리겠습니다.",
      EN: `Do you have any questions\nabout the hiring information?\nIf you fill out your inquiry,\nI'll get back to you quickly.`,
    },
  },
  inquiry: {
    KR: "채용문의",
    EN: `Inquiry`,
  },
};
