import { useState } from "react";
import styled from "styled-components";

const BigImage = ({ img }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = (e) => {
    if (!hasError) {
      setHasError(true);
      e.target.src = "/assets/imgs/business/business_placeholder_img.png"; // 대체 이미지 경로
      e.target.alt = "lumir"; // 대체 이미지 설명
      console.log("error");
    }
  };
  return <Img src={img} alt={img} onError={handleError} />;
};

export default BigImage;

const Img = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  background-repeat: no-repeat;
`;
