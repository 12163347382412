import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

const Scrollbar = ({ children }) => {
  const { isMobile } = useDisplay();

  const innerRef = useRef(null);
  const thumbRef = useRef(null);
  const scrollBarRef = useRef(null);
  const [thumbH, setThumbH] = useState(0);
  const [thumbPosition, setThumbPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);

  const calculateThumbHeight = useCallback(() => {
    if (innerRef.current) {
      const inner = innerRef.current;
      const outerHeight = window.innerHeight;
      return Math.max(30, (outerHeight / inner.scrollHeight) * outerHeight);
    }
    return 0;
  }, []);

  const handleResize = useCallback(() => {
    const newThumbHeight = calculateThumbHeight();
    setThumbH(newThumbHeight);
  }, [calculateThumbHeight]);

  const handleScroll = useCallback(() => {
    let scrollLocation =
      window.pageYOffset || document.documentElement.scrollTop;
    if (innerRef.current) {
      const inner = innerRef.current;
      const newThumbPosition =
        (scrollLocation / (inner.scrollHeight - window.innerHeight)) *
        (window.innerHeight - thumbH);
      setThumbPosition(newThumbPosition);
    }
  }, [thumbH]);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    document.body.style.userSelect = "none";
    const thumbRect = thumbRef.current.getBoundingClientRect();
    const offsetY = e.clientY - thumbRect.top;
    setStartY(offsetY);
    setIsDragging(true);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const scrollBarRect = scrollBarRef.current.getBoundingClientRect();
      const newPosition = e.clientY - scrollBarRect.top - startY;
      const maxPosition = window.innerHeight - thumbH;
      const clampedPosition = Math.max(0, Math.min(newPosition, maxPosition));
      setThumbPosition(clampedPosition);

      const scrollPercentage = clampedPosition / (window.innerHeight - thumbH);
      const scrollTo =
        scrollPercentage * (innerRef.current.scrollHeight - window.innerHeight);
      window.scrollTo(0, scrollTo);
    },
    [isDragging, startY, thumbH]
  );

  const handleMouseUp = useCallback(() => {
    document.body.style.userSelect = "";
    setIsDragging(false);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
      handleScroll();
    });

    if (innerRef.current) {
      resizeObserver.observe(innerRef.current);
    }

    handleResize();
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleResize, handleScroll, handleMouseMove, handleMouseUp]);

  return (
    <>
      {!isMobile && (
        <ScrollBar ref={scrollBarRef}>
          <ScrollbarThumb
            ref={thumbRef}
            $height={thumbH}
            $top={thumbPosition}
            $isDragging={isDragging}
            onMouseDown={handleMouseDown}
          />
        </ScrollBar>
      )}
      <InnerContainer ref={innerRef}>{children}</InnerContainer>
    </>
  );
};

export default React.memo(Scrollbar);

const ScrollBar = styled.span`
  z-index: 9;
  position: fixed;
  right: 0;
  top: 0;
  width: 11px;
  height: 100vh;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 1;
  &:hover {
    opacity: 1;
  }
`;

const ScrollbarThumb = styled.span.attrs((props) => ({
  style: {
    top: `${props.$top}px`,
    height: `${props.$height}px`,
  },
}))`
  position: absolute;
  right: 0;
  background-color: gray;
  opacity: ${({ $top }) => ($top === 0 ? 0 : 0.5)};
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  transition: width 0.3s, opacity 0.3s, background-color 0.3s;

  &:hover {
    width: 10px;
    opacity: 0.8;
  }

  &:active {
    ${(props) =>
      props.$isDragging &&
      `
    width: 10px;
    opacity: 0.8;
  `};
  }
`;

const InnerContainer = styled.div``;
