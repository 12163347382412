import React from "react";
import styled from "styled-components";
import ContentBox2 from "../contentBox2";
import PretendardText from "../../atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";

const TypeB = ({title, content, fileUrl}) => {
    const {isMobile} = useDisplay();

    return (
        <ContentBox2
            style={{marginTop: "40px"}}
            title={
                <div>
                    <Title $isMobile={isMobile} style={{marginBottom: 0}}>
                        {title}
                    </Title>
                    {/*{fileUrl ? (*/}
                    {/*    <img*/}
                    {/*        src={fileUrl}*/}
                    {/*        alt=""*/}
                    {/*        style={{*/}
                    {/*            width: isMobile ? "80px" : "",*/}
                    {/*            marginBottom: isMobile ? "17px" : "10px",*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*) : null}*/}

                </div>
            }
            content={
                <div>
                    <ContentText $isMobile={isMobile}>
                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                    </ContentText>
                    {fileUrl ? (
                        <img
                            src={fileUrl}
                            alt=""
                            style={{
                                width: isMobile ? "80px" : "",
                                marginBottom: isMobile ? "17px" : "10px",
                            }}
                        />
                    ) : null}
                </div>
            }

        />
    );
};

export default TypeB;

const ContentText = styled(PretendardText)`
    font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
    font-weight: 400;
    line-height: 140%;
    color: #262c31;
    letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;

const Title = styled(PretendardText)`
    font-size: ${(props) =>
            props.$isMobile ? "20px" : props.$isTablet ? "24px" : "34px"};
    font-weight: ${(props) => (props.$isMobile ? 600 : 500)};
    line-height: 145%;
    color: #262c31;
    margin-bottom: 10px;
    letter-spacing: ${(props) => (props.$isMobile ? "-0.2px" : "-0.68px")};
    max-width: 479px;
`;
