import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import InputForm from "../../molecules/inputForm";

import { langState } from "../../../store/langState";
import { inquiryLang } from "../../../lang/inquiryLang";

const TextNumberForm = ({ data, setList, list }) => {
  let temp = list;

  const langValue = useRecoilValue(langState);

  return (
    <>
      <InputForm
        title={data ? data.title : "Name"}
        essential={data ? data.isRequired : false}
        type="number"
        placeholder={inquiryLang.placeholderName[langValue]}
        onChange={(e) => {
          let tempIndex = list.findIndex((item) => item.id === data.id);
          temp[tempIndex] = {
            ...data,
            value: e.target.value,
          };

          setList(temp);
        }}
        style={{
          marginBottom: 25,
        }}
      />
    </>
  );
};

export default TextNumberForm;
