import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";
import { langState } from "../../../../store/langState";
import { useRecoilValue } from "recoil";
import { nationalRnDLang } from "../../../../lang/CompanyIntroduction/nationalRnD";

const RnDModal2 = () => {
  const { isMobile } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <div>
      <div style={{ display: isMobile ? "flex" : "" }}>
        {isMobile ? (
          <div style={{ width: "3px", height: "16px", backgroundColor: "#00AEEF" }} />
        ) : null}
        <Title $isMobile={isMobile}>{nationalRnDLang.rndModal2?.title[langValue]}</Title>
      </div>
      <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" }}>
        <LiText $isMobile={isMobile}>ㆍ</LiText>
        <LiText $isMobile={isMobile}>{nationalRnDLang.rndModal2?.content1[langValue]}</LiText>
      </div>
      <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" }}>
        <LiText $isMobile={isMobile}>ㆍ</LiText>
        <LiText $isMobile={isMobile}>{nationalRnDLang.rndModal2?.content2[langValue]}</LiText>
      </div>
      <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "30px" }}>
        <LiText $isMobile={isMobile}>ㆍ</LiText>
        <LiText $isMobile={isMobile}>{nationalRnDLang.rndModal2?.content3[langValue]}</LiText>
      </div>
      <img
        src={
          isMobile
            ? `/assets/imgs/introduce/rnd/m_rnd2_1${langValue === "KR" ? "_kr" : ""}.png`
            : `/assets/imgs/introduce/rnd/rnd2_1${langValue === "KR" ? "_kr" : ""}.png`
        }
        alt=""
        style={{ width: isMobile ? "100%" : "", marginBottom: isMobile ? "20px" : "30px" }}
      />
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        <img
          src="/assets/imgs/introduce/rnd/rnd2_2.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
        <img
          src="/assets/imgs/introduce/rnd/rnd1_3.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
        <img
          src="/assets/imgs/introduce/rnd/rnd1_4.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
      </div>
    </div>
  );
};

export default RnDModal2;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 26.1px;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.18px")};
  border-left: ${(props) => (props.$isMobile ? "none" : "3px solid #00aeff")};
  padding-left: 10px;
  margin-bottom: ${(props) => (props.$isMobile ? "15px" : "20px")};
`;

const LiText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "15px")};
  font-weight: 400;
  line-height: 21px;
  color: #5a636a;
`;
