export const languageType = ["KR", "EN"];

export const inquiryLang = {
  title: {
    KR: "문의하기",
    EN: "Contact",
  },
  content: {
    KR: "루미르에 궁금한 점이 있나요?\n빠르게 답변드리겠습니다.",
    EN: "Do you have any questions about Lumir? \nI'll get back to you quickly.",
  },
  basicInfo: {
    KR: "기본 정보",
    EN: "Basic information",
  },
  placeholderName: {
    KR: "입력해 주세요.",
    EN: "Please enter it",
  },
  placeholderPhone: {
    KR: "전화번호를 입력해 주세요.",
    EN: "Phone Number",
  },
  placeholderEmail: {
    KR: "이메일을 입력해 주세요.",
    EN: "Email",
  },
  placeholderTextarea: {
    KR: "내용을 입력해 주세요.",
    EN: "Please enter an item.",
  },
  agreeCheckbox: {
    KR: "개인정보 수집 및 이용에 동의합니다.",
    EN: "I agree to collect and use personal information.",
  },
  file: {
    KR: "파일 업로드",
    EN: "File Upload",
  },
  inquiryCotent: {
    KR: "문의 내용",
    EN: "Inquiry Details",
  },
  send: {
    KR: "문의하기",
    EN: "Send",
  },
  preparedModal: {
    KR: "필수 항목을 모두 입력하세요.",
    EN: "Please enter all required entries.",
  },
  confirmModal: {
    KR: "문의하기를 제출할까요?",
    EN: "Do you submit it as you wrote it",
  },
  completed: {
    KR: "제출이 완료되었습니다.",
    EN: "Submission completed successfully.",
  },
};
