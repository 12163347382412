import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";

import { route } from "../../../router/route";
import useDisplay from "../../../hooks/useDisplay";
import { fileDownload } from "../../../util/file-management";
import usePdfDownload from "../../../hooks/usePdfDownload";

function MIDCardM({ style, title, date, number, data }) {
  const navigation = useNavigate();

  const { downloadPdf } = usePdfDownload();

  return (
    <>
      <div
        onClick={() => {
          navigation(`${route.managementInfoDetail}/${data.id}`);
        }}
        style={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          width: "100%",
          padding: "20px 10px",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#778088",
              marginRight: 10,
            }}
          >
            {number}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#262C31",
            }}
          >
            {title}
          </PretendardText>
        </div>

        {data.fileUrl ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              downloadPdf({
                src: data.fileUrl,
                downloadFileName: data.serverFileName,
              });
            }}
            style={{
              border: "1px solid #ADB4BA",
              width: 129,
              height: 41,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <PretendardText
              style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "18.9px",
                color: "#262C31",
                marginRight: 5,
              }}
            >
              파일 다운로드
            </PretendardText>
            <img style={{}} src="/assets/icons/download_gray.svg" />
          </div>
        ) : null}

        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "18.9px",
            color: "#ADB4BA",
            marginTop: 10,
          }}
        >
          {date}
        </PretendardText>
      </div>
    </>
  );
}

export default MIDCardM;
