import styled from "styled-components";
import { obc } from "../../../../lang/renewal/productsLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import MainFeature from "../components/MainFeature";

const Obc = ({ langValue }) => {
  const data = obc[langValue];
  return (
    <Layout bgColor={data.bgColor} title={"OBC"} subTitle={"탑재컴퓨터"}>
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <MainFeature featureList={data.feature} />
        <DescriptImg imageList={data.desctiptImg} />
      </ContentsWrapper>
    </Layout>
  );
};

export default Obc;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
