import axios from "axios";
import { url } from "../util/url";

const baseURL = `${url.api}/`;

export const _axios = axios.create({
  baseURL,
});

export default class api {
  static async getAuthorizeHeader() {
    const token = sessionStorage.getItem("token");

    return token
      ? {
          ...(token ? { Authorization: `${token}` } : {}),
        }
      : {};
  }

  static async send({ url, method, params, data, headers }) {
    try {
      // TODO: 오류로 인한 임시 주석처리
      // const res = await _axios.request({
      //   url,
      //   method,
      //   params,
      //   data,
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     ...(await api.getAuthorizeHeader()),
      //     ...headers,
      //   },
      // });
      // return { data: res.data, status: res.status };
    } catch (ex) {
      throw new Error(ex);
    }
  }

  static async get(url, params, headers) {
    return api.send({ method: "GET", url, params, headers });
  }

  static async del(url, params, data) {
    return api.send({ method: "DELETE", url, params, data });
  }

  static async post(url, data, params, headers) {
    return api.send({ method: "POST", url, data, params, headers });
  }

  static async put(url, data, params, headers) {
    return api.send({ method: "PUT", url, data, params, headers });
  }

  static async patch(url, data, params, headers) {
    return api.send({ method: "PATCH", url, data, params, headers });
  }

  static async fileForm(url, file, method, params) {
    const formData = new FormData();
    formData.append("file", file);

    return api.send({
      url,
      method: method || "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      params,
    });
  }
}
