export const languageType = ["KR", "EN"];

export const jobAnnounceLang = {
  empty: {
    KR: "등록된 공고가 없습니다.",
    EN: "There is no registered announcement.",
  },
  searchEmpty: {
    KR: "검색결과가 없습니다.",
    EN: "No search results found.",
  },
  hiring: {
    KR: "채용중",
    EN: "Hiring",
  },
  end: {
    KR: "채용마감",
    EN: "End",
  },
  isFullTime: {
    KR: "상시채용",
    EN: "Full-Time",
  },
  completed: {
    KR: "제출이 완료되었습니다.",
    EN: "Submission completed successfully.",
  },
  send: {
    KR: "문의하기",
    EN: "Send",
  },
  isEndModal: {
    KR: "채용마감되었습니다.",
    EN: "The recruitment has been closed.",
  },
  preparedModal: {
    KR: "필수 항목을 모두 입력하세요.",
    EN: "Please enter all required entries.",
  },
  confirmModal: {
    KR: "입사 지원서를 제출할까요?",
    EN: "Do you submit it as you wrote it",
  },
  jobApplicationBtn: {
    KR: "입사 지원하기",
    EN: "Job Application",
  },
  list: {
    KR: "목록으로",
    EN: "List",
  },
  jobApplication: {
    title: {
      KR: "입사 지원",
      EN: "Job Application",
    },
    content: {
      pc: {
        KR: "루미르에 입사를 희망하시나요?\n아래 내용을 작성해 주시면 빠른 시일 내 연락드리겠습니다.",
        EN: `Do you want to join Lumir?\nIf you fill out the information below, we will contact you as soonas possible.`,
      },
      mo: {
        KR: "루미르에 입사를 희망하시나요?\n아래 내용을 작성해 주시면 빠른 시일 내 연락드리겠습니다.",
        EN: `Do you want to join Lumir?\nIf you fill out the information below,\nwe will contact you as soonas possible.`,
      },
    },
  },
  sendBtn: {
    KR: "지원하기",
    EN: "Send",
  },
  supportContent: {
    KR: "지원 내용",
    EN: "Support Content",
  },
  basicInformation: {
    KR: "기본 정보",
    EN: "Basic information",
  },
};
