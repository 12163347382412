import React from "react";
import styled from "styled-components";
import { homeLang } from "../../../lang/home/homeLang";
import { useRecoilValue } from "recoil";
import { langState } from "../../../store/langState";
import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../../components/atoms/text/pretendardText";
import { route } from "../../../router/route";
import NewsList from "../components/news";
import { useNavigate } from "react-router-dom";

const News = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const navigation = useNavigate();

  return (
    <Section7 $isMobile={isMobile}>
      <SectionWrapper
        $isDesktop={isDesktop}
        $isTablet={isTablet}
        style={{
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: isMobile ? "31px" : "48px",
          }}
        >
          <SubTitleText
            $isMobile={isMobile}
            style={{
              fontSize: isMobile ? "18px" : "",
              textAlign: isMobile ? "center" : "",
              marginBottom: isMobile || isTablet ? "15px" : "",
            }}
          >
            {homeLang.section6.title[langValue]}
          </SubTitleText>
          <MoreBtn
            $isMobile={isMobile}
            onClick={() => navigation(`${route.newArticle}/1`)}
            className="custom-cursor"
          >
            {homeLang.moreBtn[langValue]}
          </MoreBtn>
        </div>
        <NewsList />
      </SectionWrapper>
    </Section7>
  );
};

export default News;

const Section7 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.$isMobile ? "80px 0 180px 0" : "120px 0 130px 0"};
  background-color: white;
`;

const SectionWrapper = styled.div`
  width: ${(props) => (props.$isDesktop ? "100%" : "")};
  max-width: 1400px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  padding: ${(props) =>
    props.$isDesktop ? "0" : props.$isTablet ? "0 70px" : "0 20px"};
`;

const SubTitleText = styled(PretendardText)`
  font-size: 24px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: -0.24px;
  white-space: pre-wrap;
`;

const MoreBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.$isMobile ? "11px 35px" : "14px 40px")};
  border: 1px solid #adb4ba;
  background-color: #fff;
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 150%;
  /* cursor: pointer; */
`;
