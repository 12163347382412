import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { menuLang } from "../../../lang/lang";
import InqueryModal from "../../../pages/modal/inqueryModal";
import { langState } from "../../../store/langState";
import { menuState } from "../../../store/menuState";
import PretendardText from "../../atoms/text/pretendardText";
import { route } from "../../../router/route";

const MHeader = ({ isOpen, setMHeaderOpen }) => {
  const navigation = useNavigate();
  const { pathname } = useLocation();

  const menuAtom = useRecoilValue(menuState);

  const [language, setLanguage] = useRecoilState(langState);
  const [isSecondOpen, setIsSecondOpen] = useState(-1);
  const [isThirdOpen, setIsThirdOpen] = useState(-1);
  const [isFourthOpen, setIsFourthOpen] = useState(-1);

  const [isInqueryModal, setIsInqueryModal] = useState(false);

  useEffect(() => {
    getProductFunc();
  }, [language]);

  const getProductFunc = async () => {
    menuAtom?.map((item, index) => {
      if (pathname.includes(item.originLink)) {
        setIsSecondOpen(index);

        item.subMenus2?.map((item2, index2) => {
          if (pathname.includes(item2.originLink || item2.link)) {
            setIsThirdOpen(index2);

            item2.subMenus3?.map((item3, index3) => {
              if (pathname.includes(item3.originLink || item3.link)) {
                setIsFourthOpen(index3);
              }
            });
          }
        });
      }
    });
  };

  return (
    <>
      <MHeaderWrapper $isOpen={isOpen}>
        <MHeaderContainer>
          {/* top */}
          <div style={{ display: "flex", gap: "3rem" }}>
            <img
              src="/assets/icons/logo_black.png"
              alt=""
              style={{ marginLeft: "20px", marginBottom: "25px" }}
            />
            <ChangeLangBtn
              onClick={() =>
                setLanguage((prev) => (prev === "KR" ? "EN" : "KR"))
              }
            >
              <MenuText
                className="custom-cursor"
                style={{
                  width: "20px",
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "20.7px",
                  color: "#262C31",
                  letterSpacing: "-1%",
                }}
              >
                {language}
              </MenuText>
              {/* <img src="/assets/icons/lang-toggle.png" alt="" /> */}
            </ChangeLangBtn>
          </div>
          {/* close */}
          <img
            src="/assets/icons/header_x.png"
            alt=""
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "15px",
            }}
            onClick={() => setMHeaderOpen(false)}
          />
          {/* menu */}
          <div>
            {menuAtom?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <MenuWrapper>
                    <Menu
                      className="custom-cursor"
                      onClick={() => {
                        setMHeaderOpen(false);
                        navigation(item.link);
                      }}
                    >
                      {item.title.startsWith("MENU")
                        ? menuLang[item.title][language]
                        : item.title}
                    </Menu>
                    <img
                      src="/assets/icons/header_arrow.png"
                      alt=""
                      style={{
                        transform:
                          isSecondOpen === index ? "rotate(180deg)" : "",
                      }}
                      onClick={() => {
                        if (isSecondOpen === index) {
                          setIsSecondOpen(-1);
                        } else {
                          setIsSecondOpen(index);
                        }

                        setIsThirdOpen(-1);
                        setIsFourthOpen(-1);
                      }}
                    />
                  </MenuWrapper>

                  {/* second depth */}
                  {isSecondOpen === index && item.subMenus2 ? (
                    <>
                      {item.subMenus2?.map((item2, index2) => {
                        if (item2.originLink && item2.subMenus3.length <= 0) {
                          return;
                        }
                        if (item2.exposure === false) {
                          return;
                        }

                        return (
                          <React.Fragment key={index2}>
                            <SecondMenuWrapper
                              $active={
                                item2.subMenus3?.length === 0 &&
                                item2.link === pathname
                              }
                            >
                              <SecondMenu
                                className="custom-cursor"
                                $active={
                                  item2.subMenus3?.length === 0 &&
                                  item2.link === pathname
                                }
                                onClick={() => {
                                  setMHeaderOpen(false);
                                  navigation(item2.link);
                                }}
                              >
                                {item2.title.startsWith("MENU")
                                  ? menuLang[item2.title][language]
                                  : item2.title}
                              </SecondMenu>
                              {item2.subMenus3?.length > 0 ? (
                                <img
                                  src="/assets/icons/header_arrow.png"
                                  alt=""
                                  style={{
                                    transform:
                                      isThirdOpen === index2
                                        ? "rotate(180deg)"
                                        : "",
                                  }}
                                  onClick={() => {
                                    if (isThirdOpen === index2) {
                                      setIsThirdOpen(-1);
                                    } else {
                                      setIsThirdOpen(index2);
                                    }
                                    setIsFourthOpen(-1);
                                  }}
                                />
                              ) : null}
                            </SecondMenuWrapper>

                            {/* third depth */}
                            {isThirdOpen === index2 && item2.subMenus3 ? (
                              <>
                                {item2.subMenus3?.map((item3, index3) => {
                                  if (
                                    item3.originLink &&
                                    item3.subMenus4.length <= 0
                                  ) {
                                    return;
                                  }
                                  if (item3.exposure === false) {
                                    return;
                                  }

                                  return (
                                    <React.Fragment key={index3}>
                                      <ThirdMenuWrapper
                                        $active={
                                          item3.subMenus4?.length === 0 &&
                                          item3.link === pathname
                                        }
                                      >
                                        <ThirdMenu
                                          className="custom-cursor"
                                          $active={
                                            item3.subMenus4?.length === 0 &&
                                            item3.link === pathname
                                          }
                                          onClick={() => {
                                            setMHeaderOpen(false);
                                            navigation(item3.link);
                                          }}
                                        >
                                          {item3.title.startsWith("MENU")
                                            ? menuLang[item3.title][language]
                                            : item3.title}
                                        </ThirdMenu>
                                        {item3.subMenus4?.length > 0 ? (
                                          <img
                                            src="/assets/icons/header_arrow.png"
                                            alt=""
                                            style={{
                                              transform:
                                                isFourthOpen === index3
                                                  ? "rotate(180deg)"
                                                  : "",
                                            }}
                                            onClick={() => {
                                              if (isFourthOpen === index3) {
                                                setIsFourthOpen(-1);
                                              } else {
                                                setIsFourthOpen(index3);
                                              }
                                            }}
                                          />
                                        ) : null}
                                      </ThirdMenuWrapper>

                                      {/* fourth depth */}
                                      {isFourthOpen === index3 &&
                                      item3.subMenus4 ? (
                                        <>
                                          {item3.subMenus4?.map(
                                            (item4, index4) => {
                                              if (item4.exposure === false) {
                                                return;
                                              }

                                              return (
                                                <FourthMenuWrapper
                                                  $active={
                                                    item4.link === pathname
                                                  }
                                                  key={index4}
                                                >
                                                  <FourthMenu
                                                    className="custom-cursor"
                                                    $active={
                                                      item4.link === pathname
                                                    }
                                                    onClick={() => {
                                                      setMHeaderOpen(false);
                                                      navigation(item4.link);
                                                    }}
                                                  >
                                                    {item4.title.startsWith(
                                                      "MENU"
                                                    )
                                                      ? menuLang[item4.title][
                                                          language
                                                        ]
                                                      : item4.title}
                                                  </FourthMenu>
                                                </FourthMenuWrapper>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : null}
                                    </React.Fragment>
                                  );
                                })}
                              </>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
          {/* contact */}
          <ContactMenuWrapper
            onClick={() => {
              setMHeaderOpen(false);

              navigation(route.tel);

              setTimeout(() => {
                const element = document.getElementById("tel");
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }, 100);
            }}
          >
            <ContactMenu>CONTACT</ContactMenu>
          </ContactMenuWrapper>
        </MHeaderContainer>
        {/* <BG $isOpen={isOpen} onClick={() => setMHeaderOpen(false)} /> */}
      </MHeaderWrapper>
      {isInqueryModal ? <InqueryModal setVisiable={setIsInqueryModal} /> : null}
    </>
  );
};

export default MHeader;

const MHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  /* overflow-y: auto; */
`;

const MHeaderContainer = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100svh;
  background: #fff;
  z-index: 10;
  padding: 16px 0 50px 0;
`;

const MenuWrapper = styled.div`
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #d7dce2;
`;

const Menu = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #5a636a;
  /* cursor: pointer; */
`;

const SecondMenuWrapper = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 16px 30px;
  border-bottom: 1px solid #f1f1f1;
  background-color: ${(props) => (props.$active ? "#00AEEF05" : "")};
`;

const SecondMenu = styled(PretendardText)`
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: ${(props) => (props.$active ? "#00AEEF" : "#5a636a")};
  /* cursor: pointer; */
`;

const ThirdMenuWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 40px;
  border-bottom: 1px solid #f1f1f1;
  background-color: ${(props) => (props.$active ? "#00AEEF05" : "")};
`;

const ThirdMenu = styled(PretendardText)`
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: ${(props) => (props.$active ? "#00AEEF" : "#778088")};
  /* cursor: pointer; */
`;

const FourthMenuWrapper = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 50px;
  border-bottom: 1px solid #f1f1f1;
  background-color: ${(props) => (props.$active ? "#00AEEF05" : "")};
`;

const FourthMenu = styled(PretendardText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: ${(props) => (props.$active ? "#00AEEF" : "#778088")};
  /* cursor: pointer; */
`;

const ContactMenuWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  padding: 18px 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #d7dce2;
`;

const ContactMenu = styled(PretendardText)`
  color: #00aeef;
  font-size: 15px;
  font-weight: 600;
  line-height: 140%;
`;

const ChangeLangBtn = styled.button`
  height: 30px;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #d7dce2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const MenuText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 23.2px;
  color: #5a636a;
  /* cursor: pointer; */

  &:hover {
    color: #00aeef;
  }
`;

const BG = styled.div`
  width: 100%;
  height: 100svh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ isOpen }) => (isOpen ? "5" : "-1")};
`;
