import { route } from "../../router/route";

// main video
export const mainVideo = {
  fallbackImg: "/assets/imgs/renewal/home/videos/main-fallback.webp",
  pcVideo: "/assets/imgs/renewal/home/videos/main-video.webm",
  mobileVideo:
    "/assets/imgs/renewal/home/videos/compressed/main-video-mobile.mp4",
};

// intro
export const CATIS = "/assets/imgs/renewal/home/intro/attachment.webp";
export const CATIS_TEXT = "/assets/imgs/renewal/home/intro/catis-text.webp";
export const LumirX = "/assets/imgs/renewal/home/intro/LumirX.webp";
export const INTRO_BG = "/assets/imgs/renewal/home/intro/intro-bg.webp";

export const intro = {
  title: { KR: "위성시스템", EN: "Satellite System" },
  subTitle: {
    KR: [
      "C-band SAR 위성 <span>차세대중형위성 5호</span>",
      "초고해상도 소형 SAR 위성 <span>LumirX</span>",
    ],
    EN: [
      "C-band SAR Satellite <span>CAS500-5</span>",
      "Ultra-high-resolution Small SAR Satellite <span>LumirX</span>",
    ],
  },
  description: {
    KR: "주·야간 전천후 지구관측이 가능한 위성 SAR 시스템",
    EN: "A satellite SAR system capable of observing the earth in all weather conditions day and night",
  },
  link: [route.cas500_5_renewal, route.lumirX_renewal],
};

//device
export const DOCKDO_BG = "/assets/imgs/renewal/home/device/dockdo.webp";

export const device = {
  KR: [
    {
      image: "/assets/imgs/renewal/home/device/DTNPL.webp",
      title: "달 탐사선 우주인터넷 통신장치",
      bg: DOCKDO_BG,
      link: route.satellite_mounted_device_DTNPL,
    },
    {
      image: "/assets/imgs/renewal/home/device/OBC.webp",
      title: "차세대중형위성 3호 탑재컴퓨터",
      link: route.satellite_mounted_device_OBC,
      bg: DOCKDO_BG,
    },
    {
      image: "/assets/imgs/renewal/home/device/IDHU.webp",
      title: "차세대중형위성 1/2/4호<br/>영상자료처리장치",
      link: route.satellite_mounted_device_IDHU,
      bg: DOCKDO_BG,
    },
  ],
  EN: [
    {
      image: "/assets/imgs/renewal/home/device/DTNPL.webp",
      title: "Lunar Exploration Space Internet Communication Device",
      bg: DOCKDO_BG,
      link: route.satellite_mounted_device_DTNPL,
    },
    {
      image: "/assets/imgs/renewal/home/device/OBC.webp",
      title: "CAS500-3 Computer",
      link: route.satellite_mounted_device_OBC,
      bg: DOCKDO_BG,
    },
    {
      image: "/assets/imgs/renewal/home/device/IDHU.webp",
      title: "CAS500-1/2/4 Image Data Handling Unit",
      link: route.satellite_mounted_device_IDHU,
      bg: DOCKDO_BG,
    },
  ],
  link: route.satellite_mounted_device,
};

export const deviceExplanation = {
  KR: {
    title: "위성 탑재 장치",
    subTitle: "인공위성 핵심 전장품들의 최초 국산화",
    description: [
      "관측, 자료저장, 통신, 제어 등",
      " 위성의 임무에 따라 필요한 탑재장치",
    ],
  },
  EN: {
    title: "Satellite Mounted Device",
    subTitle: "First localization of core components for satellites",
    description: [
      "Devices mounted on satellites",
      "as required for observation, data storage, communication, and control of satellites",
    ],
  },
};

//we do
export const weDo = {
  title: "We Do",
  highResolution: {
    KR: {
      title: "0.3 m 초고해상도 영상",
      description:
        "LumirX는 주야간 전천후 0.3 m의 초고해상도 지구관측 영상을 획득할 수 있는 성능을 갖고 있습니다.",
      images: [
        {
          src: "/assets/imgs/renewal/home/what-we-do/video1-3.webp",
          width: 2578,
          height: 4399,
        },
      ],
    },
    EN: {
      title: "0.3 m Ultra-high Resolution Image",
      description:
        "LumirX is capable of acquiring ultra-high-resolution 0.3 m earth observation images in all weather conditions day and night.",
      images: [
        {
          src: "/assets/imgs/renewal/home/what-we-do/video1-3.webp",
          width: 2578,
          height: 4399,
        },
      ],
    },
  },
  observationMode: {
    KR: {
      title: "4가지 관측모드",
      description:
        "LumirX는 0.3 m 초고해상 모드(Spotlight), 1.0 m 표준해상도 모드(Stripmap), 0.3 m 초고해상도 3D 관측모드(SAR Tomography),<br/>10 m 해상도로100 km 이상 관측폭을 갖는 광역모드(ScanSAR)까지 4가지 관측모드를 지원하여 사용자의 다양한 요구에 부합하는 영상을 제공합니다.",
      images: [
        "/assets/imgs/renewal/home/what-we-do/observation1.webp",
        "/assets/imgs/renewal/home/what-we-do/observation3.webp",
        "/assets/imgs/renewal/home/what-we-do/observation2.webp",
        "/assets/imgs/renewal/home/what-we-do/observation4.webp",
      ],
      videos: {
        desktop: [
          "/assets/imgs/renewal/home/videos/초고해상도모드.webm",
          "/assets/imgs/renewal/home/videos/표준모드.webm",
          "/assets/imgs/renewal/home/videos/3D모드.webm",
          "/assets/imgs/renewal/home/videos/광역모드.webm",
        ],
        mobile: [
          "/assets/imgs/renewal/home/videos/compressed/superhigh_mobile.mp4",
          "/assets/imgs/renewal/home/videos/compressed/standard_mobile.mp4",
          "/assets/imgs/renewal/home/videos/compressed/3d_mobile.mp4",
          "/assets/imgs/renewal/home/videos/compressed/wide_mobile.mp4",
        ],
      },
    },
    EN: {
      title: "4 Observation Modes",
      description:
        "LumirX supports four observation modes: 0.3 m ultra-high resolution mode (Spotlight), 1.0 m standard resolution mode (Stripmap), 0.3 m ultra-high resolution 3D observation mode (SAR Tomography), and wide area mode (ScanSAR) with a resolution of 10 m or more and an observation width of more than 100 km, providing images that meet various user requirements.",
      images: [
        "/assets/imgs/renewal/home/what-we-do/observation1.webp",
        "/assets/imgs/renewal/home/what-we-do/observation3.webp",
        "/assets/imgs/renewal/home/what-we-do/observation2.webp",
        "/assets/imgs/renewal/home/what-we-do/observation4.webp",
      ],
      videos: {
        desktop: [
          "/assets/imgs/renewal/home/videos/초고해상도모드.webm",
          "/assets/imgs/renewal/home/videos/표준모드.webm",
          "/assets/imgs/renewal/home/videos/3D모드.webm",
          "/assets/imgs/renewal/home/videos/광역모드.webm",
        ],
        mobile: [
          "/assets/imgs/renewal/home/videos/compressed/superhigh_mobile.mp4",
          "/assets/imgs/renewal/home/videos/compressed/standard_mobile.mp4",
          "/assets/imgs/renewal/home/videos/compressed/3d_mobile.mp4",
          "/assets/imgs/renewal/home/videos/compressed/wide_mobile.mp4",
        ],
      },
    },
  },
  lightWeight: {
    KR: {
      title: "150 kg 경량",
      description:
        "루미르는 New Space 기술을 이용하여 자체적으로 150 kg 급의 초소형 SAR 위성 LumirX를 개발하고 있습니다.",
      images: [
        // "/assets/imgs/renewal/home/what-we-do/kg1.webp",
        // "/assets/imgs/renewal/home/what-we-do/kg2.webp",
        // "/assets/imgs/renewal/home/what-we-do/kg3.webp",
        "/assets/imgs/renewal/home/what-we-do/LumirX_LightWeight.webp",
      ],
    },
    EN: {
      title: "150 kg Lightweight",
      description:
        "Lumir is developing its own ultra-small SAR satellite LumirX weighing 150 kg using New Space technology.",
      images: [
        // "/assets/imgs/renewal/home/what-we-do/kg1.webp",
        // "/assets/imgs/renewal/home/what-we-do/kg2.webp",
        // "/assets/imgs/renewal/home/what-we-do/kg3.webp",
        "/assets/imgs/renewal/home/what-we-do/LumirX_LightWeight.webp",
      ],
    },
  },
};

//constellation
const NUMBER_MAPPING = [1, 2, 6, 10, 14, 18];
const YEAR_MAPPING = ["2026", "2026", "2027", "2028", "2029", "2030"];

export const LAUNCH_ARROW =
  "/assets/imgs/renewal/home/constellation/arrow.webp";
export const LUMIRX_MIDDLE =
  "/assets/imgs/renewal/home/constellation/LumirX.webp";
export const EARTH = "/assets/imgs/renewal/home/constellation/earth.webp";
export const CONSTELLATION_BG =
  "/assets/imgs/renewal/home/constellation/bg-space.webp";

export const constellation = {
  KR: {
    title: "18 SAR Micro-satellites",
    description: `루미르는 세계 최고 수준의 초고해상도 초소형 SAR 위성인 LumirX를 개발하여
<br/> 주야간 전천후에도 지구 전역의 주요 관심지역을 관측할 수 있는 초고해상도 및 고품질 SAR 이미지를 제공합니다. 
<br/><br/>
2026년부터 LumirX 시리즈를 지속 발사하여, 2030년까지 18기의 위성의 군집 위성을 구축하고<br/>
지구 전역에 근실시간 데이터 제공 서비스를 제공할 계획입니다. 
`,
  },
  EN: {
    title: "18 SAR Micro-satellites",
    description: `Lumir is developing LumirX, the world's highest level ultra-high-resolution ultra-small SAR satellite, 
  which can observe major areas of interest worldwide in all weather conditions day and night, 
  providing ultra-high-resolution and high-quality SAR images. 
  <br/><br/>
  From 2026, Lumir will continue to launch the LumirX series and build a cluster of 18 satellites by 2030, 
  providing near-real-time data services worldwide. 
  `,
  },
  schedule: [
    { year: "Q1 2026", content: 1 },
    { year: "Q4 2026", content: 1 },
    { year: "2027", content: 4 },
    { year: "2028", content: 4 },
    { year: "2029", content: 4 },
    { year: "2030", content: 4 },
  ],
  numberMapping: NUMBER_MAPPING,
  yearMapping: YEAR_MAPPING,
};

//we service
export const SERVICE_BG = "/assets/imgs/renewal/home/we-service/bg.webp";

export const weService = {
  KR: {
    title: "SAR data",
    description: `루미르의 SAR 위성 군집 시스템은 지구상의 200개 주요 도시를 1일 1회 이상 주기적으로 지구관측 데이터를 축적하여 전 지구 영역의 영상 데이터베이스를 구축합니다. 루미르가 제공하는 SAR 데이터와 원격 모니터링 서비스는 재난 관리, 환경 모니터링, 국방 및 안보, 자원 탐사, 교통 관리 등 다양한 분야에 활용될 수 있습니다.
`,
  },
  EN: {
    title: "SAR data",
    description: `Lumir's SAR satellite cluster system accumulates earth observation data of 200 major cities on earth more than once a day to build an image database of the entire earth. Lumir's SAR data and remote monitoring services can be used in various fields such as disaster management, environmental monitoring, national defense and security, resource exploration, and traffic management.
`,
  },
  services: [
    {
      title: {
        KR: "<span>AI</span> 를 이용한 SAR 영상 내 객체 탐지 기술",
        EN: "Object Detection Capabilities in SAR Images Using <span>AI</span>",
      },
      image: "/assets/imgs/renewal/home/we-service/AI.webp",
      video: {
        desktop: "/assets/imgs/renewal/home/videos/compressed/AI.mp4",
        mobile: "/assets/imgs/renewal/home/videos/compressed/AI_mobile.mp4",
      },
    },
    {
      title: {
        KR: "<span>InSAR</span> 기술을 이용한 지형변형 탐지 기술",
        EN: "Terrain Deformation Detection Technology Using <span>InSAR</span> Technology",
      },
      image: "/assets/imgs/renewal/home/we-service/in-sar.webp",
      video: {
        desktop: "/assets/imgs/renewal/home/videos/compressed/insar.mp4",
        mobile: "/assets/imgs/renewal/home/videos/compressed/insar_mobile.mp4",
      },
    },
  ],
};

//sardip card
export const SARDIP_BG = "/assets/imgs/renewal/home/sardip-card/sardip.webp";
