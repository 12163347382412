import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";

function IRCard({ style, title, date, number }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigation = useNavigate();
  const [isDetail, setIsDetail] = useState(false);
  const langValue = useRecoilValue(langState);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#fff",
            borderBottom: "1px solid #ADB4BA",
            height: isMobile ? 64 : 66,
            width: "100%",
            padding: isMobile ? "0px 8px" : isTablet ? "0px 20px" : "0px 30px",
            ...style,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 600,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              color: "#778088",
              width: isMobile ? 20 : isTablet ? 60 : 70,
            }}
          >
            {number}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 400,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              color: "#262C31",
              flex: 1,
            }}
          >
            {title}
            {langValue === "KR" ? "년" : ""}
          </PretendardText>

          {isDetail ? (
            <img
              onClick={() => {
                setIsDetail(false);
              }}
              style={{
                cursor: "pointer",
                height: isSmall ? 24 : 30,
                width: isSmall ? 24 : 30,
              }}
              src="/assets/icons/up_arrow.svg"
            />
          ) : (
            <img
              onClick={() => {
                setIsDetail(true);
              }}
              style={{
                cursor: "pointer",
                height: isSmall ? 24 : 30,
                width: isSmall ? 24 : 30,
              }}
              src="/assets/icons/down_arrow.svg"
            />
          )}
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              background: "#F9F9F9",
              borderBottom: "1px solid #ADB4BA",
              width: "100%",
              padding: isMobile
                ? "16px 10px 15px"
                : isTablet
                ? "24px 20px 24px"
                : "24px 102px 24px",
            }}
          >
            <QuarterWrapper
              style={{
                marginBottom: 0,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <QuarterText
                style={{
                  marginBottom: isMobile ? "5px" : 0,
                }}
                $isSmall={isSmall}
              >
                {langValue === "KR"
                  ? "일정 제목이 이곳에 들어갑니다."
                  : "Title"}
              </QuarterText>
              <QuarterDateText $isSmall={isSmall}>
                {langValue === "KR" ? "2023년 1월 2일" : "January 2, 2023"}
              </QuarterDateText>
            </QuarterWrapper>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default IRCard;

const QuarterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: "center";
  margin-bottom: 24px;
  box-sizing: content-box;
`;

const QuarterText = styled(PretendardText)`
  color: #262c31;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.1px;
  margin-right: ${(props) => (props.$isSmall ? "10px" : "15px")};
`;

const QuarterDateText = styled(PretendardText)`
  color: #778088;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.1px;
`;
