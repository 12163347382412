import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";

import { langState } from "../../../../store/langState";
import { useRecoilValue } from "recoil";
import { nationalRnDLang } from "../../../../lang/CompanyIntroduction/nationalRnD";

const RnDModal5 = ({ title }) => {
  const { isMobile } = useDisplay();

  const langValue = useRecoilValue(langState);

  return (
    <div>
      <div style={{ display: isMobile ? "flex" : "" }}>
        {isMobile ? (
          <div style={{ width: "3px", height: "16px", backgroundColor: "#00AEEF" }} />
        ) : null}
        <Title $isMobile={isMobile}>{nationalRnDLang.rndModal5?.title[langValue]}</Title>
      </div>
      <ContentText $isMobile={isMobile} style={{ marginBottom: isMobile ? "15px" : "10px" }}>
        {nationalRnDLang.rndModal5?.content1[langValue]}
      </ContentText>
      <ContentText $isMobile={isMobile} style={{ marginBottom: "30px" }}>
        {nationalRnDLang.rndModal5?.content2[langValue]}
      </ContentText>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <img
          src={`/assets/imgs/introduce/rnd/rnd5_1${langValue === "KR" ? "_kr" : ""}.png`}
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
        <img
          src="/assets/imgs/introduce/rnd/rnd5_2.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
        {isMobile ? (
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src="/assets/imgs/introduce/rnd/m_rnd5_3_1.png" alt="" />
              <ContentText style={{ fontWeight: 600, width: "100%" }}>
                SAM (SAR Antenna Module)
              </ContentText>
              <div style={{ width: "100%" }}>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.sam1[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.sam2[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.sam3[langValue]}
                </ContentText>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src="/assets/imgs/introduce/rnd/m_rnd5_3_2.png" alt="" />
              <ContentText style={{ fontWeight: 600, width: "100%" }}>
                TRM (Transmit / Receive Module)
              </ContentText>
              <div style={{ width: "100%" }}>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.trm1[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.trm2[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.trm3[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.trm4[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.trm5[langValue]}
                </ContentText>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src="/assets/imgs/introduce/rnd/m_rnd5_3_3.png" alt="" />
              <ContentText style={{ fontWeight: 600, width: "100%" }}>
                TCM (TRM Control Module)
              </ContentText>
              <div style={{ width: "100%" }}>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.tcm1[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.tcm2[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.tcm3[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.tcm4[langValue]}
                </ContentText>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.tcm5[langValue]}
                </ContentText>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src="/assets/imgs/introduce/rnd/m_rnd5_3_4.png" alt="" />
              <ContentText style={{ fontWeight: 600, width: "100%" }}>
                DCM (Dividing / Combining Module)
              </ContentText>
              <div style={{ width: "100%" }}>
                <ContentText style={{ display: "flex" }}>
                  <span>ㆍ</span>
                  {nationalRnDLang.rndModal5?.dcm1[langValue]}
                </ContentText>
              </div>
            </div>
          </div>
        ) : (
          <img src="/assets/imgs/introduce/rnd/rnd5_3.png" alt="" />
        )}
      </div>
    </div>
  );
};

export default RnDModal5;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 26.1px;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.18px")};
  border-left: ${(props) => (props.$isMobile ? "none" : "3px solid #00aeff")};
  padding-left: 10px;
  margin-bottom: ${(props) => (props.$isMobile ? "15px" : "20px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "15px")};
  font-weight: 400;
  line-height: 135%;
  color: #5a636a;
`;
