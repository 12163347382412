import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import moment from "moment";

import useDisplay from "../../../hooks/useDisplay";

import BorderBottomInput from "../../../components/atoms/input/borderBottomInput";
import PretendardText from "../../../components/atoms/text/pretendardText";
import MIDCard from "../../../components/organisms/investment/midCard";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import MIDCardM from "../../../components/organisms/investment/midCardM";

import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { financialInfoLang } from "../../../lang/investment/financialInfoLang";

import DateInput from "../../../components/atoms/input/dateInput";
import MMInfoApi from "../../../api/mmInfoApi";
import { route } from "../../../router/route";
import Pagination from "../../../components/organisms/pagination";
import { mmInfoLang } from "../../../lang/investment/mmInfoLang";
import { fileDownload } from "../../../util/file-management";
import { data } from "../../home/datas/managementInfo";

const items = data;

const ManagementInfo = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();
  const langValue = useRecoilValue(langState);

  const [keyword, setKeyword] = useState(items.keyword);
  const [totalCtn, setTotalCtn] = useState(items.totalCtn);
  const [startDate, setStartDate] = useState(items.startDate);
  const [endDate, setEndDate] = useState(items.endDate);

  const [ruleData, setRuleData] = useState(items.ruleData);
  const [dataList, setDataList] = useState(items.dataList);
  const [pagination, setPagination] = useState({
    size: items.pagination.size, // 한 페이지에 표시될 게시글 수
    rangeSize: items.pagination.rangeSize, // 하단 페이지 사이즈

    totalCount: items.pagination.totalCount,
    totalPages: items.pagination.totalPages,
    first: false,
    last: false,
  });

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setStartDate(
      searchParams.get("startDate") ? searchParams.get("startDate") : ""
    );
    setEndDate(searchParams.get("endDate") ? searchParams.get("endDate") : "");

    getMMInfoListAPI();
    getRuleAPI();
  }, [location, langValue]);

  const getRuleAPI = async () => {
    try {
      let response;
      if (langValue === "KR") {
        response = (await MMInfoApi.GetRuleKR()).data.data;
      } else {
        response = (await MMInfoApi.GetRuleEN()).data.data;
      }

      setRuleData(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getMMInfoListAPI = async () => {
    try {
      const data = {
        pageNo: page,
        pageSize: pagination.size,
        keyword: searchParams.get("keyword"),
        language: langValue,
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get("endDate"),
      };

      const response = (await MMInfoApi.GetList(data)).data.data;
      // const response = items.dataList;

      setTotalCtn(response.totalCount);
      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: response.totalPage,
        first: response.isFirst,
        last: response.isLast,
      });
      setDataList(response.items);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_management_info.png"
            : "/assets/imgs/main-section/management_info.png"
        }
        title={mainBannerLang.managementInformationDisclosure.title[langValue]}
        content={
          mainBannerLang.managementInformationDisclosure.content[langValue]
        }
      />
      <MiddleNavBar pathname={pathname} />

      <div
        style={{
          padding: isMobile
            ? "90px 20px 80px"
            : isTablet
            ? "100px 70px"
            : "120px 0px 148px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <DateInput
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);

                      navigate(
                        `${route.managementInfo}/1?keyword=${keyword}&startDate=${e.target.value}&endDate=${endDate}`
                      );
                    }}
                    placeholder={"YYYY-MM-DD"}
                  />
                </div>

                <div
                  style={{
                    width: 16,
                    height: 1,
                    background: "#ADB4BA",
                    margin: "0px 10px",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <DateInput
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);

                      navigate(
                        `${route.managementInfo}/1?keyword=${keyword}&startDate=${startDate}&endDate=${e.target.value}`
                      );
                    }}
                    placeholder={"YYYY-MM-DD"}
                  />
                </div>
              </div> */}

              <div
                style={{
                  width: "100%",
                  height: 48,
                  position: "relative",
                }}
              >
                <BorderBottomInput
                  type="text"
                  placeholder="Search"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.managementInfo}/1?keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`
                      );
                    }
                  }}
                />
                <img
                  onClick={() => {
                    navigate(
                      `${route.managementInfo}/1?keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`
                    );
                  }}
                  style={{
                    position: "absolute",
                    right: -6,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    // cursor: "pointer",
                    height: 24,
                    width: 24,
                  }}
                  src="/assets/icons/search_02.svg"
                />
              </div>

              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  marginTop: 40,
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: isTablet ? "14px" : "16px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "18.9px" : "23.2px",
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* <div
                  style={{
                    width: isTablet ? 122 : 150,
                  }}
                >
                  <DateInput
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      navigate(
                        `${route.managementInfo}/1?keyword=${keyword}&startDate=${e.target.value}&endDate=${endDate}`
                      );
                    }}
                    placeholder={"YYYY-MM-DD"}
                  />
                </div>

                <div
                  style={{
                    width: 16,
                    height: 1,
                    background: "#ADB4BA",
                    margin: "0px 10px",
                  }}
                />
                <div
                  style={{
                    width: isTablet ? 122 : 150,
                  }}
                >
                  <DateInput
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      navigate(
                        `${route.managementInfo}/1?keyword=${keyword}&startDate=${startDate}&endDate=${e.target.value}`
                      );
                    }}
                    placeholder={"YYYY-MM-DD"}
                  />
                </div> */}

                {/* <div
                  style={{
                    marginLeft: isTablet ? 24 : 40,
                    width: isTablet ? 240 : 440,
                    height: 48,
                    position: "relative",
                  }}
                >
                  <BorderBottomInput
                    type="text"
                    placeholder="Search"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(
                          `${route.managementInfo}/1?keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`
                        );
                      }
                    }}
                  />
                  <img
                    onClick={() => {
                      navigate(
                        `${route.managementInfo}/1?keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`
                      );
                    }}
                    style={{
                      position: "absolute",
                      right: -6,
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                      height: 24,
                      width: 24,
                    }}
                    src="/assets/icons/search_02.svg"
                  />
                </div> */}
              </div>
            </div>
          )}

          {dataList.length > 0 ? (
            <>
              <div
                style={{
                  borderTop: "2px solid #778088",
                  borderBottom: "2px solid #778088",
                  marginTop: isMobile ? 10 : 20,
                }}
              >
                {dataList.map((item, index) => {
                  return isSmall ? (
                    <MIDCardM
                      key={index}
                      data={item}
                      number={index + 1 + (page - 1) * 10}
                      title={item.title}
                      date={moment.utc(item.createdAt).format("YYYY.MM.DD")}
                      style={{
                        borderBottom:
                          dataList.length === index + 1
                            ? "none"
                            : "1px solid #ADB4BA",
                      }}
                    />
                  ) : (
                    <MIDCard
                      key={index}
                      data={item}
                      number={index + 1 + (page - 1) * 10}
                      title={item.title}
                      date={moment.utc(item.createdAt).format("YYYY.MM.DD")}
                      style={{
                        borderBottom:
                          dataList.length === index + 1
                            ? "none"
                            : "1px solid #ADB4BA",
                      }}
                    />
                  );
                })}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: isSmall ? 40 : 60,
                }}
              >
                <Pagination
                  route={route.managementInfo}
                  queryString={`keyword=${keyword}`}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            </>
          ) : (
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "21px",
                marginTop: 120,
                marginBottom: 60,
                textAlign: "center",
              }}
            >
              {searchParams.get("keyword") === "" ||
              searchParams.get("keyword") == null
                ? mmInfoLang.empty[langValue]
                : mmInfoLang.searchEmpty[langValue]}
            </PretendardText>
          )}

          {ruleData && ruleData.fileUrl ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: isSmall ? 50 : 0,
              }}
            >
              <div
                onClick={() => {
                  fileDownload(
                    ruleData.serverFileName,
                    ruleData.originFileName
                  );
                }}
                style={{
                  background: "#00AEEF",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 229,
                  height: 54,
                  position: isSmall ? "relative" : "absolute",
                  right: isSmall ? "unset" : 0,
                  bottom: isSmall ? "unset" : -26,
                  // cursor: "pointer",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "26.1px",
                    marginRight: 5,
                  }}
                >
                  {financialInfoLang.regulation[langValue]}
                </PretendardText>
                <img style={{}} src="/assets/icons/download.svg" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ManagementInfo;
