import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "../../components/templates/middleNavBar";
import useDisplay from "../../hooks/useDisplay";
import { intro, menus } from "../../lang/renewal/productsLang";
import { route } from "../../router/route";
import { langState } from "../../store/langState";
import FramerSlideIn from "../../styles/framerSlideIn";
import FramerWipe from "../../styles/framerWipe";
import MenuSection from "./renewalDetail/MenuSection";

const ProductsIntro = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile, isDesktop } = useDisplay();

  const data = intro[langValue];
  return (
    <IntroSection $bgImage={intro.bgImage}>
      <PageWrapper>
        <Container>
          <BigTitle $isMobile={isMobile}>
            {data.bigTexts.map((text, index) => (
              <BigText key={index} $index={index} $isDesktop={isDesktop}>
                <FramerWipe delay={`${index / 2}`} direction={"down"}>
                  {text}
                </FramerWipe>
              </BigText>
            ))}
          </BigTitle>
          <FramerSlideIn>
            <Description>
              <Title>{data.description.title}</Title>
              <Wrapper>
                {data.description.contents.map((item, index) => (
                  <Contents key={index}>
                    {item.map((text, index) => (
                      <TextSection key={index}>{text}</TextSection>
                    ))}
                  </Contents>
                ))}
              </Wrapper>
            </Description>
          </FramerSlideIn>
        </Container>
      </PageWrapper>
      <LumirX src={intro.bgIcon} />
    </IntroSection>
  );
};

const IntroSection = styled.div`
  position: relative;
  background-image: ${({ $bgImage }) => `url(${$bgImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
`;

const Container = styled.div`
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 18.8rem;
  padding: 18rem 0;
`;

const BigTitle = styled.div`
  font-size: ${({ $isMobile }) => ($isMobile ? "4rem" : "7.5rem")};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white; /* 글자 색상 (투명하게 보이도록 흰색으로 설정) */
`;

const BigText = styled.h2`
  position: relative;
  letter-spacing: 0.1em;
  ${({ $index, $isDesktop }) => {
    if (!$isDesktop) {
      switch ($index) {
        case 0:
          return ``;
        case 1:
          return `text-align: right;`;
        case 2:
          return ``;
        case 3:
          return ``;
        default:
          return `
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 10px;
          `;
      }
    } else {
      switch ($index) {
        case 0:
          return ``;
        case 1:
          return `left: -18%;`;
        case 2:
          return `left: -3%;`;
        case 3:
          return `left: -8%;`;
        default:
          return `
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 10px;
          `;
      }
    }
  }}
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.5rem;
`;

const Title = styled.h2`
  font-size: 4.8rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const TextSection = styled.div`
  font-size: 2rem;
`;

const LumirX = styled.img`
  position: absolute;
  width: 9.6rem;
  height: auto;
  object-fit: cover;
  top: 40%;
  left: 45%;
`;

const ProductsRenewal = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname, hash } = useLocation();
  const [activeSection, setActiveSection] = useState(null);

  const data = menus[langValue];

  useEffect(() => {
    const section = hash.split("?")[0].replace("#", "");
    const device = hash.split("=")[1];

    if (section && device) {
      setActiveSection({ section, device });
    } else {
      setActiveSection(null);
    }
  }, [hash]);

  useEffect(() => {
    const id = hash.split("?")[0].replace("#", "");
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [hash]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/renewal/business/main/header.webp"
            : "/assets/imgs/renewal/business/main/header.webp"
        }
        title="Products"
        content={data.header}
      />
      <MiddleNavBar pathname={pathname} />
      <ProductsIntro />
      <PageWrapper
        $isDesktop={isDesktop}
        $isTablet={isTablet}
        id={route.satellite_system}
      >
        <MenuSection
          title={data.system.title}
          description={data.system.description}
          menuList={data.system.menuList}
          bgColor={data.system.bgColor}
          hash={data.system.hash}
          isActive={activeSection?.section === route.satellite_system}
          activeDevice={activeSection?.device}
          {...{ isMobile, isDesktop }}
        />
      </PageWrapper>
      <GrayWrapper
        $isDesktop={isDesktop}
        $isTablet={isTablet}
        id={route.satellite_mounted_device}
      >
        <MenuSection
          title={data.mountedDevice.title}
          description={data.mountedDevice.description}
          menuList={data.mountedDevice.menuList}
          bgColor={data.mountedDevice.bgColor}
          hash={data.mountedDevice.hash}
          isActive={activeSection?.section === route.satellite_mounted_device}
          activeDevice={activeSection?.device}
          {...{ isMobile, isDesktop }}
        />
      </GrayWrapper>
      {/* <PageWrapper
        $isDesktop={isDesktop}
        $isTablet={isTablet}
        id={route.electric_ground}
      >
        <MenuSection
          title={menuList.electric.title}
          description={menuList.electric.description}
          menuList={menuList.electric.menuList}
          bgColor={menuList.electric.bgColor}
          hash={menuList.electric.hash}
          isActive={activeSection?.section === route.electric_ground}
          activeDevice={activeSection?.device}
        />
      </PageWrapper> */}
      <PageWrapper
        $isDesktop={isDesktop}
        $isTablet={isTablet}
        id={route.civilian_device}
      >
        <MenuSection
          title={data.minsu.title}
          description={data.minsu.description}
          menuList={data.minsu.menuList}
          bgColor={data.minsu.bgColor}
          hash={data.minsu.hash}
          isActive={activeSection?.section === route.civilian_device}
          activeDevice={activeSection?.device}
          {...{ isMobile, isDesktop }}
        />
      </PageWrapper>
    </>
  );
};

export default ProductsRenewal;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const GrayWrapper = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;
