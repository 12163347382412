export const languageType = ["KR", "EN"];

export const talentTypeLang = {
  harmonia: {
    pc: {
      KR: "서로 화합하고 조화를 이루는 가운데\n다른 생각을 가진 개인의 독창성과 자유를 인정하고 포용하는\nLumir의 열린 기업 정신에 맞는 인재를 찾고있습니다.",
      EN: `We are looking for talented people who fit Lumir's open corporate spirit that recognizes\nand embraces the originality and freedom of individuals\nwith different thoughts in harmony and harmony with each other.`,
    },
    mo: {
      KR: "서로 화합하고 조화를 이루는 가운데\n다른 생각을 가진 개인의 독창성과 자유를 인정하고 포용하는 Lumir의 열린 기업 정신에 맞는 인재를 찾고있습니다.",
      EN: `We are looking for talented people who fit Lumir's\nopen corporate spirit that recognizes\nand embraces the originality and\nfreedom of individuals with different thoughts\nin harmony and harmony with each other.`,
    },
  },
  image: {
    pc: "/assets/imgs/talent-type/단체사진.webp",
    mo: "/assets/imgs/talent-type/단체사진.webp",
  },
  individuality: {
    KR: "개성",
    EN: "Individuality",
  },
  balance: {
    KR: "조화",
    EN: "Balance",
  },
  seek: {
    KR: "루미르는 일과 삶의 균형을 추구합니다.",
    EN: "Lumir Seeks a Work-Life Balance.",
  },
  seek01: {
    title: {
      KR: "자유로운 휴가 사용",
      EN: "Free Use of Vacation",
    },
    content: {
      KR: "근로자 휴가비 지원사업 지원",
      EN: "Support for workers' vacation\nexpenses",
    },
  },
  seek02: {
    title: {
      KR: "고용안정 휴가",
      EN: "Employment Security Leave",
    },
    content: {
      KR: "육아휴직, 남성출산 휴가",
      EN: "paternity leave, male maternity\nleave",
    },
  },
  seek03: {
    title: {
      KR: "식대 및 다과 지원",
      EN: "Meal and Refreshment Support",
    },
    content: {
      KR: "다양한 제휴식당, 중식·간식·석식 제공",
      EN: "Various affiliated restaurants,\nsnack, and dinner are provided",
    },
  },
  seek04: {
    title: {
      KR: "경조사 지원",
      EN: "Support for Congratulations\nand Condolences",
    },
    content: {
      KR: "생일 및 명절 기프트 및 경조사 지원",
      EN: "Support for birthday and holiday\ngifts and celebrations",
    },
  },
  seek05: {
    title: {
      KR: "스톡옵션 부여",
      EN: "Granting Stock Options",
    },
    content: {
      KR: "스톡옵션 및 우리사주조합",
      EN: "Stock options and employee stockownership association",
    },
  },
  seek06: {
    title: {
      KR: "인센티브 제도",
      EN: "Incentive System",
    },
    content: {
      KR: "성과급 및 인센티브 지급",
      EN: "Paying performance and incentives",
    },
  },
  seek07: {
    title: {
      KR: "건강한 생활",
      EN: "Healthy Life",
    },
    content: {
      KR: "건강검진, 가족·의료비 지원",
      EN: "Medical examinations, medical\nexpenses support",
    },
  },
  seek08: {
    title: {
      KR: "우수직원 포상",
      EN: "Outstanding Employee Rewards",
    },
    content: {
      KR: "포상금 및 상폐, 채용장려금",
      EN: "Rewards and commercial liabilities\nand employment incentives",
    },
  },
  seek09: {
    title: {
      KR: "업무추진비 지원",
      EN: "Support for Business\nPromotion Expenses",
    },
    content: {
      KR: "매 월 사내 업무추진비 지원",
      EN: "Support for in-house business\npromotion expenses every month",
    },
  },
  seek10: {
    title: {
      KR: "초청 세미나 개최",
      EN: "Invited Seminar Held",
    },
    content: {
      KR: "우주산업 전문가 초청 세미나 개최",
      EN: "Invited seminar for space industry\nexperts",
    },
  },
  seek11: {
    title: {
      KR: "직무발명 보상",
      EN: "Job Invention Compensation",
    },
    content: {
      KR: "특허 발명에 따른 보상, 등록비 지원",
      EN: "Compensation for patent inventions,\nsupport for registration fees",
    },
  },
  seek12: {
    title: {
      KR: "통신비 지원",
      EN: "Support for Communication\nExpenses",
    },
    content: {
      KR: "통신비 일부 지원",
      EN: "Partial support for communication\ncosts",
    },
  },
  recruitment: {
    title: {
      KR: "채용 절차를 확인하세요.",
      EN: "Please Check The Recruitment Process.",
    },
    content: {
      pc: {
        KR: "*입사지원 서류에 허위사실이 발견될 경우, 채용확정 이후라도 채용이 취소될 수 있습니다.",
        EN: `*If false information is found in the job application documents,\nthe recruitment may be canceled even after the recruitment is confirmed.`,
      },
      mo: {
        KR: "*입사지원 서류에 허위사실이 발견될 경우, 채용확정 이후라도 채용이 취소될 수 있습니다.",
        EN: `*If false information is found in the\njob application documents,\nthe recruitment may be canceled\neven after the recruitment is confirmed.`,
      },
    },
  },
  recruitmentStep01: {
    KR: "서류전형",
    EN: "Document Screening",
  },
  recruitmentStep02: {
    KR: "1차 면접",
    EN: "First Interview",
  },
  recruitmentStep03: {
    KR: "2차 면접",
    EN: "Second Interview",
  },
  recruitmentStep04: {
    KR: "최종합격",
    EN: "Final Pass",
  },
  recruitmentIntro: {
    pc: {
      KR: "루미르의 새로운 인재를\n기다리고 있습니다!",
      EN: `Waiting for Lumir's\nNew Talent!`,
    },
    mo: {
      KR: "루미르의 새로운 인재를\n기다리고 있습니다!",
      EN: `Waiting for\nLumir's New Talent!`,
    },
  },
  more: {
    KR: "더보기",
    EN: "More",
  },
  empty: {
    KR: "등록된 공고가 없습니다.",
    EN: "There is no registered announcement.",
  },
};
