import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pageTitleMapping } from "../constant/page-view";

const PageTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    // pathname에서 동적 라우트 파라미터 제거 (예: :page, :id 등)
    const basePathname = location.pathname.replace(/\/\d+$/, "");

    // 페이지 타이틀 설정
    document.title = pageTitleMapping[basePathname] || "루미르";
  }, [location]);

  return null;
};

export default PageTitleUpdater;
