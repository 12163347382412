import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";

import { route } from "../../../router/route";
import { langState } from "../../../store/langState";
import { jobAnnounceLang } from "../../../lang/recruit/jobAnnounceLang";

function JACardM({
  order,
  style,
  isHiring,
  title,
  content,
  isFullTime,
  id,
  end,
  link,
}) {
  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);
  const isEndShow = end && isHiring && !isFullTime ? true : false;

  return (
    <>
      <div
        onClick={() => {
          link
            ? navigation(`${route.jobAnnounceDetail}/${id}`)
            : window.open(route.jobAnnounceSaramin);
        }}
        style={{
          display: "flex",
          // cursor: "pointer",
          flexDirection: "column",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          width: "100%",
          padding: "20px 10px",
          ...style,
        }}
        className="custom-cursor"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#778088",
              marginRight: 10,
            }}
          >
            {order}
          </PretendardText>
          {isHiring ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: 66,
                  height: 28,
                  borderRadius: 5,
                  border: "1px solid #00AEEF",
                  marginRight: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isFullTime ? (
                  <PretendardText
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "16.2px",
                      color: "#00AEEF",
                    }}
                  >
                    {jobAnnounceLang.isFullTime[langValue]}
                  </PretendardText>
                ) : (
                  <PretendardText
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "16.2px",
                      color: "#00AEEF",
                    }}
                  >
                    {jobAnnounceLang.hiring[langValue]}
                  </PretendardText>
                )}
              </div>
              {isEndShow && (
                <PretendardText
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "16.2px",
                  }}
                >
                  ~ {end}
                </PretendardText>
              )}
            </div>
          ) : (
            <div
              style={{
                width: 66,
                height: 28,
                borderRadius: 5,
                border: "1px solid #ADB4BA",
                marginRight: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16.2px",
                  color: "#ADB4BA",
                }}
              >
                {jobAnnounceLang.end[langValue]}
              </PretendardText>
            </div>
          )}

          <PretendardText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "18.9px",
              color: "#5A636A",
            }}
          >
            {title}
          </PretendardText>
        </div>

        <PretendardText
          style={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "23.2px",
            color: "#262C31",
            marginBottom: 12,
          }}
        >
          {content}
        </PretendardText>
      </div>
    </>
  );
}

export default JACardM;
