import React from "react";
import { useMediaQuery } from "react-responsive";
import { responsiveSize } from "../constant/responsiveSize";

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    query: `(max-width:${responsiveSize.MODILE_MAX_WIDTH}px)`,
  });
  return <>{isMobile && children}</>;
};
