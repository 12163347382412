export const workspace = {
  title: {
    KR: "사업장소개",
    EN: "Workspace",
  },
  KR: {
    new: {
      category: "New",
      title: "새로운 출발, 신사옥",
      desc: "루미르는 약 1,200평 규모의 위성 연구소 부지를 마련하고 2025년 준공을 목표로 하고 있습니다.",
      imgs: ["/assets/imgs/introduce/workspace/new_building2.webp"],
    },
    facility: {
      category: "Manufacturing<br/>Facilities",
      title: "제조 시설 및 설비",
      desc: "루미르는 국내 최초로 한국항공우주연구원으로부터 유럽우주국 품질규격(ECSS)에 기반한 제조 공정을 인증 받았으며, 인공위성 제작에 필요한 기반 시설들을 보유하고 있습니다. ",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility.webp",
          name: "Anechoic Chamber",
          desc: "안테나의 근접전계 측정을 위한 테스트 시설",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility1.png",
          name: "Clean Room",
          desc: "인공위성 제조에 특화된 클린룸",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility2.webp",
          name: "",
          desc: "",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility3.png",
          name: "Soldering",
          desc: "솔더링",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility4.png",
          name: "TRM 제조",
          desc: "TRM 생산 및 시험",
        },
      ],
    },
    facility2: {
      category: "Test Facilities",
      title: "시험 시설",
      desc: "루미르는 우주산업에 필요한 핵심 제조 설비들을 보유하고 있습니다.",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility.png",
          name: "근접전계측정장치",
          desc: "안테나 시험 시설",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility2-2.png",
          name: "ETB실",
          desc: "",
        },
      ],
    },
    facility3: {
      category: "Laboratory",
      title: "연구소",
      desc: "",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility3-1.png",
          name: "기업부설연구소",
          desc: "",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility3-2.png",
          name: "공간기술연구소",
          desc: "",
        },
      ],
    },
    // facility2: {
    //   category: "Devices",
    //   title: "인공위성 제조 설비",
    //   desc: "루미르는 우주산업에 필요한 핵심 제조 설비들을 보유하고 있습니다.",
    //   imgs: [
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Reflow_MC.png",
    //       name: "Reflow MC",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Thermal_cycling_chanber.png",
    //       name: "Thermal Cycling Chamber",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Forming.png",
    //       name: "Forming",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Mounter.png",
    //       name: "Mounter",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/X-RAY_3D_CT_System.png",
    //       name: "X-RAY 3D CT System",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Wire_Bonder.png",
    //       name: "Wire Bonder",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Rework_MC.png",
    //       name: "Rework MC",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Drying_Oven.png",
    //       name: "Drying Oven",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Screen_Printing.png",
    //       name: "Screen Printing",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Stir_Defoam.png",
    //       name: "Stir Defoam",
    //     },
    //   ],
    // },
  },
  EN: {
    new: {
      category: "New",
      title: "New Start, New Building",
      desc: "Lumir is preparing a satellite research institute site of about 1,200 pyeong and aims to complete it by 2025.",
      imgs: ["/assets/imgs/introduce/workspace/new_building2.webp"],
    },
    facility: {
      category: "Manufacturing<br/>Facilities",
      title: "Manufacturing Facilities",
      desc: "Lumir has been certified with the manufacturing process based on the quality standard (ECSS) of the European Space Agency (ESA) from the Korea Aerospace Research Institute (KARI) and has the infrastructure necessary for satellite manufacturing.",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility.webp",
          name: "Anechoic Chamber",
          desc: "Test facility for near-field measurement of antennas",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility1.png",
          name: "Clean Room",
          desc: "Clean room specialized for satellite manufacturing",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility2.webp",
          name: "",
          desc: "",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility3.png",
          name: "Soldering",
          desc: "",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility4.png",
          name: "TRM Manufacturing",
          desc: "TRM production and testing",
        },
      ],
    },
    facility2: {
      category: "Test Facilities",
      title: "Test Facilities",
      desc: "Lumir has the core manufacturing equipment necessary for the space industry.",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility.png",
          name: "NFS",
          desc: "(Near Field System for SAR Satellite)",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility2-2.png",
          name: "Electrical Test Bench Room",
          desc: "",
        },
      ],
    },
    facility3: {
      category: "Laboratory",
      title: "Laboratory",
      desc: "",
      imgs: [
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility3-1.png",
          name: "Enterprise-Attached Research Center",
          desc: "",
        },
        {
          src: "/assets/imgs/renewal/introduce/workspace/facility3-2.png",
          name: "Space Technology Research Center",
          desc: "",
        },
      ],
    },
    // facility2: {
    //   category: "Devices",
    //   title: "Satellite Manufacturing Equipment",
    //   desc: "Lumir has the core manufacturing equipment necessary for the space industry.",
    //   imgs: [
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Reflow_MC.png",
    //       name: "Reflow MC",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Thermal_cycling_chanber.png",
    //       name: "Thermal Cycling Chamber",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Forming.png",
    //       name: "Forming",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Mounter.png",
    //       name: "Mounter",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/X-RAY_3D_CT_System.png",
    //       name: "X-RAY 3D CT System",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Wire_Bonder.png",
    //       name: "Wire Bonder",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Rework_MC.png",
    //       name: "Rework MC",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Drying_Oven.png",
    //       name: "Drying Oven",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Screen_Printing.png",
    //       name: "Screen Printing",
    //     },
    //     {
    //       src: "/assets/imgs/renewal/introduce/workspace/Stir_Defoam.png",
    //       name: "Stir Defoam",
    //     },
    //   ],
    // },
  },
};
