import styled from "styled-components";
import { cas500_5 } from "../../../../lang/renewal/productsLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import MainFeature from "../components/MainFeature";

const Cas5005 = ({ langValue }) => {
  const data = cas500_5[langValue];

  return (
    <Layout bgColor={data.bgColor} title={"CAS500-5"} subTitle={data.header}>
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents img={data.bigImg[0].image} textList={data.bigImg} />
        </FramerSlideIn>
        <MainFeature featureList={data.feature} />
        <DescriptImg imageList={data.desctiptImg} />
      </ContentsWrapper>
    </Layout>
  );
};

export default Cas5005;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
