import React from "react";
import { motion } from "framer-motion";

const FramerFadeIn = ({ style, children, duration = null, delay = null, amount = 0.5 }) => {
  return (
    <motion.div
      style={{ ...style }}
      initial={{ opacity: 0 }}
      viewport={{ once: true, amount: amount }}
      whileInView={{
        opacity: 1,
        transition: { duration: duration ?? 1, delay: delay ?? 0.1 },
      }}
    >
      {children}
    </motion.div>
  );
};

export default FramerFadeIn;
