import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

const ContentBox2 = ({ title, content, style }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <ContentBoxWrapper style={{ ...style }} $isDesktop={isDesktop}>
      <Title $isDesktop={isDesktop}>{title}</Title>
      <Content $isDesktop={isDesktop}>{content}</Content>
    </ContentBoxWrapper>
  );
};

export default ContentBox2;

const ContentBoxWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
`;

const Title = styled(PretendardText)`
  font-weight: 500;
  font-size: ${(props) => (props.$isDesktop ? "34px" : "20px")};
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isDesktop ? "-0.68px" : "-0.2px")};
  padding-top: ${(props) => (props.$isDesktop ? "40px" : "0")};
  padding-bottom: ${(props) => (props.$isDesktop ? "0" : "15px")};
`;

const Content = styled(PretendardText)`
  font-weight: 400;
  font-size: ${(props) => (props.$isDesktop ? "18px" : "15px")};
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isDesktop ? "-0.18px" : "")};
  width: ${(props) => (props.$isDesktop ? "920px" : "100%")};
  padding-top: ${(props) => (props.$isDesktop ? "40px" : "15px")};
  border-top: ${(props) =>
    props.$isDesktop ? "1px solid #d7dce2" : "2px solid #d7dce2"};
`;
