import api from "./api";

export default class NoticeApi {
  static async GetList(data) {
    return api.get(`notice/list`, data);
  }

  static async GetDetail(id) {
    return api.get(`notice/${id}`);
  }
}
