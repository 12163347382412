export const gavsData = {
  id: 25,
  createdAt: "2024-04-19T14:58:36.427Z",
  updatedAt: "2024-05-24T16:39:07.994Z",
  pageName: "GAVS",
  productName: "GAVS",
  subtitle: "GAVS: Gas Admission Value Solenoid",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/772f1859-4cee-47b7-8f65-bd2dba40652b.png",
  serverFileName: "772f1859-4cee-47b7-8f65-bd2dba40652b.png",
  originFileName: "Untitled-5 5.png",
  exposure: true,
  __productContents__: [
    {
      id: 123,
      createdAt: "2024-04-19T14:58:36.427Z",
      updatedAt: "2024-06-12T18:38:07.832Z",
      title: "개요",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르는&nbsp;Zero0,&nbsp;EPL&nbsp;Ga&nbsp;등급의&nbsp;IECEx&nbsp;내압방폭&nbsp;인증을&nbsp;만족하는&nbsp;대용량&nbsp;솔레노이드를&nbsp;개발하였습니다.</p><p>루미르가&nbsp;개발한&nbsp;솔레노이드는&nbsp;대형&nbsp;선박&nbsp;엔진의&nbsp;전자식&nbsp;능동제어&nbsp;밸브를&nbsp;구성하는&nbsp;핵빔&nbsp;부품이며</p><p>능동&nbsp;정밀&nbsp;연료&nbsp;제어로&nbsp;인한 배출가스&nbsp;감소&nbsp;및&nbsp;연료&nbsp;효율을&nbsp;높이는&nbsp;중요한&nbsp;기능을&nbsp;구현합니다.</p><p><br></p><p>IMO(국제해사기구)의&nbsp;Nox&nbsp;규제구역이&nbsp;확대되고&nbsp;세계&nbsp;선급&nbsp;협회에서도&nbsp;선박용&nbsp;엔진&nbsp;능동&nbsp;연료제어&nbsp;밸브에&nbsp;대한 IECEx&nbsp;인증이</p><p>의무화되면서,&nbsp;친환경&nbsp;선박&nbsp;기자재의&nbsp;수요가&nbsp;증가함에&nbsp;따라&nbsp;루미르가&nbsp;개발한&nbsp;솔레노이드는의&nbsp;수요는&nbsp;더욱&nbsp;많아질&nbsp;것으로&nbsp;예상됩니다.&nbsp;</p><p>루미르의&nbsp;선박&nbsp;엔진&nbsp;연료제어&nbsp;밸브용&nbsp;솔레노이드는&nbsp;세계&nbsp;최초&nbsp;IECEx&nbsp;내압방폭&nbsp;인증&nbsp;제품이며&nbsp;국내&nbsp;특허와&nbsp;미국&nbsp;특허를&nbsp;모두&nbsp;확보하였습니다.&nbsp;</p>",
      __productContentImages__: [],
    },
    {
      id: 124,
      createdAt: "2024-04-19T14:58:36.427Z",
      updatedAt: "2024-06-12T18:38:07.866Z",
      title: "특허",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>ㆍ국내&nbsp;특허&nbsp;번호&nbsp;:&nbsp;21-49082,&nbsp;2021-04-15</p><p>ㆍ미국&nbsp;특허&nbsp;번호&nbsp;:&nbsp;US&nbsp;11,767,928&nbsp;B2</p>",
      __productContentImages__: [],
    },
    {
      id: 125,
      createdAt: "2024-04-19T14:58:36.427Z",
      updatedAt: "2024-06-12T18:38:07.902Z",
      title: "img1",
      orderNum: 3,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: "<p>123</p>",
      __productContentImages__: [
        {
          id: 38,
          createdAt: "2024-05-26T22:08:18.230Z",
          updatedAt: "2024-05-26T22:08:18.230Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1cf00ae6-3e45-4b9a-a7a4-b79d133ba99a.png",
          serverFileName: "1cf00ae6-3e45-4b9a-a7a4-b79d133ba99a.png",
          originFileName: "gavskr_0112.png",
        },
      ],
    },
    {
      id: 127,
      createdAt: "2024-04-19T14:58:36.427Z",
      updatedAt: "2024-06-12T18:38:07.930Z",
      title: "기능 · 용도 · 특징",
      orderNum: 5,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르의&nbsp;IECEx&nbsp;인증&nbsp;대용량&nbsp;솔레노이드&nbsp;제품은&nbsp;방폭&nbsp;구조를&nbsp;구비하는&nbsp;솔레노이드&nbsp;밸브, 연료&nbsp;공급&nbsp;시스템&nbsp;및</p><p>그&nbsp;제조방법에&nbsp;대해&nbsp;국내/외&nbsp;특허를&nbsp;보유하고&nbsp;있는&nbsp;제품으로 선박용&nbsp;엔진의&nbsp;연료(Gas)조절&nbsp;밸브입니다.&nbsp;</p>",
      __productContentImages__: [],
    },
    {
      id: 149,
      createdAt: "2024-05-24T18:42:04.534Z",
      updatedAt: "2024-06-12T18:38:07.962Z",
      title: "img3",
      orderNum: 6,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 18,
          createdAt: "2024-05-24T18:42:04.710Z",
          updatedAt: "2024-05-26T22:08:18.321Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6e65dc4f-aa4b-47ad-a831-0efe2fd4857b.png",
          serverFileName: "6e65dc4f-aa4b-47ad-a831-0efe2fd4857b.png",
          originFileName: "gavskr_01.png",
        },
      ],
    },
    {
      id: 150,
      createdAt: "2024-05-24T18:42:04.751Z",
      updatedAt: "2024-06-12T18:38:07.986Z",
      title: "img4",
      orderNum: 7,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 19,
          createdAt: "2024-05-24T18:42:04.869Z",
          updatedAt: "2024-05-24T18:42:04.869Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2f7b82bf-7dd4-4ef0-bbdf-4eac0c47b6d2.png",
          serverFileName: "2f7b82bf-7dd4-4ef0-bbdf-4eac0c47b6d2.png",
          originFileName: "Group 1410117136.png",
        },
      ],
    },
    {
      id: 151,
      createdAt: "2024-05-24T18:42:04.884Z",
      updatedAt: "2024-06-12T18:38:08.019Z",
      title: "개발기술의 차별성",
      orderNum: 8,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        '<p><br></p><p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4d5137dc-b8d4-435b-9985-9231b07fa3d3.png"></p><p><br></p><p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/b8c67d36-a3d0-4fa1-aaa6-74e177aefa9e.png"></p>',
      __productContentImages__: [],
    },
    {
      id: 152,
      createdAt: "2024-05-24T18:42:05.339Z",
      updatedAt: "2024-06-12T18:38:08.046Z",
      title: "고객사",
      orderNum: 9,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e9154aa2-f61e-485f-a9d1-02eb34f5c6e9.png"></p>',
      __productContentImages__: [],
    },
  ],
};
