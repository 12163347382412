import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const NumberDisplay = ({ value = 1, yearValue = 2026 }) => {
  const [displayedValue, setDisplayedValue] = useState(value);
  const [isAnimating, setIsAnimating] = useState(false);
  const prevValueRef = useRef(value);
  const numberRef = useRef(null);

  useEffect(() => {
    if (value !== prevValueRef.current && !isAnimating) {
      animateValue(
        numberRef,
        value,
        setDisplayedValue,
        prevValueRef,
        setIsAnimating
      );
    }
  }, [value, isAnimating]);

  const animateValue = (ref, newValue, setDisplayed, prevRef, setAnimating) => {
    setAnimating(true);

    gsap.to(ref.current, {
      yPercent: -100,
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        setDisplayed(newValue);
        gsap.fromTo(
          ref.current,
          { yPercent: 100, opacity: 0 },
          {
            yPercent: 0,
            opacity: 1,
            duration: 0.3,
            ease: "power2.out",
            onComplete: () => {
              prevRef.current = newValue;
              setAnimating(false);
            },
          }
        );
      },
    });
  };

  return (
    <Container>
      <Span>Launch of </Span> <Number ref={numberRef}>{displayedValue}</Number>{" "}
      <Span>
        LumirX by <Year>{yearValue}</Year>
      </Span>
    </Container>
  );
};

export default NumberDisplay;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled.div`
  width: 2em;
  text-align: center;
`;

const Span = styled.div`
  /* Add any additional styling here */
`;

const Year = styled.div`
  text-align: center;
  width: 2em; // 4자리 연도를 수용할 수 있도록 너비 증가
  display: inline-block;
`;
