export const data = {
  KR: {
    Y2000: [
      {
        id: 112,
        createdAt: "2024-02-28T17:18:37.196Z",
        updatedAt: "2024-06-10T12:57:44.984Z",
        date: "2009. 10",
        content: "㈜제이엔엠시스템 설립",
        orderNum: 37,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2000",
        language: "KR",
      },
    ],
    Y2010: [
      {
        id: 100,
        createdAt: "2024-02-28T17:14:22.959Z",
        updatedAt: "2024-06-10T12:58:01.412Z",
        date: "2019. 12",
        content: "반사파 기반 C-밴드 고속 빔포밍 모듈 수주(KARI)",
        orderNum: 25,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 101,
        createdAt: "2024-02-28T17:14:34.462Z",
        updatedAt: "2024-06-10T12:58:00.815Z",
        date: "2019. 10",
        content: "EO/IR위성 전력계 전기지상지원장비 수주 (KARI)",
        orderNum: 26,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 102,
        createdAt: "2024-02-28T17:14:47.281Z",
        updatedAt: "2024-06-10T12:58:00.276Z",
        date: "2019. 05",
        content: "기술혁신형 중소기업(Inno-Biz) 인증",
        orderNum: 27,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 103,
        createdAt: "2024-02-28T17:14:58.938Z",
        updatedAt: "2024-06-10T12:57:59.671Z",
        date: "2018. 09",
        content: "기관투자 유치 (Series A)",
        orderNum: 28,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 104,
        createdAt: "2024-02-28T17:15:20.933Z",
        updatedAt: "2024-06-10T12:57:59.095Z",
        date: "2018. 07",
        content: "달 탐사선 탑재용 DTN 탑재체 제작 수주 (ETRI)",
        orderNum: 29,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/59096cbf-5e44-40e6-8ce8-04d046f4c51d.png",
        serverFileName: "59096cbf-5e44-40e6-8ce8-04d046f4c51d.png",
        originFileName:
          "201807_달 탐사선 탑재용 DTN 탑재체 제작 수주 (ETRI) 1 (1).png",
        year: "2010",
        language: "KR",
      },
      {
        id: 105,
        createdAt: "2024-02-28T17:15:42.585Z",
        updatedAt: "2024-06-10T12:57:58.462Z",
        date: "2018. 03",
        content: "국내최초 우주급 SMT절차 인증 취득 (KARI)",
        orderNum: 30,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 106,
        createdAt: "2024-02-28T17:16:57.845Z",
        updatedAt: "2024-06-10T12:57:55.693Z",
        date: "2017. 11",
        content: "다목적실용위성7호 전력계 전기지상지원장비 수주 (KARI)",
        orderNum: 31,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 107,
        createdAt: "2024-02-28T17:17:11.270Z",
        updatedAt: "2024-06-10T12:57:53.859Z",
        date: "2017. 07",
        content: "전문연구요원 지정업체 선정",
        orderNum: 32,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 108,
        createdAt: "2024-02-28T17:17:27.378Z",
        updatedAt: "2024-06-10T12:57:53.135Z",
        date: "2016. 09",
        content: "사명변경 루미르㈜",
        orderNum: 33,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 109,
        createdAt: "2024-02-28T17:17:39.856Z",
        updatedAt: "2024-06-10T12:57:52.428Z",
        date: "2016. 09",
        content: "AS9100C(항공기 및 우주선의 설계, 개발 및 생산) 인증",
        orderNum: 34,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 110,
        createdAt: "2024-02-28T17:17:52.045Z",
        updatedAt: "2024-06-10T12:57:51.577Z",
        date: "2016. 07",
        content: "차세대중형위성 1호, 2호 영상데이터처리장치 수주 (KARI)",
        orderNum: 35,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
      {
        id: 111,
        createdAt: "2024-02-28T17:18:06.356Z",
        updatedAt: "2024-06-10T12:57:45.990Z",
        date: "2012. 06",
        content: "ISO 9001/14001, 기업부설연구소 등록",
        orderNum: 36,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "KR",
      },
    ],
    Y2020: [
      {
        id: 114,
        createdAt: "2024-10-10T12:57:42.035Z",
        updatedAt: "2024-10-10T12:58:21.072Z",
        date: "2024. 10",
        content: "코스닥 상장",
        orderNum: 1,
        exposure: true,
        fileUrl: "",
        serverFileName: "",
        originFileName: "",
        year: "2020",
        language: "KR",
      },
      {
        id: 113,
        createdAt: "2024-06-10T12:57:42.035Z",
        updatedAt: "2024-06-10T12:58:21.072Z",
        date: "2024. 06",
        content: "우수기업연구소 선정",
        orderNum: 1,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/510ce6bc-59af-4bb0-aa19-f92268590cae.jpg",
        serverFileName: "510ce6bc-59af-4bb0-aa19-f92268590cae.jpg",
        originFileName: "우수기업연구소.jpg",
        year: "2020",
        language: "KR",
      },
      {
        id: 77,
        createdAt: "2024-02-28T17:06:45.906Z",
        updatedAt: "2024-06-10T12:58:21.075Z",
        date: "2023. 12",
        content: "대한민국 ICT 대상 디지털 부문 (혁신기술) 선정",
        orderNum: 2,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/66919797-5025-4845-b770-53ea42da9103.png",
        serverFileName: "66919797-5025-4845-b770-53ea42da9103.png",
        originFileName: "202312_대한민국ICT대상디지털부문선정 1.png",
        year: "2020",
        language: "KR",
      },
      {
        id: 78,
        createdAt: "2024-02-28T17:07:10.705Z",
        updatedAt: "2024-06-10T12:58:20.022Z",
        date: "2023. 09",
        content: "방산혁신기업 100 선정",
        orderNum: 3,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/65df33c9-3005-4d15-ba9d-0cf77805331e.png",
        serverFileName: "65df33c9-3005-4d15-ba9d-0cf77805331e.png",
        originFileName: "방산혁신기업100 1.png",
        year: "2020",
        language: "KR",
      },
      {
        id: 79,
        createdAt: "2024-02-28T17:07:24.606Z",
        updatedAt: "2024-06-10T12:58:19.434Z",
        date: "2023. 06",
        content: "기관투자 유치 (Series C-2)",
        orderNum: 4,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 80,
        createdAt: "2024-02-28T17:07:48.050Z",
        updatedAt: "2024-06-10T12:58:18.804Z",
        date: "2023. 05",
        content: "차세대중형위성 5호 C-밴드 영상레이다 수주",
        orderNum: 5,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 81,
        createdAt: "2024-02-28T17:08:09.219Z",
        updatedAt: "2024-06-10T12:58:18.130Z",
        date: "2023. 05",
        content: "누리호 3차 부탑재위성 Lumir-T1 발사 성공",
        orderNum: 6,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3bd232f8-d2e2-4eb6-b42c-5b2cf40fd87e.png",
        serverFileName: "3bd232f8-d2e2-4eb6-b42c-5b2cf40fd87e.png",
        originFileName: "202305_누리호3차부탑재위성Lumir-T1발사성공 1 (1).png",
        year: "2020",
        language: "KR",
      },
      {
        id: 82,
        createdAt: "2024-02-28T17:08:31.253Z",
        updatedAt: "2024-06-10T12:58:17.388Z",
        date: "2023. 05",
        content: "스페이스이노베이션 2단계 사업자 선정",
        orderNum: 7,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 83,
        createdAt: "2024-02-28T17:08:43.319Z",
        updatedAt: "2024-06-10T12:58:16.787Z",
        date: "2023. 04",
        content: "중기부 스케일업 팁스 출연 R&D 선정",
        orderNum: 8,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 84,
        createdAt: "2024-02-28T17:09:02.037Z",
        updatedAt: "2024-06-10T12:58:16.115Z",
        date: "2023. 02",
        content: "중기부 스케일업 팁스 매칭투자기업 선정",
        orderNum: 9,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/b9197ac5-a6e2-496c-b91c-b33cbe05cca1.png",
        serverFileName: "b9197ac5-a6e2-496c-b91c-b33cbe05cca1.png",
        originFileName: "202302_스케일업팁스R&D선정 1 (1).png",
        year: "2020",
        language: "KR",
      },
      {
        id: 85,
        createdAt: "2024-02-28T17:09:23.504Z",
        updatedAt: "2024-06-10T12:58:15.629Z",
        date: "2022. 10",
        content: "누리호 3차 발사 부탑재위성 업체 선정",
        orderNum: 10,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/88a88de1-3914-4a23-be8b-800a40096ad5.png",
        serverFileName: "88a88de1-3914-4a23-be8b-800a40096ad5.png",
        originFileName: "202210_누리호3차발사부탑재위성업체선정 1 (1).png",
        year: "2020",
        language: "KR",
      },
      {
        id: 86,
        createdAt: "2024-02-28T17:09:41.601Z",
        updatedAt: "2024-06-10T12:58:13.088Z",
        date: "2022. 10",
        content: "기관투자 유치 (Series C-1)",
        orderNum: 11,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 87,
        createdAt: "2024-02-28T17:10:27.981Z",
        updatedAt: "2024-06-10T12:58:12.335Z",
        date: "2022. 10",
        content: "소재·부품·장비 전문기업 인증",
        orderNum: 12,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6b59e321-30cb-4789-beb0-987e9171fc40.png",
        serverFileName: "6b59e321-30cb-4789-beb0-987e9171fc40.png",
        originFileName: "image 518.png",
        year: "2020",
        language: "KR",
      },
      {
        id: 88,
        createdAt: "2024-02-28T17:10:56.779Z",
        updatedAt: "2024-06-10T12:58:11.792Z",
        date: "2022. 05",
        content:
          "스페이스이노베이션 사업 초분광,가시광 듀얼센서 카메라를 탑재한 초소형 위성 개발 과제 선정",
        orderNum: 13,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 89,
        createdAt: "2024-02-28T17:11:15.926Z",
        updatedAt: "2024-06-10T12:58:11.178Z",
        date: "2022. 04",
        content:
          "스페이스챌린지 사업 재사용 발사체 고기동 연착륙을 위한 유도항법제어 기술 개발 과제 선정",
        orderNum: 14,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 90,
        createdAt: "2024-02-28T17:11:27.028Z",
        updatedAt: "2024-06-10T12:58:09.925Z",
        date: "2022. 04",
        content:
          "KOITA 기업부설연구소 R&D역량강화 지원사업 초분광 머신비전 시스템 과제 선정",
        orderNum: 15,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 91,
        createdAt: "2024-02-28T17:11:49.456Z",
        updatedAt: "2024-06-10T12:58:09.309Z",
        date: "2021. 10",
        content: "차세대중형위성 3호 탑재컴퓨터 수주(KAI)",
        orderNum: 16,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/5e346533-45d3-4823-8d16-f20aa4fb276e.png",
        serverFileName: "5e346533-45d3-4823-8d16-f20aa4fb276e.png",
        originFileName: "초소형OBC_신제품 1 (2).png",
        year: "2020",
        language: "KR",
      },
      {
        id: 92,
        createdAt: "2024-02-28T17:12:03.678Z",
        updatedAt: "2024-06-10T12:58:08.593Z",
        date: "2021. 10",
        content: "기관투자 유치 (Series B)",
        orderNum: 17,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 93,
        createdAt: "2024-02-28T17:12:15.904Z",
        updatedAt: "2024-06-10T12:58:08.013Z",
        date: "2021. 06",
        content:
          "스페이스파이오니어 사업 고속고기동 위성의 제어모멘트 자이로 과제 선정",
        orderNum: 18,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 94,
        createdAt: "2024-02-28T17:12:45.743Z",
        updatedAt: "2024-06-10T12:58:07.466Z",
        date: "2021. 06",
        content:
          "스페이스파이오니어 사업 Ka밴드 송신기 및 Ka밴드 능동형 어레이 안테나 과제 선정",
        orderNum: 19,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 95,
        createdAt: "2024-02-28T17:12:56.260Z",
        updatedAt: "2024-06-10T12:58:06.856Z",
        date: "2021. 05",
        content:
          "글로벌 ICT 미래 유니콘 육성사업(ICT GROWTH 사업) 참여 기업 선정",
        orderNum: 20,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 96,
        createdAt: "2024-02-28T17:13:10.589Z",
        updatedAt: "2024-06-10T12:58:04.140Z",
        date: "2021. 04",
        content: "뿌리기업 인증",
        orderNum: 21,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 97,
        createdAt: "2024-02-28T17:13:29.689Z",
        updatedAt: "2024-06-10T12:58:03.248Z",
        date: "2020. 09",
        content: "차세대중형위성 4호 영상자료처리장치 수주 (KARI)",
        orderNum: 22,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2087fdbf-615a-4bd5-9312-34715154e79b.png",
        serverFileName: "2087fdbf-615a-4bd5-9312-34715154e79b.png",
        originFileName: "IDHU 1 (1).png",
        year: "2020",
        language: "KR",
      },
      {
        id: 98,
        createdAt: "2024-02-28T17:13:43.155Z",
        updatedAt: "2024-06-10T12:58:02.568Z",
        date: "2020. 05",
        content: "벤처기업 인증",
        orderNum: 23,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
      {
        id: 99,
        createdAt: "2024-02-28T17:13:55.340Z",
        updatedAt: "2024-06-10T12:58:01.981Z",
        date: "2020. 03",
        content: "무인항공기 초분광카메라 수주 (ETRI)",
        orderNum: 24,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "KR",
      },
    ],
  },
  EN: {
    Y2000: [
      {
        id: 73,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2009. 10",
        content: "Establishment of JENM System Co., Ltd",
        orderNum: 36,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2000",
        language: "EN",
      },
    ],
    Y2010: [
      {
        id: 50,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2019. 12",
        content:
          "Contract awarded for a Reflective Wave-Based C-Band High-Speed Beamforming Module (KARI)",
        orderNum: 24,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 51,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2019. 10",
        content:
          "EO/IR satellite power meter electric ground support equipment (KARI)",
        orderNum: 25,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 52,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2019. 05",
        content: "Inno-Biz (Inno-Biz) Certification",
        orderNum: 26,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 57,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2018. 09",
        content: "Attracting Institutional Investment (Series A)",
        orderNum: 27,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 58,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2018. 07",
        content:
          "Contract awarded to manufacture DTN payloads for lunar probe (ETRI)",
        orderNum: 28,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 59,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2018. 03",
        content: "Korea's first space-class SMT procedure certification (KARI)",
        orderNum: 29,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 60,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2017. 11",
        content:
          "Contract awarded for the Electric Ground Support Equipment of Multi-purpose Satellite -7 Power Meter (KARI)",
        orderNum: 30,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 61,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2017. 07",
        content: "Selection of designated professional researchers",
        orderNum: 31,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 63,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2016. 09",
        content: "Name change Lumir Co., Ltd",
        orderNum: 32,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 67,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2016. 09",
        content:
          "AS9100C (Design, Development and Production of Aircraft and Spacecraft) Certification",
        orderNum: 33,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 69,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2016. 07",
        content:
          "Contract awarded for CAS500-1/2 image data processing units (KARI)",
        orderNum: 34,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
      {
        id: 72,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2012. 06",
        content: "ISO 9001/14001, Corporate Research Institute Registration",
        orderNum: 35,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2010",
        language: "EN",
      },
    ],
    Y2020: [
      {
        id: 116,
        createdAt: "2024-10-10T12:57:42.035Z",
        updatedAt: "2024-10-10T12:58:21.072Z",
        date: "2024. 10",
        content: "KOSDAQ Listing",
        orderNum: 1,
        exposure: true,
        fileUrl: "",
        serverFileName: "",
        originFileName: "",
        year: "2020",
        language: "EN",
      },
      {
        id: 115,
        createdAt: "2024-06-10T12:57:42.035Z",
        updatedAt: "2024-06-10T12:58:21.072Z",
        date: "2024. 06",
        content: "Selected as an Excellent Corporate Research Institute",
        orderNum: 1,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/510ce6bc-59af-4bb0-aa19-f92268590cae.jpg",
        serverFileName: "510ce6bc-59af-4bb0-aa19-f92268590cae.jpg",
        originFileName: "우수기업연구소.jpg",
        year: "2020",
        language: "EN",
      },
      {
        id: 53,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-04-19T11:58:17.615Z",
        date: "2023. 12",
        content:
          "Korea's ICT Grand Prize in Digital (Innovative Technology) Selection",
        orderNum: 1,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/13a90b1e-8554-49b4-99bb-d16d79c17298.png",
        serverFileName: "13a90b1e-8554-49b4-99bb-d16d79c17298.png",
        originFileName: "202312_대한민국ICT대상디지털부문선정 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 54,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-04-19T11:58:43.466Z",
        date: "2023. 09",
        content: "100 companies selected for defense innovation",
        orderNum: 2,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e55a6217-645e-483a-a92b-cf36e42f0e6a.png",
        serverFileName: "e55a6217-645e-483a-a92b-cf36e42f0e6a.png",
        originFileName: "방산혁신기업100 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 74,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-28T17:19:10.261Z",
        date: "2023. 06",
        content: "Attracting Institutional Investment (Series C-2)",
        orderNum: 3,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 55,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-28T17:19:16.861Z",
        date: "2023. 05",
        content: "Contract awarded for CAS500-5 C-band image radar",
        orderNum: 4,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 56,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-28T17:19:25.543Z",
        date: "2023. 05",
        content:
          "Nuri's 3rd Launch Secondary Payload Lumir-T1 Launched Successfully",
        orderNum: 5,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/f10341e4-d439-45b7-9f44-c4b162f8cfc8.png",
        serverFileName: "f10341e4-d439-45b7-9f44-c4b162f8cfc8.png",
        originFileName: "202305_누리호3차부탑재위성Lumir-T1발사성공 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 62,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-28T17:19:33.467Z",
        date: "2023. 05",
        content: "Selection of Space Innovation Phase 2 Operators",
        orderNum: 6,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 70,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2023. 04",
        content:
          "R&D selected to appear on the Mid-term and Mid-term Scale-Up Tips",
        orderNum: 7,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 64,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T07:37:52.156Z",
        date: "2023.02",
        content:
          "Mid-term and Mid-term Scale-Up Tips Matching Investment Company Selection",
        orderNum: 8,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/08564471-bd92-458a-8c2c-0705de3b388c.png",
        serverFileName: "08564471-bd92-458a-8c2c-0705de3b388c.png",
        originFileName: "202302_스케일업팁스R&D선정 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 65,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T07:38:09.406Z",
        date: "2022.10",
        content: "Nuri's 3rd launch sub-satellite company was selected",
        orderNum: 9,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/ce1899de-e9f8-4a80-9007-c636861b6d4f.png",
        serverFileName: "ce1899de-e9f8-4a80-9007-c636861b6d4f.png",
        originFileName: "202210_누리호3차발사부탑재위성업체선정 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 66,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2022.10",
        content: "Attracting Institutional Investment (Series C-1)",
        orderNum: 10,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 68,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-04-19T11:59:42.093Z",
        date: "2022.10",
        content:
          "Certification of companies specializing in materials, parts and equipment",
        orderNum: 11,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d493d39b-1e1e-4953-bffc-0fba2d2b468c.png",
        serverFileName: "d493d39b-1e1e-4953-bffc-0fba2d2b468c.png",
        originFileName: "image 518.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 71,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2022.05",
        content:
          "Space Innovation Project Selected tasks for developing ultra-small satellites equipped with dual-sensor cameras in hyperspectral and visible light",
        orderNum: 12,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 39,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2022.04",
        content:
          "Selection of task for development of guided navigation control technology for the soft landing of high-movement projectiles for the Space Challenge Project Reuse Project",
        orderNum: 13,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 40,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2022.04",
        content:
          "KOITA Corporation-Aided Research Institute selected tasks for R&D capability enhancement support project hyperspectral machine vision system",
        orderNum: 14,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 41,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T07:38:31.206Z",
        date: "2021.10",
        content: "Contract awarded for CAS500-3 onboard computer (KAI)",
        orderNum: 15,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/7782e38f-2d76-4e7c-bbb0-d08034e2b802.png",
        serverFileName: "7782e38f-2d76-4e7c-bbb0-d08034e2b802.png",
        originFileName: "초소형OBC_신제품 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 42,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2021.10",
        content: "Attracting Institutional Investment (Series B)",
        orderNum: 16,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 43,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2021.06",
        content:
          "The selection of gyro tasks for the control moment of the high-speed high-speed high-altitude satellite in the Space Pioneer business",
        orderNum: 17,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 44,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2021.06",
        content:
          "Space Pioneer Business Ka Band Transmitter and Ka Band Active Array Antenna Task Selection",
        orderNum: 18,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 45,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2021.05",
        content:
          "Selection of companies participating in the Global ICT Future Unicorn Promotion Project (ICT GROWTH Project)",
        orderNum: 19,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 46,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2021.04",
        content: "Root company certification",
        orderNum: 20,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 47,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T07:38:52.454Z",
        date: "2020.09",
        content:
          "Contract awarded for CAS500-4 image data processing unit (KARI)",
        orderNum: 21,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c1941f7e-b76a-433a-ad93-163c6c3c5fa3.png",
        serverFileName: "c1941f7e-b76a-433a-ad93-163c6c3c5fa3.png",
        originFileName: "IDHU 1.png",
        year: "2020",
        language: "EN",
      },
      {
        id: 48,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T04:38:55.730Z",
        date: "2020.05",
        content: "Venture Business Certification",
        orderNum: 22,
        exposure: true,
        fileUrl: null,
        serverFileName: null,
        originFileName: null,
        year: "2020",
        language: "EN",
      },
      {
        id: 49,
        createdAt: "2024-02-26T04:38:55.730Z",
        updatedAt: "2024-02-26T07:39:05.752Z",
        date: "2020.03",
        content: "Contract awarded for UAV Ultra-Spectral Camera (ETRI)",
        orderNum: 23,
        exposure: true,
        fileUrl:
          "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/ef9f3912-f2c4-417c-8e70-38f3b509e1f1.png",
        serverFileName: "ef9f3912-f2c4-417c-8e70-38f3b509e1f1.png",
        originFileName:
          "201807_달 탐사선 탑재용 DTN 탑재체 제작 수주 (ETRI) 1.png",
        year: "2020",
        language: "EN",
      },
    ],
  },
};
