export const languageType = ["KR", "EN"];

export const brochureLang = {
  brochure: {
    KR: "브로슈어",
    EN: "Brochure",
  },
  video: {
    KR: "영상 갤러리",
    EN: "Video Gallery",
  },
  empty: {
    KR: "등록된 게시물이 없습니다.",
    EN: "There are no registered posts.",
  },
  searchEmpty: {
    KR: "검색결과가 없습니다.",
    EN: "No search results found.",
  },
};
