import styled from "styled-components";
import Layout from "../components/Layout";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import BigImgContents from "../components/BigImgContents";
import DescriptDevice from "../components/DescriptDevice";
import DescriptImg from "../components/DescriptImg";
import { root } from "../../../../styles/GlobalStyle";

const data = {
  bigImg: [
    {
      title: "X-Band 하향링크모듈 전기지상지원장비",
      content:
        "X-Band 하향링크모듈(XDM: X-band Downlink Module) 및 XDM 구성 장치의 테스트를 수행하고, 통합 테스트에서 XDM 출력 RF 수신 및 해석을 수행하는 지상지원장비입니다.",
      image: "/assets/imgs/renewal/business/XdmEgse/main.png",
    },
  ],
  deviceList: [
    {
      title: "장비의 구성",
      content:
        "위성 총조립/시험 및 발사장 작업 단계에서 탑재체 자료전송 부체계(PDTS, Payload Data Transmission Subsystem)를 구성하는 XDM(XTX, MAS, XAA)의 신속한 검증에 사용할 수 있도록 하드웨어 및 소프트웨어 를 조합해서 구성하고 있습니다. 연결도구는 열진공, 전자파 등 환경시험에 사용되는 것으로 XDM에 연결되는 유(또는 무)선 RF신호 수집 장치 및 케이블이 포함되어 있습니다.",
    },
    {
      title: "장비의 기능",
      content: [
        "신호 모사 및 주파수, 스펙트럼, 변복조 등 XDM 주요 사양의 실시간 중앙 모니터링 및 처리(시각화, 캡처, 보관 등) 기능이 구비되어 있으며, XDM 단위 검증을 위한 XDM 입력(IDHU 출력)의 모사가 가능합니다.",
        "XDM 출력의 실시간 수신 및 임무(20개 이상) 시험 영상 자료(raw data, processed data) 저장이 가능하며, 저장 용량을 초과하는 경우, 이전 시험 자료의 백업 수단이 구비되어 있습니다. XTX, MAS, XAA의 구동(작동), 제어 및 상태 감시가 가능하며, 패킷, 압축, 암호화, 부호화 등 주요 영상 규격 및 성능의 적절성 비교와 고속(위성 총 조립/시험 일정 영향을 최소화하도록 성능 설정) 분석이 가능합니다.",
        "XDM EGSE는 X-밴드 안테나 장치 XAA 구동에 필요한 TPF(Tracking Parameter Files) 를 안테나 구동제어기 APD에 전달하고, TPF 지시에 따른 안테나 지향 장치 APM의 적절한 구동 여부를 감시할 수 있습니다. 자료전송 부체계 설계에 따른 중요 영상규격의(압축, 암호, 변조, 부호화 등) 하드웨어 및 소프트웨어 모사 (복원 포함) 기능을 포함하고 있습니다.",
      ].join("<br/><br/>"),
    },
  ],
  desctiptImg: [
    {
      title: "XDM-EGSE의 제원",
      src: "/assets/imgs/renewal/business/XdmEgse/item.png",
    },
  ],
};

const XdmEgse = () => {
  return (
    <Layout
      bgColor={data.bgColor}
      title={"XDM-EGSE"}
      subTitle={"X-Band 하향링크모듈<br/>전기지상지원장비"}
    >
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <DescriptDevice deviceList={data.deviceList} />
        <DescriptImg imageList={data.desctiptImg} />
      </ContentsWrapper>
    </Layout>
  );
};

export default XdmEgse;

const ContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;
