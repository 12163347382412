// tokenManager.js
import { AuthAPI } from "@lumir-company/admin-server-api";
import { getCookie, setCookie } from "../../util/cookies";

class TokenManager {
  constructor() {
    this.isRefreshing = false;
    this.refreshSubscribers = [];
    this.baseUrl = process.env.REACT_APP_API_SDK_URL;
  }

  // 현재 토큰 가져오기
  getToken() {
    return getCookie("accessToken");
  }

  // 토큰 갱신 구독
  subscribeTokenRefresh(callback) {
    this.refreshSubscribers.push(callback);
  }

  // 토큰 갱신 후 구독자들에게 새 토큰 전달
  onTokenRefreshed(newToken) {
    this.refreshSubscribers.forEach((callback) => callback(newToken));
    this.refreshSubscribers = [];
  }

  // 새로운 토큰 발급
  async refreshToken() {
    if (this.isRefreshing) {
      return new Promise((resolve) => {
        this.subscribeTokenRefresh((token) => {
          resolve(token);
        });
      });
    }

    this.isRefreshing = true;

    try {
      const authApi = new AuthAPI(this.baseUrl);
      const response = await authApi.login({
        accountId: process.env.REACT_APP_ACCOUNT_ID,
        password: process.env.REACT_APP_ACCOUNT_PW,
      });

      const newToken = response.accessToken;
      setCookie("accessToken", newToken, 0.5);
      this.onTokenRefreshed(newToken);

      return newToken;
    } catch (error) {
      this.refreshSubscribers = [];
      throw error;
    } finally {
      this.isRefreshing = false;
    }
  }
}

export const tokenManager = new TokenManager();
