import React from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";
import VideoHandler from "../../home/renewal/common/VideoHandler";

const ContentsLayout = ({ data }) => {
  const { isMobile, isDesktop } = useDisplay();
  return (
    <LayoutContainer id={data.id} $isDesktop={isDesktop}>
      <LayoutWidth>
        <Title $isMobile={isMobile}>{data.title}</Title>
        <SubTitle>{data.subTitle}</SubTitle>
        <Description>{data.description}</Description>
        <VideoHandler
          mediaUrl={isDesktop ? data.video.desktop : data.video.mobile}
          imageUrl={data.image}
        />
      </LayoutWidth>
    </LayoutContainer>
  );
};

export default ContentsLayout;

const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${(props) => (props.$isDesktop ? "0 10rem" : "0 5rem")};
`;

const LayoutWidth = styled.div`
  width: 100%;
  max-width: 1400px;
`;

const Title = styled.div`
  font-size: ${({ $isMobile }) => ($isMobile ? "4rem" : "4.8rem")};
  font-weight: 700;
  margin-bottom: 2.6rem;
`;

const SubTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.9rem;
`;

const Description = styled.div`
  font-size: 2rem;
  font-weight: 400;
  margin-top: 2rem;
  color: #8a8a8a;
  margin-bottom: 5.2rem;
`;
