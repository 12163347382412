import React, { useEffect, useState } from "react";
import MainSection from "../../../../../components/templates/mainSection";
import MiddleNavBar from "../../../../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../../../../components/atoms/text/pretendardText";
import FramerFadeIn from "../../../../../styles/framerFadeIn";
import useDisplay from "../../../../../hooks/useDisplay";
import { getProduct } from "../../../../../util/getProduct";
import { langState } from "../../../../../store/langState";
import { useRecoilValue } from "recoil";
import { mainBannerLang } from "../../../../../lang/lang";
import TypeA from "../../../../../components/templates/product/typeA";
import TypeB from "../../../../../components/templates/product/typeB";
import TypeC from "../../../../../components/templates/product/typeC";
import { lunarExplorationData } from "./data";

const LunarExploration = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [productName, setProductName] = useState("");
  const [pageName, setPageName] = useState("");
  const [img, setImg] = useState(false);
  const [subTitle, setSubTitle] = useState("");
  const [sortedPageName, setSortedPageName] = useState([]);

  const getProductFunc = async () => {
    // const response = await getProduct(langValue === "KR" ? 2 : 9);

    const response = lunarExplorationData;

    setImg(response?.fileUrl);
    setSubTitle(response?.subtitle);
    setProductName(response.productName);
    setPageName(response.pageName);

    sortAndSave(response);
  };

  const sortAndSave = async (response) => {
    const sortedComponentName = [];

    const sortedContent = response?.__productContents__?.sort(
      (a, b) => a.orderNum - b.orderNum
    );

    sortedContent?.map((item) => {
      switch (item.productContentType) {
        case "A":
          sortedComponentName.push({
            pageType: TypeA,
            title: item.title,
            content: item.content,
          });
          break;
        case "B":
          sortedComponentName.push({
            pageType: TypeB,
            title: item.title,
            content: item.content,
            fileUrl: item.fileUrl,
          });
          break;
        case "C":
          sortedComponentName.push({
            pageType: TypeC,
            imageSet: item.__productContentImages__,
          });
          break;
      }
    });

    if (sortedComponentName.length > 0) {
      setSortedPageName(sortedComponentName);
    }
  };

  useEffect(() => {
    getProductFunc();
  }, []);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_lunar_exploration.png"
            : "/assets/imgs/main-section/lunar_exploration.png"
        }
        title={pageName}
        content={mainBannerLang.lunarExploration?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
        <ImgWrapper
          $isMobile={isMobile}
          style={{ marginBottom: isMobile ? "15px" : "10px" }}
        >
          <FramerFadeIn duration={1.5}>
            <img
              src={img ? img : "/assets/imgs/business/heavy_satellite5.png"}
              alt=""
              style={{ width: isMobile ? "115.8px" : "" }}
            />
          </FramerFadeIn>
        </ImgWrapper>
        <Title $isMobile={isMobile} $isTablet={isTablet}>
          {productName}
        </Title>
        <SubTitleWrapper $isMobile={isMobile} $isTablet={isTablet}>
          {subTitle}
        </SubTitleWrapper>
        {sortedPageName.map((Item, index) => {
          const PageComponent = Item.pageType;
          return (
            <PageComponent
              key={index}
              title={Item.title}
              content={Item.content}
              fileUrl={Item.fileUrl ? Item.fileUrl : null}
              imageSet={Item.imageSet ? Item.imageSet : null}
            />
          );
        })}
      </PageWrapper>
    </>
  );
};

export default LunarExploration;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const ImgWrapper = styled.div`
  width: ${(props) => (props.$isMobile ? "150px" : "250px")};
  height: ${(props) => (props.$isMobile ? "150px" : "250px")};
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #adb4ba;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) =>
    props.$isMobile ? "20px" : props.$isTablet ? "24px" : "34px"};
  font-weight: ${(props) => (props.$isMobile ? 600 : 500)};
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.2px" : "-0.68px")};
  margin-bottom: 10px;
  text-align: center;
`;

const SubTitleWrapper = styled.div`
  padding: 8px 15px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) =>
    props.$isMobile ? "15px" : props.$isTablet ? "18px" : "22px"};
  font-weight: 400;
  line-height: 140%;
  color: #5a636a;
  margin-bottom: ${(props) => (props.$isMobile ? "40px" : "36px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;
