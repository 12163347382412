import React, { useRef } from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

const MenuItem = ({
  onClick,
  label = "",
  link = "#",
  img = "",
  bgColor = "black",
}) => {
  const { isMobile, isDesktop } = useDisplay();
  const imgRef = useRef(null);

  return (
    <>
      <Container className="custom-cursor">
        {!isDesktop ? (
          <LinkItemMobile onClick={onClick} $bgColor={bgColor}>
            <LabelMobile $isMobile={isMobile}>{label}</LabelMobile>
            <Arrow $bgColor={bgColor}>&#8594;</Arrow>
          </LinkItemMobile>
        ) : (
          <>
            <LinkItem onClick={onClick} $bgColor={bgColor}>
              <Label>{label}</Label>
              <Arrow $bgColor={bgColor}>&#8594;</Arrow>
            </LinkItem>
            <Image src={img} alt="" ref={imgRef} />
          </>
        )}
      </Container>
    </>
  );
};

export default MenuItem;

const Container = styled.div`
  position: relative;
`;

const LinkItemMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  padding: 20px 0;
  border-bottom: 1px solid #000;
  transition: 0.4s;
  color: ${({ $bgColor }) => $bgColor};
`;

const LinkItem = styled(LinkItemMobile)`
  color: ${({ $bgColor }) => $bgColor};
  transition: color 0s, box-shadow 0.4s ease;

  ${Container}:hover & {
    color: white;
    background: transparent;
    box-shadow: ${({ $bgColor }) => `inset 0 125px 0 0 ${$bgColor}`};
  }
`;

const LabelMobile = styled.span`
  font-size: ${({ $isMobile }) => ($isMobile ? "5rem" : "7rem")};
  font-weight: 700;
  /* color: white; 글자 색상 (투명하게 보이도록 흰색으로 설정) */
  /* -webkit-text-stroke: 2px black; 테두리 두께 및 색상 설정 */
  padding-left: 10px;
  transition: 0.5s;
`;

const Label = styled(LabelMobile)`
  ${Container}:hover & {
    transform: translateX(20px);
  }
`;

const Arrow = styled.span`
  font-size: 7rem;
  font-weight: 700;
  color: ${({ $bgColor }) =>
    $bgColor}; // white; /* 글자 색상 (투명하게 보이도록 흰색으로 설정) */
  /* -webkit-text-stroke: ${({ $bgColor }) =>
    `2px ${$bgColor}`}; 테두리 두께 및 색상 설정 */
  transform: rotate(-45deg);

  transition: 0.5s;

  ${Container}:hover & {
    color: white;
    transform: translateX(-20px);
  }
`;

const Image = styled.img`
  max-height: 300px;
  max-width: 400px;
  min-height: 300px;
  min-width: auto;
  width: auto; /* 가로 길이 자동 조정 */
  height: auto; /* 세로 길이 자동 조정 */
  //비율 유지
  object-fit: contain;

  position: absolute;
  top: -100px;
  right: 10rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;

  ${Container}:hover & {
    opacity: 1;
  }
`;
