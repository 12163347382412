import React from "react";
import ContentBox from "../../../../../components/templates/contentBox";
import styled from "styled-components";
import PretendardText from "../../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../../hooks/useDisplay";
import { langState } from "../../../../../store/langState";
import { useRecoilValue } from "recoil";
import { xbandLang } from "../../../../../lang/Business/xband";
import { titleLang } from "../../../../../lang/Business/title";

const ProductDetails = () => {
  const { isMobile } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <ContentBox
        title={titleLang.productDetails[langValue]}
        content={
          <ContentText $isMobile={isMobile}>{xbandLang.productDetails[langValue]}</ContentText>
        }
      />
      <img
        src={isMobile ? "/assets/imgs/business/m_xband2.png" : "/assets/imgs/business/xband2.png"}
        alt=""
        style={{ marginTop: "30px" }}
      />
    </>
  );
};

export default ProductDetails;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;
