import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import PretendardText from "../../components/atoms/text/pretendardText";

import RecruitApi from "../../api/recruitApi";
import useDisplay from "../../hooks/useDisplay";
import PreparedModal from "../modal/preparedModal";

import { jobAnnounceLang } from "../../lang/recruit/jobAnnounceLang";
import { route } from "../../router/route";
import { langState } from "../../store/langState";

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PretendardText
        style={{
          fontSize: "20px",
          fontWeight: 600,
          color: "#262C31",
        }}
      >
        Loading...
      </PretendardText>
    </div>
  );
};

const JobAnnounceDetail = () => {
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const { id } = useParams();

  const langValue = useRecoilValue(langState);

  const [isPreparedModal, setIsPreparedModal] = useState(false);
  const [isEndModal, setIsEndModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataInfo, setDataInfo] = useState();
  const [preDataInfo, setPreDataInfo] = useState();
  const [nextDataInfo, setNextDataInfo] = useState();

  const isShowDate =
    dataInfo?.start && dataInfo?.end && dataInfo?.recruitStatus === "채용 중"
      ? true
      : false;

  useEffect(() => {
    getRecruitDetailAPI();
  }, [id, langValue]);

  const getRecruitDetailAPI = async () => {
    setIsLoaded(false);
    try {
      const response = await RecruitApi.GetDetail(id);
      const nowData = response.data.data.now;

      setDataInfo(nowData);
      setPreDataInfo(response.data.data.prev);
      setNextDataInfo(response.data.data.next);
    } catch (e) {
      console.error("Failed to fetch recruit detail:", e);
      // 에러 처리 로직을 추가할 수 있습니다. 예: 에러 메시지 표시
    }
    setIsLoaded(true);
  };

  if (!isLoaded) return <Loading />;

  return (
    <>
      <div
        style={{
          padding: isMobile
            ? "80px 20px"
            : isTablet
            ? "100px 70px"
            : "120px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {dataInfo ? (
          <div
            style={{
              maxWidth: 1400,
              width: isSmall ? "100%" : "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isSmall ? "column" : "row",
                alignItems: isSmall ? "flex-start" : "center",
                marginBottom: isSmall ? 0 : 24,
              }}
            >
              {dataInfo.recruitStatus !== "채용 마감" ? (
                <div
                  style={{
                    width: isSmall ? 66 : 92,
                    height: isSmall ? 28 : 36,
                    borderRadius: 5,
                    border: "1px solid #00AEEF",
                    marginRight: isSmall ? 5 : 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {dataInfo.permanent ? (
                    <PretendardText
                      style={{
                        color: "#00AEEF",
                        fontSize: isSmall ? "12px" : "18px",
                        fontWeight: isSmall ? 500 : 600,
                        lineHeight: isSmall ? "16.2px" : "26.1px",
                      }}
                    >
                      {jobAnnounceLang.isFullTime[langValue]}
                    </PretendardText>
                  ) : (
                    <PretendardText
                      style={{
                        fontSize: isSmall ? "12px" : "18px",
                        fontWeight: isSmall ? 500 : 600,
                        lineHeight: isSmall ? "16.2px" : "26.1px",
                        color: "#00AEEF",
                      }}
                    >
                      {jobAnnounceLang.hiring[langValue]}
                    </PretendardText>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: isSmall ? 66 : 92,
                    height: isSmall ? 28 : 36,
                    borderRadius: 5,
                    border: "1px solid #ADB4BA",
                    marginRight: isSmall ? 5 : 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: isSmall ? "12px" : "18px",
                      fontWeight: isSmall ? 500 : 600,
                      lineHeight: isSmall ? "16.2px" : "26.1px",
                      color: "#ADB4BA",
                    }}
                  >
                    {jobAnnounceLang.end[langValue]}
                  </PretendardText>
                </div>
              )}
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "22px" : "28px",
                  fontWeight: 500,
                  lineHeight: isSmall ? "31.9px" : "40.6px",
                  marginTop: isSmall ? 20 : 0,
                }}
              >
                {dataInfo.title}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  color: isSmall ? "#5A636A" : "#778088",
                  fontSize: isSmall ? "12px" : "20px",
                  fontWeight: isSmall ? 500 : 600,
                  lineHeight: isSmall ? "16.2px" : "29px",
                  marginRight: isSmall ? 5 : 10,
                }}
              >
                {dataInfo.department}
              </PretendardText>

              {isShowDate && (
                <PretendardText
                  style={{
                    color: "#ADB4BA",
                    fontSize: isSmall ? "12px" : "20px",
                    fontWeight: isSmall ? 500 : 600,
                    lineHeight: isSmall ? "16.2px" : "29px",
                  }}
                >
                  {/* {moment.utc(dataInfo.createdAt).format("YYYY.MM.DD")} */}
                  {dataInfo.start} ~ {dataInfo.end}
                </PretendardText>
              )}
            </div>

            <div
              style={{
                width: "100%",
                borderBottom: "1px solid #ADB4BA",
                marginTop: 26,
                marginBottom: 25,
              }}
            />

            <div
              style={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
                height: "100%",
                marginBottom: 25,
                textAlign: "center",
              }}
            >
              <a href="https://app.lumir.space/lrim/apply" target="_blank">
                <img
                  src="/assets/imgs/job-announce/상하단입사지원.jpg"
                  width={"100%"}
                  alt="이미지 설명"
                />
              </a>
              <div
                className="ql-editor"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <img src={dataInfo.content} alt="recruit info" width={"100%"} />
              </div>
              <a href="https://app.lumir.space/lrim/apply" target="_blank">
                <img
                  src="/assets/imgs/job-announce/상하단입사지원.jpg"
                  width={"100%"}
                  alt="이미지 설명"
                />
              </a>
            </div>

            {/* {preDataInfo ? (
              <div
                onClick={() => {
                  navigate(`${route.jobAnnounceDetail}/${preDataInfo.id}`);
                }}
                style={{
                  height: isSmall ? 63 : 80,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  borderTop: "1px solid #ADB4BA",
                  borderBottom: "1px solid #ADB4BA",
                  cursor: "pointer",
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: isSmall ? "16px" : "18px",
                    fontWeight: 600,
                    lineHeight: isSmall ? "23.2px" : "26.1px",
                    width: isSmall ? 50 : 95,
                    borderRight: "1px solid #ADB4BA",
                  }}
                >
                  Prev
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: isSmall ? "16px" : "18px",
                    fontWeight: isSmall ? 400 : 600,
                    lineHeight: isSmall ? "23.2px" : "26.1px",
                    marginLeft: isSmall ? 15 : 20,
                  }}
                >
                  {preDataInfo ? preDataInfo.title : ""}
                </PretendardText>
              </div>
            ) : null}

            {nextDataInfo ? (
              <div
                onClick={() => {
                  navigate(`${route.jobAnnounceDetail}/${nextDataInfo.id}`);
                }}
                style={{
                  height: isSmall ? 63 : 80,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  borderBottom: "1px solid #ADB4BA",
                  borderTop: preDataInfo ? "none" : "1px solid #ADB4BA",
                  cursor: "pointer",
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: isSmall ? "16px" : "18px",
                    fontWeight: 600,
                    lineHeight: isSmall ? "23.2px" : "26.1px",
                    width: isSmall ? 50 : 95,
                    borderRight: "1px solid #ADB4BA",
                  }}
                >
                  Next
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: isSmall ? "16px" : "18px",
                    fontWeight: isSmall ? 400 : 600,
                    lineHeight: isSmall ? "23.2px" : "26.1px",
                    marginLeft: isSmall ? 15 : 20,
                  }}
                >
                  {nextDataInfo ? nextDataInfo.title : ""}
                </PretendardText>
              </div>
            ) : null} */}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: 40,
              }}
            >
              <ListBtn
                $isSmall={isSmall}
                onClick={() => {
                  navigate(`${route.jobAnnounce}/1`);
                }}
              >
                <PretendardText
                  style={{
                    color: "#2F3941",
                    fontSize: isSmall ? "15px" : "18px",
                    fontWeight: 600,
                    lineHeight: isSmall ? "21px" : "26.1px",
                  }}
                >
                  {jobAnnounceLang.list[langValue]}
                </PretendardText>
              </ListBtn>
            </div>
          </div>
        ) : null}
      </div>
      {isEndModal ? (
        <PreparedModal
          isScroll={true}
          title={jobAnnounceLang.isEndModal[langValue]}
          onClick={() => {
            setIsEndModal(false);
          }}
        />
      ) : null}
      {isPreparedModal ? (
        <PreparedModal
          isScroll={true}
          title={jobAnnounceLang.preparedModal[langValue]}
          onClick={() => {
            setIsPreparedModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default JobAnnounceDetail;

const ListBtn = styled.button`
  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
