import React from "react";
import FramerSlideIn from "../../../styles/framerSlideIn";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import PretendardText from "../../atoms/text/pretendardText";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper/modules";

const ServiceScopeSwiper = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '">' + "</div>";
    },
  };

  const { isMobile, isTablet, isCard } = useDisplay();

  return isCard ? (
    <Swiper
      style={{
        height: "400px",
      }}
      spaceBetween={20}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={pagination}
      modules={[Autoplay, Pagination]}
      className="keymember"
    >
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope7.png" alt="" />
          <ContentText>Reflow MC</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope8.png" alt="" />
          <ContentText>Thermal Cycling Chanber</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope9.png" alt="" />
          <ContentText>Forming</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope10.png" alt="" />
          <ContentText>Mounter</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope11.png" alt="" />
          <ContentText>X-RAY 3D CT System</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope12.png" alt="" />
          <ContentText>Wire Bonder</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope13.png" alt="" />
          <ContentText>Rework MC</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope14.png" alt="" />
          <ContentText>Drying Oven</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope15.png" alt="" />
          <ContentText>Screen & Printing</ContentText>
        </ImgContainer>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer>
          <img src="/assets/imgs/business/scope16.png" alt="" />
          <ContentText>Stir & Defoam</ContentText>
        </ImgContainer>
      </SwiperSlide>
    </Swiper>
  ) : (
    <>
      <ImgWrapper>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope7.png" alt="" />
            <ContentText>Reflow MC</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope8.png" alt="" />
            <ContentText>Thermal Cycling Chanber</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope9.png" alt="" />
            <ContentText>Forming</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope10.png" alt="" />
            <ContentText>Mounter</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope11.png" alt="" />
            <ContentText>X-RAY 3D CT System</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope12.png" alt="" />
            <ContentText>Wire Bonder</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope13.png" alt="" />
            <ContentText>Rework MC</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope14.png" alt="" />
            <ContentText>Drying Oven</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope15.png" alt="" />
            <ContentText>Screen & Printing</ContentText>
          </ImgContainer>
        </FramerSlideIn>
        <FramerSlideIn>
          <ImgContainer>
            <img src="/assets/imgs/business/scope16.png" alt="" />
            <ContentText>Stir & Defoam</ContentText>
          </ImgContainer>
        </FramerSlideIn>
      </ImgWrapper>
    </>
  );
};

export default ServiceScopeSwiper;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const ContentText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  color: #262c31;
  letter-spacing: -0.18px;
`;
