export const constellationLang = {
  title1: {
    KR: "탐구의 여정",
    EN: "Journey of Inquiry",
  },
  content1: {
    KR: "루미르는 올드스페이스, 뉴스페이스의 사업 분야 뿐 아니라 새로운 비즈니스인 Data Fusion까지 도달합니다.\nLumir-X와 Lumir-V 위성의 데이터를 융합하여 높은 공간 및 분광 해상도 영상과 정보를 생성합니다.\n루미르가 개발한 많은 위성들을 통해 우주의 ‘별자리’를 형성하며 탐사 및 연구합니다.",
    EN: "Lumir reaches a new business, Data Fusion, as well as the businesses of Old Space and Newspace.\nIt fuses data from Lumir-X and Lumir-V satellites to produce high-space and spectral resolution images and information.\nIt explores and researches the 'constellations' of the universe through many satellites developed by Lumir.",
  },
  content2: {
    KR: "루미르는 Milestone을 통해 정확한 모니터링을 진행하고\n우주에서도 지구의 변화를 빠르게 파악하고 대응하여 인류에 공헌하고자 합니다.\nLumir-X, Lumir-V의 결합된 위성 데이터를 통해 환경, 도시 등 다양한 분야에서 활용 예정입니다.",
    EN: "Lumir conducts accurate monitoring through Milestone\nWe want to contribute to humanity by quickly grasping and responding to changes on Earth in space.\nThe combined satellite data of Lumir-X and Lumir-V will be used in various fields such as environment and cities.",
  },
};
