import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "./../../../components/templates/middleNavBar";
import BodyConatin from "../../../components/molecules/layout/bodyContain";

import PreparedModal from "../../modal/preparedModal";

const StockInfo = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <MainSection
        bg={"/assets/imgs/main-section/stock.png"}
        title="Stock Information"
        content="Check the current stock price status."
        blackText={true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin />

      <PreparedModal
        onClick={() => {
          navigate(-1);
        }}
      />
    </>
  );
};

export default StockInfo;
