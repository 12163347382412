import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  forwardRef,
} from "react";
import styled from "styled-components";

const ProgressBar = ({ progress, onProgressChange }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFill $progress={progress} />
      <ProgressInput
        type="range"
        min="0"
        max="100"
        step="0.0001"
        value={progress}
        onChange={onProgressChange}
      />
    </ProgressBarContainer>
  );
};

const MainVideo = forwardRef(
  ({ videoSrc, fallbackImageSrc, isDesktop }, ref) => {
    const videoRef = useRef(null);
    const animationRef = useRef(null);
    const [videoState, setVideoState] = useState({
      isLoaded: false,
      isPlaying: false,
      isMetadataLoaded: false,
      showPlayButton: false,
      progress: 0,
      duration: 0,
    });
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isVideoSupported, setIsVideoSupported] = useState(true);

    const updateVideoState = useCallback((newState) => {
      setVideoState((prevState) => ({ ...prevState, ...newState }));
    }, []);

    const handlePlay = useCallback(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement
          .play()
          .then(() =>
            updateVideoState({ showPlayButton: false, isPlaying: true })
          )
          .catch((error) => {
            console.error("Play failed:", error);
            updateVideoState({ showPlayButton: true });
          });
      }
    }, [updateVideoState]);

    const animateProgress = useCallback(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        const progress =
          (videoElement.currentTime / videoElement.duration) * 100;
        updateVideoState({ progress: parseFloat(progress.toFixed(4)) });
        animationRef.current = requestAnimationFrame(animateProgress);
      }
    }, [updateVideoState]);

    const handleVideoEvent = useCallback(
      (event) => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        switch (event.type) {
          case "loadedmetadata":
            updateVideoState({
              isMetadataLoaded: true,
              duration: videoElement.duration,
            });
            break;
          case "canplaythrough":
            updateVideoState({ isLoaded: true });
            handlePlay();
            break;
          case "play":
            updateVideoState({ isPlaying: true, showPlayButton: false });
            animationRef.current = requestAnimationFrame(animateProgress);
            break;
          case "pause":
            updateVideoState({ isPlaying: false, showPlayButton: true });
            cancelAnimationFrame(animationRef.current);
            break;
          case "ended":
            updateVideoState({
              isPlaying: false,
              showPlayButton: true,
              progress: 100,
            });
            cancelAnimationFrame(animationRef.current);
            break;
          default:
            console.log(`Unhandled video event: ${event.type}`);
        }
      },
      [handlePlay, updateVideoState, animateProgress]
    );

    useEffect(() => {
      const handleLoad = () => setIsPageLoaded(true);
      if (document.readyState === "complete") {
        handleLoad();
      } else {
        window.addEventListener("load", handleLoad);
        return () => window.removeEventListener("load", handleLoad);
      }
    }, []);

    useEffect(() => {
      if (!isPageLoaded) return;

      const videoElement = videoRef.current;

      if (videoElement) {
        setIsVideoSupported(videoElement.canPlayType("video/mp4") !== "");

        if (isVideoSupported) {
          const events = [
            "loadedmetadata",
            "canplaythrough",
            "play",
            "pause",
            "ended",
          ];
          events.forEach((event) =>
            videoElement.addEventListener(event, handleVideoEvent)
          );

          videoElement.preload = "metadata";
          videoElement.load();

          return () => {
            events.forEach((event) =>
              videoElement.removeEventListener(event, handleVideoEvent)
            );
            cancelAnimationFrame(animationRef.current);
          };
        }
      }
    }, [handleVideoEvent, isPageLoaded, isVideoSupported]);

    const handleProgressChange = (e) => {
      const video = videoRef.current;
      if (!video) return;

      const newTime = (parseFloat(e.target.value) / 100) * video.duration;
      video.currentTime = newTime;
      updateVideoState({ progress: parseFloat(e.target.value) });
    };

    return (
      <VideoContainer ref={ref} $isDesktop={isDesktop}>
        <BackgroundMedia
          style={{ backgroundImage: `url(${fallbackImageSrc})` }}
        />
        {!isPageLoaded && <LoadingIndicator>Loading...</LoadingIndicator>}
        {isPageLoaded && isVideoSupported && (
          <>
            <VideoElement
              ref={videoRef}
              loop
              muted
              playsInline
              autoPlay
              preload="metadata"
              poster={fallbackImageSrc}
              style={{ opacity: videoState.isPlaying ? 1 : 0 }}
            >
              <source src={videoSrc} type="video/mp4" />
            </VideoElement>
            {videoState.showPlayButton && (
              <PlayButton onClick={handlePlay} aria-label="Play video">
                ▶
              </PlayButton>
            )}
          </>
        )}
        {!isVideoSupported && (
          <FallbackContent>
            <p>
              Your browser does not support the video tag. Please upgrade your
              browser to view this content.
            </p>
          </FallbackContent>
        )}
        {videoState.isMetadataLoaded && (
          <ProgressBar
            progress={videoState.progress}
            onProgressChange={handleProgressChange}
          />
        )}
      </VideoContainer>
    );
  }
);

MainVideo.displayName = "MainVideo";

export default React.memo(MainVideo);

const VideoContainer = styled.div`
  position: relative;
  top: ${(props) => (props.$isDesktop ? "-80px" : "-65px")};
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackgroundMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease-in-out;
`;

const VideoElement = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
`;

const FallbackContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);

  p {
    text-align: center;
    color: #fff;
    font-size: 16px;
    padding: 20px;
  }
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const ProgressBarFill = styled.div.attrs((props) => ({
  style: {
    width: `${props.$progress}%`,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: white;
`;

const ProgressInput = styled.input`
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 12px;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1px;
    height: 12px;
  }

  &::-moz-range-thumb {
    width: 1px;
    height: 12px;
    border: none;
    background: transparent;
  }
`;
