import React from "react";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import ReportDLCard from "../../molecules/investment/reportDLCard";
import { langState } from "../../../store/langState";
import { shareholderLang } from "../../../lang/investment/shareholderLang";
import { fileDownload } from "../../../util/file-management";

const ShareholdersM = ({ data }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <div
      style={{
        border: "1px solid #ADB4BA",
        padding: "16px 14px 15px 15px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 16,
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              marginBottom: 15,
            }}
          >
            {shareholderLang.meetingSummary[langValue]}
          </PretendardText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "18.9px",
                marginBottom: 2,
              }}
            >
              {shareholderLang.meetingPlace[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18.9px",
              }}
            >
              {data ? data.place : ""}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 8,
            }}
          >
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "18.9px",
                marginBottom: 2,
              }}
            >
              {shareholderLang.meetingDate[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                color: "#778088",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18.9px",
              }}
            >
              {data ? data.meetingDate : ""}
            </PretendardText>
          </div>
        </div>

        <div
          style={{
            borderTop: "1px solid #D7DCE2",
            padding: "14px 0px 0px 0px",
            width: "100%",
          }}
        >
          {data && data?.fileUrlCR ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameCR, data.originFileNameCR);
              }}
              content={shareholderLang.meetingDLData01[langValue]}
            />
          ) : null}
          {data && data?.fileUrlCA ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameCA, data.originFileNameCA);
              }}
              content={shareholderLang.meetingDLData02[langValue]}
            />
          ) : null}
          {data && data?.fileUrlRD ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameRD, data.originFileNameRD);
              }}
              content={shareholderLang.meetingDLData03[langValue]}
            />
          ) : null}
          {data && data?.fileUrlAR ? (
            <ReportDLCard
              onClick={() => {
                fileDownload(data.serverFileNameAR, data.originFileNameAR);
              }}
              content={shareholderLang.meetingDLData04[langValue]}
              style={{
                marginBottom: 0,
              }}
            />
          ) : null}
        </div>
      </div>

      {!data?.fileUrlCR &&
      !data?.fileUrlCA &&
      !data?.fileUrlRD &&
      !data?.fileUrlAR ? null : (
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #D7DCE2",
            margin: "17px 0px 15px",
          }}
        />
      )}

      <PretendardText
        style={{
          color: "#262C31",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "23.2px",
          marginBottom: 8,
        }}
      >
        {shareholderLang.meetingResult[langValue]}
      </PretendardText>
      <PretendardText
        style={{
          color: "#5A636A",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "18.9px",
          marginBottom: 23,
        }}
      >
        {data ? data.result : ""}
      </PretendardText>
      <div
        style={{
          width: "100%",
          height: 68,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          background: "#F9F9F9",
          borderTop: "1px solid #ADB4BA",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <PretendardText
          style={{
            color: "#000000",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "21.6px",
            flex: 1,
            textAlign: "center",
          }}
        >
          {shareholderLang.meetingAgenda[langValue]}
        </PretendardText>
        <PretendardText
          style={{
            color: "#000000",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "21.6px",
            width: 80,
            textAlign: "center",
            marginRight: 10,
          }}
        >
          {shareholderLang.meetingApprovalRate[langValue]}
        </PretendardText>
      </div>
      <div
        style={{
          width: "100%",
          height: 75,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 12,
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "18.9px",
              marginBottom: 5,
            }}
          >
            {shareholderLang.meeting1stAgenda[langValue]}
          </PretendardText>
          <PretendardText
            style={{
              color: "#5A636A",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "18.9px",
            }}
          >
            {data ? data.firstAgenda : ""}
          </PretendardText>
        </div>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "18.9px",
            width: 80,
            textAlign: "center",
            marginRight: 10,
          }}
        >
          {data ? data.firstRate : ""}
        </PretendardText>
      </div>
      <div
        style={{
          width: "100%",
          height: 75,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 12,
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "18.9px",
              marginBottom: 5,
            }}
          >
            {shareholderLang.meeting2stAgenda[langValue]}
          </PretendardText>
          <PretendardText
            style={{
              color: "#5A636A",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "18.9px",
            }}
          >
            {data ? data.secondAgenda : ""}
          </PretendardText>
        </div>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "18.9px",
            width: 80,
            textAlign: "center",
            marginRight: 10,
          }}
        >
          {data ? data.secondRate : ""}
        </PretendardText>
      </div>
      <div
        style={{
          width: "100%",
          height: 75,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #ADB4BA",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 12,
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "18.9px",
              marginBottom: 5,
            }}
          >
            {shareholderLang.meeting3stAgenda[langValue]}
          </PretendardText>
          <PretendardText
            style={{
              color: "#5A636A",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "18.9px",
            }}
          >
            {data ? data.thirdAgenda : ""}
          </PretendardText>
        </div>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "18.9px",
            width: 80,
            textAlign: "center",
            marginRight: 10,
          }}
        >
          {data ? data.thirdRate : ""}
        </PretendardText>
      </div>

      <PretendardText
        style={{
          color: "#778088",
          fontSize: "11px",
          fontWeight: 400,
          lineHeight: "14.85px",
          marginTop: 10,
        }}
      >
        {data ? data.reference : ""}
      </PretendardText>
    </div>
  );
};

export default ShareholdersM;
