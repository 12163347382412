import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";
import { cmgc } from "../../../../lang/renewal/productsLang";
import FramerSlideIn from "../../../../styles/framerSlideIn";
import { root } from "../../../../styles/GlobalStyle";
import BigImgContents from "../components/BigImgContents";
import DescriptImg from "../components/DescriptImg";
import Layout from "../components/Layout";
import WideImg from "../components/WideImg";

const Cmgc = ({ langValue }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const data = cmgc[langValue];

  return (
    <Layout bgColor={data.bgColor} title={"CMGC"} subTitle={data.header}>
      <ContentsWrapper>
        <FramerSlideIn>
          <BigImgContents textList={data.bigImg} img={data.bigImg[0].image} />
        </FramerSlideIn>
        <FullWidthSection
          $isMobile={isMobile}
          $isTablet={isTablet}
          $isDesktop={isDesktop}
        >
          <FramerSlideIn>
            <WideImg title={data.wideImg.title} img={data.wideImg.src} />
          </FramerSlideIn>
        </FullWidthSection>
        <FramerSlideIn>
          <DescriptImg imageList={data.desctiptImg} />
        </FramerSlideIn>
      </ContentsWrapper>
    </Layout>
  );
};

export default Cmgc;

const ContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8.5rem;
`;

const FullWidthSection = styled.div`
  position: relative;
  width: ${(props) =>
    props.$isDesktop ? "calc(100% + 20rem)" : "calc(100% + 10rem)"};
  left: ${(props) => (props.$isDesktop ? "-10rem" : "-5rem")};
`;
