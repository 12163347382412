export const languageType = ["KR", "EN"];

export const noticeLang = {
  empty: {
    KR: "등록된 게시물이 없습니다.",
    EN: "There are no registered posts.",
  },
  searchEmpty: {
    KR: "검색결과가 없습니다.",
    EN: "No search results found.",
  },
  list: {
    KR: "목록으로",
    EN: "List",
  },
};
