export const languageType = ["KR", "EN"];

export const shareholderLang = {
  cmTitle: {
    pc: {
      KR: "존경하는 주주여러분 안녕하십니까?\n루미르에서 소식을 전합니다.",
      EN: `Dear respectful shareholders, How are you?\nHere's the news from Lumir.`,
    },
    mo: {
      KR: "존경하는 주주여러분 안녕하십니까?\n루미르에서 소식을 전합니다.",
      EN: `Dear respectful shareholders,\nHow are you?\nHere's the news from Lumir.`,
    },
  },
  cmContent01: {
    pc: {
      KR: "친애하는 루미르 주주 여러분,\n루미르 주식회사로서 여러분에게 소식을 전하려고 합니다. 먼저, 저희는 감사의 말씀을 전하고자 합니다.\n우리는 인공위성 탑재장치부터 인공위성 시스템, 위성기반 정보 서비스 사업 등 다양한 분야에서의 우주개발 사업 역량을 갖추고 있습니다.\n현재까지의 기술력을 바탕으로 미래를 대비하며, 루미르가 더 나은 성과를 이룰 수 있도록 끊임없는 노력을 기울이고 있습니다.\n매년 국가 R&D사업에 참여하며 미래 기술에 대한 연구 및 개발을 진행하고 있는데요. 이는 우리의 높은 기술력으로 더 나은 미래를 위해 노력하고 있다는 확고한 증거입니다.\n      \n여러분의 끊임없는 관심과 격려는 루미르의 성공에 큰 영감을 주고 있습니다.\n주주 여러분의 투자와 지지 덕분에 우리는 높은 목표를 향해 나아가고 있습니다. 여기서 다시 한 번 감사의 말씀을 드립니다.\n\n루미르는 매년 끊임없이 발전하며 성과를 거두고 있습니다.\n특히, 2024년에는 신사옥의 완공을 앞두고 있어 더 나은 업무환경과 서비스를 제공할 예정입니다.\n주주 여러분과 함께하는 이 시점에서, 앞으로도 더 나은 미래를 위해 최선을 다하겠습니다.",
      EN: `Dear Lumir shareholders,\nAs Lumir Inc., I would like to bring you the news. First of all, wewould like to express our gratitude.\nWe have space development business capabilities in various fields suchas satellite mounting devices, satellite systems, and satellite-based\ninformation service businesses.\nWe are preparing for the future based on our technology so far, and weare making constant efforts to help Lumir achieve better results.\nEvery year, we participate in national R&D projects and conductresearch and development on future technologies. This is a solid proofthat we are\nstriving for a better future with our high technology.\n\nYour constant attention and encouragement is a great inspiration forLumir's success.\nThanks to the investment and support of our shareholders, we aremoving toward a high goal. I would like to thank you again.\n\nLumir continues to improve and achieve results every year.\nIn particular, the completion of the new building is set to becompleted in 2024, so we plan to provide a better work environment and services.\nAt this point with shareholders, we will continue to do our best for abetter future.`,
    },
    mo: {
      KR: "친애하는 루미르 주주 여러분,\n루미르 주식회사로서 여러분에게 소식을 전하려고 합니다. 먼저, 저희는 감사의 말씀을 전하고자 합니다.\n우리는 인공위성 탑재장치부터 인공위성 시스템, 위성기반 정보 서비스 사업 등 다양한 분야에서의 우주개발 사업 역량을 갖추고 있습니다.\n현재까지의 기술력을 바탕으로 미래를 대비하며, 루미르가 더 나은 성과를 이룰 수 있도록 끊임없는 노력을 기울이고 있습니다.\n매년 국가 R&D사업에 참여하며 미래 기술에 대한 연구 및 개발을 진행하고 있는데요. 이는 우리의 높은 기술력으로 더 나은 미래를 위해 노력하고 있다는 확고한 증거입니다.\n      \n여러분의 끊임없는 관심과 격려는 루미르의 성공에 큰 영감을 주고 있습니다.\n주주 여러분의 투자와 지지 덕분에 우리는 높은 목표를 향해 나아가고 있습니다. 여기서 다시 한 번 감사의 말씀을 드립니다.\n\n루미르는 매년 끊임없이 발전하며 성과를 거두고 있습니다.\n특히, 2024년에는 신사옥의 완공을 앞두고 있어 더 나은 업무환경과 서비스를 제공할 예정입니다.\n주주 여러분과 함께하는 이 시점에서, 앞으로도 더 나은 미래를 위해 최선을 다하겠습니다.",
      EN: `Dear Lumir shareholders,\nAs Lumir Inc., I would like to bring you the news. First of all, wewould like to express our gratitude.\nWe have space development business capabilities in various fields suchas satellite mounting devices, satellite systems, and satellite-based information service businesses.\nWe are preparing for the future based on our technology so far, and weare making constant efforts to help Lumir achieve better results.\nEvery year, we participate in national R&D projects and conductresearch and development on future technologies. This is a solid proofthat we are striving for a better future with our high technology.\n\nYour constant attention and encouragement is a great inspiration forLumir's success.\nThanks to the investment and support of our shareholders, we aremoving toward a high goal.\nI would like to thank you again.\n\nLumir continues to improve and achieve results every year.\nIn particular, the completion of the new building is set to becompleted in 2024,\nso we plan to provide a better work environment and services.\nAt this point with shareholders,\nwe will continue to do our best for abetter future.`,
    },
  },
  cmShareholders: {
    KR: "주주 여러분!",
    EN: `Shareholders!`,
  },
  cmContent02: {
    KR: "여러분의 지속적인 지지에 감사드립니다. 앞으로도 루미르와 함께 미래를 향해 나아가기를 기대합니다.\n감사합니다.",
    EN: `Thank you for your continued support. I look forward to moving forwardwith Lumir in the future.\nThank you.`,
  },
  cmCEO: {
    KR: "대표이사 남명용",
    EN: `CEO Nam Myungyong`,
  },
  downloadPDF: {
    KR: "PDF 다운로드",
    EN: `Download PDF`,
  },
  meetingTitle: {
    KR: "기 정기 주주총회",
    EN: `th Annual Meeting of Shareholders`,
  },
  meetingPreTitle: {
    KR: "이전 주주총회",
    EN: `Previous General Shareholders' Meeting`,
  },
  meetingSummary: {
    KR: "개요",
    EN: `Summary`,
  },
  meetingPlace: {
    KR: "장소",
    EN: `Place`,
  },
  meetingPlaceValue: {
    pc: {
      KR: "경기도 수원시 영통구 광교중앙로 140 수원컨벤션센터 3층 컨벤션홀, 1층 전시홀",
      EN: `Convention Hall on the 3rd floor of Suwon Convention Center, 140 Gwanggyo Jungang-ro, Yeongtong-gu,\nSuwon-si, Gyeonggi-do, and Exhibition Hall on the 1st floor`,
    },
    mo: {
      KR: "경기도 수원시 영통구 광교중앙로 140 수원컨벤션센터 3층 컨벤션홀, 1층 전시홀",
      EN: `Convention Hall on the 3rd floor of Suwon Convention Center, 140 Gwanggyo Jungang-ro, Yeongtong-gu,\nSuwon-si, Gyeonggi-do, and Exhibition Hall on the 1st floor`,
    },
  },
  meetingDate: {
    KR: "시간",
    EN: `Date`,
  },
  meetingDateValue: {
    KR: "2023년 3월 15일 오전 9시",
    EN: `March 15, 2023 at 9:00 AM`,
  },
  meetingDLData01: {
    KR: "소집결의",
    EN: `Convocation Resolution`,
  },
  meetingDLData02: {
    KR: "소집공고",
    EN: `Convocation Announcement`,
  },
  meetingDLData03: {
    KR: "의결권 대리행사 권유 참고서류",
    EN: `Reference Documents Recommending the Exercise of Voting Rights by Proxy`,
  },
  meetingDLData04: {
    KR: "감사보고서",
    EN: `Audit Report`,
  },
  meetingResult: {
    KR: "결과",
    EN: `Result`,
  },
  meetingResultValue: {
    KR: "NN기 정기주주총회 (참석 주식수 : 0,000,000,000 주)",
    EN: `NNth Annual General Meeting of Shareholders (Number of shares attended: 0,000,000,000 shares)`,
  },
  meetingAgenda: {
    KR: "부의 안건",
    EN: `Agenda`,
  },
  meetingApprovalRate: {
    KR: "찬성률",
    EN: `Approval Rate`,
  },
  meeting1stAgenda: {
    KR: "제 1호 의안",
    EN: `1st Agenda`,
  },
  meeting1stAgendaValue: {
    KR: "안건명이 이곳에 들어갑니다.",
    EN: `Title`,
  },
  meeting2stAgenda: {
    KR: "제 2호 의안",
    EN: `2st Agenda`,
  },
  meeting2stAgendaValue: {
    KR: "안건명이 이곳에 들어갑니다.",
    EN: `Title`,
  },
  meeting3stAgenda: {
    KR: "제 3호 의안",
    EN: `3st Agenda`,
  },
  meeting3stAgendaValue: {
    KR: "안건명이 이곳에 들어갑니다.",
    EN: `Title`,
  },
  meetingEtc: {
    KR: "※ 참석 주식수 : 개회 선언 시 발표한 의결권이 있는 보통주식 중 출석한 주식의 총수(1호 의안) 기준\n(주주총회 의안별로 의결권이 있는 주식수는 관계법령 적용 등으로 다르며, 이에 따라 참석 주식수도 차이가 발생할 수 있음.)",
    EN: `※ Number of shares attended: Based on the total number of shares present (No. 1) among common stocks with voting rights announced at the time of the declaration of opening\n(The number of shares with voting rights varies depending on the agenda of the general shareholders' meeting due to the application of relevant laws, etc., and accordingly, there may be a difference in\nthe number of shares present.)`,
  },
};
