export const dataFusionLang = {
  title: {
    KR: "SAR & HSC 영상 데이터 퓨전",
    EN: "SAR & HSC Image Data Fusion",
  },
  content1: {
    KR: "SAR 영상을 통해 지형/지리, 지형 및 구조물 변형을 파악합니다.\n초분광 영상을 통해 지질, 재질을 확인하며\n이러한 영상 정보를 결합하여 고해상도 공간 및 분광 정보를 확인합니다.",
    EN: "Identify topography/geography, topography, and structural deformation through SAR images.\nCheck the lipids and materials through hyperspectral images,\nand combine these image information to check high-resolution space and spectral information.",
  },
  title2: {
    KR: "이종 센서 정보 융합 활용 사례",
    EN: "Use of Heterogeneous Sensor Information Convergence",
  },
  content2: {
    KR: "루미르의 이종 센서 정보 융합을 통한 영상 정보 솔루션은 다양한 분야에서 활용될 수 있습니다.\n환경 및 기후 분야, 도시 및 기반 시설의 안전 분야 등에 대해 빠르고 정확한 모니터링을 통해 인류에 공헌합니다.",
    EN: "Lumir's image information solution through heterogeneous sensor information fusion can be used in various fields.\nIt contributes to humanity through fast and accurate monitoring of the environment and climate sectors, as well as the safety sectors of cities and infrastructure.",
  },
  infrastructure: {
    KR: "Time-series InSAR 기반 철도 시설물의 변형을 측정하여 효율적으로 관리가 가능합니다. 시설물의 결함을 빠르게 모니터링함으로써 안전을 보장할 수 있습니다.",
    EN: "It can be efficiently managed by measuring the deformation of the Time-series InSAR-based railway facilities. It can ensure safety by quickly monitoring the defects in the facilities.",
  },
  infrastructure: {
    KR: "Time-series InSAR 기반 철도 시설물의 변형을 측정하여 효율적으로 관리가 가능합니다. 시설물의 결함을 빠르게 모니터링함으로써 안전을 보장할 수 있습니다.",
    EN: "It can be efficiently managed by measuring the deformation of the Time-series InSAR-based railway facilities. It can ensure safety by quickly monitoring the defects in the facilities.",
  },
  city: {
    KR: "운영 중인 발전소의 지반 침하를 InSAR로 모니터링합니다. 구조적 변화 및 지형을 파악하고, 초분광 영상을 통해 위험을 조기에 식별합니다.",
    EN: "InSAR monitors the ground subsidence of the operating plant. Identify structural changes and topography, and use hyperspectral imaging Identify risks early.",
  },
  environment: {
    KR: "SAR를 통해 해상도가 향상된 초분광 이미지를 활용하여, 좁은 지역의 한정된 오염을 감지하고 오염 원인을 특정할 수 있습니다.",
    EN: "SAR enables the detection of limited pollution in a narrow area and identification of the source of the pollution using improved hyperspectral images.",
  },
};
