import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useDisplay from "../../hooks/useDisplay";
import { useLocation } from "react-router-dom";

const ScrollTop = () => {
  const { isMobile, isTablet } = useDisplay();
  const [btnVisible, setBtnVisible] = useState(false);
  const { pathname } = useLocation();

  const handleScroll = () => {
    const { scrollY } = window;

    scrollY > 200 ? setBtnVisible(true) : setBtnVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {btnVisible && (isMobile || isTablet || pathname === "/") ? (
        <ScrollBtnWrapper onClick={goToTop}>
          <img
            src="/assets/icons/scroll_top.png"
            alt=""
            style={{
              filter: "drop-shadow(0px 4px 15px rgba(18, 40, 48, 0.25))",
            }}
          />
        </ScrollBtnWrapper>
      ) : null}
    </>
  );
};

export default ScrollTop;

const ScrollBtnWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 10;
`;
