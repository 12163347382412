import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { shareholderLang } from "../../../lang/investment/shareholderLang";
import { langState } from "../../../store/langState";

function GSMCard({ style, number, data }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  const [isDetail, setIsDetail] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#fff",
            borderBottom: "1px solid #ADB4BA",
            height: 70,
            width: "100%",
            padding: "0px 30px",
            ...style,
          }}
        >
          <PretendardText
            style={{
              fontSize: isTablet ? "16px" : "18px",
              fontWeight: 600,
              lineHeight: isTablet ? "23.2px" : "26.1px",
              color: "#778088",
              width: isTablet ? 60 : 70,
            }}
          >
            {number}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: isTablet ? "16px" : "18px",
              fontWeight: 400,
              lineHeight: isTablet ? "23.2px" : "26.1px",
              color: "#262C31",
              flex: 1,
            }}
          >
            {langValue === "KR"
              ? `${data.roundNum}기 주주총회 결과`
              : `Results of the ${data.roundNum}th General Shareholders' Meeting`}
          </PretendardText>

          {isDetail ? (
            <img
              onClick={() => {
                setIsDetail(false);
              }}
              style={{
                cursor: "pointer",
                width: isTablet ? 24 : 30,
                height: isTablet ? 24 : 30,
              }}
              src="/assets/icons/up_arrow.svg"
            />
          ) : (
            <img
              onClick={() => {
                setIsDetail(true);
              }}
              style={{
                cursor: "pointer",
                width: isTablet ? 24 : 30,
                height: isTablet ? 24 : 30,
              }}
              src="/assets/icons/down_arrow.svg"
            />
          )}
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderBottom: "1px solid #ADB4BA",
              width: "100%",
              padding: isTablet ? "30px " : "30px 40px",
            }}
          >
            <PretendardText
              style={{
                color: "#000000",
                fontSize: isTablet ? "16px" : "20px",
                fontWeight: 700,
                lineHeight: isTablet ? "23.2px" : "30px",
                marginBottom: isTablet ? 16 : 26,
              }}
            >
              {shareholderLang.meetingResult[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                color: "#5A636A",
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 400,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                marginBottom: 11,
              }}
            >
              {data.content}
            </PretendardText>
            <div
              style={{
                width: "100%",
                height: 56,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#F9F9F9",
                borderTop: "1px solid #ADB4BA",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <PretendardText
                style={{
                  color: "#000000",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 600,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  flex: 1,
                  textAlign: "center",
                }}
              >
                {shareholderLang.meetingAgenda[langValue]}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#000000",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 600,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  width: 200,
                  textAlign: "center",
                }}
              >
                {shareholderLang.meetingApprovalRate[langValue]}
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                height: 56,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  marginLeft: 30,
                  width: 140,
                }}
              >
                {shareholderLang.meeting1stAgenda[langValue]}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#5A636A",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  flex: 1,
                }}
              >
                {data.firstAgenda}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#494949",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  width: 200,
                  textAlign: "center",
                }}
              >
                {data.firstRate}
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                height: 67,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  marginLeft: 30,
                  width: 140,
                }}
              >
                {shareholderLang.meeting2stAgenda[langValue]}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#5A636A",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  flex: 1,
                }}
              >
                {data.secondAgenda}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  width: 200,
                  textAlign: "center",
                }}
              >
                {data.secondRate}
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                height: 67,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  marginLeft: 30,
                  width: 140,
                }}
              >
                {shareholderLang.meeting3stAgenda[langValue]}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#5A636A",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  flex: 1,
                }}
              >
                {data.thirdAgenda}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isTablet ? "16px" : "18px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "23.2px" : "21.6px",
                  width: 200,
                  textAlign: "center",
                }}
              >
                {data.thirdRate}
              </PretendardText>
            </div>

            <PretendardText
              style={{
                color: "#778088",
                fontSize: isTablet ? "11px" : "13px",
                fontWeight: 400,
                lineHeight: isTablet ? "14.85px" : "17.55px",
                marginTop: 10,
              }}
            >
              {data.reference}
            </PretendardText>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default GSMCard;
