import React from "react";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";

import { langState } from "../../../../store/langState";
import { useRecoilValue } from "recoil";
import { nationalRnDLang } from "../../../../lang/CompanyIntroduction/nationalRnD";

const RnDModal6 = ({ title }) => {
  const { isMobile } = useDisplay();

  const langValue = useRecoilValue(langState);

  return (
    <div>
      <div style={{ display: isMobile ? "flex" : "" }}>
        {isMobile ? (
          <div style={{ width: "3px", height: "16px", backgroundColor: "#00AEEF" }} />
        ) : null}
        <Title $isMobile={isMobile}>{nationalRnDLang.rndModal6?.title[langValue]}</Title>
      </div>
      <ContentText $isMobile={isMobile} style={{ marginBottom: "10px" }}>
        {nationalRnDLang.rndModal6?.content1[langValue]}
      </ContentText>
      <ContentText $isMobile={isMobile} style={{ marginBottom: "30px" }}>
        {nationalRnDLang.rndModal6?.content2[langValue]}
      </ContentText>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <img
          src="/assets/imgs/introduce/rnd/rnd6_1.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
        <img
          src="/assets/imgs/introduce/rnd/rnd6_2.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
        <img
          src="/assets/imgs/introduce/rnd/rnd6_3.png"
          alt=""
          style={{ width: isMobile ? "100%" : "" }}
        />
      </div>
      <GrayBox>
        <ContentText>HMVS</ContentText>
      </GrayBox>
    </div>
  );
};

export default RnDModal6;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "0" : "-0.18px")};
  border-left: ${(props) => (props.$isMobile ? "none" : "3px solid #00aeff")};
  padding-left: 10px;
  margin-bottom: ${(props) => (props.$isMobile ? "15px" : "20px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "15px")};
  font-weight: 400;
  line-height: 135%;
  color: #5a636a;
`;

const GrayBox = styled.div`
  width: 100%;
  height: 51px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
