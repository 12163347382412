export const certification = [
  {
    src: "",
    cardTitle: `Space Enterprise<br />Affiliated Research Institute<br />Intellectual Property</>`,
    title: {
      KR: "우주 기업부설연구소 지성서",
      EN: "Space Enterprise Affiliated Research Institute Intellectual Property",
    },
    date: "2024.06 ~ 2027.06",
    image: "/assets/imgs/certification/certi9.png",
  },
  {
    src: "",
    cardTitle: "2023 ICT Target<br />Ministerial Marks",
    title: {
      KR: "2023 ICT 대상 장관표창장",
      EN: "2023 ICT Target Ministerial Marks",
    },
    date: "2023.12.12",
    image: "/assets/imgs/certification/certi0.png",
  },
  {
    src: "",
    cardTitle: `Selection of Defense<br />Innovation Company`,
    title: {
      KR: "방산혁신기업 선정서",
      EN: "Selection of Defense Innovation Company",
    },
    date: "2023.11.03",
    image: "/assets/imgs/certification/certi1.png",
  },
  {
    src: "",
    cardTitle: `Preliminary registration<br />card for space objects</>`,
    title: {
      KR: "우주물체 예비등록증",
      EN: "Preliminary registration card<br />for space objects",
    },
    date: "2023.04.05",
    image: "/assets/imgs/certification/certi8.png",
  },
  {
    src: "",
    cardTitle: `Confirmation of a company<br/>specializing in materials and<br/>parts and equipment</>`,
    title: {
      KR: "소재부품장비 전문기업확인서",
      EN: "Confirmation of a company<br />specializing in materials and<br />parts and equipment",
    },
    date: "2022.10.26~2025.10.25",
    image: "/assets/imgs/certification/certi2.png",
  },
  {
    src: "",
    cardTitle: `Confirmation of<br />Inno-Biz (Inno-Biz)</>`,
    title: {
      KR: "기술혁신형 중소기업(Inno-Biz) 확인서",
      EN: "Confirmation of Inno-Biz (Inno-Biz)",
    },
    date: "2022.04.05",
    image: "/assets/imgs/certification/certi3.png",
  },
  {
    src: "",
    cardTitle: `ISO 9001 </>`,
    title: {
      KR: "ISO 9001 2015 품질경영시스템",
      EN: "ISO 9001 2015 Quality Management System",
    },
    date: "2021.07.01",
    image: "/assets/imgs/certification/certi4.png",
  },
  {
    src: "",
    cardTitle: `ISO 14001 2015</>`,
    title: {
      KR: "ISO 14001 2015 환경경영시스템<br/>인공위성 시스템 탑재 장치의 설계,<br/>개발 및 제작",
      EN: "ISO 14001 2015 Design, Development, and Production of<br />a Satellite System Mounted Device<br />for Environmental Management<br />System",
    },
    date: "2021.07.01",
    image: "/assets/imgs/certification/certi5.png",
  },
  {
    src: "",
    cardTitle: `confirmation of root enterprise</>`,
    title: { KR: "뿌리기업 확인서", EN: "confirmation of root enterprise" },
    date: "2021.04.26",
    image: "/assets/imgs/certification/certi6.png",
  },
  {
    src: "",
    cardTitle: `AS 9100D and ISO 9001</>`,
    title: { KR: "AS 9100D and ISO 9001", EN: "AS 9100D and ISO 9001" },
    date: "2015~2025.09.20",
    image: "/assets/imgs/certification/certi7.png",
  },
];

export const intellectualStatus = [
  {
    src: "",
    cardTitle: `Patent No. 10-2454706`,
    title: { KR: "특허 제10-2454706호", EN: "Patent No. 10-2454706" },
    date: "2024.06 ~ 2027.06",
  },
  {
    src: "",
    cardTitle: `Patent No. 10-2392276`,
    title: { KR: "특허 제10-2392276호", EN: "Patent No. 10-2392276" },
    date: "2022.04.26",
  },
  {
    src: "",
    cardTitle: `Patent No. 10-2363328`,
    title: { KR: "특허 제10-2363328호", EN: "Patent No. 10-2363328" },
    date: "2022.02.10",
  },
  {
    src: "",
    cardTitle: "U.S. Patent",
    title: { KR: "미국특허", EN: "U.S. Patent" },
    date: "YYYY.MM.DD",
  },
  {
    src: "",
    cardTitle: `Patent No. 10-1427770`,
    title: { KR: "특허 제10-1427770호", EN: "Patent No. 10-1427770" },
    date: "2020.02.18",
  },
  {
    src: "",
    cardTitle: `Patent No. 10-1376908`,
    title: { KR: "특허 제10-1376908호", EN: "Patent No. 10-1376908" },
    date: "2018.10.08",
  },
  {
    src: "",
    cardTitle: `Patent No. 10-1667400`,
    title: { KR: "특허 제10-1667400호", EN: "Patent No. 10-1667400" },
    date: "2016.10.12",
  },
];
