import styled from "styled-components";
import Image from "./Image";
import useDisplay from "../../../../hooks/useDisplay";
import FramerSlideIn from "../../../../styles/framerSlideIn";

const DescriptImg = ({ imageList }) => {
  const { isDesktop } = useDisplay();
  return (
    <Section>
      {imageList?.map((img, i) => (
        <FramerSlideIn key={i}>
          <Wrapper key={i} $isDesktop={isDesktop}>
            <Title>{img.title}</Title>
            <ImageContainer>
              <Image img={img.src} />
            </ImageContainer>
          </Wrapper>
        </FramerSlideIn>
      ))}
    </Section>
  );
};

export default DescriptImg;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 7.5rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $isDesktop }) =>
    $isDesktop ? "4fr 8fr" : "1fr"};
  gap: 2rem;
`;

const Title = styled.h6`
  font-size: 2.3rem;
  font-weight: 700;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
`;
