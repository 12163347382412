export const nationalRnDLang = {
  section1: {
    title: {
      KR: "루미르는 끊임없는 연구개발과 차별화된 기술력으로\n새로운 가치를 창출하고 있습니다.",
      EN: "Lumir is creating new value through constant R&D\nand differentiated technology.",
    },
    subTitle: {
      KR: "루미르는 과기부에서 주관하는 거대 우주개발 프로젝트인 스페이스파이오니어와 스페이스이노베이션을 모두 수주하며,\n대한민국 우주산업 분야의 선두로 끊임없이 발전하고 있습니다.",
      EN: "Lumir has secured all major space development projects organized by the Ministry of Science and ICT,\nand continues to advance as a leader in the Korean space industry.",
    },
    content1: {
      KR: "뛰어난 기술력을 활용한\n우주분야 미래선도",
      EN: "Leading the future of space\nwith advanced technology",
    },
    content2: {
      KR: "차세대 우주기술의\n국산화 및 성능개선",
      EN: "Localization and performance\nimprovement of next-generation\nspace technology",
    },
    content3: {
      KR: "다양한 산업 분야 활용 및\n국가 환경 발전 이바지",
      EN: "Application across various industries\nand contribution to national development",
    },
  },
  scienceAndICT: {
    title: {
      KR: "과학기술정보통신부",
      EN: "Ministry of Science and ICT",
    },
    category1: {
      title: {
        KR: "스페이스파이오니어",
        EN: "Space Pioneer",
      },
      content: {
        KR: "스페이스파이오니어 사업은 국가 우주전략기술을 자립화하고 원천기술을 확보하여 국가 우주기술 역량 향상 및 우주산업 생태계 선순환 기반 마련하고, 본 사업을 통해 개발하는 기술의 실제 체계사업적용을 목표로 체계에서 적용할 수 있는 기술수준 (TRL7단계, QM(인증모델))까지 개발하는 사업입니다.",
        EN: "The Space Pioneer project aims to develop technology levels (TRL 7, QM) that can be applied to systems, fostering self-reliance in national space strategy and securing foundational technologies to enhance national space capabilities and establish a virtuous cycle in the space industry ecosystem.",
      },
      product1: {
        KR: "Ka-Band 송신기 및 Ka Band 능동형 어레이 안테나",
        EN: "Ka-Band Transmitter and Ka-Band Active Array Antenna",
      },
      product2: {
        KR: "고속 고기동 위성의 제어모멘트 자이로",
        EN: "Control Moment Gyro for High-Speed, High-Maneuverability Satellite",
      },
    },
    category2: {
      title: {
        KR: "스페이스이노베이션",
        EN: "Space Innovation",
      },
      content: {
        KR: "스페이스이노베이션사업은 국내 중소‧벤처기업이 우주시장에 진출할 수 있도록 초소형위성 기반 비즈니스 모델 개발, 우주검증 및 사업화를 위한 사업입니다.",
        EN: "The Space Innovation project supports domestic SMEs and venture companies in entering the space market by developing small satellite-based business models, space verification, and commercialization.",
      },
      product1: {
        KR: "초분광, 가시광 듀얼센서 카메라를 탑재한 초소형 위성",
        EN: "Ultra-Small Satellite with Hyperspectral\nand Visible Dual-Sensor Cameras",
      },
    },
  },
  defenseScience: {
    title: {
      KR: "국방과학연구소",
      EN: "Agency for Defense Development",
    },
    category1: {
      title: {
        KR: "민군겸용우주기술개발",
        EN: "Civil-Military Space Technology Development",
      },
      content: {
        KR: "해당 사업은 ｢민·군기술협력사업 촉진법｣에 따라 민·군기술협력사업 중에서 민과 군에서 공통적으로 활용할 수 있는 민·군겸용기술개발사업으로 추진 중인 2022년도 착수대상 우주기술개발과제에 대한 주관연구개발기관을 선정한 사업입니다.",
        EN: "This project, in accordance with the Civil-Military Technology Cooperation Promotion Act, selected the main research and development institution for the 2022 space technology development project, which is being promoted as a dual-use technology development project that can be commonly used by both civil and military sectors.",
      },
      product1: {
        KR: "우주용 고신뢰성 대용량 적층형 메모리 모듈 개발",
        EN: "Development of High-Reliability, Large-Capacity Stackable Memory Modules for Space",
      },
    },
  },
  SME: {
    title: {
      KR: "중소기업기술정보진흥원",
      EN: "Korea Technology & Information\nPromotion Agency for SMEs",
    },
    category1: {
      title: {
        KR: "중소기업기술정보진흥원 투자형 R&D",
        EN: "Investment-Type R&D by the Korea Technology & Information Promotion Agency for SMEs",
      },
      content: {
        KR: "중소기업기술정보진흥원 투자형 R&D 사업은 투자·R&D·글로벌 등 기업성장 분야의 민간 전문역량을 활용하여 유망중소벤처를 선별하고 민간투자와 정부자금을 매칭지원(투자·R&D)하여 기술 집약형 유망 중소벤처의 스케일업을 촉진하고자 하는 사업입니다.",
        EN: "The Investment-Type R&D project by the Korea Technology & Information Promotion Agency for SMEs aims to promote the scale-up of technology-intensive promising SMEs by leveraging private expertise in areas such as investment, R&D, and global growth, matching private investment with government funding.",
      },
      product1: {
        KR: "초고해상도 초소형 SAR위성 탑재용 X-Band 능동위상배열안테나 개발",
        EN: "Development of X-Band Active Phased Array Antenna\nfor Ultra-High-Resolution, Ultra-Small SAR Satellite",
      },
    },
  },
  promotionAssociation: {
    title: {
      KR: "한국산업기술진흥협회",
      EN: "Korea Industrial Technology Promotion Association",
    },
    category1: {
      title: {
        KR: "R&D 역량강화 사업",
        EN: "R&D Capacity Building Project",
      },
      content: {
        KR: "중소기업기술정보진흥원에서 주관하는 R&D 역량강화 사업은 대학·출연(연)의 공공연구성과 및 전문성을 활용하여 주요 혁신성장 산업분야 기업 부설연구소의 질적 성장 지원 미래 시장성이 높은 기술·제품 개발을 지원함으로써 기업부설연구소의 기술혁신을 촉진하고자 하는 사업입니다.",
        EN: "The R&D Capacity Building Project, organized by the Korea Technology & Information Promotion Agency for SMEs, aims to promote technological innovation in corporate-affiliated research institutes by utilizing public research outcomes and expertise from universities and research institutes, supporting qualitative growth in major innovative growth industries and the development of highly marketable future technologies and products.",
      },
      product1: {
        KR: "초분광 센서를 이용한 머신비전 시스템 구축 개발",
        EN: "Development of Machine Vision System Using Hyperspectral Sensors",
      },
    },
  },
  rndModal1: {
    title: {
      KR: "Ka-Band 송신기 및 Ka Band 능동형 어레이 안테나",
      EN: "Ka-Band Transmitter and Ka-Band Active Array Antenna",
    },
    content1: {
      KR: "실용위성의 ka 밴드 다운링크 시스템의 구성품으로 전자 빔조향 안테나 기술 및 소프트웨어 정의 라디오 기술이 적용된 고기능/소형/경량의 Ka 밴드 안테나 및 Ka 밴드 데이터 전송 장치",
      EN: "A high-performance, small, lightweight Ka-band antenna and Ka-band data transmission device equipped with electronic beam steering antenna technology and software-defined radio technology, as part of the Ka-band downlink system of practical satellites.",
    },
    content2: {
      KR: "Ka 밴드 송신기, Ka 밴드 능동형 어레이 안테나 단일 유닛 모듈화를 통한 시스템 최적화",
      EN: "System optimization through modularization of the Ka-band transmitter and Ka-band active array antenna into a single unit.",
    },
    content3: {
      KR: "4 Gbps 이상의 고속 데이터 전송이 필요한 위성 탑재체 PDTS(Payload Data Transmission Subsystem)의 핵심 구성품",
      EN: "A key component of the Payload Data Transmission Subsystem (PDTS) of satellites that require high-speed data transfer rates of 4 Gbps or more.",
    },
  },
  rndModal2: {
    title: {
      KR: "고속 고기동 위성의 제어모멘트자이로",
      EN: "Control Moment Gyro for High-Speed, High-Maneuverability Satellites",
    },
    content1: {
      KR: "제어모멘트자이로(Control Moment Gyro, CMG)는 인공위성의 자세를 고속 고기동으로 변환할 수 있는 저궤도(LEO) 위성용 자세제어시스템의 핵심 구성품입니다.",
      EN: "The Control Moment Gyro (CMG) is a key component of attitude control systems for Low Earth Orbit (LEO) satellites, enabling the conversion of satellite attitude to high-speed, high-maneuverability postures.",
    },
    content2: {
      KR: "반작용 휠(Reaction Wheel) 장착 방식보다 수십 배 큰 제어 토크 발생이 가능한 위성 고속 고기동 자세 변환 기술의 핵심 기술입니다.",
      EN: "It is a core technology for high-speed, high-maneuverability attitude transformation in satellites, capable of generating control torques dozens of times greater than those possible with reaction wheel systems.",
    },
    content3: {
      KR: "중형급 실용위성에 탑재 가능한 위성 자세제어용 CMG 4기로 구성된 CMA(Control Moment Gyro Assembly) 개발을 진행 중입니다.",
      EN: "Currently, we are developing a Control Moment Gyro Assembly (CMA) consisting of four CMGs for satellite attitude control, which can be mounted on medium-sized practical satellites.",
    },
  },
  rndModal3: {
    title: {
      KR: "스페이스이노베이션 - 초분광, 가시광 듀얼센서 카메라를 탑재한 초소형 위성",
      EN: "Space Innovation - Ultra-Small Satellite with Hyperspectral and Visible Dual-Sensor Cameras",
    },
    content1: {
      KR: "스페이스이노베이션 사업은 중소벤처기업이 우주시장에 진출할 수 있도록 초소형위성 기반의 비즈니스 모델 개발 및 우주검증 목표 사업으로, 루미르는 초분광·가시광 듀얼센서 카메라를 탑재한 초소형 인공위성 개발 및 관측 데이터를 통한 국내외 영상서비스를 제공하고자 합니다.",
      EN: "The Space Innovation project is aimed at developing small satellite-based business models and conducting space verification to help SMEs and venture companies enter the space market. Lumir is developing an ultra-small satellite equipped with hyperspectral and visible dual-sensor cameras, aiming to provide imaging services both domestically and internationally using the collected observation data.",
    },
    content2: {
      KR: "초분광/가시광 카메라의 특징을 활용하여 대상표적 식별뿐만 아니라 표적의 매질 정보 추출 확인이 가능합니다.",
      EN: "By utilizing the characteristics of hyperspectral/visible cameras, it is possible to not only identify target objects but also extract and verify material information of the targets.",
    },
    content3: {
      KR: "탑재체 획득 영상에 대한 이미지 분석 및 퓨전 수행이 가능합니다.",
      EN: "It enables image analysis and fusion for the images acquired by the payload.",
    },
    subTitle1: {
      KR: "대상 표적 구분 및 표적 매질 정보 파악",
      EN: "Target Identification and Media Information Analysis",
    },
    subContent1: {
      KR: "가시광카메라(EOC)의 높은 공간해상도를 통해 RGB 영상 획득",
      EN: "RGB image acquisition through high spatial resolution of the visible light camera (EOC).",
    },
    subContent2: {
      KR: "초분광카메라(HSC)의 높은 파장분해능을 통해 암석과 식생 구분",
      EN: "Differentiation of rocks and vegetation using high wavelength resolution of hyperspectral cameras (HSC).",
    },
    subTitle2: {
      KR: "식생 파악 (NDVI index 활용)",
      EN: "Vegetation Identification Using NDVI Index",
    },
    subContent3: {
      KR: "NDVI Index를 활용하여 수락산의 식생을 구분",
      EN: "Using the NDVI Index to classify the vegetation of Suraksan Mountain.",
    },
    content4: {
      KR: "탑재체 획득 영상에 대한 이미지 분석 및 퓨전 수행",
      EN: "Image analysis and fusion for payload-acquired images.",
      caption: {
        KR: "취득영상에 대한 Image Fusion 처리",
        EN: "Image Fusion Processing for Acquired Images",
      },
    },
  },
  rndModal4: {
    title: {
      KR: "우주용 고신뢰성 대용량 적층형 메모리 모듈 개발",
      EN: "Development of High-Reliability, Large-Capacity Stackable Memory Modules for Space",
    },
    content1: {
      KR: "루미르는 국방과학연구소(ADD)가 주관하는 우주용 고신뢰성 대용량 적층형 메모리 모듈을 개발하고 있습니다. 루미르는 기 확보하고 있는 차세대중형위성 등에 적용된 대용량 저장장치의 메모리 모듈과 호환 적용을 검토하고 있으며, 적층 기술의 우주용 RF 부품 응용 활용 및 한국형 우주용 부품 제조 공정의 표준화를 기대하고 있습니다.",
      EN: "Lumir is developing high-reliability, large-capacity stackable memory modules for space, under the supervision of the Agency for Defense Development (ADD). Lumir is considering compatibility with memory modules used in large-capacity storage devices applied to CAS500, and expects to standardize the application of stack technology to RF components for space, as well as the manufacturing process for Korean space components.",
    },
  },
  rndModal5: {
    title: {
      KR: "초고해상도 초소형 SAR위성 탑재용 X-Band 능동위상배열안테나 개발",
      EN: "Development of X-Band Active Phased Array Antenna for Ultra-High-Resolution, Ultra-Small SAR Satellite",
    },
    content1: {
      KR: "루미르가 개발하고 있는 초고해상도 초소형 SAR위성 탑재용 X-Band 능동위상배열안테나는 SAM(SAR Antenna), TRM(Transmit/Receive Module), TCM(TRM Control Module), DCM(Dividing & Combining Module)으로 구성되어 있습니다.",
      EN: "The X-Band active phased array antenna for ultra-high-resolution, ultra-small SAR satellites that Lumir is developing consists of an SAR antenna (SAM), transmit/receive module (TRM), TRM control module (TCM), and dividing & combining module (DCM).",
    },
    content2: {
      KR: "이 장치는 해외 수출제한품목 문제 해결로 인한 비용 절감은 물론 소형 경량화를 통해 수출 경쟁력을 확보함으로써 초소형 영상레이다(SAR) 위성 시장 진출을 기대하고 있습니다.",
      EN: "This device is expected to enter the ultra-small SAR satellite market by solving issues related to export restrictions on foreign items, reducing costs, and securing export competitiveness through miniaturization and weight reduction.",
    },
    sam1: {
      KR: "도파관 광벽 슬롯 배열 안테나",
      EN: "Waveguide Slot Array Antenna",
    },
    sam2: {
      KR: "복사패턴 형성",
      EN: "Radiation Pattern Formation",
    },
    sam3: {
      KR: "Elevation -10° to 10° 빔 조향 복사패턴 형성",
      EN: "Elevation -10° to 10° Beam Steering Radiation Pattern Formation",
    },
    trm1: {
      KR: "RF 출력 위상/세기 가변",
      EN: "Variable RF Output Phase/Intensity",
    },
    trm2: {
      KR: "송신 신호 출력 증폭",
      EN: "Amplified Transmission Signal Output",
    },
    trm3: {
      KR: "수신 신호 저잡음 증폭",
      EN: "Low-Noise Amplification of Received Signal",
    },
    trm4: {
      KR: "내부 온도 제공",
      EN: "Internal Temperature Monitoring",
    },
    trm5: {
      KR: "무게 및 장착영역을 고려한 소형화 설계",
      EN: "Miniaturized Design Considering Weight and Mounting Area",
    },
    tcm1: {
      KR: "PRF에 따라 TRM 출력 및 경로 제어",
      EN: "Controlling TRM Output and Path According to PRF",
    },
    tcm2: {
      KR: "빔 조향시 TRM의 RF 출력 위상제어",
      EN: "RF Output Phase Control of TRM During Beam Steering",
    },
    tcm3: {
      KR: "TRM 온도 감시 및 온도 변화에 따른 RF 출력 위상/세기 제어",
      EN: "TRM Temperature Monitoring and RF Output Phase/Intensity Control Based on Temperature Changes",
    },
    tcm4: {
      KR: "28 VDC의 안정화된 전원을 공급받아 TRM에 전원 제공",
      EN: "Supplying TRM with 28 VDC Stabilized Power",
    },
    tcm5: {
      KR: "TRM 전류 상태 감시",
      EN: "Monitoring TRM Current Status",
    },
    dcm1: {
      KR: "RF 신호의 분배, 결합",
      EN: "Distribution and Combination of RF Signals",
    },
  },
  rndModal6: {
    title: {
      KR: "초분광 센서를 이용한 머신비전 시스템 구축 개발",
      EN: "Development of Machine Vision System Using Hyperspectral Sensors",
    },
    content1: {
      KR: "루미르는 해외기술에 전량 의존하고 있는 초분광 카메라의 핵심 기술을 확보하여 세계적인 수준의 성능을 갖춘 머신비전 제품을 개발 중입니다.",
      EN: "Lumir is developing a machine vision product with world-class performance by securing core technologies for hyperspectral cameras, which have previously relied entirely on foreign technology.",
    },
    content2: {
      KR: "이 기술은 국내 머신비전 시스템에서 주로 사용하는 가시광 비전 센서의 정보 제한성을 개선할 수 있으며, IoT 기술과 결합하여 F&B 분야에서 자동화된 검출 시스템을 구축하고 비용을 절감하는 효과를 가져옵니다.",
      EN: "This technology can improve the information limitations of visible light vision sensors, which are mainly used in domestic machine vision systems, and in combination with IoT technology, it enables the establishment of automated detection systems in the F&B sector, resulting in cost savings.",
    },
  },
  menuBar: {
    scienceAndICT: {
      KR: "과학기술정보통신부",
      EN: "Ministry of Science and ICT",
    },
    defenseScience: {
      KR: "국방과학연구소",
      EN: "Agency for Defense Development",
    },
    koreaTechnologyInformation: {
      KR: "중소기업기술정보진흥원",
      EN: "Korea Technology & Information\nPromotion Agency for SMEs",
    },
    koreaIndustrialTechnology: {
      KR: "한국산업기술진흥협회",
      EN: "Korea Industrial Technology\nPromotion Association",
    },
  },
};
