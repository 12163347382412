import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { financialInfoLang } from "../../../lang/investment/financialInfoLang";

function BRCard({ style, title, date, number }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  const [isDetail, setIsDetail] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#fff",
            borderBottom: "1px solid #ADB4BA",
            height: isMobile ? 64 : 66,
            width: "100%",
            padding: isMobile ? "0px 8px" : isTablet ? "0px 20px" : "0px 30px",
            ...style,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 600,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              color: "#778088",
              width: isMobile ? 20 : isTablet ? 60 : 70,
            }}
          >
            {number}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 400,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              color: "#262C31",
              flex: 1,
            }}
          >
            {title}
            {langValue === "KR" ? "년" : ""}
          </PretendardText>

          {isDetail ? (
            <img
              onClick={() => {
                setIsDetail(false);
              }}
              style={{
                cursor: "pointer",
                width: isSmall ? 24 : 30,
                height: isSmall ? 24 : 30,
              }}
              src="/assets/icons/up_arrow.svg"
            />
          ) : (
            <img
              onClick={() => {
                setIsDetail(true);
              }}
              style={{
                cursor: "pointer",
                width: isSmall ? 24 : 30,
                height: isSmall ? 24 : 30,
              }}
              src="/assets/icons/down_arrow.svg"
            />
          )}
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              borderBottom: "1px solid #ADB4BA",
              height: isMobile ? "auto" : 74,
              width: "100%",
              padding: isTablet ? "0px 20px" : "0px",
            }}
          >
            <QuarterWrapper
              $isMobile={isMobile}
              $isTablet={isTablet}
              style={{
                paddingLeft: isMobile ? 10 : 0,
              }}
            >
              <QuarterText $isSmall={isSmall}>
                {financialInfoLang.bnReport1Quarter[langValue]}
              </QuarterText>
              <img
                style={{
                  cursor: "pointer",
                  width: isTablet ? 30 : 34,
                  height: isTablet ? 30 : 34,
                }}
                src="/assets/icons/download02.svg"
              />
            </QuarterWrapper>
            <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
              <QuarterText $isSmall={isSmall}>
                {financialInfoLang.bnReport2Quarter[langValue]}
              </QuarterText>
              <img
                style={{
                  cursor: "pointer",
                  width: isTablet ? 30 : 34,
                  height: isTablet ? 30 : 34,
                }}
                src="/assets/icons/download02.svg"
              />
            </QuarterWrapper>
            <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
              <QuarterText $isSmall={isSmall}>
                {financialInfoLang.bnReport3Quarter[langValue]}
              </QuarterText>
              <img
                style={{
                  cursor: "pointer",
                  width: isTablet ? 30 : 34,
                  height: isTablet ? 30 : 34,
                }}
                src="/assets/icons/download02.svg"
              />
            </QuarterWrapper>
            <QuarterWrapper
              $isMobile={isMobile}
              $isTablet={isTablet}
              style={{
                paddingRight: isMobile ? 10 : 0,
                border: "none",
              }}
            >
              <QuarterText $isSmall={isSmall}>
                {financialInfoLang.bnReport4Quarter[langValue]}
              </QuarterText>
              <img
                style={{
                  cursor: "pointer",
                  width: isTablet ? 30 : 34,
                  height: isTablet ? 30 : 34,
                }}
                src="/assets/icons/download02.svg"
              />
            </QuarterWrapper>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default BRCard;

const QuarterWrapper = styled.div`
  width: ${(props) => (props.$isMobile || props.$isTablet ? "100%" : "258px")};

  height: ${(props) => (props.$isMobile ? "64px" : "34px")};
  border-right: ${(props) => (props.$isMobile ? "none" : "1px solid #d7dce2")};
  border-bottom: ${(props) => (props.$isMobile ? "1px solid #d7dce2" : "none")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.$isTablet ? "0px 20px" : props.$isMobile ? "0px 10px" : "0px 40px"};

  box-sizing: ${(props) => (props.$isMobile ? "border-box" : "content-box")};
`;

const QuarterText = styled(PretendardText)`
  color: rgb(38, 44, 49);
  font-size: ${(props) => (props.$isSmall ? "16px" : "20px")};
  font-weight: ${(props) => (props.$isSmall ? "600" : "500")};
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "29px")};
  margin-right: 10px;
`;
