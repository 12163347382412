import { data as jobAnnounceData } from "../pages/home/datas/jobAnnounce";
import api from "./api";

export default class RecruitApi {
  static async GetList(data) {
    // return api.get(`recruit`, data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/process/position/?page=${data.pageNo}&limit=${data.pageSize}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();

      return {
        items: res.data.recruitmentFieldList.map((item) => {
          return {
            recruitStatus: item.status,
            department: "",
            title: item.title,
            id: item._id,
            permanent: item.status === "상시 채용",
            start: item.start,
            end: item.end,
            link: item.desc,
          };
        }),
        totalCount: res.data.totalCount || "-",
        totalPages: res.data.totalPages,
        isLast: data.pageNo === res.data.totalPages,
        isFirst: data.pageNo === 1,
      };
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  }

  static async GetRecent(data) {
    // return api.get(`recruit/recent`, data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/process/position/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();

      return res.data
        .map((item) => {
          return {
            recruitStatus: item.status,
            department: "",
            title: item.title,
            id: item._id,
          };
        })
        .filter(
          (item) =>
            item.recruitStatus === "상시 채용" ||
            item.recruitStatus === "채용 중"
        )
        .sort((a, b) => {
          // "채용 중"인 항목을 앞으로 정렬
          if (a.recruitStatus === "채용 중" && b.recruitStatus !== "채용 중") {
            return -1;
          }
          if (a.recruitStatus !== "채용 중" && b.recruitStatus === "채용 중") {
            return 1;
          }
          return 0;
        })
        .slice(0, 3);
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  }

  static async GetDetail(id) {
    // return api.get(`recruit/by-id${id}`);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/process/position/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();

      return {
        data: {
          data: {
            now: {
              recruitStatus: res.data.status,
              department: "",
              title: res.data.title,
              id: res.data._id,
              permanent: res.data.status === "상시 채용",
              content: jobAnnounceData.find((item) => item.id === id)?.img,
              isFullTime: res.data.employmentType === "상시 채용",
              createdAt: res.data.createdAt,
              updateAt: res.data.updatedAt,
              start: res.data.start,
              end: res.data.end,
            },
          },
        },
      };
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  }

  static async GetFormEN() {
    return api.get(`application-form/en`);
  }

  static async GetFormKR() {
    return api.get(`application-form/kr`);
  }

  static async AppSubmit(id, data) {
    return api.post(`application/${id}`, data);
  }
}
