import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";

const VideoHandler = ({ imageUrl, mediaUrl, isVideoUse = true }) => {
  const [state, setState] = useState({
    isLoaded: false,
    isPlaying: false,
    showPlayButton: false,
    mediaError: false,
    isPageLoaded: false,
  });

  const videoRef = useRef(null);

  const updateState = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const handleCanPlayThrough = useCallback(() => {
    updateState({ isLoaded: true });
    handlePlay();
  }, [updateState]);

  const handlePlay = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement
        .play()
        .then(() => {
          updateState({ isPlaying: true, showPlayButton: false });
        })
        .catch((error) => {
          console.error("Play failed:", error);
          updateState({ isPlaying: false, showPlayButton: true });
        });
    }
  }, [updateState]);

  const handlePause = useCallback(() => {
    updateState({ isPlaying: false, showPlayButton: true });
  }, [updateState]);

  const handleMediaError = useCallback(() => {
    console.error("Media failed to load");
    updateState({ mediaError: true, showPlayButton: false });
  }, [updateState]);

  useEffect(() => {
    const handlePageLoad = () => {
      updateState({ isPageLoaded: true });
    };

    if (document.readyState === "complete") {
      handlePageLoad();
    } else {
      window.addEventListener("load", handlePageLoad);
      return () => window.removeEventListener("load", handlePageLoad);
    }
  }, [updateState]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && state.isPageLoaded) {
      videoElement.addEventListener("canplaythrough", handleCanPlayThrough);
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
      videoElement.addEventListener("error", handleMediaError);

      // 초기 로드 및 자동 재생 시도
      videoElement.load();

      return () => {
        videoElement.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough
        );
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
        videoElement.removeEventListener("error", handleMediaError);
      };
    }
  }, [
    state.isPageLoaded,
    handleCanPlayThrough,
    handlePlay,
    handlePause,
    handleMediaError,
  ]);

  const renderMedia = useCallback(() => {
    if (state.mediaError) {
      return <Image src={imageUrl} alt="Fallback image" />;
    }

    if (!state.isPageLoaded) {
      return <Image src={imageUrl} alt="Placeholder image" />;
    }

    return (
      <>
        <Video
          ref={videoRef}
          loop
          muted
          playsInline
          preload="auto"
          onError={handleMediaError}
          poster={imageUrl}
        >
          <source src={mediaUrl} type="video/mp4" />
        </Video>
        {state.isLoaded && !state.isPlaying && state.showPlayButton && (
          <PlayButton onClick={handlePlay} aria-label="Play video">
            ▶
          </PlayButton>
        )}
      </>
    );
  }, [state, imageUrl, mediaUrl, handleMediaError, handlePlay]);

  // TODO: IR 이후 주석 풀기
  return (
    <Container>
      {isVideoUse ? (
        <>
          {renderMedia()}
          {!state.isLoaded && state.isPageLoaded && !state.mediaError && (
            <LoadingOverlay>
              <Image src={imageUrl} alt="Video placeholder" />
            </LoadingOverlay>
          )}
        </>
      ) : (
        <Image src={imageUrl} alt="Video placeholder" />
      )}
    </Container>
  );
};

export default React.memo(VideoHandler);

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const LoadingIndicator = styled.div`
  color: white;
  font-size: 18px;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
