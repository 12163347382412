import React, { useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useLocation, useNavigate } from "react-router-dom";
import useDisplay from "../../hooks/useDisplay";
import PreparedModal from "../../pages/modal/preparedModal";
import { route } from "../../router/route";

const SubMenu = ({ depthList }) => {
  const { isMobile } = useDisplay();
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [isPreparedModal, setIsPreparedModal] = useState(false);

  return (
    <SubMenuWrapper $isMobile={isMobile}>
      {depthList?.map((item, index) => {
        if (item.exposure === false) {
          return;
        }

        return (
          <SubMenuTextWrapper
            key={index}
            onClick={() => {
              if (item.link === route.faq) {
                return;
              }

              if (item.link === route.stockInfo) {
                return setIsPreparedModal(true);
              } else {
                navigation(item.link);
              }
            }}
          >
            <SubMenuText
              $active={pathname.includes(item.link)}
              $isMobile={isMobile}
            >
              {item.title}
            </SubMenuText>
            <CrossLine />
          </SubMenuTextWrapper>
        );
      })}

      {isPreparedModal ? (
        <PreparedModal
          onClick={() => {
            setIsPreparedModal(false);
          }}
        />
      ) : null}
    </SubMenuWrapper>
  );
};

export default SubMenu;

const SubMenuWrapper = styled.div`
  min-width: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: ${($isMobile) => ($isMobile ? "32px" : "40px")};
  left: 0px;
  z-index: 10;
`;

const SubMenuTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubMenuText = styled(PretendardText)`
  width: 100%;
  font-weight: 400;
  font-size: ${(props) => (props.$isMobile ? "12px" : "15px")};
  line-height: 21px;
  color: ${(props) => (props.$active ? "#00aeef" : "#5a636a")};
  /* cursor: pointer; */
  padding: ${(props) => (props.$isMobile ? "12px 20px" : "15px")};

  &:hover {
    color: #00aeef;
  }
`;

const CrossLine = styled.div`
  border-bottom: 1px solid #f1f1f1;
  width: 90%;
`;
