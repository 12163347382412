import React, { useEffect, useState } from "react";
import styled from "styled-components";
import KickinText from "../../../components/atoms/textarea/kickinText";

const firstIconImage = "/assets/imgs/renewal/home/intro/start-bg.webp";
const earthSatelliteImage =
  "/assets/imgs/renewal/home/intro/earth-satellite.png";

const data = {
  KR: {
    text1: "주야간 전천후",
    text2: "지구를 비추다",
  },
  EN: {
    text1: "Day and night, <br/>all-weather",
    text2: "Lighting up <br/>the earth",
  },
};

const MainTemp = ({ langValue, isDesktop, isMobile }) => {
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);

  useEffect(() => {
    const preloadImage = (src) => {
      return new Promise((resolve, reject) => {
        if (typeof window === "undefined") {
          // Server-side: resolve immediately
          resolve();
          return;
        }
        const img = new window.Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    };

    preloadImage(firstIconImage)
      .then(() => setIsBackgroundLoaded(true))
      .catch((error) =>
        console.error("Failed to load background image:", error)
      );
  }, []);

  return (
    <Container $isDesktop={isDesktop} $isLoaded={isBackgroundLoaded}>
      <Wrapper $isDesktop={isDesktop}>
        <KickinText>
          <Text dangerouslySetInnerHTML={{ __html: data[langValue].text1 }} />
        </KickinText>
        <ImageContainer $width={!isMobile && 48} $isDesktop={isDesktop}>
          <Image src={earthSatelliteImage} />
        </ImageContainer>
        <KickinText delay="1s">
          <Text dangerouslySetInnerHTML={{ __html: data[langValue].text2 }} />
        </KickinText>
      </Wrapper>
    </Container>
  );
};

export default MainTemp;

const Container = styled.div`
  position: relative;
  top: ${(props) => (props.$isDesktop ? "-80px" : "-65px")};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${(props) =>
    props.$isLoaded ? `url(${firstIconImage})` : "none"};
  background-color: ${(props) => (props.$isLoaded ? "transparent" : "#000")};
  color: white;
  background-size: cover;
`;

const Wrapper = styled.div`
  position: absolute;
  max-width: 1400px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  justify-content: ${(props) =>
    props.$isDesktop ? "space-between" : "center"};
  align-items: center;
`;

const Text = styled.div`
  font-size: 40.9px;
  line-height: 7.5rem;
  font-weight: 700;

  letter-spacing: 0.09em;
  text-align: center;

  width: fit-content;
`;

const ImageContainer = styled.div`
  ${(props) => !props.$isDesktop && "height: 50%;"}
  width: auto;
  text-align: center;
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
`;
