import { route } from "../../router/route";

export const sardip = {
  KR: {
    header: "루미르는 AI기반 지구관측 SAR 솔루션을 제공합니다.",
    intro: {
      subTitle: "SARDIP™ Web Platform",
      title1: {
        WIDE: "AI기반 지구관측 <span>SAR 솔루션</span>",
        THIN: "AI기반 지구관측<br/><span>SAR 솔루션</span>",
      },
      image: "/assets/imgs/renewal/data/intro.png",
      title2: ["LUMIR", "SARDIP™", "PLATFORM"],
      leftLists: [
        "· Web-based user-friendly interface",
        "· Data search and download",
        "· Image analysis solutions provided",
      ],
      rightContents: [
        {
          title: "SARDIP Web Platform",
          description: [
            "루미르는 SARDIP 웹플랫폼을 통해 LumirX의 영상을 검색, 구매 및 분석 서비스를 제공합니다.<br/>SARDIP™을 통해 <span>근실시간으로 최신 SAR 영상을 구매</span>할 수 있으며,<br/>방대한 <span>SAR 영상 아카이브를 빠른 시간내에 검색</span>하여 고객이 원하는 영상을 쉽게<br/>찾아내고 구매할 수 있습니다.",
            "SARDIP™는 InSAR 기반 지형정보 분석, AI 기반 객체 탐지 서비스를 제공하여<br/><span>데이터 분석 결과를 빠른 시간 내</span>에 얻을 수 있습니다.",
            "향후 LumirX 위성 시리즈의 <span>영상획득 요청 기능</span>을 추가하여<br/>고객 요청부터 영상 및 정보 획득을 SARDIP™ 플랫폼에서 일괄 처리할 것입니다.",
          ],
          type: "text",
        },
        {
          title: "핵심 기능",
          description: [
            "· SAR 영상의 검색, 구매, 분석",
            "· 사용자 SAR 영상 업로드 및 분석",
            "· 영상 획득 스케쥴 검색, 영상 획득 요청",
          ],
          type: "list",
        },
      ],
    },
    data: [
      {
        name: "SARImageProcessing",
        id: route.sarImage,
        title: "SAR Level-1 영상 처리",
        subTitle: "원시 데이터로부터 SAR Level-1 제품 생성",
        description:
          "SARDIP™은 LumirX에 의해 획득된 원시 데이터로부터 몇 분 내에 Level-1A/B/C/D SAR 영상을 생성합니다.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/sar_image.mp4",
          mobile: "/assets/imgs/renewal/data/videos/sar_image.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/sar_image.webp",
      },
      {
        name: "AIDetection",
        id: route.ai,
        title: "AI기반 객체 탐지",
        subTitle: "선박 식별",
        description:
          "고해상도로 촬영한 SAR 영상에서 선박, 항공기 등 군사적, 경제적으로 중요한 물체를 AI 기반으로 자동적으로 탐지하고, 식별할 수 있습니다.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/SAR활용_선박식별.mp4",
          mobile:
            "/assets/imgs/renewal/data/videos/SAR활용_선박식별_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/SAR활용_선박식별.png",
      },
      {
        name: "DisasterAnalyst",
        id: route.disaster,
        title: "재난재해 피해분석",
        subTitle: "지진 피해지역 파악",
        description:
          "Differential InSAR 기법을 이용하여 지진 등 단시간내에 발생한 재난재해의 피해영역을 파악할 수 있습니다. 재난 발생 전후에 촬영된 영상의 위상간섭무늬를 분석하여 재난 피해 범위 및 심각도를 분석합니다.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/지진_피해지역_파악.mp4",
          mobile:
            "/assets/imgs/renewal/data/videos/지진_피해지역_파악_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/지진_피해지역_파악.png",
      },
      {
        name: "CityChangeMonitoring",
        id: route.monitoring,
        title: "도시 변화 모니터링",
        subTitle: "도심 지역의 지표 변화",
        description:
          "장시간에 걸쳐 획득된 여러 장의 SAR 영상을 활용하여 지표면의 기간에 따른 침하/융기를 mm 단위로 정밀하게 분석할 수 있습니다.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/도심지역의_지표변화.mp4",
          mobile:
            "/assets/imgs/renewal/data/videos/도심지역의_지표변화_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/도심지역의_지표변화.png",
      },
      {
        name: "WaterFacilityManage",
        id: route.water_facility,
        title: "수자원 시설 안전 관리",
        subTitle: "댐 변형 모니터링",
        description:
          "댐, 건물, 도로, 철도 등 주요 대형 인프라의 안정성 분석을 위해 시계열 InSAR 기법을 적용하여 시설물의 변형 정도를 파악할 수 있습니다. 장시간에 걸쳐 획득된 여러 장의 SAR 영상을 시계열로 분석하여 mm 단위의 변형정도를 알아내어 시설물 안전여부를 조기에 파악할 수 있는 정보를 제공합니다.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/댐변형_모니터링.mp4",
          mobile: "/assets/imgs/renewal/data/videos/댐변형_모니터링_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/댐변형_모니터링.png",
      },
    ],
  },
  EN: {
    header: "Lumir provides AI-based Earth Observation SAR solutions.",
    intro: {
      subTitle: "SARDIP™ Web Platform",
      title1: {
        WIDE: "AI-based Earth Observation <span>SAR Solution</span>",
        THIN: "AI-based Earth Observation<br/><span>SAR Solution</span>",
      },
      image: "/assets/imgs/renewal/data/intro.png",
      title2: ["LUMIR", "SARDIP™", "PLATFORM"],
      leftLists: [
        "· Web-based user-friendly interface",
        "· Data search and download",
        "· Image analysis solutions provided",
      ],
      rightContents: [
        {
          title: "SARDIP Web Platform",
          description: [
            "Lumir provides LumirX images through the SARDIP web platform, providing search, purchase, and analysis services.<br/>Through SARDIP™, you can purchase the latest SAR images in <span>near real-time</span> and search a vast <span>SAR image archive quickly</span> to easily find and purchase the images you want.",
            "SARDIP™ provides InSAR-based terrain information analysis and AI-based object detection services, allowing you to obtain <span>data analysis results in a short time</span>.",
            "In the future, we will add a <span>feature to request image acquisition</span> for the LumirX satellite series, and process image and information acquisition from customer requests to the SARDIP™ platform in batch.",
          ],
          type: "text",
        },
        {
          title: "Key Features",
          description: [
            "· Search, purchase, and analysis of SAR images",
            "· Upload and analyze user SAR images",
            "· Search for image acquisition schedules, and request image acquisition",
          ],
          type: "list",
        },
      ],
    },
    data: [
      {
        name: "SARImageProcessing",
        id: route.sarImage,
        title: "SAR Level-1 Image Processing",
        subTitle: "Generate SAR Level-1 product from raw data",
        description:
          "SARDIP™ generate Level-1A/B/C/D SAR Images from raw data acquired by LumirX in several minutes after downlink.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/sar_image.mp4",
          mobile: "/assets/imgs/renewal/data/videos/sar_image_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/sar_image.webp",
      },
      {
        name: "AIDetection",
        id: route.ai,
        title: "AI-based Object Detection",
        subTitle: "Ship Identification",
        description:
          "In high-resolution SAR images, AI can automatically detect and identify objects such as ships and aircraft that are militarily and economically important.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/SAR활용_선박식별.mp4",
          mobile:
            "/assets/imgs/renewal/data/videos/SAR활용_선박식별_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/SAR활용_선박식별.png",
      },
      {
        name: "DisasterAnalyst",
        id: route.disaster,
        title: "Disaster Damage Analysis",
        subTitle: "Earthquake Damage Area Identification",
        description:
          "Using Differential InSAR techniques, you can identify the damage area of disasters such as earthquakes that occur within a short period of time. Analyze the phase interference patterns of images taken before and after the disaster to analyze the range and severity of the disaster damage.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/지진_피해지역_파악.mp4",
          mobile:
            "/assets/imgs/renewal/data/videos/지진_피해지역_파악_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/지진_피해지역_파악.png",
      },
      {
        name: "CityChangeMonitoring",
        id: route.monitoring,
        title: "Urban Change Monitoring",
        subTitle: "Surface Change in Urban Areas",
        description:
          "Using multiple SAR images acquired over a long period of time, you can precisely analyze the subsidence/uplift of the surface in mm units over time.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/도심지역의_지표변화.mp4",
          mobile:
            "/assets/imgs/renewal/data/videos/도심지역의_지표변화_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/도심지역의_지표변화.png",
      },
      {
        name: "WaterFacilityManage",
        id: route.water_facility,
        title: "Water Resource Facility Safety Management",
        subTitle: "Dam Deformation Monitoring",
        description:
          "For the safety analysis of major infrastructures such as dams, buildings, roads, and railways, apply time-series InSAR techniques to identify the degree of deformation of facilities. By analyzing multiple SAR images acquired over a long period of time in a time series, you can provide information that can identify the safety of facilities early by identifying the degree of deformation in mm units.",
        video: {
          desktop: "/assets/imgs/renewal/data/videos/댐변형_모니터링.mp4",
          mobile: "/assets/imgs/renewal/data/videos/댐변형_모니터링_mobile.mp4",
        },
        image: "/assets/imgs/renewal/data/videos/댐변형_모니터링.png",
      },
    ],
  },
};
