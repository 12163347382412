import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";

import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "./../../../components/templates/middleNavBar";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import PretendardText from "../../../components/atoms/text/pretendardText";
import GSMCard from "../../../components/organisms/investment/gsmCard";

import useDisplay from "../../../hooks/useDisplay";
import Shareholders from "../../../components/templates/investment/shareholders";
import ShareholdersM from "../../../components/templates/investment/shareholdersM";
import GSMCardM from "../../../components/organisms/investment/gsmCardM";

import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { shareholderLang } from "../../../lang/investment/shareholderLang";
import ShareholderApi from "../../../api/shareholderApi";
import Pagination from "../../../components/organisms/pagination";
import { route } from "../../../router/route";
import { meetingLang } from "../../../lang/investment/meetingLang";

const Meeting = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();
  const langValue = useRecoilValue(langState);

  const [totalCtn, setTotalCtn] = useState(0);

  const [shareHolderData, setShareHolder] = useState();
  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 3, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    getShareholderListAPI();
    getShareHolderAPI();
  }, [location, langValue]);

  const getShareHolderAPI = async () => {
    try {
      let response;
      if (langValue === "KR") {
        response = (await ShareholderApi.GetKR()).data.data;
      } else {
        response = (await ShareholderApi.GetEN()).data.data;
      }

      setShareHolder(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getShareholderListAPI = async () => {
    try {
      const data = {
        pageNo: page,
        pageSize: pagination.size,
        language: langValue,
      };

      const response = (await ShareholderApi.GetList(data)).data.data;

      setTotalCtn(response.totalCount);
      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: response.totalPage,
        first: response.isFirst,
        last: response.isLast,
      });
      setDataList(response.items);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_communication.png"
            : "/assets/imgs/main-section/communication.png"
        }
        title={mainBannerLang.generalShareholderMeeting.title[langValue]}
        content={mainBannerLang.generalShareholderMeeting.content[langValue]}
        blackText={isMobile ? false : true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
            marginBottom: isSmall ? 40 : 51,
          }}
        >
          {shareHolderData ? shareHolderData.roundNum : ""}
          {shareholderLang.meetingTitle[langValue]}
        </PretendardText>

        {isMobile ? (
          <ShareholdersM data={shareHolderData} />
        ) : (
          <Shareholders data={shareHolderData} />
        )}

        {dataList.length > 0 ? (
          <>
            <PretendardText
              style={{
                color: "#262C31",
                fontSize: isSmall ? "18px" : "24px",
                fontWeight: 600,
                lineHeight: isSmall ? "26.1px" : "34.8px",
                marginTop: isSmall ? 80 : 120,
              }}
            >
              {shareholderLang.meetingPreTitle[langValue]}
            </PretendardText>

            <div
              style={{
                borderTop: "2px solid #778088",
                borderBottom: "2px solid #778088",
                marginTop: isMobile ? 10 : 20,
                width: "100%",
              }}
            >
              {dataList.map((item, index) => {
                return isMobile ? (
                  <GSMCardM
                    key={index}
                    number={index + 1 + (page - 1) * 10}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    data={item}
                  />
                ) : (
                  <GSMCard
                    key={index}
                    number={index + 1 + (page - 1) * 10}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                    data={item}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: isSmall ? 40 : 60,
              }}
            >
              <Pagination
                route={route.meeting}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </>
        ) : null}
      </BodyConatin>
    </>
  );
};

export default Meeting;
