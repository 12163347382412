export const lunarExplorationData = {
  id: 2,
  createdAt: "2024-04-19T14:09:20.086Z",
  updatedAt: "2024-04-19T14:10:45.409Z",
  pageName: "달 탐사용 통신장치",
  productName: "달 탐사용 통신장치",
  subtitle: "DTNPL: Delay-Tolerant Network Payload",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/fd78f924-73f6-45e9-8dac-ad9444496611.png",
  serverFileName: "fd78f924-73f6-45e9-8dac-ad9444496611.png",
  originFileName: "Untitled-2 2.png",
  exposure: true,
  __productContents__: [
    {
      id: 63,
      createdAt: "2024-04-19T14:09:20.086Z",
      updatedAt: "2024-05-24T18:05:22.774Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>우주인터넷 장비는 우주 탐사선과 인공위성 등을 서로 연결해 우주에서 인터넷 서비스가 가능하게 하는 장치입니다.</p><p>지연-내성 네트워크인 DTN(Delay-Tolerant Network)은 네트워크를 연결하는 중계 부분인 노드에서 데이터를 저장해두고</p><p>다른 노드와 연결되면 저장했던 데이터를 다시 전송해주는 통신 기술이며, 일부 구간에서 통신이 끊겨도</p><p>데이터를 중간에 저장해두어 연결이 회복되면 다시 전달하는 방식의 우주용 인터넷입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 64,
      createdAt: "2024-04-19T14:09:20.086Z",
      updatedAt: "2024-05-24T18:05:22.808Z",
      title: "제원",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>ㆍ크기: 120 mm*170 mm*39 mm</p><p>ㆍ무게: 0.79 kg</p><p>ㆍ소모전력: &lt;12 W</p>",
      __productContentImages__: [],
    },
    {
      id: 130,
      createdAt: "2024-05-24T18:04:29.787Z",
      updatedAt: "2024-05-24T18:05:22.836Z",
      title: "img1",
      orderNum: 3,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 2,
          createdAt: "2024-05-24T18:04:29.972Z",
          updatedAt: "2024-05-24T18:04:29.972Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a2400713-a6e8-4cf8-a685-a4a2a8a6a91c.png",
          serverFileName: "a2400713-a6e8-4cf8-a685-a4a2a8a6a91c.png",
          originFileName: "Group 1410116902.png",
        },
      ],
    },
    {
      id: 131,
      createdAt: "2024-05-24T18:04:29.983Z",
      updatedAt: "2024-05-24T18:05:22.863Z",
      title: "우주인터넷 장치의 필요성",
      orderNum: 4,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>환경이 다른 우주에서는 지구와 다른 인터넷 기술을 사용해야 합니다.</p><p>즉, 지구를 바라볼 때만 전파를 지구로 직접 전송할 수 있어 하루에 연결되는 시간에 제한이 있으며,</p><p>전파가 먼 거리를 이동하며 중간에 끊길 위험이 있습니다.</p><p><br></p><p>예를 들어, 화성에서 지구로 전파를 빛의 속도로 전송하는 데 최소 5분 이상 걸리는데,</p><p>현재 우주 통신체계에서는 중간에 데이터 전송이 끊기면 처음부터 다시 전송해야 합니다.</p><p><br></p><p>이러한 문제를 해결하고자, 우주인터넷은 여러 대 탐사선과 위성 등을 지구의 중계기처럼 사용합니다.</p><p>우주 탐사선들을 노드로 이용하면 지구의 인터넷처럼 신호를 끊이지 않고 전송하면서 활용 폭이 넓어질 수 있습니다.</p><p>또한 전송 과정에서 오류가 발생하는 일도 줄어들 것입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 132,
      createdAt: "2024-05-24T18:05:22.889Z",
      updatedAt: "2024-05-24T18:05:22.889Z",
      title: "DTN 경쟁력",
      orderNum: 5,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>달에 탐사선을 보낸 국가는 구소련, 유럽연합, 미국, 일본, 중국, 인도 6개국 밖에 없으며, 우리나라는 ‘한국형 달 탐사 사업을</p><p>진행중에 있습니다. 달 탐사는 단순히 과학적인 목적 뿐만 아니라 해당 국가의 우주기술력을 상징하는 것이기 때문에,</p><p>해당 개발을 통하여 루미르의 기술력을 알리고 있습니다.</p><p><br></p><p>달 탐사 위성 기술검증 DTN 탑재체는 우주 공간 내 DTN통신 기술 시험을 목적으로 개발되었습니다.</p><p>차세대 우주용 핵심소자인 RTG4 FPGA 기반 구현으로 소형, 경량화를 달성하였고, NASA가 개발한 통신 소프트웨어 ION을 </p><p>적용하는 등 타사에서 범접할 수 없는 기술이 적용되었습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 133,
      createdAt: "2024-05-24T18:05:22.912Z",
      updatedAt: "2024-05-24T18:05:22.912Z",
      title: "DTN 역할",
      orderNum: 6,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>한국의 달 궤도선이 달에 도달하면 우주인터넷 장비는 달 궤도에서 문자와 파일, 동영상을 한국 지상국과 심우주 통신망으로 전송하는 시험이 진행됩니다. 데이터 용량이 작은 메시지로 달에서 신호를 보내는 것과 반대로 지구에서 달로 신호를 보내는 양방향 통신이</p><p>모두 진행되며, 데이터 용량이 큰 영상은 달 궤도선에서 지구로 송신만 진행됩니다.</p><p><br></p><p>DTN 기술이 우주에서 표준 통신으로 정해지면 미국뿐만 아니라 한국의 달 탐사선 등 다른 국적의 탐사선끼리도 끊김 없는 통신이</p><p>가능해집니다. 한국형 달 착륙선에도 DTN 기술이 적용되며, 달 착륙선과 달 탐사용 로버가 주변에 통신 신호를 보내면</p><p>DTN으로 연결된 달 궤도선을 거쳐 지구로 전송합니다.</p>",
      __productContentImages__: [],
    },
  ],
};
