import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useDisplay from "../../hooks/useDisplay";
import FramerWipe from "../../styles/framerWipe";

const Intro = ({ data }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [activeSection, setActiveSection] = useState("first");
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const firstCurrent = firstSectionRef.current;
    const secondCurrent = secondSectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(
              entry.target === firstCurrent ? "first" : "second"
            );
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (firstCurrent) observer.observe(firstCurrent);
    if (secondCurrent) observer.observe(secondCurrent);

    return () => {
      if (firstCurrent) observer.unobserve(firstCurrent);
      if (secondCurrent) observer.unobserve(secondCurrent);
    };
  }, []);

  return (
    <IntroContainer $activeSection={activeSection}>
      <IntroWidth>
        <IntroFirst ref={firstSectionRef} $isDesktop={isDesktop}>
          <SubTitle>{data.subTitle}</SubTitle>
          <FramerWipe>
            <Title1
              dangerouslySetInnerHTML={{
                __html: isDesktop ? data.title1.WIDE : data.title1.THIN,
              }}
              $activeSection={activeSection}
              $isMobile={isMobile}
            />
          </FramerWipe>
          <ImageSection>
            <Image src={data.image} />
          </ImageSection>
        </IntroFirst>
        <IntroSecond
          ref={secondSectionRef}
          $isMobile={isMobile}
          $isTablet={isTablet}
        >
          <LeftContainer>
            {data.title2.map((text, index) => (
              <Title2 $index={index} key={index}>
                {text}
              </Title2>
            ))}
            <LeftUl>
              {data.leftLists.map((text, index) => (
                <LeftLi key={index}>{text}</LeftLi>
              ))}
            </LeftUl>
          </LeftContainer>
          <RightContainer $isDesktop={isDesktop}>
            {data.rightContents.map((content, index) => (
              <FramerWipe direction="down" delay={`${index / 2}`} key={index}>
                <Container key={index}>
                  <RightTitle>{content.title}</RightTitle>
                  <Divider />
                  {content.type === "list" ? (
                    <RightUl>
                      {content.description.map((text, index) => (
                        <RightLi key={index}>{text}</RightLi>
                      ))}
                    </RightUl>
                  ) : (
                    <Description>
                      {content.description.map((text, index) => (
                        <Content
                          key={index}
                          dangerouslySetInnerHTML={{ __html: text }}
                        />
                      ))}
                    </Description>
                  )}
                </Container>
              </FramerWipe>
            ))}
          </RightContainer>
        </IntroSecond>
      </IntroWidth>
    </IntroContainer>
  );
};

Intro.displayName = "Intro";

export default Intro;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$activeSection === "first" ? "#7f2ca7" : "white"};
  color: ${(props) => (props.$activeSection === "first" ? "white" : "black")};
  transition: background-color 0.8s ease, color 0.8s ease;
`;

const IntroWidth = styled.div`
  width: 100%;
  max-width: 1400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// 1st Section
const IntroFirst = styled.div`
  margin: ${(props) => (props.$isDesktop ? "0 10rem" : "0 5rem")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8.5rem 0;
  min-height: 100vh;
`;

const SubTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
`;

const Title1 = styled.div`
  font-size: ${({ $isMobile }) => ($isMobile ? "5.5rem" : "11rem")};
  line-height: ${({ $isMobile }) => ($isMobile ? "10rem" : "16.6rem")};
  text-align: ${({ $isDesktop }) => ($isDesktop ? "left" : "center")};

  & > span {
    font-weight: 700;
    color: ${(props) =>
      props.$activeSection === "first" ? "white" : "#7f2ca7"};
  }
`;

const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-top: 7.2rem;
`;

// 2nd Section
const IntroSecond = styled.div`
  ${({ $isMobile, $isTablet }) => {
    if ($isMobile) {
      return `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 18.5rem 5rem;
      min-height: 100vh;
      gap: 5rem;
      margin-bottom: 15.5rem;
      `;
    } else if ($isTablet) {
      return `
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      padding: 18.5rem 5rem 0 5rem;
      min-height: 90vh;
    `;
    } else {
      return `
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      padding: 18.5rem 0;
      min-height: 100vh;
    `;
    }
  }}
`;

// 2nd Section - Left
const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
`;

const Title2 = styled.div`
  font-size: 6.4rem;
  font-weight: 900;

  ${({ $index }) => {
    if ($index === 1) {
      return `
        color: #7f2ca7;
        text-shadow: -1px 0px white, 0px 1px white, 1px 0px white, 0px -1px white;
      `;
    }
  }}
`;

const LeftUl = styled.ul`
  display: flex;
  flex-direction: column;
  color: #8a8a8a;
`;

const LeftLi = styled.li`
  font-size: 1.9rem;
  line-height: 2.85rem;
`;

// 2nd Section - Right
const RightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ $isDesktop }) => ($isDesktop ? "14.9rem" : "5rem")};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
`;

const RightTitle = styled.div`
  font-size: 2.3rem;
  font-weight: 700;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a8a8a8;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 2.85rem;
`;

const Content = styled.div`
  font-weight: 400;

  & > span {
    /* color: white;
    background-color: #7f2ca7; */
    /* border-bottom: 2px solid #7f2ca7; */
    text-shadow: -1px 0px white, 0px 1px white, 1px 0px white, 0px -1px white;
    color: #7f2ca7;
    font-weight: 700;
  }
`;

const RightUl = styled.ul`
  display: flex;
  flex-direction: column;
`;

const RightLi = styled.li`
  font-size: 1.9rem;
  line-height: 2.85rem;
`;
