import React from "react";
import { styled } from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

const AwardCard = ({ src, cardTitle, title, date }) => {
  const { isMobile, isTablet } = useDisplay();

  return (
    <CardWrapper>
      {src ? (
        <img src={src} alt="" style={{ width: "320px", height: "450px", objectFit: "cover" }} />
      ) : (
        <>
          <Card $isMobile={isMobile} $isTablet={isTablet}>
            <div dangerouslySetInnerHTML={{ __html: cardTitle }} />
          </Card>
        </>
      )}
      <TitleText $isMobile={isMobile} $isTablet={isTablet}>
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </TitleText>
      <DateText>{date}</DateText>
    </CardWrapper>
  );
};

export default AwardCard;

const CardWrapper = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Card = styled.div`
  width: 320px;
  height: 450px;
  border: 1px solid var(--Gray06, #d7dce2);
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.$isMobile || props.$isTablet ? "16px" : "20px")};
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.2px;
  color: #adb4ba;
  text-align: center;
`;

const TitleText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile || props.$isTablet ? "16px" : "20px")};
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.2px;
  color: #262c31;
  text-align: center;
`;

const DateText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  color: #adb4ba;
`;
