import React from "react";
import ContentBox from "../../../../../components/templates/contentBox";
import styled from "styled-components";
import PretendardText from "../../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../../hooks/useDisplay";
import { langState } from "../../../../../store/langState";
import { useRecoilValue } from "recoil";
import { titleLang } from "../../../../../lang/Business/title";

const Specifications = () => {
  const { isMobile } = useDisplay();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <ContentBox
        style={{ marginTop: "30px" }}
        title={titleLang.specifications[langValue]}
        content={
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <ContentText $isMobile={isMobile}>
              ㆍ{titleLang.specifications.size[langValue]}: 19 inches, 27 U, 38 U<br />ㆍ
              {titleLang.specifications.weight[langValue]}: &lt;975 kg
              <br />ㆍ{titleLang.specifications.capacity[langValue]}: 1.5 TB
              <br />ㆍ{titleLang.specifications.powerConsumption[langValue]}: &lt; 6100 W
            </ContentText>
          </div>
        }
      />
    </>
  );
};

export default Specifications;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "" : "-0.18px")};
`;
