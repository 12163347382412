import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";

const MiddleImg = ({ middleImg }) => {
  const { isDesktop } = useDisplay();
  return (
    <Container>
      <Title>{middleImg.title}</Title>
      <Wrapper $isDesktop={isDesktop}>
        {middleImg.images.map((img, idx) => (
          <ImageContainer key={idx}>
            <Image src={img.image} />
            <Text>{img.text}</Text>
          </ImageContainer>
        ))}
      </Wrapper>
    </Container>
  );
};

export default MiddleImg;

const Container = styled.section`
  width: 100%;
  height: 100%;
`;

const Title = styled.h6`
  font-size: 2.3rem;
  font-weight: 700;
  margin-bottom: 1.8rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $isDesktop }) =>
    $isDesktop ? "repeat(2, 1fr)" : "1fr"};
  gap: 2.8rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5a636a;
  padding: 0.73rem;
`;
