export const imageDataProcessingData = {
  id: 5,
  createdAt: "2024-04-19T14:15:14.796Z",
  updatedAt: "2024-04-19T15:02:59.789Z",
  pageName: "영상자료처리장치",
  productName: "영상자료처리장치",
  subtitle: "IDHU : Image Data Handling Unit",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d06deee4-18de-46cd-b14b-c50667827d5e.png",
  serverFileName: "d06deee4-18de-46cd-b14b-c50667827d5e.png",
  originFileName: "영상자료처리장치 1.png",
  exposure: true,
  __productContents__: [
    {
      id: 78,
      createdAt: "2024-04-19T14:15:14.796Z",
      updatedAt: "2024-05-26T22:14:38.997Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>영상자료처리장치는 위성에 탑재된 관측센서로부터 입력되는 영상 신호(고속의 대용량 데이터)를 실시간으로 압축하여 저장하고,</p><p>암호화 및 부호화 처리하여 지상국으로 전송하는 장치입니다.</p><p>플래시 메모리 방식의 차세대 우주용 핵심소자를 적용하여 비휘발성 메모리 기반의 고용량이면서도 고속처리기술을 구현한 제품입니다.</p><p>지금까지의 제품에 비해 무게와 크기, 전력소모는 3분의 1 수준으로 줄이고 저장용량은 6배 늘어났습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 79,
      createdAt: "2024-04-19T14:15:14.796Z",
      updatedAt: "2024-05-26T22:14:39.022Z",
      title: "제원",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>ㆍ크기: 280 mm*336 mm*197 mm</p><p>ㆍ무게: 15 kg</p><p>ㆍ용량: 1.5 Tb</p><p>ㆍ소모전력: 75 W</p><p>ㆍ메모리: 플래시 메모리(비휘발성)</p>",
      __productContentImages__: [],
    },
    {
      id: 80,
      createdAt: "2024-04-19T14:15:14.796Z",
      updatedAt: "2024-05-26T22:14:39.118Z",
      title: "img1",
      orderNum: 3,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 5,
          createdAt: "2024-05-24T18:16:11.052Z",
          updatedAt: "2024-05-26T22:14:39.118Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/86c69802-0f46-4ea2-ab22-b0ac0a79279d.png",
          serverFileName: "86c69802-0f46-4ea2-ab22-b0ac0a79279d.png",
          originFileName: "영상자료01.png",
        },
        {
          id: 42,
          createdAt: "2024-05-26T22:14:39.584Z",
          updatedAt: "2024-05-26T22:14:39.584Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d2709ce2-88c4-4c5c-b6d4-7aa5d189bd7f.png",
          serverFileName: "d2709ce2-88c4-4c5c-b6d4-7aa5d189bd7f.png",
          originFileName: "영상자료02.png",
        },
      ],
    },
    {
      id: 138,
      createdAt: "2024-05-24T18:16:11.367Z",
      updatedAt: "2024-05-26T22:14:39.596Z",
      title: "비휘발성 메모리",
      orderNum: 4,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>비휘발성 메모리는 대용량 저장 공간을 지원하며, 전원 공급이 중단되어도 데이터를 보존할 수 있습니다. 국내 최초 위성용 영상자료처리 장치에 적용하여 데이터 손실의 우려가 없습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 139,
      createdAt: "2024-05-24T18:16:11.386Z",
      updatedAt: "2024-05-26T22:14:39.625Z",
      title: "고속 데이터 처리",
      orderNum: 5,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>차세대 우주용 핵심소자를 적용한 데이터 병렬 처리 방식으로 고속의 데이터 처리가 가능합니다.</p><p>고속 데이터 송수신이 가능한 암호화 및 부호화 알고리즘을 구현하여 지상국과의 제한된 통신 시간 동안</p><p>최대한 많은 데이터의 송수신이 가능합니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 140,
      createdAt: "2024-05-24T18:16:11.415Z",
      updatedAt: "2024-05-26T22:14:39.647Z",
      title: "대용량, 소형/경량, 저전력화",
      orderNum: 6,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>중형급 위성 탑재용으로 개발된 제품으로, 1.5 Tb의 대용량화, 15 kg의 소형/경량화를 구현하였습니다.</p><p>소모전력 75 W의 저전력 제품으로 동일한 성능을 필요로 하는 기능에 비해 소형화, 경량화, 저전력화 측면에서</p><p>현재 세계 최고의 수준을 자랑합니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 141,
      createdAt: "2024-05-24T18:16:11.434Z",
      updatedAt: "2024-05-26T22:14:39.671Z",
      title: "가격경쟁력",
      orderNum: 7,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르에서 탑재체의 핵심 장치인 영상자료처리장치를 자체 기술로 개발하여, 첫 국산화에 성공했습니다.﻿</p><p>자체 개발을 통해 차별화된 스펙의 제품을 공급하면서도 가격 경쟁력을 실현합니다.</p>",
      __productContentImages__: [],
    },
  ],
};
