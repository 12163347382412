export const ServiceScopeLang = {
  title: {
    KR: "루미르의 우주산업 사업 범위를 소개합니다.",
    EN: "Let me introduce the scope of Lumir's space industry business.",
  },
  title2: {
    KR: "우주산업에 필요한 핵심 장비들을 보유하고 있습니다",
    EN: "We have key equipment needed for the space industry",
  },
  scope1: {
    KR: "루미르는 해외 수입 의존도가 높았던 TRM을 \n독자기술로 개발에 성공하여 생산 및 시험까지 인하우스에서 수행하고 있습니다.",
    EN: "Lumir succeeded in developing TRM, which was highly dependent on foreign imports,\nwith its own technology and is carrying out production and testing in-house.",
  },
  scope2: {
    KR: "루미르는 전기전자 제품의 핵심 부품인 PCB의 설계를\n인하우스에서 직접 수행하고 있습니다.",
    EN: "Lumir is directly designing PCB, a key component of electrical\nand electronic products, in-house.",
  },
  scope3: {
    KR: "루미르는 인공위성 개발에 필요한 기반 시설 및 제조 장비를 보유하고 있으며\n솔더링 작업까지 인하우스에서 직접 수행이 가능합니다.",
    EN: "Lumir has the infrastructure and manufacturing equipment needed to develop satellites,\nand even soldering can be done directly from the in-house.",
  },
  tab: {
    trm: {
      KR: "TRM 제조",
      EN: "TRM Manufacturing",
    },
  },
};
