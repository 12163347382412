import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const HoverZoom = ({ imageUrl, zoom = 3, initialPosition = null }) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });
  const [imageLoaded, setImageLoaded] = useState(false);
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setImageLoaded(true);
      if (imageRef.current) {
        imageRef.current.src = imageUrl;
        imageRef.current.style.width = "100%";
        imageRef.current.style.height = "auto";
      }
    };
  }, [imageUrl]);

  useEffect(() => {
    if (initialPosition && imageLoaded) {
      const wrap = wrapperRef.current;
      if (wrap) {
        const rect = wrap.getBoundingClientRect();
        handleMouseMove({
          clientX: rect.left + initialPosition.x,
          clientY: rect.top + initialPosition.y,
        });
        setShowMagnifier(true);
      }
    }
  }, [initialPosition, imageLoaded]);

  const handleMouseMove = (e) => {
    if (!imageLoaded) return;

    const wrap = wrapperRef.current;
    const image = imageRef.current;

    if (!wrap || !image) return;

    const rect = wrap.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (
      mouseX < wrap.clientWidth &&
      mouseY < wrap.clientHeight &&
      mouseX > 0 &&
      mouseY > 0
    ) {
      const magnifierSize = 150; // Magnifier width and height
      const px = mouseX - magnifierSize / 2;
      const py = mouseY - magnifierSize / 2;

      setMagnifierPosition({ x: px, y: py });

      const rx = -(mouseX * zoom - magnifierSize / 2);
      const ry = -(mouseY * zoom - magnifierSize / 2);

      setBackgroundPosition({ x: rx, y: ry });
      setShowMagnifier(true);
    } else {
      setShowMagnifier(false);
    }
  };

  return (
    <Wrapper
      ref={wrapperRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setShowMagnifier(false)}
    >
      {!imageLoaded && <LoadingPlaceholder>Loading...</LoadingPlaceholder>}
      <img
        ref={imageRef}
        alt="Target"
        style={{ display: imageLoaded ? "block" : "none" }}
      />
      {showMagnifier && imageLoaded && (
        <MagnifierGlass
          style={{
            left: `${magnifierPosition.x}px`,
            top: `${magnifierPosition.y}px`,
            backgroundImage: `url('${imageUrl}')`,
            backgroundPosition: `${backgroundPosition.x}px ${backgroundPosition.y}px`,
            backgroundSize: `${imageRef.current?.width * zoom}px ${
              imageRef.current?.height * zoom
            }px`,
          }}
        />
      )}
    </Wrapper>
  );
};

export default HoverZoom;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const LoadingPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 1.2em;
`;

const MagnifierGlass = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 0%;
  pointer-events: none;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85),
    0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
`;
