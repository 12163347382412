export const controlDeviceData = {
  id: 6,
  createdAt: "2024-04-19T14:16:03.656Z",
  updatedAt: "2024-04-19T15:02:29.494Z",
  pageName: "제어모멘텀자이로 제어장치",
  productName: "제어모멘텀자이로 제어장치",
  subtitle: "CMGC : Control Momentum Gyro Controller",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3e5d373e-b985-4303-9adb-6e483930e1a8.png",
  serverFileName: "3e5d373e-b985-4303-9adb-6e483930e1a8.png",
  originFileName: "자이로템펀 1.png",
  exposure: true,
  __productContents__: [
    {
      id: 84,
      createdAt: "2024-04-19T14:16:03.656Z",
      updatedAt: "2024-05-24T18:18:11.873Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>위성 제어 모멘텀 자이로는 위성 본체 자세제어계 (AOCS: Attitude and Orbit Control System)에 포함된 구동장치로,</p><p>기존의 인공위성 자세제어를 위하여 반작용 토크를 발생하는 반작용 휠 (reaction wheel)을 대체할 차세대 위성 구동 장치입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 85,
      createdAt: "2024-04-19T14:16:03.656Z",
      updatedAt: "2024-05-24T18:18:11.992Z",
      title: "img1",
      orderNum: 2,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 7,
          createdAt: "2024-05-24T18:18:12.167Z",
          updatedAt: "2024-05-24T18:18:12.167Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a2d7239f-4bc1-499d-9be2-b5928c3475b8.png",
          serverFileName: "a2d7239f-4bc1-499d-9be2-b5928c3475b8.png",
          originFileName: "Group 1410116480.png",
        },
      ],
    },
    {
      id: 86,
      createdAt: "2024-04-19T14:16:03.656Z",
      updatedAt: "2024-05-24T18:18:12.181Z",
      title: "제원",
      orderNum: 3,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>ㆍ대상위성: 400 – 600 kg 중형위성급</p><p>ㆍ크기: 250 x 230 x 95 mm</p><p>ㆍ무게: &lt; 4.0 kg</p><p>ㆍ소모전력: &lt; 15 W</p><p>ㆍ제어가능 회전 속도: &lt; 6000 RPM</p><p>ㆍ제어 정밀도: 14 bits, 8ch 동시</p><p>ㆍ내방사선: &lt; 35 krad</p>",
      __productContentImages__: [],
    },
    {
      id: 87,
      createdAt: "2024-04-19T14:16:03.656Z",
      updatedAt: "2024-05-24T18:18:12.216Z",
      title: "장치의 구성",
      orderNum: 4,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>제어모멘텀 휠 장치는 각운동량을 발생시키는 모멘텀 휠, 각운동량의 방향을 변화시키는 김발구동장치, 모멘텀 휠과 김발구동장치를 구동/제어하는 제어기, 제어모멘텀 휠 장치에서 발생하는 진동이 위성체로 전달되는 것을 저감 시키는 진동흡수장치로 구성됩니다.</p><p><br></p><p>제어기는 제어모멘텀 휠 장치에 전원을 공급하고, 상위 체계로부터 명령을 받아 장치를 제어하는 장치로</p><p>전원보드조립체, 제어보드조립체, 구동보드조립체로 구성되어 있습니다. </p><p><br></p><p>전원보드조립체는 상위 체계로부터 입력된 전원을 제어기에서 사용하는 전원으로 변환하여 공급합니다.</p><p>제어보드조립체는 FPGA를 기반으로 모터를 제어하며, 제어모멘텀 휠 장치에서 제어기로 입력되는 신호와 제어기 온도, 전류 등의</p><p>아날로그 신호들을 수집합니다. 구동보드조립체는 제어모멘텀 휠 장치로부터 신호를 입력 받아 제어모멘텀 휠 장치를 제어합니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 142,
      createdAt: "2024-05-24T18:18:12.244Z",
      updatedAt: "2024-05-24T18:18:12.244Z",
      title: "장치의 경쟁력",
      orderNum: 5,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르는 국내 위성사업에서 전량 수입에 의존하던 제어 모멘텀 휠 제어기 부분을 국산화를 달성했습니다.</p><p>기존 제품 대비 경량화/소형화 등 성능과 가격에서 경쟁력 우위를 확보하였으며, 내방사선 설계가 반영된 부품을 차용하여</p><p>위성의 QM (Qualification Model), FM (Flight Model)에 바로 반영 가능합니다.</p>",
      __productContentImages__: [],
    },
  ],
};
