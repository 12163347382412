export const catisData = {
  id: 22,
  createdAt: "2024-04-19T14:54:41.790Z",
  updatedAt: "2024-05-24T17:58:31.913Z",
  pageName: "CATIS™",
  productName: "CATIS™",
  subtitle: "소형 SAR 탑재체",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c6020765-6e70-4473-9feb-cad8d26fa2ad.png",
  serverFileName: "c6020765-6e70-4473-9feb-cad8d26fa2ad.png",
  originFileName: "Untitled-10 1.png",
  exposure: true,
  __productContents__: [
    {
      id: 101,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:28.582Z",
      title: "개요",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르는&nbsp;초고해상도&nbsp;소형&nbsp;인공위성용&nbsp;영상레이다&nbsp;장치&nbsp;CATIS™&nbsp;(Compact&nbsp;Any-Time&nbsp;Imaging&nbsp;System)을&nbsp;자체&nbsp;개발&nbsp;중에&nbsp;있습니다.</p><p>CATIS™은&nbsp;0.3&nbsp;m&nbsp;초고해상도&nbsp;X-Band&nbsp;영상레이다&nbsp;장치이며,&nbsp;고도&nbsp;500&nbsp;km의&nbsp;지구&nbsp;저궤도를&nbsp;돌며&nbsp;관측하고자&nbsp;하는&nbsp;영역에</p><p>광대역&nbsp;RF&nbsp;신호를&nbsp;방사하고&nbsp;반사되는&nbsp;신호를&nbsp;수신하여&nbsp;지구관측&nbsp;데이터를&nbsp;획득하는&nbsp;장치입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 102,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:28.611Z",
      title: "제품 상세",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>CATIS™는 루미르가 개발한 소형위성 탑재용 영상레이더 (SAR) 탑재체입니다.</p><p>SAR는 전파탑재체의 한 종류이며 특정 주파수의 전자파를 관측 대상에 쏘아서 반사된 전자파를 측정하여</p><p>관측 대상에 대한 영상 정보를 획득하는 장치입니다. SAR는 관측 신호를 스스로 발생하는 능동센서이므로, 전자광학 (EO) 탑재체나</p><p>적외선 (IR) 탑재체 등과는 달리 구름과 비 등의 기상 환경에 영향을 받지 않고 주야간 구분없이 관측이 가능합니다.</p><p>또한, SAR는 전자파의 위상 정보를 이용하여 관측 대상의 미세한 변형을 관측할 수 있으며</p><p>편파의 성질을 이용해 관측대상의 매질 정보도 취득할 수 있습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 103,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:28.635Z",
      title: "기능 · 용도 · 특징",
      orderNum: 3,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>영상레이다&nbsp;장치&nbsp;CATIS™는&nbsp;레이다&nbsp;신호&nbsp;송수신&nbsp;장치&nbsp;SCEU,&nbsp;레이다&nbsp;위상배열&nbsp;안테나&nbsp;SATA,</p><p>데이터&nbsp;다운링크&nbsp;위상배열&nbsp;안테나&nbsp;KaPA의 3가지&nbsp;구성품으로&nbsp;이루어집니다.&nbsp;</p><p><br></p><p><strong>레이다&nbsp;신호&nbsp;송수신&nbsp;장치&nbsp;SCEU</strong></p><p>영상레이다의&nbsp;해상도를&nbsp;결정하는&nbsp;레이다&nbsp;신호를&nbsp;생성&nbsp;및&nbsp;수신하고&nbsp;영상레이다의&nbsp;동기신호를&nbsp;생성하는&nbsp;장치</p><p><br></p><p><strong>레이다&nbsp;위상배열&nbsp;안테나&nbsp;SATA</strong></p><p>레이다&nbsp;신호&nbsp;송수신&nbsp;장치에서&nbsp;생성된&nbsp;RF&nbsp;레이다&nbsp;신호를&nbsp;목표&nbsp;관측&nbsp;지역으로&nbsp;방사하고&nbsp;반사된&nbsp;RF&nbsp;레이다&nbsp;신호를&nbsp;수신하는&nbsp;장치&nbsp;</p><p><br></p><p><strong>데이터&nbsp;다운링크&nbsp;위상배열&nbsp;안테나&nbsp;KaPA</strong></p><p>획득된&nbsp;초고해상도&nbsp;SAR&nbsp;영상데이터를&nbsp;2&nbsp;Gbps의&nbsp;전송속도로&nbsp;지상으로&nbsp;전송하는&nbsp;장치</p>",
      __productContentImages__: [],
    },
    {
      id: 104,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:28.659Z",
      title: "img1",
      orderNum: 4,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 9,
          createdAt: "2024-05-24T18:25:24.277Z",
          updatedAt: "2024-05-24T18:25:24.277Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6d9703ba-d02c-4c96-8a27-6acc4e578200.png",
          serverFileName: "6d9703ba-d02c-4c96-8a27-6acc4e578200.png",
          originFileName: "Group 1410116975.png",
        },
      ],
    },
    {
      id: 105,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:28.721Z",
      title: "img2",
      orderNum: 5,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: "",
      __productContentImages__: [
        {
          id: 40,
          createdAt: "2024-05-26T22:13:28.721Z",
          updatedAt: "2024-05-26T22:13:28.721Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/68e607d5-b6e9-4089-aad7-22c02706304b.png",
          serverFileName: "68e607d5-b6e9-4089-aad7-22c02706304b.png",
          originFileName: "CATISkr_01.png",
        },
        {
          id: 41,
          createdAt: "2024-05-26T22:13:29.085Z",
          updatedAt: "2024-05-26T22:13:29.085Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/cf2cff82-b64c-45f9-8cf7-98b298f13f33.png",
          serverFileName: "cf2cff82-b64c-45f9-8cf7-98b298f13f33.png",
          originFileName: "CATISkr_02.png",
        },
      ],
    },
    {
      id: 106,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:29.097Z",
      title: "레이다 신호 송수신장치 SCEU",
      orderNum: 6,
      productContentType: "B",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/7e0a30fe-7a47-4de7-b245-1269a1be42d7.png",
      serverFileName: "7e0a30fe-7a47-4de7-b245-1269a1be42d7.png",
      originFileName: "Group 1410116724.png",
      content:
        "<p><strong>1,200 MHz의 광대역 신호 생성 기능</strong></p><p>SCEU는 세계 최고 수준인 1,200 MHz의 광대역 신호 생성 및 처리가 가능하며 타사의 위성과 비교하여 2배 이상 향상된</p><p>대역폭 성능을 보유하고 있습니다.</p><p>SCEU는 DDS 알고리즘 기반으로 디지털 신호를 생성하고 RF SoC (System On Chip)로 디지털 신호를 합성하여</p><p>1,200 MHz의 광대역 신호 생성 및 처리합니다.</p><p><br></p><p><strong>16 Tbit 대용량 저장 기능</strong></p><p>SCEU의 영상데이터 저장능력은 16 Tbit로 국내 실용급 위성인 다목적실용위성 6호의 저장용량과 비교하여</p><p>16배 향상된 성능을 보유하고 있습니다.</p><p><br></p><p><strong>2 Gbps 고속 데이터 전송을 위한 CCSDS SCCC 방식의 변조 기능</strong></p><p>SCEU는 영상 데이터 지상전송을 위해 CCSDS SCCC 표준의 ACM (Adaptive Coding &amp; Modulation)을 구현하였습니다.</p><p>SCEU는 Ka-Band 링크 환경에 따라 최적의 변조방식으로 자동 변경하여 2 Gbps의 전송성능을 유지합니다.</p><p><br></p><p><strong>3U PCB 사이즈 소형경량화 구현</strong></p><p>루미르가 자체 개발한 SCEU는 세계 최고 수준의 성능을 유지하면서 신호의 생성 및 수신, 획득한 영상데이터의 저장,</p><p>지상 전송을 위한 영상데이터 변조 기능을 디지털 기술로 통합하여 3U 크기의 1 유닛으로 소형 경량화한 제품입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 107,
      createdAt: "2024-04-19T14:54:41.790Z",
      updatedAt: "2024-05-26T22:13:29.120Z",
      title: "능동 위상배열 안테나 SATA",
      orderNum: 7,
      productContentType: "B",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/371e99cf-044f-4d63-9b80-4e45a97e8787.png",
      serverFileName: "371e99cf-044f-4d63-9b80-4e45a97e8787.png",
      originFileName: "Group 1410116725.png",
      content:
        "<p><strong>1,200 MHz의 광대역 대역폭 특성 구현</strong></p><p>SATA는 세계 최고 수준인 1,200 MHz의 광대역 대역폭 특성을 갖는 Ridge 도파관 형태의 고효율 능동 위상배열 안테나로</p><p>타사의 위성과 비교하여 2배 이상 향상된 대역폭 성능을 보유하고 있습니다.</p><p>광대역 Ridge 도파관의 저손실 안테나 구조로 안테나 이득 42 dBi를 구현하였고 일반적인 평판형 안테나와 이득 비교 시</p><p>1dB 이상의 향상된 성능을 보유하고 있습니다.</p><p><br></p><p><strong>3.2 kW 고출력 증폭 및 빔 조향 구현</strong></p><p>SATA는 파워매칭 및 RF HMIC 기술을 적용하여 전력손실을 최소화한 40 Watt 출력의 TRM 80개를</p><p>합성 첨두출력 3.2 kW의 고출력 성능을 구현하였으며 TRM의 개별 위상의 조정 기능으로 ± 10° 빔 조향을 지원합니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 144,
      createdAt: "2024-05-24T18:25:25.083Z",
      updatedAt: "2024-05-26T22:13:29.144Z",
      title: "데이터 다운링크 안테나 KaPA",
      orderNum: 8,
      productContentType: "B",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6f631c62-659b-4baa-a1a0-785e13ff629e.png",
      serverFileName: "6f631c62-659b-4baa-a1a0-785e13ff629e.png",
      originFileName: "Group 1410117021.png",
      content:
        "<p><strong>± 60°의 광각 빔 조향 구현</strong></p><p>KaPA는 방위각 및 고각방향으로 ± 60°의 전기적인 빔조향이 가능하며 지상국으로 영상데이터 전송 시</p><p>위성자세의 변경 없이 통신이 가능합니다.</p><p>KaPA는 8 채널 RF SoC의 6 bit 위상 조정 기능으로 0.1°의 빔 지향 정밀도를 갖는 ± 60° 빔 조향 성능을 구현하였습니다.</p><p><br></p><p><strong>66.6 dBm의 고출력 EIRP 구현</strong></p><p>KaPA는 영상데이터 전송을 위한 Ka-Band 링크 조건에서 연중 99.9%의 운용이 가능한 성능인</p><p>66.6 dBm의 고출력 EIRP로 영상데이터를 지상으로 전송합니다.</p><p>KaPA는 22 dBi 이상의 안테나 이득과 RF SoC로 지상전송신호를 46.6 dBm의 출력으로 증폭하여</p><p>66.6 dBm의 고출력 EIRP 성능을 구현하였습니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 145,
      createdAt: "2024-05-24T18:25:25.227Z",
      updatedAt: "2024-05-26T22:13:29.169Z",
      title: "img3",
      orderNum: 9,
      productContentType: "C",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content: null,
      __productContentImages__: [
        {
          id: 12,
          createdAt: "2024-05-24T18:25:25.281Z",
          updatedAt: "2024-05-24T18:25:25.281Z",
          fileUrl:
            "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a077351c-5e2d-441f-bf68-42af559153d2.png",
          serverFileName: "a077351c-5e2d-441f-bf68-42af559153d2.png",
          originFileName: "Group 1410117154.png",
        },
      ],
    },
    {
      id: 146,
      createdAt: "2024-05-24T18:25:25.296Z",
      updatedAt: "2024-05-26T22:13:29.190Z",
      title: "영상레이다 영상 활용 분야",
      orderNum: 10,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c3f5b191-530e-4237-9af2-309dd0f27d83.png"></p>',
      __productContentImages__: [],
    },
  ],
};
