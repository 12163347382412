import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PretendardText from "../../components/atoms/text/pretendardText";
import { useNavigate } from "react-router-dom";
import useDisplay from "../../hooks/useDisplay";
import { useRecoilValue } from "recoil";
import { langState } from "../../store/langState";

const TalentCard = ({ link, department, status, title, order, style }) => {
  const langValue = useRecoilValue(langState);

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const titleRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    if (titleRef.current) {
      setIsOverflowing(
        titleRef.current.scrollWidth > titleRef.current.clientWidth
      );
    }
  }, [title]);

  return (
    <CardWrapper
      style={{ ...style }}
      $isSmall={isSmall}
      $isMobile={isMobile}
      $isTablet={isTablet}
    >
      <OrderBox $isSmall={isSmall}>{order}</OrderBox>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingBottom: isMobile ? "15px" : "21px",
          paddingLeft: "10px",
          borderBottom: "1px solid #D7DCE2",
          marginTop: isSmall ? "69px" : "96px",
          marginBottom: isSmall ? "15px" : "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: isSmall ? "5px" : "7px",
            marginBottom: "15px",
          }}
        >
          <StatusBox $isSmall={isSmall}>{status}</StatusBox>
          <PretendardText
            style={{
              fontSize: isSmall ? "14px" : "18px",
              fontWeight: 600,
              lineHeight: isSmall ? "18.9px" : "26.1px",
              color: "#778088",
            }}
          >
            {department}
          </PretendardText>
        </div>
        <Title
          $isSmall={isSmall}
          ref={titleRef}
          title={isOverflowing ? title : undefined}
        >
          {title}
        </Title>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          // cursor: "pointer",
        }}
        onClick={() => navigation(link)}
      >
        <PretendardText
          style={{
            fontSize: isSmall ? "16.75px" : "22px",
            fontWeight: 500,
            lineHeight: isSmall ? "20.1px" : "26.25px",
            color: "#262c31",
          }}
          className="custom-cursor"
        >
          {langValue === "EN" ? "More" : "상세보기"}
        </PretendardText>
        <img
          src="/assets/icons/go-more.png"
          alt=""
          style={{
            width: isSmall ? 18.27 : 24,
            height: isSmall ? 18.27 : 24,
          }}
        />
      </div>
    </CardWrapper>
  );
};

export default TalentCard;

const CardWrapper = styled.div`
  width: ${(props) => (props.$isSmall ? "100%" : "440px")};
  height: ${(props) =>
    props.$isMobile ? "210px" : props.$isTablet ? "240px" : "285px"};
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #adb4ba;
  background-color: #fff;
  padding: ${(props) => (props.$isSmall ? "0 23px" : "0 30px")};
`;

const OrderBox = styled.div`
  width: ${(props) => (props.$isSmall ? "49px" : "65px")};
  height: ${(props) => (props.$isSmall ? "49px" : "65px")};
  border-radius: ${(props) => (props.$isSmall ? "0 0 15px 0" : "0 0 20px 0")};
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00aeef;
  font-size: ${(props) => (props.$isSmall ? "18.27px" : "24px")};
  font-weight: 800;
  line-height: ${(props) => (props.$isSmall ? "21.93px" : "28.64px")};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusBox = styled.div`
  padding: ${(props) => (props.$isSmall ? "3.8px 9.77px" : "6px 12px")};
  border-radius: 5px;
  border: 1px solid #00aeef;
  color: #00aeef;
  font-size: ${(props) => (props.$isSmall ? "12px" : "16px")};
  font-weight: ${(props) => (props.$isSmall ? "500" : "600")};
  line-height: ${(props) => (props.$isSmall ? "16.2px" : "19.09px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: ${(props) => (props.$isSmall ? "16px" : "24px")};
  font-weight: 600;
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "34.8px")};
  color: #262c31;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  padding: 5px 0;
`;
