import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { route } from "../../../router/route";

import useDisplay from "../../../hooks/useDisplay";

import PretendardText from "../../../components/atoms/text/pretendardText";
import VideoGalleryCard from "../../../components/organisms/relationOffice/videoGalleryCard";
import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import VideoGalleryCardM from "../../../components/organisms/relationOffice/videoGalleryCardM";
import PromotionApi from "../../../api/promotionApi";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { brochureLang } from "../../../lang/relation/brochureLang";

const VideoGallery = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const langValue = useRecoilValue(langState);

  const [swiper, setSwiper] = useState(null);

  const [tab, setTab] = useState([
    {
      title: brochureLang.video[langValue],
      link: route.videoGallery,
    },
    {
      title: brochureLang.brochure[langValue],
      link: route.brochure,
    },
  ]);

  const [activeTab, setActiveTab] = useState(0);
  const [videoList, setVideoList] = useState([
    {
      link: "RGURHh1v6RY",
      imageUrl: "/assets/imgs/youtube/1.jpg",
      videoCategory: "LUMIR",
      title: `루미르, 지구 관측 위성 '루미르X'로 다양한 관측 데이터 쏜다..."2026년 1기 올린다"`,
      createdAt: "2024/09/20",
    },
  ]);

  useEffect(() => {
    setTab([
      {
        title: brochureLang.video[langValue],
        link: route.videoGallery,
      },
      {
        title: brochureLang.brochure[langValue],
        link: route.brochure,
      },
    ]);

    // getVideoAPI();
  }, [langValue]);

  const [activeIndex, setActiveIndex] = useState(1);

  // const getVideoAPI = async () => {
  //   try {
  //     const data = {
  //       pageNo: 1,
  //       pageSize: 100,
  //       language: langValue,
  //       promotionType: "VIDEO",
  //     };

  //     const response = (await PromotionApi.GetList(data)).data.data;
  //     setVideoList(response.items);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_public_relation.png"
            : "/assets/imgs/main-section/public_relation.png"
        }
        title={mainBannerLang.publicRelationsOffice.title[langValue]}
        content={mainBannerLang.publicRelationsOffice.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />

      <div
        style={{
          padding: isMobile
            ? "80px 20px"
            : isTablet
            ? "100px 70px"
            : "120px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              marginBottom: isMobile ? "50px" : "62px",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {tab.map((item, index) => {
              return (
                <TabWrapper
                  $isMobile={isMobile}
                  $isTablet={isTablet}
                  key={index}
                  id={index}
                  $activeTab={activeTab}
                  onClick={() => {
                    if (item.title !== brochureLang.brochure[langValue]) {
                      navigate(item.link);
                    } else {
                      navigate(`${item.link}/1`);
                    }

                    setActiveTab(index);
                  }}
                >
                  <TabText
                    id={index}
                    $activeTab={activeTab}
                    $isMobile={isMobile}
                    $isTablet={isTablet}
                  >
                    {item.title}
                  </TabText>
                </TabWrapper>
              );
            })}
          </div>

          {videoList.length > 0 ? (
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Swiper
                style={{
                  width: "100%",
                }}
                spaceBetween={isMobile ? 20 : isTablet ? 80 : 200}
                slidesPerView={1}
                loop={false}
                onSwiper={(swiper) => {
                  setSwiper(swiper);
                }}
                onActiveIndexChange={(swiper) => {
                  setActiveIndex(swiper.activeIndex + 1);
                }}
              >
                {videoList.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {isSmall ? (
                        <VideoGalleryCardM data={item} />
                      ) : (
                        <VideoGalleryCard data={item} />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  position: isSmall ? "relative" : "absolute",
                  left: isSmall ? "unset" : 0,
                  bottom: isSmall ? "unset" : 90,
                  marginTop: isSmall ? 30 : 0,
                  zIndex: 999,
                }}
              >
                <img
                  onClick={() => {
                    swiper.slidePrev();
                  }}
                  src="/assets/icons/pagination/left_arrow02.svg"
                  style={{
                    width: 26,
                    height: 26,
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                />
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "26.1px",
                  }}
                >
                  {activeIndex}
                </PretendardText>
                <img
                  src="/assets/icons/pagination/vector.svg"
                  style={{
                    margin: "0px 5px",
                  }}
                />
                <PretendardText
                  style={{
                    color: "#778088",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "26.1px",
                  }}
                >
                  {videoList.length}
                </PretendardText>

                <img
                  onClick={() => {
                    swiper.slideNext();
                  }}
                  src="/assets/icons/pagination/right_arrow02.svg"
                  style={{
                    width: 26,
                    height: 26,
                    cursor: "pointer",
                    marginLeft: 15,
                  }}
                />
              </div>
            </div>
          ) : (
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "21px",
                marginTop: isMobile ? 20 : isTablet ? 40 : 60,
                textAlign: "center",
              }}
            >
              {brochureLang.empty[langValue]}
            </PretendardText>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoGallery;

const TabText = styled(PretendardText)`
  color: ${(props) => (props.$activeTab === props.id ? "#262c31" : "#adb4ba")};
  position: relative;
  bottom: ${(props) => (props.$activeTab === props.id ? "-1px" : "0")};
  font-size: ${(props) =>
    props.$isMobile ? "15px" : props.$isTablet ? "18px" : "20px"};

  font-weight: 600;

  line-height: ${(props) =>
    props.$isMobile ? "21px" : props.$isTablet ? "26px" : "29px"};

  letter-spacing: -0.2px;
  text-align: center;
`;

const TabWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$isTablet ? "340px" : "375px"};
  height: ${(props) => (props.$isMobile ? "41px" : "59px")};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$activeTab === props.id ? "3px solid #00AEEF" : "1px solid #ADB4BA"};
`;
