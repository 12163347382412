import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

import PretendardText from "../../components/atoms/text/pretendardText";
import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";

const ArticleCard = ({ src, author, date, title, style, link, id }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  return (
    <CardWrapper
      $isMobile={isMobile}
      $isTablet={isTablet}
      style={{ ...style }}
      onClick={() => {
        if (link) {
          window.open(link, "_blank", "noopener, noreferrer");
        } else {
          navigation(`${route.lumirNewsDetail}/${id}`);
        }
      }}
      className="custom-cursor"
    >
      <ImageContainer $height={isMobile ? "186px" : "312px"}>
        {isLoading && (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        )}
        <StyledImage
          src={src}
          alt={title}
          loading="lazy"
          onError={(error) => {
            console.log("Image load failed:", src, error);
            setImageError(true);
            setIsLoading(false);
          }}
          onLoad={() => {
            setIsLoading(false);
          }}
          $isHidden={isLoading}
        />
        {imageError && (
          <ErrorWrapper>
            {/* <ErrorImage src="/assets/imgs/placeholder_img.png" alt="" /> */}
            <StyledImage
              src={"/assets/imgs/placeholder_img.png"}
              alt={title}
              loading="lazy"
              onError={(error) => {
                console.log("Image load failed:", src, error);
                setImageError(true);
                setIsLoading(false);
              }}
              onLoad={() => {
                console.log("Image loaded successfully");
                setIsLoading(false);
              }}
              $isHidden={isLoading}
            />
          </ErrorWrapper>
        )}
      </ImageContainer>

      <MetaWrapper $isMobile={isMobile} $isTablet={isTablet}>
        <MetaText $isMobile={isMobile} $isTablet={isTablet}>
          {author}
        </MetaText>
        <MetaText
          $isMobile={isMobile}
          $isTablet={isTablet}
          style={{ fontWeight: 400 }}
        >
          {date}
        </MetaText>
      </MetaWrapper>
      <Title $isMobile={isMobile} $isTablet={isTablet}>
        {title}
      </Title>
    </CardWrapper>
  );
};

export default ArticleCard;

const CardWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$isTablet ? "48%" : "440px"};
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* cursor: pointer; */
`;

const MetaWrapper = styled.div`
  display: flex;
  gap: ${(props) =>
    props.$isMobile ? "5.38px" : props.$isTablet ? "7px" : "10px"};
`;

const MetaText = styled(PretendardText)`
  font-size: ${(props) =>
    props.$isMobile || props.$isTablet ? "13px" : "14px"};
  font-weight: 600;
  line-height: ${(props) =>
    props.$isMobile || props.$isTablet ? "17.55px" : "18.9px"};
  color: #adb4ba;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) =>
    props.$isMobile ? "15px" : props.$isTablet ? "17px" : "20px"};

  font-weight: 600;
  line-height: ${(props) =>
    props.$isMobile ? "21px" : props.$isTablet ? "26px" : "29px"};

  color: #262c31;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.$height};
  background-color: #f5f5f5;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.$isHidden ? 0 : 1)};
  transition: opacity 0.3s ease;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;

const LoadingSpinner = styled.div`
  width: 32px;
  height: 32px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;

const ErrorImage = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
