// @ts-check

import {
  AuthAPI,
  NewsAPI,
  NoticeAPI,
  PageViewAPI,
  PressReleaseAPI,
  S3API,
} from "@lumir-company/admin-server-api";
import { tokenManager } from "./api-token-manager";

class ApiSDKRepository {
  constructor(config) {
    this.baseUrl = config.baseUrl;

    // Initialize APIs
    this.authApi = new AuthAPI(this.baseUrl);
    this.noticeApi = new NoticeAPI(this.baseUrl);
    this.newsApi = new NewsAPI(this.baseUrl);
    this.pressReleaseApi = new PressReleaseAPI(this.baseUrl);
    this.s3Api = new S3API(this.baseUrl);
    this.pageViewApi = new PageViewAPI(this.baseUrl);
  }

  async executeApiCall(apiInstance, methodName, ...args) {
    try {
      // 현재 토큰으로 먼저 시도
      apiInstance.setToken(tokenManager.getToken());
      return await apiInstance[methodName](...args);
    } catch (error) {
      // 401 에러면 토큰 갱신 후 재시도

      const newToken = await tokenManager.refreshToken();
      apiInstance.setToken(newToken);
      return await apiInstance[methodName](...args);
    }
  }

  /**
   * Notice API methods
   * @param {*} page
   * @param {*} limit
   * @returns {Promise<import("./types").NoticeListResponseDTO>}
   */
  async getNotices(page, limit) {
    const { data: notices } = await this.executeApiCall(
      this.noticeApi,
      "getNotices",
      page,
      limit
    );
    return notices;
  }

  async getNoticeById(noticeId) {
    return this.executeApiCall(this.noticeApi, "getNoticeById", noticeId);
  }

  /**
   * @param {number} page
   * @param {number} limit
   * @returns {Promise<import("./types").NewsListResponseDTO>}
   */
  async getNews(page, limit) {
    const response = await this.executeApiCall(
      this.newsApi,
      "getNews",
      page,
      limit
    );

    return {
      items: response.data.items,
      total: response.data.total,
      page: response.data.page,
      limit: response.data.limit,
      hasMore: response.data.hasMore,
    };
  }

  /**
   * @param {*} newsId
   * @returns {Promise<import("./types").NewsItemDTO>}
   */
  async getNewsById(newsId) {
    const { data: news } = await this.executeApiCall(
      this.newsApi,
      "getNewsById",
      newsId
    );
    return news;
  }

  /**
   *
   * @param {*} page
   * @param {*} limit
   * @returns {Promise<import("./types").GetPressReleasesResponseDTO>}
   */
  async getPressReleases(page, limit) {
    const { data: news } = await this.executeApiCall(
      this.pressReleaseApi,
      "getPressReleases",
      page,
      limit
    );
    return news;
  }

  async getPressReleaseById(pressReleaseId) {
    return this.executeApiCall(
      this.pressReleaseApi,
      "getPressReleaseById",
      pressReleaseId
    );
  }

  // S3 API methods
  async getFile(fileKey) {
    return this.executeApiCall(this.s3Api, "getFile", fileKey);
  }

  // Page View API methods
  /**
   * 새로운 페이지 뷰를 생성하는 함수
   * @param {import("./types").CreatePageViewParams} params - 페이지 뷰 생성에 필요한 파라미터
   * @returns {Promise<import("./types").PageViewResponseDto>} - 생성된 페이지 뷰
   */
  async createPageView(params) {
    return this.executeApiCall(this.pageViewApi, "createPageView", params);
  }

  /**
   * 새로운 페이지 뷰를 생성하는 함수
   * @param {import("./types").UpdatePageViewDto} params - 페이지 뷰 생성에 필요한 파라미터
   * @returns {Promise<import("./types").PageViewResponseDto>} - 생성된 페이지 뷰
   */
  async updatePageView(params) {
    return this.executeApiCall(this.pageViewApi, "updatePageView", params);
  }
}

export const apiSDKRepository = new ApiSDKRepository({
  baseUrl: process.env.REACT_APP_API_SDK_URL,
});
