import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";

import PretendardText from "../../../components/atoms/text/pretendardText";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { financialInfoLang } from "../../../lang/investment/financialInfoLang";

const GovernanceReport = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [dataList, setDataList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_review_report.png"
            : "/assets/imgs/main-section/review_report.png"
        }
        title={mainBannerLang.governanceReport.title[langValue]}
        content={mainBannerLang.governanceReport.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {financialInfoLang.gnReportTitle[langValue]}
        </PretendardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: "wrap",
            marginTop: isMobile ? 40 : 30,
            marginBottom: isMobile ? 50 : 120,
            gap: isMobile ? "0px" : isTablet ? "30px 12px" : "30px 32px",
          }}
        >
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              2023{langValue === "KR" ? "년" : ""}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              2022{langValue === "KR" ? "년" : ""}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
          <QuarterWrapper $isMobile={isMobile} $isTablet={isTablet}>
            <QuarterText $isSmall={isSmall}>
              2021{langValue === "KR" ? "년" : ""}
            </QuarterText>
            <img
              style={{
                cursor: "pointer",
                width: isTablet ? 30 : 34,
                height: isTablet ? 30 : 34,
              }}
              src="/assets/icons/download02.svg"
            />
          </QuarterWrapper>
        </div>

        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isSmall ? "18px" : "24px",
            fontWeight: 600,
            lineHeight: isSmall ? "26.1px" : "34.8px",
          }}
        >
          {financialInfoLang.bnReportPre[langValue]}
        </PretendardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",

            flexWrap: "wrap",
            marginTop: isMobile ? 40 : 30,
            marginBottom: isMobile ? 10 : 60,
            gap: isSmall ? "0px" : "30px 32px",
          }}
        >
          {dataList.length > 0
            ? dataList.map((item, index) => {
                return (
                  <QuarterWrapper
                    key={index}
                    $isMobile={isMobile}
                    $isTablet={isTablet}
                    style={{
                      width: isSmall ? "100%" : 440,
                      height: isSmall ? 90 : 85,
                      marginBottom: isSmall ? 30 : 0,
                    }}
                  >
                    <div>
                      <QuarterText $isSmall={isSmall}>
                        {2020 - index}
                        {langValue === "KR" ? "년" : ""}
                      </QuarterText>
                      {isSmall ? (
                        <PretendardText
                          style={{
                            color: "#5A636A",
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "17.55px",
                            marginTop: 8,
                          }}
                        >
                          {financialInfoLang.gnReportReport[langValue]}
                        </PretendardText>
                      ) : null}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {isSmall ? null : (
                        <PretendardText
                          style={{
                            color: "#5A636A",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "18.9px",
                            marginRight: 10,
                          }}
                        >
                          {financialInfoLang.gnReportReport[langValue]}
                        </PretendardText>
                      )}

                      <img
                        style={{
                          cursor: "pointer",
                          width: isTablet ? 30 : 34,
                          height: isTablet ? 30 : 34,
                        }}
                        src="/assets/icons/download02.svg"
                      />
                    </div>
                  </QuarterWrapper>
                );
              })
            : null}
        </div>

        <MoreBtn $isSmall={isSmall}>
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: isSmall ? "15px" : "18px",
              fontWeight: 600,
              lineHeight: isSmall ? "21px" : "26.1px",
            }}
          >
            {financialInfoLang.more[langValue]}
          </PretendardText>
        </MoreBtn>
      </BodyConatin>
    </>
  );
};

export default GovernanceReport;

const QuarterWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$isTablet ? "32%" : "440px"};
  height: ${(props) => (props.$isMobile ? "74px" : "85px")};

  border: 1px solid rgb(173, 180, 186);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.$isTablet || props.$isMobile ? "0px 30px" : "0px 31px"};
  margin-bottom: ${(props) => (props.$isMobile ? "30px" : "0px")};
`;

const QuarterText = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) => (props.$isSmall ? "16px" : "20px")};
  font-weight: ${(props) => (props.$isSmall ? "600" : "500")};
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "29px")};
`;

const MoreBtn = styled.button`
  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
