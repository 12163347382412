export const bodyPowerMeterData = {
  id: 19,
  createdAt: "2024-04-19T14:30:26.337Z",
  updatedAt: "2024-05-24T14:58:58.921Z",
  pageName: "위성 본체 전력계",
  productName: "위성 본체 전력계",
  subtitle: "Satellite Bus Power SubsystemElectrical Ground Support Equipment",
  language: "KR",
  fileUrl:
    "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2baaac30-160b-408a-a624-0f8a640034f8.png",
  serverFileName: "2baaac30-160b-408a-a624-0f8a640034f8.png",
  originFileName: "위성-본체-전력계 1.png",
  exposure: true,
  __productContents__: [
    {
      id: 92,
      createdAt: "2024-04-19T14:30:26.337Z",
      updatedAt: "2024-05-24T18:20:13.225Z",
      title: "제품 상세",
      orderNum: 1,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>위성 본체 전력계 전기지상지원장비는 위성 전력계의 지상시험 및 검증을 위하여 위성의 종합 조립 및 성능 시험에 필요한</p><p>전력 공급과 위성에 비축된 전력을 소모하기 위한 부하 모사, 위성의 전원 계통 상태 모니터링, 신호 획득 및 모사,</p><p>태양전지판의 전력생성을 모사하는 장비입니다.</p>",
      __productContentImages__: [],
    },
    {
      id: 93,
      createdAt: "2024-04-19T14:30:26.337Z",
      updatedAt: "2024-05-24T18:20:13.252Z",
      title: "제원",
      orderNum: 2,
      productContentType: "A",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>ㆍ크기: 19인치 표준 랙, 22 U - 1 pcs, 35 U - 2 PCs</p><p>ㆍ무게: &lt; 900 kg</p><p>ㆍ위성체 Bus/배터리 전원공급: 5000 W / 80 V / 60 A (Full Load)</p><p>ㆍ태양전지판 전원공급 모사: 3채널 각각 900 W / 150 V / 15 A (Full Load)</p><p>ㆍ최대 소모전력: &lt; 16000 W</p><p>ㆍ인터페이스: 1553B, UART, Ethernet</p><p>ㆍ절전 등의 위험 상황을 감지하여 10분 이상의 자가 동력 장치 존재</p>",
      __productContentImages__: [],
    },
    {
      id: 94,
      createdAt: "2024-04-19T14:30:26.337Z",
      updatedAt: "2024-05-24T18:20:13.284Z",
      title: "장비의 특징",
      orderNum: 3,
      productContentType: "B",
      fileUrl: null,
      serverFileName: null,
      originFileName: null,
      content:
        "<p>루미르는 다목적실용위성 7호 (KOMPSAT-7) 전력계 EGSE 사업에 참여하여 기존에 턴 키 형태로 전량 외주 제작되던</p><p>태양전지배열기 시뮬레이터(SAS: Solar Array Simulator) 국산화 개발을 달성하였습니다.</p><p><br></p><p>성공적인 과업 완수가 EO/IR 위성의 전력계 EGSE 사업 수주로 이어지는 등</p><p>해당 분야에서 타사 대비 월등한 기술 실력으로 우위를 점하고 있습니다.</p>",
      __productContentImages__: [],
    },
  ],
};
