import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { rnd } from "../../../../../lang/renewal/performanceLang";
import { langState } from "../../../../../store/langState";
import FramerSlideIn from "../../../../../styles/framerSlideIn";
import BigImgContents from "./BigImgContents";
import Layout from "./Layout";

const Second = ({ isMobile }) => {
  const langValue = useRecoilValue(langState);
  const [data, setData] = useState(rnd(isMobile)[langValue].contents[1].info);

  useEffect(() => {
    setData(rnd(isMobile)[langValue].contents[1].info);
  }, [isMobile, langValue]);

  return (
    <Layout title={data.title}>
      <FramerSlideIn>
        <BigImgContents {...data} />
      </FramerSlideIn>
      <FramerSlideIn>
        <img
          src={data.imgs[0]}
          alt=""
          width={"100%"}
          style={{ marginBottom: "2rem" }}
        />
      </FramerSlideIn>
      <FramerSlideIn>
        <img src={data.imgs[1]} alt="" width={"100%"} />
      </FramerSlideIn>
      <ImageWrapper $isMobile={isMobile}>
        <FramerSlideIn>
          <img src={data.imgs[2]} alt="" width={"100%"} />
        </FramerSlideIn>
        <FramerSlideIn>
          <img src={data.imgs[3]} alt="" width={"100%"} />
        </FramerSlideIn>
      </ImageWrapper>
    </Layout>
  );
};

export default Second;

const ImageWrapper = styled.div`
  text-align: center;
  display: ${(props) => (props.$isMobile ? "flex" : "grid")};
  flex-direction: column;
  grid-template-columns: 5fr 4fr;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
`;
