import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";
import ReportDLCard from "../../molecules/investment/reportDLCard";
import PlayDLCard from "../../molecules/investment/playDLCard";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { performanceLang } from "../../../lang/investment/performanceLang";

function PACard({ style, title, date, number }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigation = useNavigate();
  const [isDetail, setIsDetail] = useState(false);
  const langValue = useRecoilValue(langState);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#fff",
            borderBottom: "1px solid #ADB4BA",
            height: isMobile ? 64 : 66,
            width: "100%",
            padding: isMobile ? "0px 8px" : isTablet ? "0px 20px" : "0px 30px",
            ...style,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 600,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              color: "#778088",
              width: isMobile ? 20 : isTablet ? 60 : 70,
            }}
          >
            {number}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 400,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              color: "#262C31",
              flex: 1,
            }}
          >
            {title}
            {langValue === "KR" ? "년" : ""}
          </PretendardText>

          {isDetail ? (
            <img
              onClick={() => {
                setIsDetail(false);
              }}
              style={{
                cursor: "pointer",
                height: isSmall ? 24 : 30,
                width: isSmall ? 24 : 30,
              }}
              src="/assets/icons/up_arrow.svg"
            />
          ) : (
            <img
              onClick={() => {
                setIsDetail(true);
              }}
              style={{
                cursor: "pointer",
                height: isSmall ? 24 : 30,
                width: isSmall ? 24 : 30,
              }}
              src="/assets/icons/down_arrow.svg"
            />
          )}
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              borderBottom: "1px solid #ADB4BA",
              width: "100%",
              padding: isMobile ? 0 : isTablet ? "0px 10px" : "30px 0px",
            }}
          >
            <QuarterWrapper
              $isSmall={isSmall}
              style={{
                paddingTop: isSmall ? 12 : 0,
                paddingLeft: isSmall ? 10 : 0,
              }}
            >
              <QuarterText $isSmall={isSmall}>
                {performanceLang.quarter1[langValue]}
              </QuarterText>

              <Division $isSmall={isSmall} />

              <PlayDLCard content={performanceLang.paData01[langValue]} />
              <PlayDLCard content={performanceLang.paData02[langValue]} />
              <ReportDLCard
                content={performanceLang.paData03[langValue]}
                style={{
                  marginBottom: 0,
                }}
              />
            </QuarterWrapper>
            <QuarterWrapper $isSmall={isSmall}>
              <QuarterText $isSmall={isSmall}>
                {performanceLang.quarter2[langValue]}
              </QuarterText>

              <Division $isSmall={isSmall} />

              <PlayDLCard content={performanceLang.paData01[langValue]} />
              <PlayDLCard content={performanceLang.paData02[langValue]} />
              <ReportDLCard
                content={performanceLang.paData03[langValue]}
                style={{
                  marginBottom: 0,
                }}
              />
            </QuarterWrapper>
            <QuarterWrapper $isSmall={isSmall}>
              <QuarterText $isSmall={isSmall}>
                {performanceLang.quarter3[langValue]}
              </QuarterText>

              <Division $isSmall={isSmall} />

              <PlayDLCard content={performanceLang.paData01[langValue]} />
              <PlayDLCard content={performanceLang.paData02[langValue]} />
              <ReportDLCard
                content={performanceLang.paData03[langValue]}
                style={{
                  marginBottom: 0,
                }}
              />
            </QuarterWrapper>
            <QuarterWrapper
              $isSmall={isSmall}
              style={{
                paddingRight: isSmall ? 10 : 0,
                marginBottom: isSmall ? 0 : 0,
                border: "none",
              }}
            >
              <QuarterText $isSmall={isSmall}>
                {performanceLang.quarter4[langValue]}
              </QuarterText>

              <Division $isSmall={isSmall} />

              <PlayDLCard content={performanceLang.paData01[langValue]} />
              <PlayDLCard content={performanceLang.paData02[langValue]} />
              <ReportDLCard
                content={performanceLang.paData03[langValue]}
                style={{
                  marginBottom: 0,
                }}
              />
            </QuarterWrapper>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default PACard;

const QuarterWrapper = styled.div`
  width: ${(props) => (props.$isSmall ? "100%" : "260px")};

  border-right: ${(props) => (props.$isSmall ? "none" : "1px solid #d7dce2")};
  border-bottom: ${(props) => (props.$isSmall ? "1px solid #d7dce2" : "none")};

  display: flex;
  flex-direction: column;

  padding: ${(props) => (props.$isSmall ? "0px 10px 16px 10px" : "0px 40px")};
  box-sizing: ${(props) => (props.$isSmall ? "border-box" : "content-box")};
  margin-bottom: ${(props) => (props.$isSmall ? "15px" : "0px")};
`;

const QuarterText = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) => (props.$isSmall ? "16px" : "20px")};
  font-weight: 600;
  line-height: ${(props) => (props.$isSmall ? "23.2px" : "29px")};
`;

const Division = styled(PretendardText)`
  width: 100%;
  margin: ${(props) => (props.$isSmall ? "15px 0px" : "20px 0px")};

  border-top: 1px solid rgb(173, 180, 186);
`;
