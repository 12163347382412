import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import SubMenu from "../molecules/subMenu";
import { useRecoilValue } from "recoil";
import { langState } from "../../store/langState";
import useDisplay from "../../hooks/useDisplay";

const MiddleNavBarMenu = ({
  index,
  depthTitle,
  depthList,
  openNum,
  setOpenNum,
}) => {
  const { isMobile, isMiddleNav } = useDisplay();
  const language = useRecoilValue(langState);

  return (
    <div
      style={{
        width: isMiddleNav ? "100%" : "",
        padding: isMobile ? "0 20px" : "",
        position: "relative",
      }}
    >
      <DepthWrapper
        language={language}
        onClick={() => (openNum === index ? setOpenNum(0) : setOpenNum(index))}
        className="custom-cursor"
      >
        <MenuText $isMobile={isMobile}>{depthTitle}</MenuText>
        <img
          src="/assets/icons/arrow-down.png"
          alt=""
          style={{
            // cursor: "pointer",
            transform: `${
              openNum === index ? "rotate(180deg)" : "rotate(0deg)"
            }`,
          }}
          className="custom-cursor"
        />
      </DepthWrapper>

      {openNum === index ? <SubMenu depthList={depthList} /> : null}
    </div>
  );
};

export default MiddleNavBarMenu;

const DepthWrapper = styled.div`
  gap: ${(props) => (props.language === "KR" ? "85px" : "40px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* cursor: pointer; */
`;

const MenuText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "12px" : "15px")};
  font-weight: ${(props) => (props.$isMobile ? 500 : 600)};
  line-height: 135%;
`;
