import { useState, useCallback } from "react";

const usePrefetch = () => {
  const [prefetchedUrls, setPrefetchedUrls] = useState(new Set());

  const prefetch = useCallback(
    async (url) => {
      if (prefetchedUrls.has(url)) return;

      try {
        const res = await fetch(url, {
          method: "GET",
          mode: "no-cors",
          headers: {
            Purpose: "prefetch", // 이 헤더를 추가하여 프리페치 요청을 식별합니다
          },
        });
        if (res.ok) {
          setPrefetchedUrls((prev) => new Set(prev).add(url));
        }
      } catch (error) {
        console.error("Prefetch failed:", error);
      }
    },
    [prefetchedUrls]
  );

  return prefetch;
};

export default usePrefetch;
