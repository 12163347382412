import React from "react";
import styled from "styled-components";
import PretendardText from "../../../components/atoms/text/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { useRecoilValue } from "recoil";
import RecruitList from "../components/recruit";
import { homeLang } from "../../../lang/home/homeLang";
import { useNavigate } from "react-router-dom";
import { route } from "../../../router/route";

const Recruit = () => {
  const langValue = useRecoilValue(langState);
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const navigation = useNavigate();

  return (
    <Section8 $isMobile={isMobile}>
      <SectionWrapper
        $isDesktop={isDesktop}
        $isTablet={isTablet}
        style={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? "15px" : "",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <SubTitleText
            style={{
              fontSize: isMobile ? "18px" : "",
              textAlign: isMobile ? "center" : "",
            }}
          >
            {isDesktop ? (
              <>{homeLang.section7.title_desktop[langValue]}</>
            ) : (
              <>{homeLang.section7.title[langValue]}</>
            )}
          </SubTitleText>
          <MoreBtn
            className="custom-cursor"
            $isMobile={isMobile}
            onClick={() => navigation(`${route.jobAnnounce}/1`)}
          >
            {homeLang.moreBtn[langValue]}
          </MoreBtn>
        </div>
        <RecruitList />
        <div
          style={{
            marginTop: isDesktop ? "80px" : "30px",
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <RecruitBox
            $isMobile={isMobile}
            $isDesktop={isDesktop}
            src="/assets/imgs/home/recruit1.png"
            onClick={() => navigation(route.talentType)}
          >
            <PretendardText
              style={{
                fontSize: isDesktop ? "24px" : "16px",
                fontWeight: "700",
                lineHeight: "150%",
                color: "#fff",
                marginBottom: isDesktop ? 0 : "24px",
                marginLeft: isDesktop ? 0 : "30px",
                whiteSpace: "pre-wrap",
                // cursor: "pointer",
              }}
              className="custom-cursor"
            >
              {homeLang.recruitbox.title1[langValue]}
            </PretendardText>
            {isDesktop ? null : (
              <img
                src="/assets/imgs/home/m_recruit1.png"
                alt=""
                style={{
                  width: "100%",
                  height: "90px",
                  objectFit: "cover",
                }}
              />
            )}
          </RecruitBox>
          <RecruitBox
            $isMobile={isMobile}
            $isDesktop={isDesktop}
            src="/assets/imgs/home/recruit2.png"
          >
            <a href="mailto:recruit@lumir.space">
              <PretendardText
                style={{
                  fontSize: isDesktop ? "24px" : "16px",
                  fontWeight: "700",
                  lineHeight: "150%",
                  color: "#fff",
                  marginBottom: isDesktop ? 0 : "24px",
                  marginLeft: isDesktop ? 0 : "30px",
                  whiteSpace: "pre-wrap",
                  cursor: "none",
                }}
              >
                {homeLang.recruitbox.title2[langValue]}
              </PretendardText>
            </a>
            {isDesktop ? null : (
              <img
                src="/assets/imgs/home/m_recruit2.png"
                alt=""
                style={{
                  width: "100%",
                  height: "90px",
                  objectFit: "cover",
                }}
              />
            )}
          </RecruitBox>
        </div>
      </SectionWrapper>
    </Section8>
  );
};

export default Recruit;

const Section8 = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.$isMobile ? "100px 0 80px 0" : "120px 0 120px 0"};
`;

const SectionWrapper = styled.div`
  width: ${(props) => (props.$isDesktop ? "100%" : "")};
  max-width: 1400px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${(props) => (props.$isDesktop ? "row" : "column")};
  padding: ${(props) =>
    props.$isDesktop ? "0" : props.$isTablet ? "0 70px" : "0 20px"};
`;

const SubTitleText = styled(PretendardText)`
  font-size: 24px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: -0.24px;
  white-space: pre-wrap;
`;

const MoreBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.$isMobile ? "11px 35px" : "14px 40px")};
  border: 1px solid #adb4ba;
  background-color: #fff;
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 600;
  line-height: 150%;
  /* cursor: pointer; */
`;

const RecruitBox = styled.div`
  width: 100%;
  min-height: ${(props) => (props.$isDesktop ? "150px" : "190px")};
  background-image: ${(props) => (props.$isDesktop ? `url(${props.src})` : "")};
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => (props.$isDesktop ? "" : "#00AEEF")};
  padding: ${(props) => (props.$isDesktop ? "40px" : 0)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$isDesktop ? "center" : "flex-end")};
`;
