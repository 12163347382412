import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { shareholderLang } from "../../../lang/investment/shareholderLang";

function GSMCardM({ style, data, number }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  const [isDetail, setIsDetail] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            background: "#fff",
            borderBottom: "1px solid #ADB4BA",
            width: "100%",
            padding: "20px 8px",
            ...style,
          }}
        >
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              color: "#778088",
              width: 20,
            }}
          >
            {number}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "23.2px",
              color: "#262C31",
              marginRight: 10,
              flex: 1,
            }}
          >
            {langValue === "KR"
              ? `${data.roundNum}기 주주총회 결과`
              : `Results of the ${data.roundNum}th General Shareholders' Meeting`}
          </PretendardText>

          {isDetail ? (
            <img
              onClick={() => {
                setIsDetail(false);
              }}
              style={{
                cursor: "pointer",
                width: 24,
                height: 24,
              }}
              src="/assets/icons/up_arrow.svg"
            />
          ) : (
            <img
              onClick={() => {
                setIsDetail(true);
              }}
              style={{
                cursor: "pointer",
                width: 24,
                height: 24,
              }}
              src="/assets/icons/down_arrow.svg"
            />
          )}
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderBottom: "1px solid #ADB4BA",
              width: "100%",
              padding: "20px 14px",
            }}
          >
            <PretendardText
              style={{
                color: "#000000",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "23.2px",
                marginBottom: 8,
              }}
            >
              {shareholderLang.meetingResult[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                color: "#5A636A",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18.9px",
                marginBottom: 23,
              }}
            >
              {data.content}
            </PretendardText>
            <div
              style={{
                width: "100%",
                height: 68,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#F9F9F9",
                borderTop: "1px solid #ADB4BA",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <PretendardText
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "21.6px",
                  flex: 1,
                  textAlign: "center",
                }}
              >
                {shareholderLang.meetingAgenda[langValue]}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "21.6px",
                  width: 80,
                  textAlign: "center",
                  marginRight: 10,
                }}
              >
                {shareholderLang.meetingApprovalRate[langValue]}
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: 12,
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18.9px",
                    marginBottom: 5,
                  }}
                >
                  {shareholderLang.meeting1stAgenda[langValue]}
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18.9px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: 200,
                  }}
                >
                  {data.firstAgenda}
                </PretendardText>
              </div>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  width: 80,
                  textAlign: "center",
                  marginRight: 10,
                }}
              >
                {data.firstRate}
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: 12,
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18.9px",
                    marginBottom: 5,
                  }}
                >
                  {shareholderLang.meeting2stAgenda[langValue]}
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18.9px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 200,
                  }}
                >
                  {data.secondAgenda}
                </PretendardText>
              </div>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  width: 80,
                  textAlign: "center",
                  marginRight: 10,
                }}
              >
                {data.secondRate}
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #ADB4BA",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: 12,
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18.9px",
                    marginBottom: 5,
                  }}
                >
                  {shareholderLang.meeting3stAgenda[langValue]}
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#5A636A",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "18.9px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 200,
                  }}
                >
                  {data.thirdAgenda}
                </PretendardText>
              </div>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  width: 80,
                  textAlign: "center",
                  marginRight: 10,
                }}
              >
                {data.thirdRate}
              </PretendardText>
            </div>

            <PretendardText
              style={{
                color: "#778088",
                fontSize: "11px",
                fontWeight: 400,
                lineHeight: "14.85px",
                marginTop: 10,
              }}
            >
              {data.reference}
            </PretendardText>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default GSMCardM;
