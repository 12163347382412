import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";
import ReportDLCard from "../../molecules/investment/reportDLCard";
import PlayDLCard from "../../molecules/investment/playDLCard";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { performanceLang } from "../../../lang/investment/performanceLang";

function EMCard({ style, title, date, place, number }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          width: "100%",
          padding: isTablet ? "20px 20px" : "20px 30px",
          ...style,
        }}
      >
        <PretendardText
          style={{
            fontSize: isTablet ? "16px" : "18px",
            fontWeight: 600,
            lineHeight: isTablet ? "23.2px" : "26.1px",
            color: "#778088",
            width: isTablet ? 60 : 70,
          }}
        >
          {number}
        </PretendardText>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <PretendardText
            style={{
              fontSize: isTablet ? "16px" : "18px",
              fontWeight: 600,
              lineHeight: isTablet ? "23.2px" : "26.1px",
              color: "#262C31",
              marginBottom: 20,
            }}
          >
            {title}
          </PretendardText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <PretendardText
              style={{
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 600,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                color: "#778088",
                marginRight: 15,
              }}
            >
              {performanceLang.date[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 400,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                color: "#778088",
              }}
            >
              {date}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 600,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                color: "#778088",
                marginRight: 15,
              }}
            >
              {performanceLang.place[langValue]}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isTablet ? "14px" : "16px",
                fontWeight: 400,
                lineHeight: isTablet ? "18.9px" : "23.2px",
                color: "#778088",
              }}
            >
              {place}
            </PretendardText>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #BFBFBF",
            padding: "16px 20px 17px",
          }}
        >
          <PlayDLCard content={performanceLang.paData01[langValue]} />
          <PlayDLCard content={performanceLang.paData02[langValue]} />
          <ReportDLCard
            content={performanceLang.paData03[langValue]}
            style={{
              marginBottom: 0,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default EMCard;
