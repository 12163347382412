import React, { useState, useEffect } from "react";

import styled from "styled-components";
import PretendardText from "../../atoms/text/pretendardText";
import Checkbox from "../../atoms/checkbox/checkbox";
import useDisplay from "../../../hooks/useDisplay";
import { fileDownload } from "../../../util/file-management";

function BrochureCard({
  title,
  imgSrc,
  date,
  style,
  serverFileName,
  originFileName,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "100%" : isTablet ? "48%" : 320,
          ...style,
        }}
      >
        <div
          onMouseOver={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          style={{
            marginBottom: 10,
            position: "relative",
          }}
        >
          <img
            src={imgSrc}
            style={{
              width: "100%",
              height: 450,
              filter: mouseOver ? "brightness(50%)" : "",
            }}
          />

          {mouseOver ? (
            <DownloadBtn
              $isSmall={isSmall}
              onClick={() => {
                // fileDownload(serverFileName, originFileName);
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "15px" : "18px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "21px" : "26.1px",
                }}
              >
                Download
              </PretendardText>
            </DownloadBtn>
          ) : null}
        </div>
        <PretendardText
          style={{
            fontSize: isSmall ? "16px" : "20px",
            fontWeight: 600,
            lineHeight: isSmall ? "23.2px" : "29px",
            color: "#262C31",
            whiteSpace: "pre-wrap",
            marginBottom: 10,
          }}
        >
          {title}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18.9px",
            color: "#ADB4BA",
            whiteSpace: "pre-wrap",
          }}
        >
          {date}
        </PretendardText>
      </div>
    </>
  );
}

export default BrochureCard;

const DownloadBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
