import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import BodyConatin from "../../../components/molecules/layout/bodyContain";

import PreparedModal from "../../modal/preparedModal";
import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { useRecoilValue } from "recoil";
import { mainBannerLang } from "../../../lang/lang";
import DartLinkApi from "../../../api/dartLinkApi";
import PretendardText from "../../../components/atoms/text/pretendardText";
import { financialInfoLang } from "../../../lang/investment/financialInfoLang";
import styled, { keyframes } from "styled-components";
import { route } from "../../../router/route";

const ElectronicAnnounce = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const langValue = useRecoilValue(langState);
  const [darkLinkData, setDarkLinkData] = useState();

  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    const currentIframe = iframeRef.current;

    const handleLoad = () => {
      setLoading(false);
    };

    if (currentIframe) {
      currentIframe.addEventListener("load", handleLoad);
    }

    return () => {
      if (currentIframe) {
        currentIframe.removeEventListener("load", handleLoad);
      }
    };
  }, []);

  // useEffect(() => {
  //   getDartLinkAPI();
  // }, [langValue]);

  // const getDartLinkAPI = async () => {
  //   try {
  //     let response;
  //     if (langValue === "KR") {
  //       response = (await DartLinkApi.GetKR()).data.data;
  //     } else {
  //       response = (await DartLinkApi.GetEN()).data.data;
  //     }

  //     setDarkLinkData(response);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_electronic_announce.png"
            : "/assets/imgs/main-section/electronic_announce.png"
        }
        title={mainBannerLang.electronicAnnouncement.title[langValue]}
        content={mainBannerLang.electronicAnnouncement.content[langValue]}
        blackText={true}
        className="default-cursor"
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin>
        {/* {darkLinkData && darkLinkData?.exposure ? ( */}
        {true ? (
          <div
            style={{
              width: `${isDesktop ? "55%" : "100%"}`,
              height: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {loading && (
              <LoadingOverlay>
                <Spinner />
              </LoadingOverlay>
            )}
            <iframe
              ref={iframeRef}
              src={route.dart}
              width="100%"
              height="600"
              style={{ border: "none", opacity: loading ? 0 : 1 }}
              title="루미르 회사 정보"
              className="overflow-hidden"
            />
            <div
              style={{
                width: "3%",
                height: "100%",
                backgroundColor: "white",
                position: "absolute",
                right: 0,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                color: "#262C31",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
                marginBottom: 25,
              }}
            >
              {financialInfoLang.prepared[langValue]}
            </PretendardText>
          </div>
        )}
      </BodyConatin>
    </>
  );
};

export default ElectronicAnnounce;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f3f4f6; */
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  border: 5px solid #e5e7eb;
  border-top-color: #3b82f6;
`;
