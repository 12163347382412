import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { tokenManager } from "./api/renewal/api-token-manager";
import "./App.css";
import Scrollbar from "./components/atoms/scrollbar/scrollbar";
import ExternalBrowserAlert from "./components/ExternalBrowserAlert";
import Footer from "./components/templates/footer";
import Header from "./components/templates/header/header";
import ScrollTop from "./components/templates/scrollTop";
import { menus } from "./constant/menu";
import useDisplay from "./hooks/useDisplay";
import { usePageViewTracking } from "./hooks/usePageViewTracking";
import { route } from "./router/route";
import Router from "./router/router";
import { langState } from "./store/langState";
import { menuState } from "./store/menuState";
import "./styles/animation.css";
import CustomCursor from "./styles/cursor/CustomCursorSam";
import GlobalStyle from "./styles/GlobalStyle";
import { getCookie } from "./util/cookies";
import ScrollToTop from "./util/scrollToTop";

function App() {
  const { isDesktop } = useDisplay();
  const [language, setLanguage] = useRecoilState(langState);
  const setMenuAtom = useSetRecoilState(menuState);
  const location = useLocation();

  usePageViewTracking();

  const isDefaultCursorPage = [
    route.electronicAnnounce,
    route.videoGallery,
    route.managementInfoDetail,
  ].some((path) => location.pathname.startsWith(path));

  const postVisitor = async () => {
    try {
      // await VisitApi.visitor(); //TODO: 오류 메시지로 인한 임시 주석처리
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    postVisitor();
  }, []);

  useEffect(() => {
    // 쿠키에 토큰이 없을 때만 새로운 토큰을 요청
    if (!getCookie("accessToken")) {
      tokenManager.refreshToken();
    }
  }, []);

  const getProductFunc = async () => {
    try {
      // const response = await getProducts(language);
      // setMenuAtom(response);
      setMenuAtom(menus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductFunc();
  }, [language]);

  useEffect(() => {
    if (isDesktop && !isDefaultCursorPage) {
      document.body.style.cursor = "none";
    } else {
      document.body.style.cursor = "auto";
    }
  }, [isDesktop, isDefaultCursorPage]);

  return (
    <>
      <Analytics />
      <GlobalStyle isDefaultCursorPage={isDefaultCursorPage} />
      <Scrollbar>
        <ScrollToTop />
        {isDesktop && !isDefaultCursorPage && (
          <CustomCursor isDefaultCursorPage={isDefaultCursorPage} />
        )}
        <ExternalBrowserAlert />
        <Header />
        <Router />
        <ScrollTop />
        <Footer />
      </Scrollbar>
      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
      />
    </>
  );
}

export default App;
