export const languageType = ["KR", "EN"];

export const financialInfoLang = {
  regulation: {
    KR: "공시정보관리규정",
    EN: "Regulation",
  },
  bnReportTitle: {
    KR: "2022년도 보고서",
    EN: "2022 Report",
  },
  bnReport1Quarter: {
    KR: "1분기",
    EN: "First Quarter",
  },
  bnReport2Quarter: {
    KR: "2분기",
    EN: "Second Quarter",
  },
  bnReport3Quarter: {
    KR: "3분기",
    EN: "Third Quarter",
  },
  bnReport4Quarter: {
    KR: "4분기",
    EN: "Fourth Quarter",
  },
  bnReportPre: {
    KR: "이전 보고서",
    EN: "Previous Report",
  },
  rvReportTitle: {
    KR: "2023년도 보고서",
    EN: "2023 Report",
  },
  rvReportDL01: {
    KR: "감사/검토보고서",
    EN: "Audit/Review Report",
  },
  rvReportDL02: {
    KR: "재무상태표",
    EN: "Statement of Financial Position",
  },
  rvReportDL03: {
    KR: "손익계산서",
    EN: "Income Statement, Profit and Loss Account",
  },
  rvReportDL04: {
    KR: "현금흐름표",
    EN: "Cash Flow Statement",
  },
  rvReportDL05: {
    KR: "자본변동표",
    EN: "Table of Changes In Capital",
  },
  rvReportDL06: {
    KR: "주석",
    EN: "Premier",
  },
  gnReportTitle: {
    KR: "최근 3년 보고서",
    EN: "The Last Three-Year Report",
  },
  gnReportReport: {
    KR: "기업지배구조 보고서",
    EN: "Corporate Governance Report",
  },
  more: {
    KR: "더보기",
    EN: "More",
  },
  prepared: {
    KR: "해당 페이지는 준비 중입니다.",
    EN: "This page is being prepared.",
  },
};
