export const PAGE_NAMES = {
  PRODUCTS: {
    name: "제품",
    paths: ["/products"],
  },
  SERVICE: {
    name: "서비스",
    paths: ["/service"],
  },

  INTRODUCE: {
    name: "회사소개",
    paths: ["/introduce"],
  },
  INVESTMENT: {
    name: "투자정보",
    paths: ["/investment"],
  },
  CYBER_RELATION: {
    name: "사이버홍보",
    paths: ["/cyber-relation"],
  },
  RECRUIT: {
    name: "채용정보",
    paths: ["/recruit"],
  },
};

export const PAGE_NAMES_MAIN = {
  name: "메인 페이지",
  paths: ["/", "/home"],
};

export const pageTitleMapping = {
  // 홈
  "/": "루미르",
  "/home": "루미르",

  // 회사소개
  "/introduce/overview": "회사소개 | 루미르",
  "/introduce/history": "연혁 | 루미르",
  "/introduce/major-performance/heritage": "주요실적 | 루미르",
  "/introduce/workspace": "사업장 소개 | 루미르",
  "/introduce/direction": "오시는 길 | 루미르",

  // 제품/서비스
  "/products": "제품 | 루미르",
  "/service": "서비스 | 루미르",

  // 투자정보
  "/investment/financial-info/electronic-announcement": "공시자료 | 루미르",
  "/investment/financial-info/management-info": "전자공고 | 루미르",
  "/investment/shareholder/meeting": "주주총회 | 루미르",

  // 사이버홍보
  "/cyber-relation/new-article": "보도자료 | 루미르",
  "/cyber-relation/lumir-news": "루미르 뉴스 | 루미르",
  "/cyber-relation/lumir-news-detail": "루미르 뉴스 상세 | 루미르",
  "/cyber-relation/public-relations-office/video-gallery":
    "영상 갤러리 | 루미르",
  "/cyber-relation/public-relations-office/brochure": "브로슈어 | 루미르",

  // 채용정보
  "/recruit/talent-type": "인재상 | 루미르",
  "/recruit/job-announcement": "채용공고 | 루미르",
};
