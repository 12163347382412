import React, { useEffect, useState } from "react";
import MainSection from "../../../../components/templates/mainSection";
import MiddleNavBar from "../../../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import FramerFadeIn from "../../../../styles/framerFadeIn";
import styled from "styled-components";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import useDisplay from "../../../../hooks/useDisplay";
import { useRecoilValue } from "recoil";
import { langState } from "../../../../store/langState";
import { mainBannerLang } from "../../../../lang/lang";
import TypeA from "../../../../components/templates/product/typeA";
import TypeB from "../../../../components/templates/product/typeB";
import TypeC from "../../../../components/templates/product/typeC";
import { lumirVData } from "./data";

const LumirV = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [productName, setProductName] = useState("");
  const [pageName, setPageName] = useState("");
  const [img, setImg] = useState(false);
  const [subTitle, setSubTitle] = useState("");
  const [sortedPageName, setSortedPageName] = useState([]);

  const getProductFunc = async () => {
    // const response = await getProduct(langValue === "KR" ? 24 : 17);
    const response = lumirVData;

    setImg(response?.fileUrl);
    setSubTitle(response?.subtitle);
    setProductName(response.productName);
    setPageName(response.pageName);

    // setImg("/assets/imgs/business/lumir_v1.png");
    // setSubTitle("Hyperspectral Small Satellite");
    // setProductName("초소형 초분광 위성");
    // setPageName("Lumir V");

    sortAndSave(response);
  };

  const sortAndSave = async (response) => {
    const sortedComponentName = [];

    const sortedContent = response?.__productContents__?.sort(
      (a, b) => a.orderNum - b.orderNum
    );

    // const sortedContent = [
    //     {
    //         productContentType: 'A',
    //         title: '제품 배경',
    //         content: 'ㆍ루미르는 2022년 5월 정부의 ‘스페이스이노베이션 사업’의 초분광, 가시광 듀얼센서 카메라를 탑재한\n' +
    //             '초소형위성 개발 주관연구기관으로 선정되었습니다.\n' +
    //             '\n' +
    //             'ㆍ초분광 이미징 (HSI : Hyper-Spectral Imaging)은 가시광 및 적외선 영역의 256 주파수 대역 의 측정을 통해 대상 물질 고유의 재질 특성 식별이 가능합니다.\n' +
    //             '\n' +
    //             'ㆍ이 기술은 의학뿐만 아니라 식품산업 분야의 머신비전 및 스마트팜, 원격탐사, 군사 감시 등 일련의 모든 응용분야에서 사용되고 있으며\n' +
    //             '해외 시장에서도 높은 성장률을 보이고 있습니다.\n' +
    //             '\n' +
    //             'ㆍ루미르는 산기협에서 주관하는 기업부설연구소 R&D역량강화 지원사업에서 ‘초분광센서를 이용한 머신비전 시스템’\n' +
    //             '과제에 선정되었으며, 국내에서 전무한 초분광 이미징 시장의 국산화를 위해 개발 중에 있습니다.\n' +
    //             '\n' +
    //             'ㆍ루미르는 2022년 11월 전자통신연구원(ETRI)와 함께 하천의 녹조 감지 등의 목적으로 국내 최초로\n' +
    //             '드론용 초분광 카메라 시제품을 개발 완료 하였습니다. 또한 해당 실적들을 통해 현재 누리호 3차 위성에 탑재 예정인\n' +
    //             '초소형 초분광 카메라 개발에 있어서도 사업 목표달성에 지장이 없을 것으로 판단하고 있습니다. ',
    //         orderNum: 1
    //     },
    //     {
    //         productContentType: 'A',
    //         title: '제품 개요',
    //         content: '초분광 카메라 개발을 통한 국산화 및 초소형 초분광 위성 설계 및 제작',
    //         orderNum: 2
    //     },
    //     {
    //         productContentType: 'A',
    //         title: '사업 실적',
    //         content: 'ㆍ2022.11. 드론용 초분광 카메라 전자통신연구원(ETRI) 공동 개발 완료\n' +
    //             '\n' +
    //             'ㆍ2022.5. ‘스페이스이노베이션 사업’ 연구기관 선정\n' +
    //             '\n' +
    //             'ㆍ2022.4. ‘기업부설연구소 R&D 과제’ 초분광센서를 이용한 머신비전 시스템 연구 과제 선정',
    //         orderNum: 3
    //     },
    //     {
    //         productContentType: 'C',
    //         __productContentImages__:
    //             [
    //                 {
    //                     fileUrl: '/assets/imgs/business/lumir_rv_v0.png',
    //                 },

    //             ],
    //         orderNum: 4
    //     },
    //     {
    //         productContentType: 'B',
    //         title: '초분광 기술',
    //         content: '<b>초분광 카메라를 탑재하는 초소형 위성 Lumir-V과 드론용 Lumir-HSC-D\n </b>' +
    //             '\n' +
    //             '가시광 및 적외선 영역의 256 주파수 대역 측정을 통한 대상물질 고유의 재질 특성 식별' +
    //             '\n' +
    //             '\n',
    //         fileUrl: '/assets/imgs/business/lumir_rv_v1.png',
    //         orderNum: 5
    //     },
    //     {
    //         productContentType: 'B',
    //         title: '초분광 카메라 시험 Lumir-HSC-D',
    //         content: '<b>Lumir-HSC-D초분광 카메라를 이용한 녹조 감시\n </b>' +
    //             '\n' +
    //             '회남대교 ㅣ 주 1회 촬영 DB 구축 및 녹조 분석' +
    //             '\n' +
    //             '\n',
    //         fileUrl: '/assets/imgs/business/lumir_rv_v2.png',
    //         orderNum: 5
    //     },
    //     {
    //         productContentType: 'B',
    //         title: '초소형 Hyperspectral 위성',
    //         content: '',
    //         fileUrl: '/assets/imgs/business/lumir_rv_v3.png',
    //         orderNum: 5
    //     }, {
    //         productContentType: 'B',
    //         title: '초분광 영상 활용 분야',
    //         content: '',
    //         fileUrl: '/assets/imgs/business/lumir_rv_v4.png',
    //         orderNum: 5
    //     }
    // ]

    sortedContent?.map((item) => {
      switch (item.productContentType) {
        case "A":
          sortedComponentName.push({
            pageType: TypeA,
            title: item.title,
            content: item.content,
          });
          break;
        case "B":
          sortedComponentName.push({
            pageType: TypeB,
            title: item.title,
            content: item.content,
            fileUrl: item.fileUrl,
          });
          break;
        case "C":
          sortedComponentName.push({
            pageType: TypeC,
            imageSet: item.__productContentImages__,
          });
          break;
      }
    });

    if (sortedComponentName.length > 0) {
      setSortedPageName(sortedComponentName);
    }
  };

  useEffect(() => {
    getProductFunc();
  }, [langValue]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_lumir_v.png"
            : "/assets/imgs/main-section/lumir_v.png"
        }
        title={pageName}
        content={mainBannerLang.lumirV?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
        <ImgWrapper
          $isMobile={isMobile}
          style={{ marginBottom: isMobile ? "15px" : "10px" }}
        >
          <FramerFadeIn duration={1.5}>
            <img
              src={img ? img : "/assets/imgs/business/lumir_v1.png"}
              alt=""
              style={{ width: isMobile ? "102px" : "" }}
            />
          </FramerFadeIn>
        </ImgWrapper>
        <Title $isMobile={isMobile} $isTablet={isTablet}>
          {productName}
        </Title>
        <SubTitleWrapper $isMobile={isMobile} $isTablet={isTablet}>
          {subTitle}
        </SubTitleWrapper>
        {sortedPageName.map((Item, index) => {
          const PageComponent = Item.pageType;
          return (
            <PageComponent
              key={index}
              title={Item.title}
              content={Item.content}
              fileUrl={Item.fileUrl ? Item.fileUrl : null}
              imageSet={Item.imageSet ? Item.imageSet : null}
            />
          );
        })}
      </PageWrapper>
    </>
  );
};

export default LumirV;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const ImgWrapper = styled.div`
  width: ${(props) => (props.$isMobile ? "150px" : "250px")};
  height: ${(props) => (props.$isMobile ? "150px" : "250px")};
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #adb4ba;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) =>
    props.$isMobile ? "20px" : props.$isTablet ? "24px" : "34px"};
  font-weight: ${(props) => (props.$isMobile ? 600 : 500)};
  line-height: 145% px;
  color: #262c31;
  margin-bottom: 10px;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.2px" : "-0.68px")};
`;

const SubTitleWrapper = styled.div`
  padding: 8px 15px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) =>
    props.$isMobile ? "15px" : props.$isTablet ? "18px" : "22px"};
  font-weight: 400;
  line-height: 140%;
  color: #5a636a;
  margin-bottom: ${(props) => (props.$isMobile ? "40px" : "36px")};
`;
