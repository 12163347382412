import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import NumberDisplay from "./NumberDisplay";
import {
  constellation,
  CONSTELLATION_BG,
  EARTH,
  LAUNCH_ARROW,
  LUMIRX_MIDDLE,
} from "../../../../lang/renewal/homeLang";

gsap.registerPlugin(ScrollTrigger);

const Constellation = ({ langValue }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [containerRef, isVisible] = useIntersectionObserver({
    threshold: 0,
    rootMargin: "200px 0px 0px 0px",
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const earthSectionRef = useRef(null);
  const triggerRef = useRef([]);
  const yearRef = useRef([]);
  const earthRef = useRef(null);
  const scheduleRef = useRef(null);
  const [currentNumber, setCurrentNumber] = useState(1);
  const [currentYear, setCurrentYear] = useState("2026");

  useEffect(() => {
    if (isVisible && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isVisible, isLoaded]);

  useGSAP(() => {
    if (!isLoaded || !earthSectionRef.current || !scheduleRef.current) return;

    setupScrollTriggers();
  }, [isLoaded]);

  const setupScrollTriggers = () => {
    gsap.set(earthSectionRef.current, { y: isMobile ? 50 : 450 });

    const startPosition = isMobile ? "top top" : "top top-=450px";
    const endPosition = isMobile ? "bottom bottom" : "bottom bottom+=400px";

    ScrollTrigger.create({
      trigger: containerRef.current,
      start: startPosition,
      end: endPosition,
      pin: earthSectionRef.current,
      pinSpacing: false,
      anticipatePin: 1,
      scrub: true,
    });

    const scheduleStartPosition = isMobile
      ? "top top-=200px"
      : "top top-=450px";
    const scheduleEndPosition = isMobile
      ? "bottom bottom"
      : "bottom bottom+=300px";

    ScrollTrigger.create({
      trigger: containerRef.current,
      start: scheduleStartPosition,
      end: scheduleEndPosition,
      pin: scheduleRef.current,
      pinSpacing: false,
      anticipatePin: 1,
    });

    setupYearTriggers();
  };

  const setupYearTriggers = () => {
    constellation.schedule.forEach((_, index) => {
      const yearElement = yearRef.current.at(-1 - index);

      ScrollTrigger.create({
        trigger: triggerRef.current[index],
        start: "top center",
        end: "bottom center",
        onEnter: () => updateYearState(yearElement, index),
        onEnterBack: () => updateYearState(yearElement, index),
        onLeaveBack: () => {
          gsap.to(yearElement, { autoAlpha: 0.1, duration: 0.3 });
          updateCurrentValues(Math.max(0, index - 1));
        },
      });
    });
  };

  const updateYearState = (yearElement, index) => {
    gsap.to(yearElement, { autoAlpha: 1, duration: 0.3 });
    updateCurrentValues(index);
  };

  const updateCurrentValues = (index) => {
    setCurrentNumber(constellation.numberMapping[index]);
    setCurrentYear(constellation.yearMapping[index]);
  };

  const renderSchedule = () => (
    <Schedule ref={scheduleRef}>
      <ScheduleContainer $isTablet={isTablet} $isMobile={isMobile}>
        <div
          style={{
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          <ScheduleTitle $isMobile={isMobile}>
            <NumberDisplay value={currentNumber} yearValue={currentYear} />
          </ScheduleTitle>
          <ScheduleContent>
            <VerticalLine>
              <img src={LAUNCH_ARROW} alt="" />
            </VerticalLine>
            <ScheduleBox>
              {constellation.schedule
                .slice()
                .reverse()
                .map((schedule, index) => (
                  <YearContainer
                    key={index}
                    ref={(el) => (yearRef.current[index] = el)}
                  >
                    <Year>{schedule.year}</Year>
                    <LumirXContainer>
                      {[...Array(schedule.content)].map((_, i) => (
                        <LumirX
                          key={i}
                          src={LUMIRX_MIDDLE}
                          style={{
                            opacity: 1 - i * 0.3,
                            transform: isDesktop
                              ? `translateX(${i * +45}px)`
                              : `translateX(${i * +30}px)`,
                            zIndex: schedule.content - i,
                          }}
                        />
                      ))}
                    </LumirXContainer>
                    <Count>{schedule.content}</Count>
                  </YearContainer>
                ))}
            </ScheduleBox>
          </ScheduleContent>
        </div>
      </ScheduleContainer>
    </Schedule>
  );

  return (
    <Container ref={containerRef} $isMobile={isMobile} $isDesktop={isDesktop}>
      <ContentWrapper $isMobile={isMobile}>
        <LeftContents $isMobile={isMobile}>Constellation</LeftContents>
        <RightContents $isMobile={isMobile} $isDesktop={isDesktop}>
          <Content>
            <Title>{constellation[langValue].title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: constellation[langValue].description,
              }}
            />
          </Content>
          <Box>
            {isLoaded && renderSchedule()}
            <TriggerContainer>
              {constellation.schedule.map((schedule, index) => (
                <Trigger
                  key={index}
                  ref={(el) => (triggerRef.current[index] = el)}
                  $isMobile={isMobile}
                >
                  {schedule.year}
                </Trigger>
              ))}
            </TriggerContainer>
            <div style={{ height: "340vh" }}></div>
          </Box>
        </RightContents>
      </ContentWrapper>
      {isLoaded && (
        <EarthSectionWrapper>
          <EarthSection ref={earthSectionRef} $isMobile={isMobile}>
            <Earth ref={earthRef} $isMobile={isMobile} src={EARTH} />
          </EarthSection>
        </EarthSectionWrapper>
      )}
    </Container>
  );
};

export default Constellation;

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  padding: ${(props) =>
    !props.$isDesktop ? "9em 2em 9em 3em" : "10em 0 9em 13.5em"};
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.48),
      rgba(0, 0, 0, 0.48)
    ),
    linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)),
    url(${CONSTELLATION_BG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: ${(props) => (props.$isMobile ? "hidden" : "")};
`;

const ContentWrapper = styled.div`
  display: ${({ $isMobile }) => ($isMobile ? "block" : "flex")};
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  gap: 13vw;
`;

const LeftContents = styled.div`
  width: fit-content;
  position: ${({ $isMobile }) => ($isMobile ? "static" : "sticky")};
  top: 4em;
  height: 100%;
  padding-bottom: ${({ $isMobile }) => ($isMobile ? "1em" : "70vh")};
  font-size: 2.5em;
  font-weight: 600;
  color: white;
  // 글자 그림자
  /* text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);s */
  /* position: relative; // sticky 대신 relative로 변경 */
  z-index: 2; // LeftContents에 z-index 추가
`;

const RightContents = styled.div`
  position: relative;
  width: ${({ $isMobile }) => ($isMobile ? "" : "-webkit-fill-available")};
  display: flex;
  flex-direction: column;
  gap: ${({ $isDesktop }) => ($isDesktop ? "3rem" : "10rem")};
  z-index: 2; // LeftContents에 z-index 추가
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  color: white;
`;

const Title = styled.h4`
  font-size: 4.2em;
  text-align: left;
`;

const Description = styled.p`
  font-size: 1.3em;
  text-align: left;
  line-height: 1.5em;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
`;

const Schedule = styled.div`
  position: relative;
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
`;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $isMobile, $isTablet }) =>
    $isMobile ? "flex-start" : $isTablet ? "flex-end" : "center"};
  align-items: ${({ $isTablet }) => ($isTablet ? "flex-end" : "center")};
  width: 100%;
  height: 100vh;
  color: white;
  padding: 2em;
`;

const ScheduleTitle = styled.h4`
  display: flex;
  gap: 0.6em;
  font-size: ${({ $isMobile }) => ($isMobile ? "2.3em" : "2.5em")};
  margin-bottom: 2em;
`;

const ScheduleContent = styled.div`
  position: relative;
  display: flex;
  width: 80%;
  height: calc(100% - 5em);
  gap: 0.5em;
  z-index: 2;
`;

const VerticalLine = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;

  & > img {
    width: auto;
    height: 95%;
    object-fit: cover;
  }
`;

const ScheduleBox = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em 0;
`;

const YearContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  opacity: 0.1;
`;

const Year = styled.div`
  font-size: 1.5em;
  width: 5em;
  text-align: right;
  margin-right: 2em;
`;

const LumirXContainer = styled.div`
  position: relative;
  height: 60px;
  margin-right: 1em;
`;

const LumirX = styled.img`
  position: absolute;
  height: 100%;
  width: auto;
  transition: all 0.3s ease;
`;

const Count = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  visibility: hidden;
`;

const TriggerContainer = styled.div`
  position: relative;
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
`;

const Trigger = styled.div`
  /* width: 20em; */
  height: ${({ $isMobile }) => ($isMobile ? "80vh" : "50vh")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5em;
  color: white;
`;

const EarthSectionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
`;

const EarthSection = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.$isMobile ? "0" : "-10vw")};
  width: ${(props) => (props.$isMobile ? "100%" : "100%")};
  height: ${(props) => (props.$isMobile ? "100vw" : "130vh")};
  display: flex;
  z-index: 0;
  will-change: transform;
  visibility: ${(props) => (props.$isMobile ? "hidden" : "visible")};
`;

const Earth = styled.img`
  position: relative;
  bottom: ${(props) => (props.$isMobile ? "-50%" : "0")};
  left: ${(props) => (props.$isMobile ? "50%" : "0")};
  transform: ${(props) => (props.$isMobile ? "translate(-50%, -50%)" : "")};
  width: auto;
  max-height: 130%;
  object-fit: cover;
  z-index: -1;
  transition: all 1s ease-out;
`;
