import { useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

const FloatingImage = ({ width, children }) => {
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  useGSAP(() => {
    const image = imageRef.current;
    const container = containerRef.current;

    // 타임라인 생성
    const tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
    });

    // 부유 애니메이션
    tl.to(
      container,
      {
        y: "-=20px", // 위로 20px 이동
        duration: 2,
        ease: "power1.inOut",
      },
      0
    );

    // 약간의 크기 변화
    // tl.to(
    //   image,
    //   {
    //     scale: 1.0, // 5% 크기 증가
    //     duration: 2,
    //     ease: "power1.inOut",
    //   },
    //   0
    // );

    return () => {
      tl.kill(); // 컴포넌트 언마운트 시 애니메이션 정리
    };
  }, []);

  return (
    <ImageContainer ref={containerRef} $width={width}>
      <Floating ref={imageRef}>{children}</Floating>
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  width: ${(props) => (props.$width ? `${props.$width}em` : "100%")};
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Floating = styled.div`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export default FloatingImage;
