import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

const VideoHoverPlay = ({ imageUrl, videoUrl, hiddenIcons, index }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [containerRef, isVisible] = useIntersectionObserver({
    threshold: 0.1,
    root: null,
    rootMargin: "200px", // Start loading when video is 200px from viewport
  });
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.addEventListener("error", handleVideoError);
      return () => video.removeEventListener("error", handleVideoError);
    }
  }, []);

  const handleVideoError = () => {
    setVideoError(true);
  };

  const handleMouseEnter = () => {
    const video = videoRef.current;

    if (!video) return;

    setIsHovering(true);
    if (video && !videoError && isVisible) {
      video.play().catch((error) => {
        console.log("Error attempting to play video:", error);
      });
    }
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;

    if (!video) return;

    setIsHovering(false);
    if (video && !videoError) {
      video.pause();
      video.currentTime = 0;
    }
  };
  // TODO: IR 이후 주석 풀기
  return (
    <Container
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Image src={imageUrl} alt="Hover to play video" loading="lazy" />
      {!videoError && isVisible && (
        <Video ref={videoRef} loop muted playsInline $isHovering={isHovering}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      )}
      {!hiddenIcons.has(index) && (
        <Icon>
          <img src="/assets/icons/pointer.png" alt="" />
          <p>Click to play</p>
        </Icon>
      )}
    </Container>
  );
};

export default VideoHoverPlay;

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  /* object-fit: cover; */
  opacity: ${(props) => (props.$isHovering ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const Icon = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 4%;
  right: 2%;
  z-index: 10;

  img {
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
  }

  p {
    color: #fff;
    font-size: 10px;
    margin-top: 5px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
`;
