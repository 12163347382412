import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import useDisplay from "../../../../hooks/useDisplay";
import { route } from "../../../../router/route";
import { SARDIP_BG } from "../../../../lang/renewal/homeLang";

const SardipCard = () => {
  const { isMobile, isDesktop } = useDisplay();
  const navigation = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const gradationBoxRef = useRef(null);

  useEffect(() => {
    const currentRef = gradationBoxRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Container $isMobile={isMobile} $isDesktop={isDesktop}>
      <Wrapper>
        <Title>Near-Real-Time Earth Observation Data</Title>
        <Contents $isMobile={isMobile}>
          <GradationBox
            $isMobile={isMobile}
            ref={gradationBoxRef}
            $isVisible={isVisible}
          >
            <CardTitle>
              SARDIP™
              <br />
              Web Platform
            </CardTitle>
            <Button
              $bgColor={"#14A3E8"}
              onClick={() => navigation(route.service)}
              className="custom-cursor"
            >
              See more
            </Button>
          </GradationBox>
          <SardipView>
            <Image src={SARDIP_BG} alt="SARDIP" loading="lazy" />
          </SardipView>
        </Contents>
      </Wrapper>
    </Container>
  );
};

export default SardipCard;

const waveAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  /* 50% {
    transform: translateX(0);
  } */
  100% {
    transform: translateX(100%);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 0;
  background-color: white;
  padding: ${(props) =>
    !props.$isDesktop ? "9em 2em 9em 3em" : "9em 0 9em 0em"};
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1400px;
`;

const Title = styled.p`
  font-size: 2.5em;
  font-weight: 700;
`;

const Contents = styled.div`
  position: relative;
  width: 100%;
  height: ${({ $isMobile }) => ($isMobile ? "50vw" : "380px")};
  margin-top: ${({ $isMobile }) => ($isMobile ? "20px" : "40px")};
  border-radius: 30px;
  overflow: hidden;
`;

const GradationBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5em;
  position: absolute;
  width: ${({ $isMobile }) => ($isMobile ? "60%" : "56%")};
  height: 100%;
  background: linear-gradient(90deg, #00aeef 0%, rgba(127, 44, 167, 0.8) 100%);
  z-index: 2;
  padding: 0 5em;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: translateX(-100%);
    animation: ${waveAnimation} 4s ease-in-out infinite;
    animation-play-state: ${({ $isVisible }) =>
      $isVisible ? "running" : "paused"};
  }
`;

const CardTitle = styled.p`
  font-size: 2.5em;
  font-weight: 700;
  color: white;
`;

const SardipView = styled.div`
  position: absolute;
  top: 0;
  right: 0%;
  width: auto;
  height: 100%;
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;

const Button = styled.div`
  position: relative;
  z-index: 3;
  width: fit-content;
  border: 1px solid white;
  color: #fff;
  /* font-size: 1.5; */
  border-radius: 27px;
  padding: 14px 33px;
  transition: 0.3s;
  &:hover {
    background-color: white;
    color: ${(props) => `${props.$bgColor}`};
  }
`;
