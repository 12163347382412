const usePdfDownload = () => {
  const downloadPdf = async (pdf) => {
    const BASE_URL = process.env.PUBLIC_URL || "";

    const fileUrl =
      process.env.NODE_ENV === "production" ? `${BASE_URL}${pdf.src}` : pdf.src;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", pdf.downloadFileName || "download.pdf");
    link.setAttribute("target", "_blank"); // 새 탭에서 열기
    link.rel = "noopener noreferrer"; // 보안 개선
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { downloadPdf };
};

export default usePdfDownload;
