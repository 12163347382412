import React, { useEffect, useState } from "react";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "./../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../components/atoms/text/pretendardText";
import Scope1 from "./serviceScope/scope1";
import Scope2 from "./serviceScope/scope2";
import Scope3 from "./serviceScope/scope3";
import useDisplay from "../../hooks/useDisplay";
import ServiceScopeSwiper from "../../components/templates/serviceScope/serviceScopeSwiper";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";
import { ServiceScopeLang } from "../../lang/Business/serviceScope";
import { mainBannerLang } from "../../lang/lang";

const ServiceScope = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  const [tab, setTab] = useState(["TRM Manufacturing", "Artwork", "Soldering"]);

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const tabValue = [ServiceScopeLang.tab?.trm[langValue], "Artwork", "Soldering"];
    setTab(tabValue);
  }, [langValue]);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_service_scope.png"
            : "/assets/imgs/main-section/service_scope.png"
        }
        title={mainBannerLang.serviceScope?.title[langValue]}
        content={mainBannerLang.serviceScope?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
        <Title $isMobile={isMobile} $isTablet={isTablet}>
          {ServiceScopeLang.title[langValue]}
        </Title>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: isMobile ? "flex-start" : "center",
            flexWrap: isMobile ? "wrap" : "",
            margin: "30px 0 60px 0",
            gap: isMobile ? "15px 0" : "",
          }}
        >
          {tab.map((item, index) => {
            return (
              <TabWrapper
                $isMobile={isMobile}
                $isTablet={isTablet}
                $langValue={langValue}
                key={index}
                id={index}
                $activeTab={activeTab}
                onClick={() => setActiveTab(index)}
              >
                <TabText
                  $isMobile={isMobile}
                  $isTablet={isTablet}
                  id={index}
                  $activeTab={activeTab}
                >
                  {item}
                </TabText>
              </TabWrapper>
            );
          })}
        </div>
        {activeTab === 0 ? <Scope1 /> : activeTab === 1 ? <Scope2 /> : <Scope3 />}
      </PageWrapper>
      <PageWrapper style={{ backgroundColor: "#f9f9f9" }}>
        <Title $isMobile={isMobile} $isTablet={isTablet} style={{ marginBottom: "30px" }}>
          {ServiceScopeLang.title2[langValue]}
        </Title>
        <ServiceScopeSwiper />
      </PageWrapper>
    </>
  );
};

export default ServiceScope;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const Title = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "18px" : props.$isTablet ? "20px" : "24px")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.18px" : "-0.24px")};
  text-align: center;
`;

const ContentText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  color: #262c31;
  letter-spacing: -0.18px;
`;

const TabText = styled(PretendardText)`
  color: ${(props) => (props.$activeTab === props.id ? "#262c31" : "#adb4ba")};
  position: relative;
  bottom: ${(props) => (props.$activeTab === props.id ? "-1px" : "0")};
  font-size: ${(props) => (props.$isMobile || props.$isTablet ? "15px" : "20px")};
  font-weight: ${(props) => (props.$isMobile || props.$isTablet ? "600" : "500")};
  line-height: 140%;
  letter-spacing: ${(props) => (props.$isMobile ? 0 : "-0.2px")};
  text-align: center;
`;

const TabWrapper = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : props.$langValue === "KR" ? "180px" : "375px")};
  height: ${(props) => (props.$isMobile ? "41px" : "59px")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$activeTab === props.id ? "3px solid #00AEEF" : "1px solid #ADB4BA"};
`;
