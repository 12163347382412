import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import PretendardText from "../../atoms/text/pretendardText";
import RightLabelCheckbox from "../../molecules/checkbox/rightLabelCheckbox";

import useDisplay from "../../../hooks/useDisplay";
import TermsApi from "../../../api/termsApi";
import { inquiryLang } from "../../../lang/inquiryLang";
import { langState } from "../../../store/langState";

const TermsForm = ({ data, setList, list }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [termText, setTermText] = useState("");
  const [agree, setAgree] = useState(false);

  let temp = list;
  const langValue = useRecoilValue(langState);

  useEffect(() => {
    termsListAPI();
  }, [data]);

  const termsListAPI = async () => {
    try {
      const response = (await TermsApi.GetDetail(data.item)).data.data;

      setTermText(response.content);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <PretendardText
          style={{
            color: "#2F3941",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "23.2px",
            display: "contents",
          }}
        >
          {data.title}
        </PretendardText>
        {data.isRequired ? (
          <PretendardText
            style={{
              color: "#00AEEF",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "23.2px",
              display: "contents",
            }}
          >
            *
          </PretendardText>
        ) : null}
      </div>

      <div
        style={{
          border: "0.5px solid #B0B0B0",
          background: "#FAFAFA",
          minHeight: isMobile ? 194 : 145,
          maxHeight: isMobile ? 194 : 145,
          padding: 20,
          marginBottom: 10,
          overflowY: "auto",
        }}
      >
        <PretendardText
          style={{
            color: "#6B6B6B",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "16.2px",
          }}
        >
          {termText}
        </PretendardText>
      </div>
      <RightLabelCheckbox
        inputStyle={{
          marginBottom: 25,
        }}
        checked={agree}
        onChange={() => {
          let tempIndex = list.findIndex((item) => item.id === data.id);
          temp[tempIndex] = {
            ...data,
            value: !agree,
          };

          setList(temp);
          setAgree(!agree);
        }}
        text={inquiryLang.agreeCheckbox[langValue]}
      />
    </>
  );
};

export default TermsForm;
