import React from "react";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "./../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../components/atoms/text/pretendardText";
import FramerFadeIn from "../../styles/framerFadeIn";
import FramerSlideIn from "../../styles/framerSlideIn";
import useDisplay from "../../hooks/useDisplay";
import { mainBannerLang } from "../../lang/lang";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";
import { constellationLang } from "../../lang/Business/constellation";

const Constellation = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();

  const langValue = useRecoilValue(langState);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_constellation.png"
            : "/assets/imgs/main-section/constellation.png"
        }
        title={mainBannerLang.constellation?.title[langValue]}
        content={mainBannerLang.constellation?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper>
        <Section1 $isTablet={isTablet}>
          <SubTitle
            $isMobile={isMobile}
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            <span style={{ color: "#00AEEF" }}>Milestone</span>
            <br />
            {constellationLang.title1[langValue]}
          </SubTitle>
          <ContentText
            $isMobile={isMobile}
            style={{
              textAlign: "center",
              marginBottom: isMobile ? "45px" : "60px",
            }}
          >
            {constellationLang.content1[langValue]}
          </ContentText>
          <FramerFadeIn duration={1.5}>
            <img
              src="/assets/imgs/business/constellation1.png"
              alt=""
              style={{ maxWidth: "810px", width: isDesktop ? "" : "100%" }}
            />
          </FramerFadeIn>
        </Section1>
        <SectionWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
          <SubTitle
            $isMobile={isMobile}
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            Road Map
          </SubTitle>
          <ContentText
            $isMobile={isMobile}
            style={{
              textAlign: "center",
              marginBottom: isMobile ? "40px" : "50px",
            }}
          >
            {constellationLang.content2[langValue]}
          </ContentText>
          <FramerSlideIn>
            <img
              src="/assets/imgs/business/constellation2.png"
              alt=""
              style={{ maxWidth: "920px", width: isDesktop ? "" : "100%" }}
            />
          </FramerSlideIn>
        </SectionWrapper>
        <Section3 $isMobile={isMobile}>
          <SubTitle
            $isMobile={isMobile}
            style={{
              textAlign: "center",
              marginBottom: isMobile ? "15px" : "50px",
            }}
          >
            Sepcifications
          </SubTitle>
          <div>
            <ContentText
              $isMobile={isMobile}
              style={{
                width: "100%",
                marginBottom: isMobile ? "40px" : "20px",
              }}
            >
              ㆍSun-Synchronous Orbit at a Altitude of 510km
              <br />
              ㆍ1-hour Revisit Time (18 satellites on SSO)
            </ContentText>
            {isMobile ? (
              <img src="/assets/imgs/business/m_constellation3.png" alt="" />
            ) : (
              <img
                src="/assets/imgs/business/constellation3.png"
                alt=""
                style={{ maxWidth: "920px", width: isDesktop ? "" : "100%" }}
              />
            )}
          </div>
        </Section3>
      </PageWrapper>
    </>
  );
};

export default Constellation;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.$isDesktop ? "120px 0" : props.$isTablet ? "80px 70px" : "80px 20px"};
`;

const Section1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 174, 239, 0.08) 0%,
    rgba(0, 174, 239, 0) 0.01%,
    rgba(0, 174, 239, 0.08) 100%
  );
  padding: ${(props) => (props.$isTablet ? "0 70px" : "")};
  padding-top: 120px;
`;

const Section3 = styled.div`
  width: 100%;
  background-image: url("/assets/imgs/business/constellation_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${(props) => (props.$isTablet ? "0 70px" : "")};
  padding-bottom: ${(props) => (props.$isMobile ? "80px" : "120px")};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "18px" : "24px")};
  font-weight: 600;
  line-height: 145%;
  color: #262c31;
  letter-spacing: ${(props) => (props.$isMobile ? "-0.18px" : "-0.24px")};
`;

const ContentText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "15px" : "18px")};
  font-weight: 400;
  line-height: 140%;
  color: #5a636a;
  letter-spacing: -0.18px;
  white-space: pre-wrap;
`;
