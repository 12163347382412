import api from "./api";

export default class NewsApi {
  static async GetList(data) {
    return api.get(`news`, data);
  }

  static async GetDetail(id) {
    return api.get(`news/${id}`);
  }
}
