import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import MainSection from "../../../components/templates/mainSection";
import MiddleNavBar from "../../../components/templates/middleNavBar";
import BodyConatin from "../../../components/molecules/layout/bodyContain";
import PretendardText from "../../../components/atoms/text/pretendardText";
import SangsangshinText from "../../../components/atoms/text/sangsangshinText";

import useDisplay from "../../../hooks/useDisplay";
import { langState } from "../../../store/langState";
import { mainBannerLang } from "../../../lang/lang";
import { shareholderLang } from "../../../lang/investment/shareholderLang";

const Communication = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_communication.png"
            : "/assets/imgs/main-section/communication.png"
        }
        title={mainBannerLang.shareholderCommunication.title[langValue]}
        content={mainBannerLang.shareholderCommunication.content[langValue]}
        blackText={isMobile ? false : true}
      />
      <MiddleNavBar pathname={pathname} />

      <BodyConatin
        style={{
          padding: isMobile
            ? "57px 20px 80px"
            : isTablet
            ? "100px 70px"
            : "120px 0px 120px 0px",
        }}
        innerStyle={{
          maxWidth: isMobile ? "100%" : 1200,
          alignItems: "flex-start",
        }}
      >
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isMobile ? "20px" : "24px",
            fontWeight: isMobile ? 500 : 600,
            lineHeight: isMobile ? "29px" : "34.8px",
            whiteSpace: "pre-wrap",
          }}
        >
          {isMobile
            ? shareholderLang.cmTitle.mo[langValue]
            : shareholderLang.cmTitle.pc[langValue]}
        </PretendardText>

        <Division
          style={{
            margin: isMobile ? "30px 0px" : "32px 0px 30px",
          }}
        />

        <PretendardText
          style={{
            color: "#5A636A",
            fontSize: isMobile ? "15px" : "18px",
            fontWeight: 400,
            lineHeight: isMobile ? "21px" : "26.1px",
            whiteSpace: "pre-wrap",
          }}
        >
          {isMobile
            ? shareholderLang.cmContent01.mo[langValue]
            : shareholderLang.cmContent01.pc[langValue]}
        </PretendardText>

        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isMobile ? "15px" : "20px",
            fontWeight: 600,
            lineHeight: isMobile ? "21px" : "29px",
            marginTop: 30,
            marginBottom: 15,
          }}
        >
          {shareholderLang.cmShareholders[langValue]}
        </PretendardText>
        <PretendardText
          style={{
            color: "#5A636A",
            fontSize: isMobile ? "15px" : "18px",
            fontWeight: 400,
            lineHeight: isMobile ? "21px" : "26.1px",
            whiteSpace: "pre-wrap",
          }}
        >
          {shareholderLang.cmContent02[langValue]}
        </PretendardText>

        <SangsangshinText
          style={{
            color: "#262C31",
            fontSize: isMobile ? "31px" : "36px",
            fontWeight: 400,
            lineHeight: isMobile ? "31px" : "36px",
            marginTop: 30,
          }}
        >
          {shareholderLang.cmCEO[langValue]}
        </SangsangshinText>

        <div
          style={{
            margin: isMobile ? "30px 0px 40px" : "30px 0px 60px",
            borderTop: "1px solid #adb4ba",
            width: "100%",
          }}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
        >
          <div
            style={{
              background: "#00AEEF",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: 229,
              height: 54,
              cursor: "pointer",
            }}
          >
            <PretendardText
              style={{
                color: "#FFFFFF",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "26.1px",
                marginRight: 5,
              }}
            >
              {shareholderLang.downloadPDF[langValue]}
            </PretendardText>
            <img style={{}} src="/assets/icons/download.svg" />
          </div>
        </div>
      </BodyConatin>
    </>
  );
};

export default Communication;

const Division = styled.div`
  width: 100%;
  margin: 32px 0px 30px;
  border-top: 1px solid #adb4ba;
`;
